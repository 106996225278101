import React, { useEffect } from 'react';
import Repository from 'repositories/Repository';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

const { ExtendedResourceRepository } = Repository;
const SynopsisUpdate = ({children,synopsisInfo}) => {
    const { tab,list } = synopsisInfo;
    const [{ bookId }] = useStore(StoreTypes.books);
    const [,extendedContentDispatch] = useStore(
        StoreTypes.extendedContent
    );

    useEffect(()=>{
        // 寫入extendedContentStore - synopsisTab
        extendedContentDispatch({
            type: types.SET_EXTENDED_CONTENT_SYNOPSIS_TAB,
            payload: {
                synopsisTab : (tab - 1)
            }
        });
    },[extendedContentDispatch, tab]);

    useEffect(() => {
        Promise.all(list.map(lists => ExtendedResourceRepository.getJSONContent({ bookId, pathName: lists.src }))).then(obj => {
            // 寫入extendedContentStore - synopsisList
            extendedContentDispatch({
                type: types.SET_EXTENDED_CONTENT_SYNOPSIS_LIST,
                payload: {
                    synopsisList : obj
                }
            });
        })
    }, [bookId, extendedContentDispatch, list]);

    return (
        <div>{children}</div>
    )
}

export default SynopsisUpdate;
