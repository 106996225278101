import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useMusicModal } from 'components/common/MusicPlayer/MusicModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useReadAloudCommand = () => {
  const [, { closeModal }] = useMusicModal();
  const [, { openModal: externalWindownModal, setModalComponents }] = useModal();

  const execute = useCallback(async ({ json: { src, readAloudInfo } }) => {
    closeModal();
    if (readAloudInfo) {
      const component = ModalComponentGenerator.generate(ModalComponentType.ReadAloud, { src, readAloudInfo });
      setModalComponents(component);
      externalWindownModal();
    } else {
      alert('Please write vtt and mp3 path');
    }

  }, [closeModal, externalWindownModal, setModalComponents]);

  return { execute };
};
