import React, { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { ExtraFieldsForEditor } from 'constants/canvas'
import {
  MeasureToolType
} from 'constants/painterTypes';

export const MeasureToolsMap = {
  [MeasureToolType.Protractor]: '量角器',
  [MeasureToolType.LongRuler]: '長尺',
  [MeasureToolType.ShortRuler]: '短尺',
  [MeasureToolType.IsoscelesTriangle]: '等腰三角板',
  [MeasureToolType.RightTriangle]: '直角三角板',
}

export const MeasureToolsComponent = ({ object }) => {
  const measureToolType = object[ExtraFieldsForEditor.MeasureToolType] ? object[ExtraFieldsForEditor.MeasureToolType] : ""

  const changeHandler = useCallback((value) => {
    EventBus.emit({
      event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
      payload: { object, property: ExtraFieldsForEditor.MeasureToolType, value }
    });
  }, [object]);

  return (
    <>
      <select
        id="mathTools"
        value={measureToolType}
        onChange={e => changeHandler(e.target.value)}
      >
        {measureToolType === "" && (
          <option key={-1} value={''}>
            {
              "請選測量工具"
            }
          </option>
        )}
        {Object.values(MeasureToolType).map(
          (type, i) =>
            MeasureToolsMap[type] && (
              <option key={i} value={type}>
                {MeasureToolsMap[type]}
              </option>
            )
        )}
      </select>
    </>
  )
};