import { PlatformType } from 'constants/platformType';

export const getPlatform = () => {
  let platform = PlatformType.Web;

  if(window.electron) {
      return PlatformType.Electron;
  }

  if(process.env.REACT_APP_PLATFORM === PlatformType.IosDev) {
      return PlatformType.IosDev;
  }
  if(process.env.REACT_APP_PLATFORM === PlatformType.IosUat) {
      return PlatformType.IosUat;
  }
  if(process.env.REACT_APP_PLATFORM === PlatformType.IosRelease) {
      return PlatformType.IosRelease;
  }

  if(process.env.REACT_APP_PLATFORM === PlatformType.AndroidDev) {
      return PlatformType.AndroidDev;
  }
  if(process.env.REACT_APP_PLATFORM === PlatformType.AndroidUat) {
      return PlatformType.AndroidUat;
  }
  if(process.env.REACT_APP_PLATFORM === PlatformType.AndroidRelease) {
      return PlatformType.AndroidRelease;
  }
  return platform;
}
