import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { ActivityEvent, CanvasEvent } from 'events/EventTypes';
import { useEvent } from 'events/EventBus';
import { API } from 'api';
import { useReadAnnotations } from 'customHooks/db';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { useFlipBook } from 'customHooks/canvas';

export const useActivityEventHandler = () => {
    const reducers = useStore();
    const [{ pageIndex }] = reducers[StoreTypes.reader];
    const [{ token, userId }] = reducers[StoreTypes.user];
    const [{ annotationId, activityInfo: { attendeeNumber, activityTarget, ownerId } }, annotationDispatch] = reducers[StoreTypes.annotation];
    const activityId = annotationId;
    const { readAnnotationById } = useReadAnnotations();
    const flipBook = useFlipBook();

    const updateActivity = useCallback(async ({ annotations, pageIndex: targetPageIndex = pageIndex }) => {
        let activityObj = { pageIndex: targetPageIndex }
        annotations && (activityObj.annotations = annotations)
        const obj = {
            token: { jwt: token },
            teacherId: ownerId,
            activityId,
            attendeeId: userId,
            attendeeNo: attendeeNumber,
            attendee: {
                annotations: annotations,
                pageIndex: targetPageIndex
            }
        };
        await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/updateAttendee`, obj);
    }, [activityId, attendeeNumber, ownerId, pageIndex, token, userId]);

    //更新目前操作對象畫記資料
    const OnAnnotationUpdateHandler = useCallback(async (result) => {
        const isMe = activityTarget === attendeeNumber;
        //如果不是自己就會被更新
        if (!isMe) {
            EventBus.emit({
                event: ReaderEvent.RefreshCanvasEvent,
                payload: {
                    result
                }
            });
        }
    }, [activityTarget, attendeeNumber])

    //更新目前操作對象書本狀態
    const updateActivityInfoHandler = useCallback(async ({ syncTarget }) => {
        const changeTarget = syncTarget && activityTarget !== syncTarget;
        const isMe = syncTarget === attendeeNumber;
        //如果操作對象變更就更新activityTarget
        if (changeTarget) {
            annotationDispatch({
                type: types.UPDATE_ACTIVITY_INFO,
                activityTarget: syncTarget
            });
        }

        //如果操作對象變更成自己，就要重新讀取indexDB資料並上傳到firebase，讓其他人同步自己畫記
        if (isMe) {
            const result = await readAnnotationById({ id: annotationId });
            EventBus.emit({
                event: ReaderEvent.RefreshCanvasEvent,
                payload: {
                    result: result || { annotations: [], pageIndex: 0 }
                }
            });
            flipBook({ pageIndex: (result && result.pageIndex) || 0, keepCanvas: true });
            if (result) {
                updateActivity(result);
            } else {
                updateActivity({ annotations: [] });
            }
        }
    }, [activityTarget, attendeeNumber, annotationDispatch, readAnnotationById, annotationId, flipBook, updateActivity]);

    const leaveActivityHandler = useCallback(async () => {
        let obj = {
            teacherId: ownerId,
            activityId,
            attendeeNo: attendeeNumber
        }
        return await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/leaveActivity`, obj);
    }, [activityId, attendeeNumber, ownerId]);

    const toggleActivityPanelHandler = useCallback(({ enable }) => {
        annotationDispatch({
            type: types.TOGGLE_ACTIVITY_PANEL,
            enable
        });
    }, [annotationDispatch]);


    const changeActivityTargetHandler = useCallback(({ attendeeNumber }) => {
        (async () => {
            const obj = {
                teacherId: ownerId,
                activityId,
                syncTarget: attendeeNumber
            };
            await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/syncTarget`, obj)
        })();
    }, [activityId, ownerId]);

    const canvasSavedHandler = useCallback(async ({ annotations }) => {
        const obj = {
            token: { jwt: token },
            teacherId: ownerId,
            activityId,
            attendeeId: userId,
            attendeeNo: attendeeNumber,
            attendee: {
                annotations
            }
        };
        await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/updateAttendee`, obj);
    }, [activityId, attendeeNumber, ownerId, token, userId]);

    const updatePageIndexHandler = useCallback(async ({ pageIndex: targetPageIndex = pageIndex }) => {
        let activityObj = { pageIndex: targetPageIndex }
        const obj = {
            token: { jwt: token },
            teacherId: ownerId,
            activityId,
            attendeeId: userId,
            attendeeNo: attendeeNumber,
            attendee: activityObj
        };
        await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/updateAttendee`, obj);
    }, [activityId, attendeeNumber, ownerId, pageIndex, token, userId]);

    useEvent({ event: ActivityEvent.ChangeActivityTargetEvent }, changeActivityTargetHandler);
    useEvent({ event: ActivityEvent.SendActivityInfoToFirebaseEvent }, updatePageIndexHandler);
    useEvent({ event: ActivityEvent.UpdateActivityInfo }, updateActivityInfoHandler);
    useEvent({ event: ActivityEvent.OnAnnotationUpdateEvent }, OnAnnotationUpdateHandler);
    useEvent({ event: ActivityEvent.LeaveActivityEvent }, leaveActivityHandler);
    useEvent({ event: ActivityEvent.ToggleActivityPanelEvent }, toggleActivityPanelHandler);
    useEvent({ event: CanvasEvent.CanvasSavedEvent }, canvasSavedHandler);
};
