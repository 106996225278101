import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const Blacklist = [
    ReaderToolType.Bookshelf,
    ReaderToolType.OpenActivity,
    ReaderToolType.Activity,
    ReaderToolType.StickyNote,
    ReaderToolType.Link,
    ReaderToolType.MathTools,
    ReaderToolType.SynchronousCamera,
    ReaderToolType.SubmitNote,
    ReaderToolType.PaiNoteRecord,
];

export const useEditInteractiveObjectStrategy = () => {
    const [{ isActive }] = useStore(StoreTypes.canvas);

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, Blacklist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    const syncAnnotation = useCallback((id) => {
        // Do nothing
    }, []);

    const syncSingleAnnotation = useCallback((annotatoin) => {

    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return true;
    }, []);

    return {
        name: 'EditInteractiveObjectStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        syncSingleAnnotation,
        isInteractiveObjectTriggerable
    };
};
