import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { ActivityState } from 'constants/groupInteractivity';
import { QuizType } from 'constants/groupInteractivity';
import { ActivityStatus } from 'constants/groupInteractivity';

const isMobile = () => {

  try { document.createEvent('TouchEvent'); return true; }

  catch (e) { return false; }

}

export const initState = {
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  isMobile: isMobile(),
  modal: {
    isShowing: false,
    components: null,
    isHiding: false,
  },
  musicModal: {
    isShowing: false,
    src: null,
    partialSrc: [],
    subtitles: [],
    isAudioHighlighter: false,
    activeObjectId: '',
    isShowAudioControlBar: true,
    isFollowReadMode: false,
    vtts: [],
    currentTime: 0,
  },
  timerModal: {
    isShowing: false
  },
  stopwatchModal: {
    isShowing: false
  },
  paiquizModal: {
    isShowing: false
  },
  groupInteractivity: { // 供教師端使用
    component: null,
    activityState: ActivityState.Create,
    activityInfo: {
      activityType: "",
      QRCode: "",
      teacherId: "",
      id: "",
      quizGroup: 3,
      quizType: QuizType.MultipleChoice,
      quizNum: 5,
      choiceNum: 4,
      status: ActivityStatus.Created,
      createdAt: "",
    },
    paiNoteSelectedPageIndex: [],
    loading: false,
    snackbar: {
      message: "",
      style: "info",
      actionHandler: null
    }
  },
  paiNote: { // 主要是供學生端使用
    teacherId: "",
    noteId: "",
    studentInfo: {
      studentId: "",
      studentGroup: "",
      studentName: "",
      studentNum: ""
    },
    answers: [],
    loading: false
  },
  isOnline:true,
  syncNotifyDialog: {
    isShowing: false
  },
  aiSpeakModal: {
    isShowing: false
  },
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case types.WINDOW_RESIZE:
      const { windowHeight, windowWidth } = action.payload;
      return { ...state, windowHeight, windowWidth }
    case types.OPEN_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: true } }
    case types.CLOSE_MODAL:
      return { ...state, modal: { ...state.modal, isShowing: false } }
    case types.TOGGLE_HIDE_MODAL:
      return {...state, modal: {...state.modal, isHiding: action.payload}}
    case types.SET_MODAL_COMPONENTS:
      return { ...state, modal: { ...state.modal, components: action.payload.components } }
    case types.OPEN_MUSIC_MODAL:
      return { ...state, musicModal: { ...state.musicModal, isShowing: true, ...action.payload } }
    case types.CLOSE_MUSIC_MODAL:
      return {
        ...state,
        musicModal: {
          ...state.musicModal,
          isShowing: false,
          activeObjectId: '',
          vtts: [],
          isFollowReadMode: false,
          currentTime: ''
        }
      }
    case types.SET_MUSIC_MODAL_PROPERTY:
      return { ...state, musicModal: { ...state.musicModal, ...action.payload } }
    case types.OPEN_TIMER_MODAL:
      return { ...state, timerModal: { isShowing: true } }
    case types.CLOSE_TIMER_MODAL:
      return { ...state, timerModal: { isShowing: false } }
    case types.OPEN_STOPWATCH_MODAL:
      return { ...state, stopwatchModal: { isShowing: true } }
    case types.CLOSE_STOPWATCH_MODAL:
      return { ...state, stopwatchModal: { isShowing: false } }
    case types.OPEN_PAIQUIZ_MODAL:
      return { ...state, paiquizModal: { isShowing: true } }
    case types.CLOSE_PAIQUIZ_MODAL:
      return { ...state, paiquizModal: { isShowing: false } }
    case types.SET_GROUP_INTERACTIVITY_INFO:
      return { ...state,  groupInteractivity: {...state.groupInteractivity, activityInfo: {...state.groupInteractivity.activityInfo, ...action.payload}}}
    case types.RESET_GROUP_INTERACTIVITY_INFO:
      const { activityType, activityState, activityInfo, paiNoteSelectedPageIndex } = initState.groupInteractivity;
      return {...state, modal: {...state.modal, isHiding: false}, groupInteractivity: {...state.groupInteractivity, activityInfo, activityType, activityState, paiNoteSelectedPageIndex }}
    case types.CHANGE_GROUP_INTERACTIVITY_STATE:
      return {...state, groupInteractivity: { ...state.groupInteractivity, activityState: action.payload }}
    case types.SET_GROUP_INTERACTIVITY_LOADING:
      return {...state, groupInteractivity: {...state.groupInteractivity, loading: action.payload }}
    case types.SET_GROUP_INTERACTIVITY_SNACKBAR:
      return {...state, groupInteractivity: {...state.groupInteractivity, snackbar: {...state.groupInteractivity.snackbar, ...action.payload}}}
    case types.SET_PAINOTE_SELECTED_PAGE_INDEX:
      return {...state, groupInteractivity: {...state.groupInteractivity, paiNoteSelectedPageIndex: action.payload }}
    case types.SET_PAINOTE_INFO:
      return { ...state, paiNote: { ...state.paiNote, ...action.payload } };
    case types.SET_PAINOTE_ANSWERS:
      const tempArr = [...state.paiNote.answers];
      tempArr[action.payload.index] = action.payload.answer;
      return {...state, paiNote: { ...state.paiNote, answers: tempArr }};
    case types.SET_PAINOTE_STUDENT_LOADING:
      return {...state, paiNote: {...state.paiNote, loading: action.payload}}
    case types.OPEN_SYNCNOTIFY_DIALOG:
      return {...state, syncNotifyDialog: { isShowing: true }}
    case types.CLOSE_SYNCNOTIFY_DIALOG:
      return { ...state, syncNotifyDialog: { isShowing: false } }
    case types.IS_ONLINE_CHECK:
      return { ...state, isOnline: action.isOnline };
    case types.OPEN_AISPEAK_MODAL:
      return { ...state, aiSpeakModal: { isShowing: true } }
    case types.CLOSE_AISPEAK_MODAL:
      return { ...state, aiSpeakModal: { isShowing: false } }
    default:
      return state;
  }
};

const useGlobalReducer = () => useEnhanceReducer(globalReducer, initState);

export default useGlobalReducer;
