import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Bopomofo = ({ className, zhuyin = '', word = '' }) => (
  <div className={classnames(styles.v, className)}>
    <ruby>{word}<rt>{zhuyin}</rt></ruby>
  </div>
);

export default Bopomofo;
