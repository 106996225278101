import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useInternalWindowCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { src, style } }) => {
        try {
            const extendedResource = await ExtendedResourceRepository.getInternalWindowHtml({ bookId, filename: src });

            const component = ModalComponentGenerator.generate(ModalComponentType.StaticHtml, { extendedResource, extendedResourceStyle: style });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('InternalWindowCommand execute error', e);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
