import React from 'react';
import { useStore, StoreTypes } from 'context';
import { isExist } from 'util/helper'
import Bopomofo from 'components/common/Bopomofo';
import classnames from 'classnames';
import styles from './index.module.scss';

import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const RadicalInfoBar = ({
  radical,
  radicalNumber,
  allNumber,
  restNumber,
  onRadicalClick,
  className
}) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const { Name, Pinyin, Path, Phonetic } = radical || {};
  return (
    <div className={classnames(styles.radicalInfoBar, className)}>
      {
        Name && (
          <div className={classnames(styles.wrapper, styles.left)}>
            <div className={styles.title}>部首</div>
            <div className={styles.radicalBox}>
              <div className={styles.value} onClick={() => onRadicalClick(Pinyin)}>
                {Phonetic ? (
                  <Bopomofo
                    word={Name}
                    zhuyin={Phonetic}
                  />
                ) : Path ? (
                  <img
                    src={ExtendedResourceRepository.getDataSrc({
                      bookId,
                      pathName: Path
                    })}
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </div>
        )
      }

      <div className={classnames(styles.wrapper, styles.right)}>
        <div className={styles.title}>筆畫</div>
        {
          isExist(radicalNumber) && (
            <div className={classnames(styles.radicalBox)}>
              <div className={styles.label}>部首</div>
              <div className={styles.value}>{radicalNumber}</div>
            </div>
          )
        }
        {
          isExist(restNumber) && (
            <div className={classnames(styles.radicalBox, styles.pink)}>
              <div className={styles.label}>部首外</div>
              <div className={styles.value}>{restNumber}</div>
            </div>
          )
        }
        {
          isExist(allNumber) && (
            <div className={classnames(styles.radicalBox, styles.orange)}>
              <div className={styles.label}>總筆畫</div>
              <div className={styles.value}>{allNumber}</div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default RadicalInfoBar;
