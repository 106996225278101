import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./index.module.scss";
import resultStyles from "../index.module.scss";
import { useStore, StoreTypes } from "context";
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  TablePagination,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { interactivityApi } from "../api";
import {
  QuizType,
  trueFalseMap,
  ActivityType,
} from "constants/groupInteractivity";
import { getNaniOneClassCookie } from "components/Login/Popup/login.js";
import Icon from "components/Icon";
import { ResultHead } from "..";
import { RectQuizButton, SideQuizButton } from "../CustomButtons";
import { ExtendedContentType } from "components/ExtendedContent/ExtendedContentContainer";
import { useFetchBooks } from "customHooks/book";

const paperContainerStyles = makeStyles({
  root: {
    borderRadius: "16px",
    padding: "16px 24px",
  },
});

const tableContainerStyles = makeStyles({
  root: {
    marginTop: "16px",
    "& .MuiTableCell-stickyHeader": {
      padding: "8px 16px",
      fontSize: "16px",
      color: "#637381",
      borderBottom: "0px",
      userSelect: "none",
      "&:nth-child(1)": {
        borderRadius: "8px 0px 0px 8px",
      },
      "&:last-child": {
        borderRadius: "0px 8px 8px 0px",
      },
      "& span": {
        cursor: "pointer",
        "& svg": {
          fontSize: "12px",
        },
      },
    },
    "& .MuiTableCell-body": {
      borderBottom: "0px",
      padding: "0px 16px",
      height: "56px",
    },
  },
});

const resultButtonStyles = makeStyles({
  root: {
    height: "36px",
    width: "88px",
    minWidth: "88px",
    color: "white",
    background: "#315D83",
    fontSize: "14px",
    fontWeight: "700",
    "&:disabled": {
      background: "lightGrey",
    },
    "&:hover": {
      background: "#4786bb",
    },
  },
});

const columns = [
  {
    id: "createdAt",
    label: "派題時間",
    type: [
      ExtendedContentType.AnswerInteractiveTool,
      ExtendedContentType.PaiNote,
    ],
    format: (value) => new Date(value).toLocaleString().slice(0, -3),
  },
  {
    id: "pageIndex",
    label: "派題位置",
    type: [
      ExtendedContentType.AnswerInteractiveTool,
      ExtendedContentType.PaiNote,
    ],
    format: (value, extendedContentType) => {
      switch (extendedContentType) {
        case ExtendedContentType.AnswerInteractiveTool:
          return `第 ${value} 頁`;
        case ExtendedContentType.PaiNote:
          return value.map((v) => `第 ${v} 頁`).join(", ");
        default:
          return;
      }
    },
  },
  {
    id: "quizType",
    label: "題目類型",
    type: [
      ExtendedContentType.AnswerInteractiveTool,
      ExtendedContentType.PaiNote,
    ],
    format: (value, extendedContentType) => {
      switch (extendedContentType) {
        case ExtendedContentType.AnswerInteractiveTool:
          return value;
        case ExtendedContentType.PaiNote:
          return ActivityType.PaiNote;
        default:
          return;
      }
    },
  },
  {
    id: "quizNum",
    label: "題數",
    type: [ExtendedContentType.AnswerInteractiveTool],
    format: (value) => `${value} 題`,
  },
  {
    id: "action",
    type: [
      ExtendedContentType.AnswerInteractiveTool,
      ExtendedContentType.PaiNote,
    ],
    label: "",
  },
];

const sideButtonText = {
  return: {
    [ExtendedContentType.AnswerInteractiveTool]: "返回列表",
    [ExtendedContentType.PaiNote]: "返回列表",
  },
  next: {
    [ExtendedContentType.AnswerInteractiveTool]: "下一題",
    [ExtendedContentType.PaiNote]: "下一頁",
  },
  previous: {
    [ExtendedContentType.AnswerInteractiveTool]: "上一題",
    [ExtendedContentType.PaiNote]: "上一頁",
  },
};

const sideButton = [
  {
    icon: null,
    text: "return",
    bottom: "60%",
    borderColor: "#B7601A",
    color: "#7A310A",
    background: "#FFA235",
  },
  {
    icon: "arrowLeft",
    text: "previous",
    bottom: "33%",
    borderColor: "#454F5B",
    color: "white",
    background: "#637381",
  },
  {
    icon: "arrowRight",
    text: "next",
    bottom: "6%",
    borderColor: "#454F5B",
    color: "white",
    background: "#637381",
  },
];

const GroupInteractiveToolHistory = () => {
  const router = useStore(StoreTypes.router);
  const [{ catalog }] = useStore(StoreTypes.books);
  const { getBookCatalog } = useFetchBooks();
  const [quizData, setQuizData] = useState([]);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const bookId = searchParams.get("bookId");
  const annotationId = searchParams.get("annotationId");
  const userId = searchParams.get("userId");
  const bookName = searchParams.get("bookName");
  const pageIndex = Number(searchParams.get("pageIndex"));
  const extendedContentType = searchParams.get("extendedContentType");
  const tokenCookie = getNaniOneClassCookie("nani_oneclass_login_token");
  const buttonStyles = resultButtonStyles();
  const tableStyles = tableContainerStyles();
  const paperStyles = paperContainerStyles();
  const [isDescending, setIsDescending] = useState(true);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [reviewAnsNo, setReviewAnsNo] = useState(1);
  const [previewImage, setPreviewImage] = useState({
    studentName: "",
    studentGroup: "",
    studentNum: "",
    url: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [catalogLabel, setCatalogLabel] = useState([]);

  useEffect(() => {
    if (!tokenCookie || !JSON.parse(tokenCookie)) {
      alert("請登入南一 NaniBook");
      router.history.replace("/bookshelf");
    }
    const token = JSON.parse(tokenCookie).jwt;
    interactivityApi[extendedContentType]
      .get(token, {
        teacherId: userId,
        annotationId,
        bookId,
        pageIndex,
      })
      .then((res) => {
        if (res.status === "success") {
          setQuizData(res.data);
          getBookCatalog({ bookId });
        }
      })
      .catch((err) => {
        alert("無法取得紀錄");
        console.log("get groupInteractiveTool history error", err);
      });
  }, [
    annotationId,
    bookId,
    pageIndex,
    router.history,
    tokenCookie,
    userId,
    getBookCatalog,
    extendedContentType,
  ]);

  useEffect(() => {
    if (!catalog.length) return;
    const label = catalog.reduce((arr, item) => {
      return arr.concat(item.sections[0].pages);
    }, []);
    setCatalogLabel(label);
  }, [catalog]);

  useEffect(() => {
    if (!catalogLabel.length) return;
    setQuizData((quizData) =>
      quizData.map((data) => ({
        ...data,
        pageIndexLabel: Array.isArray(data.pageIndex)
          ? data.pageIndex.map((page) => catalogLabel[page].label)
          : catalogLabel[data.pageIndex].label,
      }))
    );
  }, [catalogLabel]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedData = useMemo(() => {
    return quizData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort((a, b) => {
        const createdAt_a = new Date(a.createdAt);
        const createdAt_b = new Date(b.createdAt);
        return isDescending
          ? createdAt_b - createdAt_a
          : createdAt_a - createdAt_b;
      });
  }, [isDescending, page, quizData, rowsPerPage]);

  const groupedStudents = useMemo(() => {
    if (!selectedQuiz) return;
    const groups = [];
    selectedQuiz.students.forEach((student) => {
      const groupNo = student.studentGroup - 1;
      if (!groups[groupNo]) {
        groups[groupNo] = [];
      }
      groups[groupNo].push(student);
    });
    return groups;
  }, [selectedQuiz]);

  const sideButtonAction = useCallback(
    (text) => {
      if (!selectedQuiz) return;
      // eslint-disable-next-line default-case
      switch (text) {
        case "return":
          return () => setSelectedQuiz(null);
        case "previous":
          return () =>
            setReviewAnsNo((prev) => {
              if (prev === 1) return prev;
              return prev - 1;
            });
        case "next":
          return () =>
            setReviewAnsNo((prev) => {
              if (
                prev === selectedQuiz.quizNum ||
                prev === Math.floor(selectedQuiz.pageIndex.length / 2)
              )
                return prev;
              return prev + 1;
            });
      }
    },
    [selectedQuiz]
  );

  const studentUnits = (group) => {
    const member = 8;
    const units = [];
    for (let i = 0; i < group.length; i += member) {
      const unit = group.slice(i, i + member);
      units.push(unit);
    }
    return units;
  };

  const displayAnswer = (answer) => {
    // eslint-disable-next-line default-case
    switch (selectedQuiz.quizType) {
      case QuizType.MultipleChoice:
        return answer ? answer : "未交";
      case QuizType.TrueFalse:
        return answer ? trueFalseMap[answer] : "未交";
      case QuizType.HandWriting:
        return answer ? "交卷" : "未交";
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.container}>
          <img className="logo" src="img/logo.svg" alt="onebook" />
        </div>
      </div>
      {!selectedQuiz && (
        <div className={styles.mainBg}>
          <div className={styles.container}>
            <Paper classes={paperStyles}>
              <Typography variant="h6">{`${ActivityType[extendedContentType]}-${bookName}`}</Typography>
              {sortedData.length > 0 && (
                <>
                  <TableContainer classes={tableStyles}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {columns
                            .filter((column) =>
                              column.type.includes(extendedContentType)
                            )
                            .map((column) => (
                              <TableCell key={column.id} align="left">
                                {column.id === "createdAt" ? (
                                  <span
                                    onClick={() => {
                                      setIsDescending(!isDescending);
                                    }}
                                  >
                                    {column.label}{" "}
                                    <Icon
                                      type="text"
                                      name={
                                        isDescending ? "arrowDown" : "arrowUp"
                                      }
                                    />
                                  </span>
                                ) : (
                                  column.label
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedData.map((quiz) => (
                          <TableRow hover key={quiz.id}>
                            {columns
                              .filter((column) =>
                                column.type.includes(extendedContentType)
                              )
                              .map((column) => {
                                let value = quiz[column.id];
                                if (
                                  column.id === "pageIndex" &&
                                  quiz["pageIndexLabel"]
                                )
                                  value = quiz["pageIndexLabel"];
                                return (
                                  <TableCell key={column.id} align="left">
                                    {column.format ? (
                                      column.format(value, extendedContentType)
                                    ) : (
                                      <Button
                                        classes={buttonStyles}
                                        disabled={quiz.students.length === 0}
                                        onClick={() => setSelectedQuiz(quiz)}
                                      >
                                        查看結果
                                      </Button>
                                    )}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    labelRowsPerPage="每頁列數"
                    count={quizData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              )}
              {sortedData.length === 0 && (
                <p>
                  目前尚未建立
                  {extendedContentType === ExtendedContentType.PaiNote
                    ? "筆記"
                    : "問答"}
                </p>
              )}
            </Paper>
          </div>
        </div>
      )}
      {selectedQuiz && !previewImage.url && (
        <div className={styles.resultBg}>
          {sideButton.map((button) => {
            const props = {
              ...button,
              text: sideButtonText[button.text][extendedContentType],
            };
            return (
              <SideQuizButton
                left={true}
                {...props}
                handleClick={sideButtonAction(button.text)}
              />
            );
          })}
          {sideButton.map((button) => {
            const props = {
              ...button,
              text: sideButtonText[button.text][extendedContentType],
            };
            return (
              <SideQuizButton
                left={false}
                {...props}
                handleClick={sideButtonAction(button.text)}
              />
            );
          })}
          <div
            className={resultStyles.resultCard}
            style={{ minHeight: "450px" }}
          >
            <ResultHead
              quizInfo={selectedQuiz}
              reviewAnsNo={reviewAnsNo}
              extendedContentType={extendedContentType}
            />
            <div className={resultStyles.resultContent}>
              {groupedStudents.map((group, index) => (
                <div
                  className={resultStyles.group}
                  key={index}
                  style={{
                    minWidth: `calc(180px * ${studentUnits(group).length})`,
                  }}
                >
                  <div className={resultStyles.groupHead}>
                    第 {index + 1} 組
                  </div>
                  <div className={resultStyles.students}>
                    {studentUnits(group).map((unit, index) => (
                      <div className={resultStyles.unit} key={index}>
                        {unit.map((student) => {
                          const hasAns =
                            student.answers && student.answers.length > 0;
                          return (
                            <div
                              className={resultStyles.name}
                              key={Math.random()}
                              style={
                                (selectedQuiz.quizType ===
                                  QuizType.HandWriting ||
                                  extendedContentType ===
                                    ExtendedContentType.PaiNote) &&
                                hasAns
                                  ? { cursor: "pointer" }
                                  : null
                              }
                              onClick={() => {
                                if (
                                  !hasAns ||
                                  (selectedQuiz.quizType !==
                                    QuizType.HandWriting &&
                                    extendedContentType !==
                                      ExtendedContentType.PaiNote)
                                )
                                  return;
                                setPreviewImage({
                                  studentName: student.studentName,
                                  studentGroup: student.studentGroup,
                                  studentNum: student.studentNum,
                                  url: student.answers[reviewAnsNo - 1],
                                });
                              }}
                            >
                              <span className={resultStyles.text}>
                                {`${("0" + student.studentNum).slice(-2)} ${
                                  student.studentName
                                }`}
                              </span>
                              <span
                                className={`${resultStyles.status} ${
                                  !hasAns && resultStyles.warn
                                }`}
                              >
                                {displayAnswer(
                                  !hasAns
                                    ? ""
                                    : student.answers[reviewAnsNo - 1]
                                )}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {selectedQuiz.quizType === QuizType.HandWriting && (
              <div className={styles.description}>點選學生名字預覽結果</div>
            )}
          </div>
        </div>
      )}
      {selectedQuiz && previewImage.url && (
        <div className={styles.resultBg}>
          <div className={styles.image}>
            <img src={previewImage.url} alt="手寫題預覽" />
            <span className={styles.name}>
              第 {previewImage.studentGroup} 組｜
              {("0" + previewImage.studentNum).slice(-2)}{" "}
              {previewImage.studentName}
            </span>
          </div>
          <RectQuizButton
            width="25%"
            text="結束預覽"
            color="#7A310A"
            borderColor="#B7601A"
            background="#FFA235"
            handleClick={() =>
              setPreviewImage({
                studentName: "",
                studentGroup: "",
                studentNum: "",
                url: "",
              })
            }
          />
        </div>
      )}
    </>
  );
};

export default GroupInteractiveToolHistory;
