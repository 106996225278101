import { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import { DefaultExtraFields, ExtraFieldsForEditor } from 'constants/canvas';
import { short_uuid } from 'util/uuid';
import { useStore, StoreTypes } from 'context';
import { useSaveCanvasJSON } from 'customHooks/canvas';

// const useCopyPasteHookleton = createHook(useSetState);

const useCopyPaste = () => {
  const saveCanvasJSON = useSaveCanvasJSON();
  const [{ clipboardObj, copyState, pasteState }, setState] = useSetState({
    clipboardObj: null,
    copyState: false,
    pasteState: false
  });
  const [{ canvas, activeCanvasObject }] = useStore(StoreTypes.canvas);

  useEffect(() => {
    setState({
      copyState: !!activeCanvasObject,
      pasteState: !!clipboardObj
    });
  }, [activeCanvasObject, clipboardObj, setState]);

  const copy = (beClonedObj = activeCanvasObject) => {
    if (!beClonedObj) return;
    beClonedObj.clone(
      clonedObj => {
        clonedObj.set({
          id: short_uuid()
        });
        clonedObj.forEachObject &&
          clonedObj.forEachObject(obj => {
            obj.set({ id: short_uuid() });
          });

        setState({ clipboardObj: clonedObj });
      },
      [
        ...new Set([
          ...Object.values(DefaultExtraFields),
          ...Object.values(ExtraFieldsForEditor)
        ])
      ]
    );
  };

  const paste = async () => {
    if (!clipboardObj) return;
    copy(clipboardObj);
    if (clipboardObj.type === 'activeSelection') {
      clipboardObj.canvas = canvas;
      clipboardObj.forEachObject(function(obj) {
        canvas.add(obj);
      });
      clipboardObj.setCoords();
    } else {
      canvas.add(clipboardObj);
    }
    canvas.setActiveObject(clipboardObj);
    await saveCanvasJSON();
    canvas.renderAll();
  };

  return [{ copyState, pasteState, clipboardObj }, { copy, paste }];
};

export default useCopyPaste;
