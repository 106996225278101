import { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

const Whitelist = [ReaderToolType.AttendeeNumber];

const InteractiveObjectTriggerableWhitelist = [
    InteractiveObjectContentType.Video,
    InteractiveObjectContentType.Audio,
    InteractiveObjectContentType.GoPage,
    InteractiveObjectContentType.WordGrid,
    InteractiveObjectContentType.WordExample,
    InteractiveObjectContentType.WordGlyph,
    InteractiveObjectContentType.WordMeaning,
    InteractiveObjectContentType.Submenu
];

export const useInactiveStudentStrategy = () => {
    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, Whitelist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return false;
    }, []);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return false;
    }, []);

    const syncAnnotation = useCallback((id) => {
        // Do nothing
    }, []);

    const syncSingleAnnotation = useCallback((annotatoin) => {

    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return InteractiveObjectTriggerableWhitelist.some((type) => type === contentType);
    }, []);

    return {
        name: 'InactiveStudentStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        syncSingleAnnotation,
        isInteractiveObjectTriggerable
    };
};
