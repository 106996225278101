import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useVolumeOneWordsCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const execute = useCallback(async ({ json: { volumeOneWords } }) => {
    const { src, word, wordsSetSrc } = JSON.parse(volumeOneWords);
    try {
      const response = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: src });
      const wordsSetSrcResponse = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: wordsSetSrc });
      const component = ModalComponentGenerator.generate(ModalComponentType.VolumeOneWords, {
        wordGridResource: Object.values(response),
        defaultWord: word,
        wordsResource: wordsSetSrcResponse
      });
      setModalComponents(component);
      openModal();
    } catch (e) {
      console.error('VolumeOneWordsCommand execute error', e);
    }
  }, [openModal, setModalComponents, bookId]);

  return { execute };
};
