import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const reg = /i|ɪ|e|ɛ|æ|ɑ|o|ɔ|u|ᴜ|ʌ|ə|ɚ|ɝ|aɪ|aᴜ|ɔɪ/g;

const VocabularyInfo = ({ className, kkContent, partOfSpeech }) => (
  <div className={classnames(styles.vocabularyInfo, className)}>
    <div
      className={styles.kk}
      dangerouslySetInnerHTML={{
        __html: kkContent.replace(reg, word => `<span>${word}</span>`)
      }}
    />
    {partOfSpeech && <div className={styles.partOfSpeech}>{partOfSpeech}</div>}
  </div>
);

export default VocabularyInfo;
