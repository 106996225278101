import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export const useLocationChange = callback => {
  let location = useLocation();
  useEffect(() => {
    callback(location);
  }, [callback,location]);
};

