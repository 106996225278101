import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import MusicPlayer from 'components/common/MusicPlayer';
import Repository from 'repositories/Repository';

const { ExtendedResourceRepository } = Repository;

const MarkerWithSound = ({ src, audioInfo, isShowAudioControlBar = true }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [trackList, setTrackList] = useState([]);
  const [{ audio, state, controls, ref }, setMusicInfo] = useState([]);
  const [subtitles, setSubtitles] = useState([]);
  const [partialSrc, setPartialSrc] = useState([]);
  const [activeCue, setActiveCue] = useState({});
  const trackContentRef = useRef(null);
  const playerRef = useRef(null);
  const trackListRef = useRef(null);
  const musicPlayerOnLoadHandler = useCallback(({ audio, state, controls, ref, activeCue, cues }) => {
    const activeCueEn = activeCue['en'];
    const cuesEn = cues['en'] ? cues['en'] : [];
    if (!trackContentRef.current) return;
    const trackContentHeight = trackContentRef.current.clientHeight - playerRef.current.clientHeight;
    const listHeight =
      activeCueEn &&
      activeCueEn.id &&
      document.getElementById('track' + activeCueEn.id) &&
      document.getElementById('track' + activeCueEn.id).clientHeight;

    setMusicInfo({ audio, state, controls, ref });
    setTrackList(cuesEn);

    let scrollTop = 0;

    if (activeCueEn) {
      if (activeCueEn.id) {
        if (
          cuesEn.length > 0 &&
          document.getElementById('track' + parseInt(cuesEn[0].id))
        ) {
          for (let i = 0; i < cuesEn.length; i++) {
            scrollTop += document.getElementById(
              'track' + parseInt(cuesEn[i].id)
            ).clientHeight;
            if (activeCueEn.id === cuesEn[i].id) {
              break;
            }
          }
        }
      }

      setActiveCue(activeCueEn);
      if (state.isPlaying) {
        trackContentRef &&
          (trackContentRef.current.scrollTop =
            scrollTop !== 0
              ? scrollTop - trackContentHeight / 2 - listHeight / 2
              : 0);
      }
    }
  }, []);

  useEffect(() => {
    setSubtitles([
      {
        language: 'en',
        src: ExtendedResourceRepository.getAudioVTTSrc({
          bookId,
          audioInfo
        })
      }
    ])
  }, [audioInfo, bookId]);

  return (
    <div
      className={styles.markerWithSound}
      ref={el => (trackContentRef.current = el)}
    >
      <div className={styles.listContent}>
        {trackList.map((track, index) => {
          return (
            <div
              key={track.id}
              ref={el => (trackListRef.current = el)}
              id={'track' + track.id}
              className={classnames(
                styles.track,
                styles[
                track.text &&
                  activeCue.text === track.text &&
                  activeCue.id === track.id
                  ? 'show'
                  : null
                ]
              )}
              onClick={() => {
                controls.seek(track.startTime);
                !state.isPlaying && controls.play();
              }}
            >
              <span
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: track.text }} />
            </div>
          );
        })}
      </div>
      <div
        className={styles.markerWithSoundPlayBar}
        ref={el => (playerRef.current = el)}
      >
        <MusicPlayer
          isShowAudioControlBar={isShowAudioControlBar}
          src={ExtendedResourceRepository.getAudioSrc({
            bookId,
            filename: src
          })}
          subtitles={subtitles}
          partialSrc={partialSrc}
          onLoad={musicPlayerOnLoadHandler}
          closeButton={false}
        />
        {
          //<canvas id={"seekRange"} style={{ width: "100%", height: "50px" }} />
        }
      </div>
    </div>
  );
};

export default MarkerWithSound;
