import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import { LOGIN_POPUP } from "constants/loginTypes";
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
// import { PainterMode } from 'constants/painterModes';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';

export const useAnswerInteractiveToolCommand = () => {
    const [, { openModal, setModalComponents }] = useModal();
    const [, loginDispatch] = useStore(StoreTypes.login);
    const [{token}] = useStore(StoreTypes.user);

    // const changePainterMode = ({ event, painterMode, painterToolType }) => {
    //     EventBus.emit({ event, payload: { painterMode, painterToolType } });
    // };

    const setReaderToolType = type => {
        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: type
            }
        });
    }

    const execute = useCallback(async ({ canvasSVGObject }) => {
        if(!token){
            loginDispatch({ type: LOGIN_POPUP, popupState: true });
            return;
        }
        
        try {
            gaEventReaderToolsClick({
                category: CategoryType.ReaderTool,
                action: ReaderToolType.AnswerInteractiveTool,
                label: ReaderToolType.AnswerInteractiveTool
              });
            
            const component = ModalComponentGenerator.generate(ModalComponentType.AnswerInteractiveTool, { canvasSVGObject });
            setModalComponents(component);
            setReaderToolType(ReaderToolType.AnswerInteractiveTool);

            // changePainterMode({
            //     event: ReaderToolsEvent.ClickSelectEvent,
            //     painterMode: PainterMode.Selection
            // });

            openModal();
        } catch (e) {
            console.error('AnswerInteractiveTool execute error', e);
        }
    }, [loginDispatch, openModal, setModalComponents, token]);

    return { execute };
};
