import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';

const SnackbarComponent = () => {
    const [{ snackbarMessage }] = useStore(StoreTypes.reader);

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
        EventBus.emit({
            event: ReaderToolsEvent.SetSnackbarMessage,
            payload: { snackbarMessage: '' },
        });
    }
    useEffect(() => {
        if (snackbarMessage !== '') {
            setOpen(true)
        }
    }, [snackbarMessage])
    return (
        <div className={styles.snackbar}>
            <Snackbar
                open={open}
                onClose={() => handleClose()}
                autoHideDuration={1000}
            >
                <MuiAlert variant="filled" severity="success" >
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default SnackbarComponent