import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { LANGUAGES } from 'constants/languages';
import { SubtitlesPositionType, SubtitlesFontSizeType } from 'constants/videoSettingType';
import { DefaultReaderTools ,ElectronDefaultReaderTools} from 'constants/ReaderTools';


export const initState = {
    language: LANGUAGES.TRADITIONAL_CHINESE,
    videoSetting: {
        subtitlesPosition: SubtitlesPositionType.DOWN,
        playbackRate: 1,
        subtitlesFontSize: SubtitlesFontSizeType.MEDIUM
    },
    audioSetting: {
        times: 1,
        timeGap: 2000,
        speed: 1,
        usingDefault: true
    },
    books: null,
    isUpdateOldBook: false,
    readerToolSetting: window.electron ? ElectronDefaultReaderTools : DefaultReaderTools,
    stampControls: []
};

const updateUserSettings = (state, action) => {
    const {
        language = state.language,
        videoSetting = state.videoSetting,
        audioSetting = state.audioSetting,
        readerToolSetting = state.readerToolSetting,
        stampControls = state.stampControls
    } = action;
    return { ...state, language, videoSetting, audioSetting, readerToolSetting, stampControls };
};

const updateUserFavorites = (state, action) => {
    const {
        books = state.books,
        isUpdateOldBook = state.isUpdateOldBook
    } = action;
    let userFavorites = null;

    if (state.books !== null) {
        const newBooksArr = Object.values(books);
        const isChange = newBooksArr.filter(newBook => {
            return !state.books.hasOwnProperty(newBook.id)
        }).length > 0;
        userFavorites = isChange ? { ...state, ...{ books: books, isUpdateOldBook: isUpdateOldBook } } : { ...state };
    } else {
        userFavorites = { ...state, ...{ books: books, isUpdateOldBook: isUpdateOldBook } };
    }
    return userFavorites;
};

const deleteUserFavorites = (state, action) => {
    const {
        books = state.books,
        isUpdateOldBook = state.isUpdateOldBook
    } = action;

    return { ...state, ...{ books: books, isUpdateOldBook: isUpdateOldBook } };
};

const userSettingsReducer = (state, action) => {
    switch (action.type) {
        case types.UPDATE_USER_SETTINGS:
            return updateUserSettings(state, action);
        case types.UPDATE_USER_FAVORITES:
            return updateUserFavorites(state, action);
        case types.DELETE_USER_FAVORITES:
            return deleteUserFavorites(state, action);
        default:
            return state;
    }
};

const useUserSettingsReducer = () => useEnhanceReducer(userSettingsReducer, initState);

export default useUserSettingsReducer;
