import React from 'react';
import styles from './index.module.scss';
import RangeSlider from 'components/common/RangeSlider';

const options = Array(20)
  .fill()
  .map((value, index) => index + 1);

const LineWidthSlider = ({ value, onChange = () => {} }) => (
  <div className={styles.lineWidthSlider}>
    <div className={styles.item}>
      <RangeSlider
        type="range"
        max={options.length}
        min={1}
        step={1}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
    <div className={styles.item}>
      <select value={value} onChange={e => onChange(e.target.value)}>
        {options.map(option => (
          <option key={option} value={option}>
            {option}px
          </option>
        ))}
      </select>
    </div>
  </div>
);
export default LineWidthSlider;
