import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useListeningStoryCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const defaultTab = 0;
  const execute = useCallback(async ({ json: { src, listeningInfo = defaultTab } }) => {
    try {
      const listeningResource = await ExtendedResourceRepository.getJSONContent(
        { bookId, pathName: src }
      );
      // 取得資料
      const component = ModalComponentGenerator.generate(ModalComponentType.Listening, { listeningResource, setupInfo: listeningInfo });
      setModalComponents(component);
      openModal();
    } catch (e) {
      console.error('ListeningStoryComponent execute error', e);
    }
  }, [bookId, openModal, setModalComponents]);

  return { execute };
};