import React, { useState, useEffect, useCallback } from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import classnames from 'classnames'

const ModifyColorSelector = ({
  colors,
  customizedColor = true,
  transparentOption = false,
  opacityMode,
  propertyName,
  value,
  target: object
}) => {

  const [inColors, setState] = useState(true);
  const [chooseColor, setColor] = useState('');

  const selectColorHandler = useCallback(value => {
    setColor(value);
    setState(value);
    if (object) {
      if (opacityMode && (object[propertyName].length > 7 || object.opacity === 0.35)) {
        EventBus.emit({
          event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
          payload: { object, property: 'opacity', value: 0.35 }
        });
      }
      EventBus.emit({
        event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
        payload: { object, property: propertyName, value }
      });
    }
  }, [object, opacityMode, propertyName]);

  useEffect(() => {
    if (!object[propertyName]) return;
    const hasColor = colors.findIndex(color => ~object[propertyName].indexOf(color)) > -1;
    setState(hasColor);
    setColor(object[propertyName])
  }, [colors, object, propertyName])


  return (
    <div className={styles.colorSelector}>
      {
        colors.map(color =>
          <div key={color} className={classnames(styles.color, { [styles.active]: chooseColor && ~chooseColor.indexOf(color) })} style={{ backgroundColor: color }} onClick={() => selectColorHandler(color)}>
            <div className={styles.icon}>
              <Icon type="text" name="check" />
            </div>
          </div>)
      }
      <div className={classnames(styles.color, styles.transparent)} onClick={(e) => selectColorHandler('rgba(0,0,0,0)')}>
        <Icon type="text" name="ban" />
      </div>
      {
        customizedColor && (
          <div className={classnames(styles.color, styles.customizedColor, { [styles.active]: !inColors })}>
            <div className={styles.icon}>
              <Icon type="text" name="check" />
            </div>
            <label>
              <input type="color" onChange={(e) => selectColorHandler(e.target.value)} />
            </label>
          </div>
        )
      }

    </div>
  )
}

export default ModifyColorSelector;
