import React, { forwardRef } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Item = ({ className, onClick, children }) => (
  <div
    className={classnames(styles.contextMenuItem, className)}
    onClick={onClick}
  >
    {children}
  </div>
);

const Box = forwardRef(({ children, isVisible }, ref) => {
  return (
    <div
      ref={ref}
      className={classnames(styles.contextMenuBox, {
        [styles.show]: isVisible
      })}
    >
      {children}
    </div>
  );
});

const ContextMenu = {
  Box,
  Item
};

export default ContextMenu;
