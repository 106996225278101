import React, { useEffect, useRef, useState } from "react";
import path from "path";
import { xor, toNumber } from "lodash";
import Repository from "repositories/Repository";
import styles from "./index.module.scss";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import { useStore, StoreTypes } from "context";
import { groupPages } from "util/book";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classnames from "classnames";
import { BookFlipType } from "constants/flipTypes";

const { BookContentRepository } = Repository;

const selectPageCardCheckBox = makeStyles({
  root: {
    width: "100%",
    "& .MuiButtonBase-root": {
      "&.Mui-checked": {
        color: "#B7601A",
      },
    },
    "& .MuiFormControlLabel-label": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: "500",
    },
  },
});

const SelectedPages = ({ selectedPageIndex, setSelectedPageIndex }) => {
  const selectPageCardCheckboxStyles = selectPageCardCheckBox();
  const [{ isDoublePageMode, pageIndex }] = useStore(StoreTypes.reader);
  const [{ bookId, books, catalog }] = useStore(StoreTypes.books);
  const book = books.find((book) => book.bookId === bookId);
  const [labels, setLabels] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const thumbnailsRef = useRef(new Map());

  // 選擇派筆記頁數用的縮圖
  useEffect(() => {
    (async () => {
      if (!book) {
        return;
      }

      const { pageInfos } = book;
      const pages = pageInfos.reduce((acc, item) => {
        acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
        return acc;
      }, {});
      const labelInfos = getLabel(groupPages(pages, true));
      const labels = labelInfos.map((info) =>
        info.reduce(
          (acc, item) => {
            acc.label.push(item.label);
            acc.page.push(item.page);
            acc.index = item.index;
            return acc;
          },
          { label: [], page: [], index: 0 }
        )
      );

      const results = await BookContentRepository.getThumbnailUrls({
        bookId,
        pages: Object.values(pages),
      });

      setLabels(labels);
      setThumbnailUrls(results);
    })();
  }, [bookId, book]);

  useEffect(() => {
    if (!thumbnailsRef.current.size) return;
    thumbnailsRef.current.get(isDoublePageMode ? pageIndex : Math.floor(pageIndex / 2)).scrollIntoView({
      behavior: "smooth", block: "start" 
    });
  }, [isDoublePageMode, pageIndex, thumbnailsRef.current.size])
  

  const getLabel = (data) => {
    return Object.values(data).map((pages, index) => {
      const labels = [];
      for (let page of pages) {
        const label = catalog.getLabel(page - 1).toString();
        labels.push({ label, page, index });
      }
      return labels;
    });
  };

  return (
    <div className={styles.selectPagesSection}>
      <div className={styles.title}>
        <span>選擇頁數</span>
        <span className={styles.alreadySelected}>已選擇 {selectedPageIndex.length} / 2</span>
      </div>
      <div className={styles.pagesContainer}>
        {labels.map((label) => {
          return (
            <div
              className={classnames(styles.pageCard, {
                [styles.selected]: selectedPageIndex.includes(label.index),
              })}
              ref={(node) => {
                if (node) {
                  thumbnailsRef.current.set(label.index, node);
                } else {
                  thumbnailsRef.current.delete(label.index);
                }
              }}
            >
              <FormControlLabel
                classes={selectPageCardCheckboxStyles}
                control={
                  <Checkbox
                    checked={selectedPageIndex.includes(label.index)}
                    value={label.index}
                    onClick={(e) => {
                      const tempArr = xor(selectedPageIndex, [
                        toNumber(e.target.value),
                      ]);
                      if (tempArr.length > 2) return;
                      setSelectedPageIndex(tempArr);
                    }}
                  />
                }
                label={`第 ${label.label[0]}、${label.label[1]} 頁`}
              />
              <div className={classnames(styles.thumbnail, { [styles.rightToLeft]: book.LRFlip === BookFlipType.RIGHT_TO_LEFT })}>
                <LazyLoadImage
                  key={label.page[0]}
                  src={thumbnailUrls[label.page[0]]}
                  alt=""
                  effect="blur"
                  placeholder={<span className={styles.fakeImg}></span>}
                />
                <LazyLoadImage
                  key={label.page[1]}
                  src={thumbnailUrls[label.page[1]]}
                  alt=""
                  effect="blur"
                  placeholder={<span className={styles.fakeImg}></span>}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedPages;
