import React from "react";
import Draggable from 'react-draggable';
import Icon from 'components/Icon';
import styles from './index.module.scss';

const Popup = ({content, bottom, title, closeBtn, handleClose}) => {
    const popupContent = () => {
        return (<div className={styles.boxModal}>
            <div className={styles.controlHead}>
                <h1 className={styles.title}>{title}</h1>
                {closeBtn &&
                    <div className={styles.closeButton} onClick={handleClose}>
                        <Icon type="text" name="times" />
                    </div>
                }
            </div>
            <div className={styles.content}>
                {content}
            </div>
            <div className={styles.bottom}>
                {bottom}
            </div>
        </div>)
    };
    
    return (
		<div className={styles.boxModalMask}>
            <Draggable
                handle={`.${styles.controlHead}`}
                positionOffset={{ x: '-50%', y: '-50%' }}
            >
                {popupContent()}
            </Draggable>
        </div>
	);
};

export default Popup;