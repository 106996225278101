import React, { useCallback, useEffect, useState } from "react";
import { maxBy } from "lodash";
import classNames from "classnames";
import { Player } from '@lottiefiles/react-lottie-player';
import styles from "./index.module.scss";
import studentViewStyles from "../GroupInteractiveTool/StudentView/index.module.scss";
import Reader from "components/Reader";
import { StudentQuizActionButton } from "components/GroupInteractiveTool/CustomButtons";
import { useStore, StoreTypes } from "context";
import * as types from "constants/actionTypes";
import { AnnotationType } from "constants/annotationTypes";
import uuid from "util/uuid";
import { logoutNaniOneClass } from "components/Login/Popup/login";
import { useDeleteAnnotation, useCreateAnnotation } from "customHooks/db";
import { useLocation } from "react-router-dom";
import { interactivityApi } from "components/GroupInteractiveTool/api";
import { ExtendedContentType } from "components/ExtendedContent/ExtendedContentContainer";
import { LoadingForStudent } from "components/GroupInteractiveTool/Loading";
import Popup from "components/Popup"
import popupStyles from "../Popup/index.module.scss";
import paiQuizScreenLandscape from "./paiQuizScreenLandscape.json"
import StudentInfoInputField from "components/GroupInteractiveTool/StudentInfoInputField";
import { EventBus } from "events/EventBus";
import { ReaderToolsEvent } from "events/EventTypes";
import { useRefreshReader } from "customHooks/reader";

const studentInfoField = {
  studentNum: "座號*",
  studentName: "姓名",
  studentGroup: "組別",
};

const PopupContent = () => {
  return (<div className={styles.content}>
    <div className={styles.contentImg}>
      <Player
        loop
        autoplay
        src={paiQuizScreenLandscape}
        >
      </Player>
    </div>
    <div className={styles.contentText}>裝置<span>轉為橫向</span>會更好操作喔！</div>
    </div>
  )
};

const PopupBottom = ({setIsPopupShow}) => {
  return (
    <div className={classNames(popupStyles.originalBottom, popupStyles.widthFull, { [styles.disabled]: false })}>
      <button type="button" onClick={() => setIsPopupShow(false)}>我知道了</button>
    </div>
  );
};

const BookForStudent = (props) => {
  const bookId = props.match.params.bookId;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const teacherId = searchParams.get("user");
  const QRCode = searchParams.get("code");
  const [ {
    activityInfo: { firestore },
  }, annotationDispatch] = useStore(StoreTypes.annotation);
  const [{ paiNoteNotes, paiNoteSelectedNote }, readerDispatch] = useStore(StoreTypes.reader);
  const [, userDispatch] = useStore(StoreTypes.user);
  const [, globalDispatch] = useStore(StoreTypes.global);
  const [{ annotationId: dbAnnotationId }] = useStore(StoreTypes.annotation);
  const { deleteAllAnnotation } = useDeleteAnnotation();
  const createAnnotation = useCreateAnnotation();
  const [studentInfo, setStudentInfo] = useState({
    studentId: uuid(),
    studentName: "",
    studentNum: "",
    studentGroup: 1,
  });
  // const [allNotes, setAllNotes] = useState([]);
  // const [choosenNoteInfo, setChoosenNoteInfo] = useState({});
  const [studentCreated, setStudentCreated] = useState(false);
  const [teacherAnnotation, setTeacherAnnotation] = useState({});
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [studentInfoErrors, setStudentInfoErrors] = useState({});
  const { refreshReader } = useRefreshReader();


  useEffect(() => {
    // if (!firestore || studentCreated) return;
    if (!firestore) return
    const unsuscribe = firestore.collection(`teachers/${teacherId}/notes`).where('QRCode', '==', QRCode).onSnapshot((snapshot) => {
      if (snapshot.docs.length === 0) return;
      const allNotes = snapshot.docs.map((doc) => doc.data());
      const selectedNote = maxBy(allNotes, 'createdAt');

      if (paiNoteNotes.length < allNotes.length && paiNoteNotes.length !== 0) {
        globalDispatch({
          type: types.SET_GROUP_INTERACTIVITY_SNACKBAR,
          payload: {
            message: '老師派發新的筆記了喔！',
            style: 'secondary',
            actionHandler: () => {
              readerDispatch({ type: types.SET_PAINOTE_SELECTEDNOTE, paiNoteSelectedNote: selectedNote })
            },
          }
        })
      }

      if (paiNoteNotes.length < allNotes.length) {
        readerDispatch({
          type: types.SET_PAINOTE_NOTES,
          paiNoteNotes: allNotes
        });
      }

      if (paiNoteNotes.length === 0) {
        readerDispatch({
          type: types.SET_PAINOTE_SELECTEDNOTE,
          paiNoteSelectedNote: selectedNote
        })
        // setChoosenNoteInfo(selectedNote);
      }
    });
    return () => {
      unsuscribe()
    }
  }, [firestore, studentCreated, teacherId, QRCode, globalDispatch, readerDispatch, paiNoteNotes])

  useEffect(() => {
    // 用 paiNoteSelectedNote 取得老師的 annotation
    const { preparationId } = paiNoteSelectedNote;
    if (!firestore || !preparationId || teacherAnnotation.id) return;
    const unsuscribeClassPreparation = firestore.collection(`teachers/${teacherId}/classPreparations`).where('id', '==', preparationId).onSnapshot(async (snapshot) => {
      if (snapshot.docs.length === 0) return;
      const data = snapshot.docs.map((doc) => doc.data())[0];
      setTeacherAnnotation((prev) => ({ ...prev, ...data }));
    });

    const unsuscribeAnnotations = firestore.collection(`teachers/${teacherId}/classPreparations/${preparationId}/annotations`).onSnapshot(async (snapshot) => {
      if (snapshot.docs.length === 0) return;
      const data = snapshot.docs.map((doc) => doc.data());
      setTeacherAnnotation((prev) => ({ ...prev, annotations: data }))
    });

    return () => {
      unsuscribeClassPreparation();
      unsuscribeAnnotations();
    }
  }, [firestore, paiNoteSelectedNote, teacherId, teacherAnnotation])


  useEffect(() => {
    // 如果選擇的筆記改變了，要重新創造學生
    setStudentCreated(false);
  }, [paiNoteSelectedNote.id])

  useEffect(() => {
    // 確保學生一定是訪客
    userDispatch({ type: types.RESET_USER_INFO });
    logoutNaniOneClass((result) => {
      console.log("派筆記學生端訪客進入", result);
    });
    deleteAllAnnotation();
  }, [deleteAllAnnotation, userDispatch]);

  useEffect(() => {
    // 和跳頁相關的設定
    if (!paiNoteSelectedNote.pageIndex || paiNoteSelectedNote.pageIndex.length === 0) return;
    const newPages = new Set(
      paiNoteSelectedNote.pageIndex.sort((a, b) => a - b).map((page) => Math.floor(page / 2))
    );
    readerDispatch({
      type: types.SWITCH_BOOK_PAGES_SHOW,
      isDoublePageMode: true,
    });
    // readerDispatch({
    //   type: types.TOGGLE_BOOKMARK,
    // });
    readerDispatch({
      type: types.SET_PAINOTE_PAGE,
      paiNotePage: Array.from(newPages),
    });
  }, [readerDispatch, paiNoteSelectedNote.pageIndex]);

  useEffect(() => {
    (async () => {
      if (!teacherAnnotation.id) return;
      const annotationId = dbAnnotationId || uuid();
      annotationDispatch({
        type: types.UPDATE_ANNOTATION_INFO,
        annotationId,
        annotationType: AnnotationType.GUEST,
        name: "派筆記學生端",
      });
      await createAnnotation({
        id: annotationId,
        bookId,
        name: "派筆記學生端",
        annotations: teacherAnnotation.annotations,
        type: AnnotationType.GUEST,
        pageIndex: Math.floor(paiNoteSelectedNote.pageIndex[0] / 2),
        isDoublePageMode: true,
        canvasSVGObjects: teacherAnnotation.canvasSVGObjects,
        extendedContentAnnotation: teacherAnnotation.extendedContentAnnotation,
      });
      readerDispatch({
        type: types.SET_BOOK_PAGE_INDEX,
        pageIndex: Math.floor(paiNoteSelectedNote.pageIndex[0] / 2),
      });
      refreshReader();
    })();
  }, [annotationDispatch, bookId, createAnnotation, readerDispatch, paiNoteSelectedNote, teacherAnnotation]);

  const updateStudentInfo = (key, value) => {
    setStudentInfo((prev) => ({
      ...prev,
      [key]: value,
    }));

    const newError = {...studentInfoErrors};
    delete newError[key];
    setStudentInfoErrors(newError);
  };

  const createStudent = useCallback(async () => {
    const res = await interactivityApi[ExtendedContentType.PaiNote].createStudent({
      student: { ...studentInfo },
      teacherId,
      noteId: paiNoteSelectedNote.id,
    });
    if (res.status === "success") {
      setStudentCreated(true);
      globalDispatch({
        type: types.SET_PAINOTE_INFO,
        payload: {
          studentInfo: {...studentInfo},
          noteId: paiNoteSelectedNote.id,
          teacherId
        }
      });
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setIsPopupShow(true);
    }
  }, [paiNoteSelectedNote, globalDispatch, studentInfo, teacherId])

  return (
    <>
      {!studentCreated && (
        <div
          className={`${styles.bg} ${styles.centered}`}
        >
          <>
            <div className={styles.headText}>
              請輸入資料後開啟筆記
            </div>
            <div className={studentViewStyles.inputCard}>
              <StudentInfoInputField
                studentInfoField={studentInfoField}
                studentInfo={studentInfo}
                studentInfoErrors={studentInfoErrors}
                updateStudentInfo={updateStudentInfo}
                group={paiNoteSelectedNote.group}
              />
              <div className={studentViewStyles.actionBtn}>
                <StudentQuizActionButton
                  text="開啟筆記"
                  color="#212B36"
                  borderColor="#FFA235"
                  background="#FFE1AD"
                  handleClick={() => {
                    const newErrors = {};
                    if (!studentInfo.studentNum) newErrors.studentNum = "請選擇座號";
                    if (Object.keys(newErrors).length !== 0) {
                      setStudentInfoErrors(newErrors);
                      return;
                    }
                    createStudent()
                  }}
                />
              </div>
            </div>
          </>
        </div>
      )}
      {isPopupShow && 
        <Popup 
          title="建議將裝置轉為橫向"
          content={<PopupContent />}
          bottom={<PopupBottom setIsPopupShow={setIsPopupShow}/>}
        />
      }
      <Reader bookId={bookId} />
      <LoadingForStudent />
    </>
  );
};

export default BookForStudent;
