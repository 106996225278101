import { useEffect } from "react";
import { useStore, StoreTypes } from "context";
import * as types from "constants/actionTypes";

export const useOnlineCheckInit = () => {
    const [_, dispatch] = useStore(StoreTypes.global);
    const online=()=>{
        dispatch({ type: types.IS_ONLINE_CHECK ,isOnline:true});
    }
    const offline=()=>{
        dispatch({ type: types.IS_ONLINE_CHECK, isOnline: false });
    }
    useEffect(() => {
            // window.addEventListener("online", online);
            // window.addEventListener("offline", offline);
            // return () => {
            //     window.removeEventListener("online", online);
			// 	window.removeEventListener("offline", offline);
            // }
    }, [])
}