import { useReaderEventHandler } from 'events/eventHandlers/ReaderEventHandler';
import { useUserEventHandler } from 'events/eventHandlers/UserEventHandler';
import { useExtendedContentEventHandler } from 'events/eventHandlers/ExtendedContentEventHandler'
import { useInitializeActivity } from 'components/AppService/ActivityInitializer';
import { useGAPageView } from 'components/AppService/GAService';
import { useOnlineCheckInit } from "components/AppService/OnlineCheck";
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { getBookIdFromNative } from 'util/native';

let refreshInterval
const useMobileRedirect = () => {
    const history = useHistory();
    useEffect(()=>{
        if (!window.ios && !window.android) return;
        clearInterval(refreshInterval);
        refreshInterval = setInterval(() => {
            const bookId = getBookIdFromNative(); 
            // console.group(`${bookId}`);
            // console.groupEnd();
            bookId && history.push(`/${bookId}`);
            bookId && clearInterval(refreshInterval);
        }, 100)
        return () => {
            clearInterval(refreshInterval);
        }
    })
}

export const AppService = () => {
    useGAPageView();
    useMobileRedirect();
    useReaderEventHandler();
    useUserEventHandler();
    useInitializeActivity();
    useExtendedContentEventHandler();
    useOnlineCheckInit();
    return null;
};
