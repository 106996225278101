import { useCallback } from 'react';
import { PlatformType } from 'constants/platformType';
import { getPlatform } from 'util/platform';

export const useExternalWindowCommand = () => {
    const execute = useCallback(async ({ json: { src } }) => {
        if (window.electron) {
            window.electron && window.electron.ipcRenderer.send("openExternal", src);
            return
        }

        // if (getPlatform() === PlatformType.Electron) {
        //     const { BrowserWindow } = window.remote;
        //     const win = new BrowserWindow({ width: 800, height: 600 })
        //     win.loadURL(encodeURI(src))
        //     win.on('close', function (e) {
        //         win.destroy();  // this will bypass onbeforeunload and close the app
        //     });
        // } else {
            window.open(encodeURI(src));
        // }
    }, []);

    return { execute };
};
