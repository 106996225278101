import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loadingAnimation from "./groupInteractiveToolLoading.json";
import styles from "./index.module.scss";
import { StoreTypes, useStore } from "context";

export const GroupInteractivetToolLoading = () => {
  const [{groupInteractivity: { loading }}] = useStore(StoreTypes.global)
	
	return (
    loading && <div
      className={styles.backdrop}
    >
      <Player loop autoplay src={loadingAnimation} style={{ marginBottom: "5%" }} />
      <div className={styles.text}>正在派出...</div>
    </div>
  );
};

export const LoadingForStudent = () => {
  const [{paiNote: {loading}}] = useStore(StoreTypes.global);

  return (
    loading && <div
      className={styles.backdrop}
    >
      <Player loop autoplay src={loadingAnimation} style={{ marginBottom: "5%" }} />
      <div className={styles.text}>正在截取螢幕畫面...</div>
    </div>
  );
}