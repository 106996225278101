import React from "react";
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { ReaderToolType } from 'constants/ReaderTools';
import { withTranslation } from 'react-i18next';

export const active = { backgroundColor: "#aaa", color: "#fff" };

const PanelState = {
    None: 'None',
    Pen: 'Pen',
    Highlighter: 'Highlighter',
    Shape: 'Shape',
    Line: 'Line',
    Expression: 'Expression',
    MeasureTools: 'MeasureTools'
};

let updateState;
let neglect = false;
const ActivatableReaderToolComponent = ({ t, currentReaderToolType, type, additionalClickHandler, nowPanelState }) => {    
    const doubleClickToolPanel = {
        Painting: ReaderToolType.Painting,
        Highlighter: ReaderToolType.Painting
    }

    if (type === ReaderToolType.Select) {
        if (updateState !== PanelState.None && updateState !== nowPanelState) {
            neglect = true;
        } else {
            neglect = false;
        }
        if (nowPanelState) {
            updateState = nowPanelState
        }
    }

    return (
        <Button
            key={type}
            onClick={(e) => {
                toolsEventMap[type].clickHandler();
                if (type === ReaderToolType.Painting || type === ReaderToolType.Highlighter) {
                    if (currentReaderToolType === type && !neglect) {
                        additionalClickHandler && additionalClickHandler();
                    }
                }else{
                    additionalClickHandler && additionalClickHandler();
                }
            }}
            title={`${t(toolsEventMap[type].title)}${(type === "Painting" || type === 'Highlighter') ? "(雙擊可開啟設定面板)" : ""}`} // hover tooltip
            inlineStyle={currentReaderToolType === type && active}
        >
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ActivatableReaderToolComponent);
