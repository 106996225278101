import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { StudentActivityState, QuizType, ChoiceButtons, HandWritingMode, ActivityStatus } from "constants/groupInteractivity";
import { useLocation, useParams } from "react-router-dom";
import { fabric } from "fabric";
import styles from "./index.module.scss";
import mainBg from "./studentMainBg.svg";
import answerBg from "./studentAnswerBg.svg";
import {
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import {
  pencilForToolbar,
  StudentChoiceQuizButton,
  StudentQuizActionButton,
  textForToolbar,
} from "../CustomButtons";
import Icon from "components/Icon";
import { Player } from "@lottiefiles/react-lottie-player";
import timeOut from "./timeOut.json";
import submit from "./submit.json";
import { IconButton } from "@material-ui/core";
import { useStore } from "context";
import { StoreTypes } from "context";
import { interactivityApi } from "../api";
import uuid from "util/uuid";
import { WarnDialog } from "../WarnDialog";
import { ExtendedContentType } from "components/ExtendedContent/ExtendedContentContainer";
import StudentInfoInputField from "../StudentInfoInputField";

const linearProgressStyles = makeStyles({
  root: {
    height: "8px",
    marginTop: ".5em",
    background: "white",

    "& .MuiLinearProgress-bar": {
      background: "linear-gradient(90deg, #0C53B7 0%, #74CAFF 98.47%)",
    },
  },
});

const studentInfoField = {
  studentNum: "座號*",
  studentName: "姓名",
  studentGroup: "組別",
};

const canvasToolbar = [
  {
    mode: HandWritingMode.Pencil,
    icon: pencilForToolbar,
  },
  {
    mode: HandWritingMode.Eraser,
    icon: <Icon type="text" name="eraser" />,
  },
  {
    mode: HandWritingMode.Text,
    icon: textForToolbar,
  },
  {
    mode: HandWritingMode.Delete,
    icon: <Icon type="text" name="trashAlt" />,
  },
];

const AnswerHeader = ({ studentInfo, quizInfo }) => {
  return (
    <div className={styles.answerHeader}>
      <div>
        <Icon type="text" name="user" />
        <span>
          第 {studentInfo.studentGroup} 組｜
          {("0" + studentInfo.studentNum).slice(-2)} {studentInfo.studentName}
        </span>
      </div>
      <span className={styles.total}>共 {quizInfo.quizNum} 題</span>
    </div>
  );
};

const AnswerInteractiveToolStudent = (props) => {
  const { QRCode } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const teacherId = searchParams.get('user');
  const [{ activityInfo: { firestore } }] = useStore(StoreTypes.annotation);
  const [studentActivityState, setStudentActivityState] = useState(
    StudentActivityState.Join
  );
  const [quizInfo, setQuizInfo] = useState({
    id: "",
    quizGroup: 3,
    quizType: QuizType.MultipleChoice,
    quizNum: 4,
    choiceNum: 5,
    status: ActivityStatus.Created
  });
  const [studentInfo, setStudentInfo] = useState({
    studentId: uuid(),
    studentName: "",
    studentNum: "",
    studentGroup: 1,
  });
  const [studentInfoErrors, setStudentInfoErrors] = useState({});
  const [currentProgress, setCurrentProgress] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [tempCanvas, setTempCanvas] = useState([]);
  const [handWritingMode, setHandWritingMode] = useState(
    HandWritingMode.Pencil
  );
  const [shakeChoice, setShakeChoice] = useState(false);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const answerCanvas = useRef(null);
  const canvasContainer = useRef(null);
  const fabricCanvas = useRef(null);
  const linearStyle = linearProgressStyles();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const windowInnerHeight = () => {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", windowInnerHeight);
    window.addEventListener("load", windowInnerHeight)

    return () => {
      window.removeEventListener("resize", windowInnerHeight);
      window.removeEventListener("load", windowInnerHeight);
    }
  })

  const resizeCanvas = (fabricCanvas) => {
    const ratio =
      canvasContainer.current.clientWidth / answerCanvas.current.clientWidth;
    fabricCanvas.setWidth(canvasContainer.current.clientWidth);
    fabricCanvas.setHeight("488");
    const objects = fabricCanvas.getObjects();
    objects.forEach((obj) => {
      // obj.top = obj.top * ratio;
      obj.left = obj.left * ratio;
      obj.scaleX = obj.scaleX * ratio;
      obj.scaleY = obj.scaleY * ratio;
      obj.setCoords();
    });
    fabricCanvas.renderAll();
  };

  const changeHandWritingMode = (handWritingMode) => {
    setHandWritingMode(handWritingMode);
    // eslint-disable-next-line default-case
    switch (handWritingMode) {
      case HandWritingMode.Eraser:
        fabricCanvas.current.isDrawingMode = false;
        fabricCanvas.current.selection = true;
        fabricCanvas.current.skipTargetFind = false;
        break;
      case HandWritingMode.Text:
        fabricCanvas.current.isDrawingMode = false;
        fabricCanvas.current.selection = false;
        fabricCanvas.current.skipTargetFind = true;
        break;
      case HandWritingMode.Delete:
        setOpenWarnDialog(true);
        break;
      case HandWritingMode.Pencil:
        fabricCanvas.current.isDrawingMode = true;
        fabricCanvas.current.selection = false;
        fabricCanvas.current.skipTargetFind = true;
        break;
    }
  };

  useEffect(() => {
    if (quizInfo.quizType !== QuizType.HandWriting || studentActivityState !== StudentActivityState.Answer) return;
    const canvas = new fabric.Canvas(answerCanvas.current, {
      isDrawingMode: true,
      skipTargetFind: true,
      selection: false,
    });
    canvas.setWidth(canvasContainer.current.clientWidth);
    canvas.setHeight(488);
    canvas.backgroundColor = "white";
    canvas.freeDrawingBrush.color = "DodgerBlue";
    canvas.freeDrawingBrush.width = 5;
    canvas.renderAll();

    fabricCanvas.current = canvas;

    const resizeHandler = () => resizeCanvas(canvas);
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [quizInfo.quizType, studentActivityState]);

  useEffect(() => {
    if (quizInfo.quizType !== QuizType.HandWriting || studentActivityState !== StudentActivityState.Answer) return;
    fabricCanvas.current.on("mouse:up", (e) => {
      if (handWritingMode === HandWritingMode.Eraser) {
        fabricCanvas.current
          .getActiveObjects()
          .forEach((obj) => fabricCanvas.current.remove(obj));
      }
      if (handWritingMode === HandWritingMode.Text) {
        if (e.target) return;
        const text = new fabric.IText("", {
          fontSize: 40,
          fontWeight: 700,
          charSpacing: 100,
          left: e.pointer.x,
          top: e.pointer.y,
          fill: "#333",
          backgroundColor: "transparent",
          fontFamily: "sans-serif",
        });
        fabricCanvas.current.add(text).setActiveObject(text);
        text.enterEditing();

        fabricCanvas.current.selection = true;
        fabricCanvas.current.skipTargetFind = false;
      }
    });

    return () => {
      fabricCanvas.current.discardActiveObject().renderAll();
      fabricCanvas.current.off("mouse:up");
    };
  }, [ currentProgress, handWritingMode, quizInfo.quizType, studentActivityState, tempCanvas]);

  //取得測驗資料
  useEffect(() => {
    if (!firestore || studentActivityState !== StudentActivityState.Join) return;
    const unsuscribe = firestore.collection(`teachers/${teacherId}/quizzes`).where("QRCode", "==", QRCode).where("status", "==", "created").onSnapshot(snapshot => {
      if (snapshot.docs.length === 0) return;
      const data = snapshot.docs[0].data();
      setQuizInfo(prev => ({
        ...prev,
        id: data.id,
        quizGroup: data.quizGroup,
        quizNum: data.quizNum,
        quizType: data.quizType,
        choiceNum: data.choiceNum
      }))
    });

    return () => {
      unsuscribe();
    }
  }, [QRCode, firestore, teacherId,studentActivityState])

  //監聽測驗終止
  useEffect(() => {
    if (!firestore || !quizInfo.id || studentActivityState === StudentActivityState.Submit) return;
    const unsuscribe = firestore.collection(`teachers/${teacherId}/quizzes`).where("id", "==", quizInfo.id).onSnapshot(snapshot => {
      const data = snapshot.docs[0].data();
      if (data.status === "done") {
        setQuizInfo(prev => ({
          ...prev,
          status: data.status
        }))
        setStudentActivityState(StudentActivityState.Submit)
      }
    })

    return () => {
      unsuscribe();
    }
  }, [firestore, quizInfo.id, quizInfo.quizType, studentActivityState, teacherId])

  const backgroundImage = useMemo(() => {
    switch (studentActivityState) {
      case StudentActivityState.Answer:
        return answerBg;
      case StudentActivityState.Join:
      default:
        return mainBg;
    }
  }, [studentActivityState]);

  const updateStudentInfo = (key, value) => {
    setStudentInfo(prev => ({
      ...prev,
      [key]: value
    }));
    
    const newError = {...studentInfoErrors};
    delete newError[key];
    setStudentInfoErrors(newError);
  }
  
  const updateAnswer = useCallback(
    (option) => {
      setAnswers((prev) => {
        const arr = [...prev];
        arr[currentProgress - 1] = option;
        return arr;
      });
    },
    [currentProgress]
  );

  const updateTempCanvas = useCallback((option) => {
    setTempCanvas((prev) => {
      const arr = [...prev];
      arr[currentProgress - 1] = option;
      return arr
    });
  }, [currentProgress])

  const formatAnswers = useCallback((type, currentAnswer = answers) => {
    // eslint-disable-next-line default-case
    switch(type) {
      case QuizType.HandWriting:
        return currentAnswer.map((dataURL) => {
          const byteString = atob(dataURL.split(",")[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: "image/jpeg" });
        });
      case QuizType.MultipleChoice:
      case QuizType.TrueFalse:
        return currentAnswer.join(',');
    }
  }, [answers])

  return (
    <div
      className={`${styles.bg} ${
        studentActivityState === StudentActivityState.Join && styles.centered
      }`}
      style={{ backgroundImage: `url(${backgroundImage})`, minHeight: windowHeight }}
    >
      {studentActivityState === StudentActivityState.Join && (
        <>
          <div className={styles.headText}>請輸入資料後開始作答</div>
          <div className={styles.inputCard}>
            <StudentInfoInputField
              studentInfoField={studentInfoField}
              studentInfo={studentInfo}
              studentInfoErrors={studentInfoErrors}
              updateStudentInfo={updateStudentInfo}
              group={quizInfo.quizGroup}
            />
            <div className={styles.actionBtn}>
              <StudentQuizActionButton
                text="開始"
                color="#212B36"
                borderColor="#FFA235"
                background="#FFE1AD"
                handleClick={() => {
                  const newErrors = {};
                  if (!quizInfo.id) {
                    alert("目前沒有正在進行中的測驗，請重新整理再試");
                    return;
                  }
                  if (!studentInfo.studentNum) newErrors.studentNum = "請選擇座號";
                  if (Object.keys(newErrors).length !== 0) {
                    setStudentInfoErrors(newErrors);
                    return;
                  }
                  interactivityApi[ExtendedContentType.AnswerInteractiveTool]
                    .createStudent({
                      teacherId,
                      quizId: quizInfo.id,
                      student: { 
                        ...studentInfo,
                        ...!studentInfo.studentNum && {studentNum: "-"}
                       },
                    })
                    .then((res) => {
                      if (res.status === "success") {
                        setStudentActivityState(StudentActivityState.Answer);
                      }
                    });
                }}
              />
            </div>
          </div>
        </>
      )}
      {studentActivityState === StudentActivityState.Answer && (
        <div className={styles.answerSection}>
          <AnswerHeader quizInfo={quizInfo} studentInfo={studentInfo} />
          <LinearProgress
            classes={linearStyle}
            variant="determinate"
            value={(currentProgress / quizInfo.quizNum) * 100}
          />
          <div className={styles.optionArea} ref={canvasContainer}>
            {(quizInfo.quizType === QuizType.TrueFalse ||
              quizInfo.quizType === QuizType.MultipleChoice) && (
              <>
                <div className={styles.quizNo}>第 {currentProgress} 題</div>
                <div className={styles.option}>
                  {ChoiceButtons[quizInfo.quizType]
                    .slice(0, quizInfo.choiceNum)
                    .map((option) => (
                      <div
                        className={`${styles.btn} ${
                          shakeChoice && styles.shake
                        }`}
                      >
                        <StudentChoiceQuizButton
                          text={option}
                          selected={option === answers[currentProgress - 1]}
                          handleClick={() => updateAnswer(option)}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
            {quizInfo.quizType === QuizType.HandWriting && (
              <div
                className={`${styles.canvasArea} ${
                  shakeChoice && styles.shake
                }`}
              >
                <canvas ref={answerCanvas} className={styles.answerCanvas} />
                <div className={styles.canvasToolbar}>
                  {canvasToolbar.map((tool) => (
                    <IconButton
                      key={tool.mode}
                      className={handWritingMode === tool.mode && styles.active}
                      onClick={() => {
                        changeHandWritingMode(tool.mode);
                      }}
                    >
                      {tool.icon}
                    </IconButton>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles.answerActionBtn}>
            {currentProgress > 1 && (
              <div className={styles.btn}>
                <StudentQuizActionButton
                  startIcon={<Icon type="text" name="chevronLeft" />}
                  text="上一題"
                  color="#454F5B"
                  background="#D4DDE5"
                  borderColor="#637381"
                  handleClick={() => {
                    if (quizInfo.quizType === QuizType.HandWriting) {
                      updateTempCanvas(fabricCanvas.current.toJSON()); //暫存 canvas
                      fabricCanvas.current.loadFromJSON(tempCanvas[currentProgress - 2]); //讀上一題的 json
                    }
                    setCurrentProgress((prev) => prev - 1);
                  }}
                />
              </div>
            )}
            {currentProgress !== quizInfo.quizNum ? (
              <div className={styles.btn}>
                <StudentQuizActionButton
                  endIcon={<Icon type="text" name="chevronRight" />}
                  text="下一題"
                  color="white"
                  background="#315D83"
                  borderColor="#091A3E"
                  handleClick={() => {
                    if (quizInfo.quizType === QuizType.HandWriting) {
                      const handWriteAnswer = fabricCanvas.current.toJSON();
                      if (handWriteAnswer.objects.length === 0) {
                        setShakeChoice(true);
                        setTimeout(() => {
                          setShakeChoice(false);
                        }, 500);
                        return;
                      }
                      updateTempCanvas(handWriteAnswer); //暫存
                      updateAnswer(
                        fabricCanvas.current.toDataURL({
                          format: "jpeg",
                          quality: 0.8,
                        })
                      );
                      fabricCanvas.current
                        .getObjects()
                        .forEach((obj) => fabricCanvas.current.remove(obj)); //清除 canvas
                      fabricCanvas.current.loadFromJSON(tempCanvas[currentProgress]); //讀下一題的 json
                    } else {
                      if (!answers[currentProgress - 1]) {
                        setShakeChoice(true);
                        setTimeout(() => {
                          setShakeChoice(false);
                        }, 500);
                        return;
                      }
                    }
                    setCurrentProgress((prev) => prev + 1);
                  }}
                />
              </div>
            ) : (
              <div className={styles.btn}>
                <StudentQuizActionButton
                  endIcon={<Icon type="text" name="paperPlane" />}
                  text="交卷"
                  color="#212B36"
                  background="#FFE1AD"
                  borderColor="#FFA235"
                  handleClick={() => {
                    let fabricCanvasAnswers;
                    if (quizInfo.quizType === QuizType.HandWriting) {
                      const handWriteAnswer = fabricCanvas.current.toJSON();
                      if (handWriteAnswer.objects.length === 0) {
                        setShakeChoice(true);
                        setTimeout(() => {
                          setShakeChoice(false);
                        }, 500);
                        return;
                      }
                      updateTempCanvas(fabricCanvas.current.toJSON());
                      fabricCanvasAnswers = [...answers];
                      fabricCanvasAnswers.push(
                        fabricCanvas.current.toDataURL({
                          format: "jpeg",
                          quality: 0.8,
                        })
                      );
                      updateAnswer(
                        fabricCanvas.current.toDataURL({
                          format: "jpeg",
                          quality: 0.8,
                        })
                      );
                    } else {
                      if (!answers[currentProgress - 1]) {
                        setShakeChoice(true);
                        setTimeout(() => {
                          setShakeChoice(false);
                        }, 500);
                        return;
                      }
                    }
                    interactivityApi[ExtendedContentType.AnswerInteractiveTool]
                      .updateAnswer({
                        teacherId,
                        quizId: quizInfo.id,
                        studentId: studentInfo.studentId,
                        answers: formatAnswers(
                          quizInfo.quizType,
                          fabricCanvasAnswers
                        ),
                      })
                      .then((res) => {
                        if (res.status === "success") {
                          setStudentActivityState(StudentActivityState.Submit);
                        }
                      });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {studentActivityState === StudentActivityState.Submit && (
        <div className={styles.submitSection}>
          <AnswerHeader quizInfo={quizInfo} studentInfo={studentInfo} />
          <Player
            loop
            autoplay
            src={quizInfo.status === ActivityStatus.Done ? timeOut : submit}
            style={{ width: "20%", marginTop: "6%" }}
          />
          <span className={styles.mainText}>
            {quizInfo.status === ActivityStatus.Done ? "作答時間已到" : "答案已送出"}
          </span>
          <span className={styles.subText}>
            請等待老師說明，再點擊「重新整理」按鈕
          </span>
          <div className={styles.btn}>
            <StudentQuizActionButton
              text="重新整理"
              color="#212B36"
              borderColor="#FFA235"
              background="#FFE1AD"
              handleClick={() => {
                setQuizInfo({
                  id: "",
                  quizGroup: 3,
                  quizType: QuizType.MultipleChoice,
                  quizNum: 4,
                  choiceNum: 4,
                  status: ActivityStatus.Created,
                });
                setAnswers([]);
                setTempCanvas([]);
                setCurrentProgress(1);
                setStudentInfo((prev) => ({ ...prev, studentId: uuid() }));
                setStudentActivityState(StudentActivityState.Join);
              }}
            />
          </div>
        </div>
      )}
      <WarnDialog
        open={openWarnDialog}
        title="清除作答畫布"
        subTitle="確定要清除此頁所有作答畫記紀錄嗎？"
        handleCancel={() => setOpenWarnDialog(false)}
        handleConfirm={() => {
          fabricCanvas.current
            .getObjects()
            .forEach((obj) => fabricCanvas.current.remove(obj));
          setHandWritingMode(HandWritingMode.Pencil);
          setOpenWarnDialog(false);
        }}
      />
    </div>
  );
};

export default AnswerInteractiveToolStudent;
