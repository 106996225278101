import React, { useState, useEffect } from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import classnames from 'classnames'

const ColorSelector = ({ 
  value, 
  colors, 
  onChange = () => { }, 
  customizedColor = true, 
  transparentOption = false,
  opacity = 1
}) => {
  
  const [inColors,setState] = useState(true);

  useEffect(()=>{
    if(!value)return;
    const hasColor = colors.findIndex(color => ~value.indexOf(color)) > -1;
    setState(hasColor)
  },[colors, value])


  return (
      <div className={styles.colorSelector}>
        {
          colors.map(color => 
          <div key={color} className={classnames(styles.color, { [styles.active]: value && ~value.indexOf(color) })} style={{ backgroundColor: color }} onClick={() => onChange(color)}>
            <div className={styles.icon}>
                <Icon  type="text" name="check"/>
              </div>
          </div>)
        }
        {
          customizedColor && (
            <div className={classnames(styles.color, styles.customizedColor, { [styles.active]: !inColors })}>
              <label>
                <div className={styles.icon}>
                  <Icon  type="text" name="check"/>
                </div>
                <input type="color" onChange={(e) => onChange(e.target.value)} value={value} />
              </label>
            </div>
          )
        }
        {
          transparentOption && (
            <div className={classnames(styles.color, styles.transparent)} onClick={(e) => onChange('')}>
                <Icon type="text" name="ban"/>
            </div>
          )
        }
      </div>
  )
}

export default ColorSelector;
