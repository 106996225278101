import React, { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import Tab from 'components/Tab';
import ObjectStylePanel from './ObjectStylePanel';
import PropertyForm from 'components/Tools/PropertyForm';
import { Roles } from 'constants/role';

const tabData = [{ id: 0, label: '物件樣式' }, { id: 2, label: '互動物件' }];

export const CanvasObjectPropertyViewer = () => {
    const [{ role }] = useStore(StoreTypes.user);
    const [{ activeCanvasObject }] = useStore(StoreTypes.canvas);
    const [currentTabIndex, setTabIndex] = useState(0);
    const data = useRef([]);

    useEffect(() => {
        if (role !== Roles.EDITOR) {
            data.current = tabData.slice(0, 1);
        } else {
            data.current = tabData;
        }
    }, [role]);

    return (
        <>
            {
                data.current.length > 1 &&
                <Tab
                    data={data.current}
                    onChange={index => setTabIndex(index)}
                    currentIndex={currentTabIndex}
                />
            }
            <div className={styles.panelContainer}>
                {currentTabIndex === 0 && <ObjectStylePanel />}
                {currentTabIndex === 1 && <PropertyForm object={activeCanvasObject} />}
            </div>
        </>
    );
};
