import { falseCross } from "components/GroupInteractiveTool/CustomButtons";
import { trueCircle } from "components/GroupInteractiveTool/CustomButtons";

export const ActivityType = {
  AnswerInteractiveTool: "快問快答",
  PaiNote: "派筆記",
}

export const ActivityState = {
  Create: "Create",
  Wait: "Wait",
  Review: "Review",
  PreviewHandWrite: "PreviewHandWrite"
};

export const QuizType = {
  MultipleChoice: "選擇題",
  TrueFalse: "是非題",
  HandWriting: "手寫題",
};

export const ActivityStatus = {
  Created: "created",
  Done: "done"
}


export const StudentActivityState = {
  Join: "Join",
  Answer: "Answer",
  Submit: "Submit"
}

export const HandWritingMode = {
  Pencil: "Pencil",
  Eraser: "Eraser",
  Text: "Text",
  Delete: "Delete"
}

export const ChoiceButtons = {
	[QuizType.MultipleChoice]: ['Ａ', 'Ｂ', 'Ｃ', 'Ｄ', 'Ｅ'],
	[QuizType.TrueFalse]: ['true', 'false']
}

export const trueFalseMap = {
  'true': trueCircle,
  'false': falseCross
}