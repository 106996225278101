import React, { useEffect } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import Repository from "repositories/Repository";
import { useStore, StoreTypes } from "context";
import "./content.scss";
import { parseChineseOnly } from "util/zhuyin";
const { ExtendedResourceRepository } = Repository;

const WordRadicalContent = ({ htmlPath, imgPath }) => {
  const [{ bookId, books }] = useStore(StoreTypes.books);
  const [{ html }, setState] = useSetState({ html: "" });

  const book=books.find(info=>info.bookId===bookId)
  const elementaryHighGradeCheck =
    book.display_name.includes("國小國語5") ||
    book.display_name.includes("國小國語6");

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: htmlPath ? htmlPath.split(".json")[0] : "",
      });
      setState({ html: htmlJson.html });
    };
    htmlPath && getHtml();
  }, [bookId, htmlPath, setState]);

  return (
    <div className={styles.wordRadicalContent}>
      {imgPath && (
        <img
          src={ExtendedResourceRepository.getDataSrc({
            bookId,
            pathName: imgPath,
          })}
          alt=""
        />
      )}
      {html && (
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: elementaryHighGradeCheck ? parseChineseOnly(html,"wordingLearning") : html,
          }}
        ></div>
      )}
    </div>
  );
};

export default WordRadicalContent;
