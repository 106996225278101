import React from 'react';
import { timeFormatTommss } from 'util/timeFormater';

import classnames from 'classnames';
import styles from './index.module.scss';



const TimeInfo = ({ currentTime, endTime, className }) => {

  return (
    <div className={classnames(styles.timeInfo, className)}>
      <span>{timeFormatTommss(currentTime)}</span> / <span>{timeFormatTommss(endTime)}</span>
    </div>
  )
}

export default TimeInfo;
