// const parser = new DOMParser();

// export const mergeSVG = (a, b) => {
//     const parentNode = parser.parseFromString(a, 'text/html');
//     const parentSVG = parentNode.querySelector('svg');
//     for (let child of parser.parseFromString(b, 'text/html').querySelector('svg').childNodes) {
//         if (child.outerHTML && !parentSVG.innerHTML.includes(child.outerHTML)) {
//             parentSVG.innerHTML += child.outerHTML;
//         }
//     }

//     return parentNode.querySelector('svg').outerHTML;
// };

export const mergeCanvasJSON = (from, to) => {
    const convertArrayToMap = (acc, obj) => {
        if (obj.id) {
            acc[obj.id] = obj;
        }
        return acc;
    };
    const formObjectMap = from.objects.reduce(convertArrayToMap, {});
    const toObjectMap = to.objects.reduce(convertArrayToMap, {});
    for (let id in formObjectMap) {
        if (!toObjectMap[id]) {
            to.objects.push(formObjectMap[id]);
        }
    }

    return to;
};
