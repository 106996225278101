import { useCallback } from 'react';
import { StoreTypes, useStore } from 'context';
import * as types from 'constants/actionTypes';

export const useControlImageCommand = () => {
    const [{ interactiveObjectState }, bookDispatch] = useStore(StoreTypes.books);
    const execute = useCallback(async ({ json, pageIndex, isDoublePageMode }) => {
        const baseIndex = pageIndex - pageIndex % 2;
        let foundItem = null;

        for (let item of interactiveObjectState.state) {
            if (item.pageIndex.includes(pageIndex)) {
                foundItem = item;
                break;
            }
        }

        //如果Display是否存在
        if (!foundItem) {
            //創建新的display
            foundItem = {
                pageIndex: isDoublePageMode ? [baseIndex, baseIndex + 1] : [pageIndex],
                image: json.controlObject.split(",").map(objId => ({ id: objId, src: json.src }))
            };
            interactiveObjectState.state.push(foundItem);
        } else {
            foundItem.image = json.controlObject.split(",")
                .filter((objId) => foundItem.image.map(image => image.id).includes(objId))
                .map(objId => ({ id: objId, src: json.src }))
        }

        bookDispatch({type: types.SET_INTERACTIVE_OBJECTS_STATE, interactiveObjectState: { ...interactiveObjectState, version: interactiveObjectState.version ? interactiveObjectState.version + 1 : 1 }})
    }, [interactiveObjectState, bookDispatch]);

    return { execute };
};
