import React from 'react';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';

export const ReaderToolActivityAttendeeNumber = ({ type, attendeeNumber }) => {
    return (
        <div key={type} title={toolsEventMap[type].title}>
            {(attendeeNumber === "admin") ? "老師" : attendeeNumber}
        </div>
    );
};
