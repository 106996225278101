import React, { useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import { RectQuizButton } from "../CustomButtons";
import styles from "./index.module.scss";
import { useStore } from "context";
import { StoreTypes } from "context";
import { ActivityState } from "constants/groupInteractivity";
import { ExtendedContentType } from "components/ExtendedContent/ExtendedContentContainer";
import Draggable from "react-draggable";
import useModal from "components/common/Modal/useModal";
import { usePaiNoteCommand } from "customHooks/InteractiveObjectContentCommands/paiNoteCommand";
import { useAnswerInteractiveToolCommand } from "customHooks/InteractiveObjectContentCommands/answerInteractiveToolCommand";
import { ActivityType } from "constants/groupInteractivity";

const needMiniWindow = [
  ExtendedContentType.AnswerInteractiveTool,
  ExtendedContentType.PaiNote,
];

export const MiniWindow = () => {
  const [
    {
      groupInteractivity: { activityState, activityInfo },
      modal: { isHiding },
    },
  ] = useStore(StoreTypes.global);
  const activityType = activityInfo.activityType;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { toggleHideModal }] = useModal();
  const PaiNoteCommand = usePaiNoteCommand();
  const AnswerInteractiveToolCommand = useAnswerInteractiveToolCommand();

  const QRCodeValue = useMemo(() => {
    const domain = window.location.href.includes("demo")
      ? "https://reader-demo.oneclass.com.tw"
      : process.env.REACT_APP_READER_DOMAIN;

    switch (activityType) {
      case ExtendedContentType.AnswerInteractiveTool:
        return `${domain}/answerInteractiveTool/${activityInfo.QRCode}?user=${activityInfo.teacherId}`;
      case ExtendedContentType.PaiNote:
        return `${domain}/bookForStudent/${bookId}?code=${activityInfo.QRCode}&user=${activityInfo.teacherId}`;
      default:
        return;
    }
  }, [activityType, activityInfo, bookId]);

  const MiniWindowTitle = useMemo(() => {
    switch (activityType) {
      case ExtendedContentType.AnswerInteractiveTool:
        return ActivityType.AnswerInteractiveTool;
      case ExtendedContentType.PaiNote:
        return ActivityType.PaiNote;
      default:
        return "";
    }
  }, [activityType]);

  const reOpenGroupInteractivityTool = (readerToolType) => {
    switch (readerToolType) {
      case ExtendedContentType.AnswerInteractiveTool:
        AnswerInteractiveToolCommand.execute({ canvasSVGObject: null });
        break;
      case ExtendedContentType.PaiNote:
        PaiNoteCommand.execute({ canvasSVGObject: null });
        break;
      default:
        return null;
    }
  };

  return (
    needMiniWindow.includes(activityType) && activityState !== ActivityState.Create && (
      <Draggable>
        <div className={styles.miniWindow}>
          <span>{MiniWindowTitle}</span>
          {activityState === ActivityState.Wait && (
            <QRCodeSVG value={QRCodeValue} size="200px" />
          )}
          <RectQuizButton
            width="75%"
            text="還原視窗"
            color="#212B36"
            borderColor="#FFA235"
            background="#FFE1AD"
            handleClick={() => {
              reOpenGroupInteractivityTool(activityType);
              toggleHideModal(false);
            }}
          />
        </div>
      </Draggable>
    )
  );
};
