import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';


const RangeSlider = ({
  className,
  value,
  onChange,
  max,
  min,
  step 
}) => (
  <input type="range"
      className={classnames(styles.rangeSlider,className)}
      max={max}
      min={min}
      step={step}
      value={value}
      onChange={onChange}
    />
)

export default RangeSlider;