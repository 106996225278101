import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import useSetState from 'customHooks/setState';
import WordContentList from 'components/WordContentList';
import { WordLearningContentWrapper } from 'components/WordLearningGrid/WordLearningContent';
import { convertArrayToMap } from 'util/array';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const WordGlyph = ({ dataList = [], defaultWord }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ list, listMap, currentWord }, setState] = useSetState({
    list: [],
    listMap: {},
    currentWordIndex: 0,
    currentWord: defaultWord || dataList[0].Name,
  });


  useEffect(() => {
    Promise.all(
      dataList.map(word =>
        ExtendedResourceRepository.getJSONContent({
          bookId,
          pathName: word.Path
        })
      )
    ).then(list => {
      const listData = list.filter(item => item && item.GlyphSet.length > 0).map(item => ({ ...item, Word: item.Name }));
      setState({
        list: listData,
        listMap: convertArrayToMap(listData, 'Word')
      })
    });
  }, [bookId, dataList, setState]);


  const wordsChangeHandler = ({ word }) => {
    setState({ currentWord: word });
  };

  const convertData = data =>
    Object.entries(data).map(([key, item]) => ({ label: item.Label || item.Word || item.label || item.word, value: key, relatedWords: [key] }))

  return (
    <div className={styles.wordGlyph}>
      <div className={styles.content}>
        {listMap[currentWord] &&
          listMap[currentWord].GlyphSet.map(item => (
            <div className={styles.item} key={item.Content || item.Audio || item.Path}>
              <WordLearningContentWrapper
                data={[item]}
              />
            </div>
          ))}
      </div>
      {
        Object.keys(listMap).length > 0 &&
        <WordContentList
          word={currentWord}
          className={styles.wordContentList}
          data={convertData(listMap)}
          onChange={wordsChangeHandler}
        />
      }
    </div>
  )
}

export default WordGlyph;
