import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';


export const WordExampleComponent = ({ object, saveProperty }) => {

  let wordExampleInfo = object.wordExampleInfo ? JSON.parse(object.wordExampleInfo) : { src: '', word: '' }

  const changeHandler = (infoType, value) => {
    wordExampleInfo[infoType] = value;
    saveProperty(JSON.stringify(wordExampleInfo))
  };

  return (
    <>
      <label>
        <input type="checkbox" onChange={e => changeHandler('isZhuyin', e.target.checked)} checked={wordExampleInfo.isZhuyin} />
        首冊
      </label>
      <Input className={styles.wordExampleInput} label="Src" value={wordExampleInfo.src} onChange={e => changeHandler('src', e.target.value)} />
      <Input className={styles.wordExampleInput} label="Word" value={wordExampleInfo.word} onChange={e => changeHandler('word', e.target.value)} />
    </>
  )
};