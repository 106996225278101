import { interactivityApi } from "components/GroupInteractiveTool/api";
import * as types from "constants/actionTypes";
import { ReaderToolType } from "constants/ReaderTools";
import { useStore, StoreTypes } from "context";
import { useState, useEffect, useMemo } from "react";
import { chain, uniq, map } from "lodash";

const recordBadge = [
	ReaderToolType.AnswerInteractiveTool,
	ReaderToolType.PaiNote
]

export const useGetGroupInteractiveToolPages = (type) => {
  const [{ isDoublePageMode, recordPages }, readerDispatch] = useStore(StoreTypes.reader);
  const [{ userId, token }] = useStore(StoreTypes.user);
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ annotationId }] = useStore(StoreTypes.annotation);
  
  useEffect(() => {
		if(!recordBadge.includes(type)) return;
    interactivityApi[type]
      .get(token, {
        teacherId: userId,
        annotationId,
        bookId,
      })
      .then((res) => {
        if (res.status === "success") {
          const { data } = res;
          const pages = data.reduce((arr, data) => {
						const tempArr = Array.isArray(data.pageIndex) ? [...data.pageIndex] : [data.pageIndex];
						return arr.concat(tempArr);
          }, []);
          readerDispatch({
            type: types.SET_RECORD_PAGES,
            toolType: type,
            pages: uniq(pages)
          });
        }
      })
      .catch((err) =>
        console.log("get groupInteractiveTool error", err)
      );
  }, [annotationId, bookId, token, userId, readerDispatch, type]);

  const groupInteractiveToolPages = useMemo(() => {
		return chain(recordPages[type]).map(page => isDoublePageMode ? Math.floor(page / 2) : page).uniq().value();
  }, [isDoublePageMode, recordPages, type]);

  return groupInteractiveToolPages;
};
