import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { ReaderZoomType, ReaderToolType } from 'constants/ReaderTools';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';
import { useStore, StoreTypes } from "context";
import { EASINGS } from "tldraw";

// import rightTop from "assets/icon/four-zoom-in/right-top.svg";
// import rightBottom from "assets/icon/four-zoom-in/right-bottom.svg";
// import leftTop from "assets/icon/four-zoom-in/left-top.svg";
// import leftBottom from "assets/icon/four-zoom-in/left-bottom.svg";

const FourZoomIn = () => {
  const [{ tldrawEditor }] = useStore(StoreTypes.tldraw);
    const map = {
        [ReaderZoomType.RightTop]: "/assets/icon/four-zoom-in/right-top.svg",
        [ReaderZoomType.RightBottom]: "/assets/icon/four-zoom-in/right-bottom.svg",
        [ReaderZoomType.LeftTop]: "/assets/icon/four-zoom-in/left-top.svg",
        [ReaderZoomType.LeftBottom]: "/assets/icon/four-zoom-in/left-bottom.svg"
    }

    const fourZoomClickHandler = useCallback((type) => {
        gaEventReaderToolsClick({
            category: CategoryType.ReaderTool,
            action: ReaderToolType.FourZoomIn,
            label: ReaderToolType.FourZoomIn
        });

        const shapes = tldrawEditor.getCurrentPageShapes();
        let testBound;
        shapes.forEach((shape) => {
            if (shape.type === "bookPage") {
                testBound = tldrawEditor.getShapePageBounds(shape.id)
            }
        });

        switch (type) {
            case ReaderZoomType.LeftTop:
                testBound = { ...testBound, x: 0, y: 0, h: testBound.h / 2 }
                break;
            case ReaderZoomType.LeftBottom:
                testBound = { ...testBound, x: 0, y: testBound.h / 2, h: testBound.h / 2 }
                break;
            case ReaderZoomType.RightTop:
                testBound = { ...testBound, x: testBound.w, y: 0, h: testBound.h / 2 }
                break;
            case ReaderZoomType.RightBottom:
                testBound = { ...testBound, x: testBound.w, y: testBound.h / 2, h: testBound.h / 2 }
                break;
            default:
                break;
        }
        tldrawEditor.zoomToBounds(testBound, {
            inset: 0,
            animation: { duration: 500, easing: EASINGS.easeInOutCubic },
        });
    },[tldrawEditor])

    return (
        <div className={styles['four-zoom-in']}>
            <div className={styles.box}>
                <img src={map[ReaderZoomType.LeftTop]} alt="" title="左上角" onClick={() => fourZoomClickHandler(ReaderZoomType.LeftTop)} />
                <img src={map[ReaderZoomType.RightTop]} alt="" title="右上角" onClick={() => fourZoomClickHandler(ReaderZoomType.RightTop)} />
            </div>
            <div className={styles.box}>
                <img src={map[ReaderZoomType.LeftBottom]} alt="" title="左下角" onClick={() => fourZoomClickHandler(ReaderZoomType.LeftBottom)} />
                <img src={map[ReaderZoomType.RightBottom]} alt="" title="右下角" onClick={() => fourZoomClickHandler(ReaderZoomType.RightBottom)} />
            </div>
        </div>
    )
}

export default FourZoomIn;