import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderZoomType} from 'constants/ReaderTools';

const { ExtendedResourceRepository } = Repository;

export const useWordGridCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { wordGridInfo } }) => {
        const { src, word, wordsSetSrc, idiomSetSrc } = JSON.parse(wordGridInfo);
        try {
            const response = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: src });
            const wordsSetSrcResponse = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: wordsSetSrc });
            const idiomSetSrcResponse = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: idiomSetSrc });
            const component = ModalComponentGenerator.generate(ModalComponentType.WordGrid, {
                wordGridResource: Object.values(response),
                defaultWord: word,
                wordsResource: wordsSetSrcResponse,
                idiomResource: idiomSetSrcResponse
            });
            setModalComponents(component);
            openModal();
            EventBus.emit({
                event: ReaderToolsEvent.SetReaderZoomEvent,
                payload: { type: ReaderZoomType.OriginZoom }
            })
        } catch (e) {
            console.error('WordGridCommand execute error', e);
        }
    }, [openModal, setModalComponents, bookId]);

    return { execute };
};
