import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './index.module.scss';
import classnames from 'classnames';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Icon from 'components/Icon';
import './editor.scss';

const ToolButtonType = {
    colors: {
        yellow: "黃",
        green: "綠",
        blue: "藍",
        red: "紅",
        purple: "紫",
    },
    tools: {
        close: "縮小",
        delete: "刪除"
    }
}

const colorCodes = {
    [ToolButtonType.colors.yellow]: "#ffe692",
    [ToolButtonType.colors.green]: "#7efe02",
    [ToolButtonType.colors.blue]: "#02d0fe",
    [ToolButtonType.colors.red]: "#ff75af",
    [ToolButtonType.colors.purple]: "#bf75ff"
}

const qlSizeOptions = [
    { value: "24px", text: "24px" },
    { value: "36px", text: "36px", selected: true },
    { value: "48px", text: "48px" },
    { value: "72px", text: "72px" },
    { value: "96px", text: "96px" }
]

const CustomToolbar = () => (
    <div id="toolbar">
        <select title="Size" className="ql-size" >
            {
                qlSizeOptions.map((obj, index) => {
                    return <option key={index} value={obj.value} selected={obj.selected} >{obj.text}</option>
                })
            }
        </select>
        <select className="ql-color">
            <option value="red"></option>
            <option value="green"></option>
            <option value="blue"></option>
            <option value="orange"></option>
            <option value="violet"></option>
            <option value="#d0d1d2"></option>
            <option selected></option>
        </select>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-bold"></button>
        <button className="ql-strike"></button>
    </div>
)

const Size = Quill.import("formats/size");
Size.whitelist = qlSizeOptions.map((obj) => obj.text)
Quill.register(Size, true);

const StickyNoteComponent = ({ canvasSVGObject }) => {
    const { id } = canvasSVGObject
    // const html = 
    const [html, setEditorState] = useState(canvasSVGObject.info.text);
    const [infoObject, setInfoObject] = useState(canvasSVGObject);
    // const [isMove, setIsMove] = useState(false);
    const [bgColor, setBGColor] = useState(canvasSVGObject.info.color);
    // const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 })
    const rectRef = useRef();
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const [, { closeModal }] = useModal();

    // const getDeviceXY = (e) => {
    //     let deviceX;
    //     let deviceY;
    //     if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    //         deviceX = e.nativeEvent.touches[0].pageX
    //         deviceY = e.nativeEvent.touches[0].pageY;
    //     } else {
    //         if (e.nativeEvent + "" === "[object TouchEvent]") {
    //             deviceX = e.nativeEvent.touches[0].pageX
    //             deviceY = e.nativeEvent.touches[0].pageY;
    //         } else {
    //             deviceX = e.pageX
    //             deviceY = e.pageY;
    //         }
    //     }
    //     return { deviceX, deviceY }
    // }

    const handleChange = (content, delta, source, editor) => {
        const el = editor.getHTML();
        setEditorState(el)
        infoObject.info.text = el;
        setInfoObject(infoObject);
    }

    // for moving stickyNote
    // const mouseDownHandler = useCallback((e) => {
    //     if (e.target !== rectRef.current) return;
    //     setIsMove(true)
    //     const { deviceX, deviceY } = getDeviceXY(e)
    //     let { left, top } = rectRef.current.style;
    //     if (left !== "") {
    //         left = parseInt(left.split("px")[0])
    //         top = parseInt(top.split("px")[0])
    //     } else {
    //         left = rectRef.current.offsetLeft;
    //         top = rectRef.current.offsetTop;
    //     }

    //     setRect({ x: deviceX - left, y: deviceY - top })
    // }, [])

    // const mouseMoveHandler = useCallback((e) => {
    //     if (!isMove) { return; }
    //     const { deviceX, deviceY } = getDeviceXY(e)
    //     const { x, y } = rect;
    //     rectRef.current.style.top = `${(deviceY - y)}px`
    //     rectRef.current.style.left = `${(deviceX - x)}px`
    // }, [isMove, rect])

    // const mouseUpHandler = useCallback((e) => {
    //     setIsMove(false);
    // }, [])

    // const colorClickHandler = (e) => {
    //     setBGColor(colorCodes[e.target.getAttribute("customType")])
    //     infoObject.info.color = colorCodes[e.target.getAttribute("customType")]
    //     EventBus.emit({ event: ReaderEvent.SaveCanvasSVGObjectEvent, payload: { annotationId, id, canvasSVGObject: infoObject } });
    // }

    const toolClickHandler = (e) => {
        EventBus.emit({ event: ReaderEvent.SaveCanvasSVGObjectEvent, payload: { annotationId, id, canvasSVGObject: infoObject } });
        closeModal();
    }

    useEffect(() => {
        var qlSize = document.querySelector("#toolbar .ql-size");
        qlSize.addEventListener("mousedown", function (event) {
            if (qlSize) {
                qlSize.classList.add("ql-expanded");
            }
        });

        var qlColor = document.querySelector("#toolbar .ql-color");
        qlColor.addEventListener("mousedown", function (event) {
            if (qlColor) {
                qlColor.classList.add("ql-expanded");
            }
        });
    }, [])

    // for sticky note moving
    return (
        <div
            // className={classnames(styles.stickyContainer, styles[isMove ? 'fullDragRange' : null])}
            className={classnames(styles.stickyContainer)}
        // onMouseDown={mouseDownHandler}
        // onMouseMove={mouseMoveHandler}
        // onMouseUp={mouseUpHandler}
        >

            <div className={styles.sticky}
                style={{
                    backgroundColor: bgColor,
                }}
                ref={rectRef}
            >
                <div className={styles.stickyContent}
                    style={{
                        //backgroundColor: bgColor,
                    }}
                >
                    <div className={styles.topTools}
                        style={{
                            backgroundColor: bgColor,
                        }}
                    >
                        <div className={styles.closeBtn} onClick={(e) => toolClickHandler(e)}>
                            <Icon name={'StickyNoteMin'} />
                            <div className={styles.closeBtnText}>
                                {ToolButtonType.tools.close}
                            </div>
                        </div>
                    </div>
                    {
                        <ReactQuill
                            //style={{ height: "100%" }}
                            className={styles.editor}
                            theme="snow"
                            value={html}
                            onChange={handleChange}
                            //modules={{ toolbar: toolbarOptions }}
                            modules={StickyNoteComponent.modules}
                            formats={StickyNoteComponent.formats}
                        />
                    }
                    <div className={styles.htmlTools}>
                        <CustomToolbar />
                    </div>
                    <div className={styles.bottomTools}
                        style={{
                            backgroundColor: bgColor,
                        }}
                    >
                        {/* <div customType={ToolButtonType.colors.yellow} style={{backgroundColor:colorCodes[ToolButtonType.colors.yellow]}} onClick={(e) => colorClickHandler(e)}></div>
                        <div customType={ToolButtonType.colors.green} style={{backgroundColor:colorCodes[ToolButtonType.colors.green]}} onClick={(e) => colorClickHandler(e)}></div>
                        <div customType={ToolButtonType.colors.blue} style={{backgroundColor:colorCodes[ToolButtonType.colors.blue]}} onClick={(e) => colorClickHandler(e)}></div>
                        <div customType={ToolButtonType.colors.red} style={{backgroundColor:colorCodes[ToolButtonType.colors.red]}} onClick={(e) => colorClickHandler(e)}></div>
                        <div customType={ToolButtonType.colors.purple} style={{backgroundColor:colorCodes[ToolButtonType.colors.purple]}} onClick={(e) => colorClickHandler(e)}></div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StickyNoteComponent;

StickyNoteComponent.modules = {
    toolbar: {
        container: "#toolbar"
    },
    clipboard: {
        matchVisual: false,
    }
};

/*
 * Quill StickyNoteComponent formats
 * See https://quilljs.com/docs/formats/
 */
StickyNoteComponent.formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color"
];
