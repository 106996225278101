export const getDeviceXY = (e) => {
    let deviceX;
    let deviceY;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        deviceX = e.nativeEvent.touches[0].pageX
        deviceY = e.nativeEvent.touches[0].pageY;
    } else {
        if (e.nativeEvent + "" === "[object TouchEvent]") {
            deviceX = e.nativeEvent.touches[0].pageX
            deviceY = e.nativeEvent.touches[0].pageY;
        } else {
            deviceX = e.pageX
            deviceY = e.pageY;
        }
    }
    return { deviceX, deviceY }
};
