import React from "react";
import Draggable from "react-draggable";
import styles from "./index.module.scss";
import Icon from "components/Icon";

const DragModal = ({
  content,
  bottom,
  title,
  showCloseButton,
  onCloseButtonClick,
}) => {
  return (
    <div className={styles.dragMask}>
      <Draggable
        handle={`.${styles.draglHead}`}
        positionOffset={{ x: "-50%", y: "-50%" }}
      >
        <div className={styles.dragModal}>
          <div className={styles.draglHead}>
            <h1 className={styles.dragTitle}>{title}</h1>
            {showCloseButton && (
              <div
                className={styles.dragCloseButton}
                onClick={onCloseButtonClick}
              >
                <Icon className={styles.closeIcon} type="text" name="times" />
              </div>
            )}
          </div>
          <div className={styles.dragContent}>{content}</div>
          <div className={styles.dragBottom}>{bottom}</div>
        </div>
      </Draggable>
    </div>
  );
};

export default DragModal;
