import React,{ useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

import styles from './index.module.scss';

const AngleSelector = ({ target:object }) => {
    const setAngleHandler = useCallback((property,value) => {
        if (object) {
            EventBus.emit({
                event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
                payload: { object, property, value:value%360 }
            });
        }
    }, [object]);
 
    return(
        <div className={styles.angle}>
            <div className={styles.head}>
                <span>角度</span>
                <input type="number" value={object.angle} onChange={e=>{
                        const {value} = e.target;
                        setAngleHandler('angle',parseInt(value));
                }}/>
            </div>
            <button onClick={()=>setAngleHandler('angle',90 + parseInt(object.angle))}>旋轉90度</button>
        </div>
    )
};

export default AngleSelector;