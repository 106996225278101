const answerInteractiveToolApi = {
  create: (token, data) => {
    const {QRCode, quizGroup, quizType, quizNum, choiceNum, status, bookId, annotationId, pageIndex} = data;

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/createQuiz`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({QRCode, quizGroup, quizType, quizNum, choiceNum, status, bookId, annotationId, pageIndex}),
    }).then((res) => res.json());
  },
  update: (token, {teacherId, id, status}) => {
    const data = {
      teacherId,
      quizId: id,
      status
    }
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/updateQuiz`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
  },
  get: (token, {teacherId, annotationId, bookId, pageIndex = null}) => {
    const data = {
      teacherId,
      annotationId,
      bookId,
      ...(pageIndex && {pageIndex})
    }
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/getQuiz`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
  },
  createStudent: (data) => {
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/createStudent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json());
  },
  updateAnswer: ({ teacherId, quizId, studentId, answers }) => {
    const form = new FormData();
    form.append("teacherId", teacherId);
    form.append("quizId", quizId);
    form.append("studentId", studentId);
    if (Array.isArray(answers)) {
      for (let i = 0; i < answers.length; i++) {
        form.append("files[]", answers[i], studentId + i);
      }
    } else {
      form.append("answers", answers);
    }

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/updateAnswer`, {
      method: "POST",
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      body: form,
    }).then((res) => res.json());
  },
  getAnswer: (token, {teacherId, id, no}) => 
    {
      const data = {
        teacherId,
        quizId: id,
        quizNo: no
      }
      return fetch(`${process.env.REACT_APP_API_DOMAIN}/quizzes/answer/get`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())}
};

const paiNoteApi = {
  create: (token, data) =>
    {
      const {preparationId, teacherId, bookId, pageIndex, QRCode, status, group} = data;

      return fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/createNote`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({preparationId, teacherId, bookId, pageIndex, QRCode, status, group}),
    }).then((res) => res.json())},
  get: (token, {teacherId, annotationId, bookId, pageIndex}) =>
    {
      const data = {
        teacherId,
        preparationId: annotationId,
        bookId,
        pageIndex
      }

      return fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/getNote`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())},
  update: (token, {teacherId, id, status}) => 
  {
    const data = {
      teacherId,
      noteId: id,
      status
    }

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/updateNote`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json())},
  createStudent: (data) =>
    fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/createStudent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => res.json()),
  updateAnswer: ({ teacherId, noteId, studentId, notes }) => {
    const form = new FormData();
    form.append("teacherId", teacherId);
    form.append("noteId", noteId);
    form.append("studentId", studentId);
    if (Array.isArray(notes)) {
      for (let i = 0; i < notes.length; i++) {
        form.append("files[]", notes[i], studentId + i);
      }
    } else {
      form.append("files", notes);
    }

    return fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/updateAnswer`, {
      method: "POST",
      body: form,
    }).then((res) => res.json())
  },
  getAnswer: (token, {teacherId, id, no}) => 
  {
    const data = {
      teacherId,
      noteId: id,
      noteNo: no
    }
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/notes/answer/get`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json())}
};

export const interactivityApi = {
  'AnswerInteractiveTool': answerInteractiveToolApi,
  'PaiNote': paiNoteApi
}
