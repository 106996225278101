import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
// import FillColorSelector from 'components/Tools/FillColorSelector';
import { useStore, StoreTypes } from "context";
import Repository from "repositories/Repository";
import useSetState from "customHooks/setState";
import {
  ScrollPositionType,
  ScrollDirection,
  ScrollLabel,
} from "constants/scrollPositionType";
import { EventBusType } from "events/EventBus";
import { EventBus } from "events/EventBus";
import { ReaderToolsEvent } from "events/EventTypes";
import { gaEventNextPrevPage } from "components/AppService/GAService";
import { CategoryType } from "constants/gaEventType";
import {ReaderToolType} from 'constants/ReaderTools'
import classnames from "classnames";
import { ScrollPositionButtonStateType } from "constants/scrollPositionType";

const { ExtendedResourceRepository } = Repository;

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedContentButton = (props) => {
  const html =
    props.el.props && props.el.props.html ? props.el.props.html : false;
  const synopsisInfo =
    props.el.props && props.el.props.synopsisInfo
      ? props.el.props.synopsisInfo
      : false;

  // to do...
  const styleVersion = props.el.props
    ? props.el.props.extendedResourceStyle
    : null;
  const schoolYear = styleVersion
    ? styleVersion.split("_").includes("108")
    : false;
  const semester = styleVersion ? styleVersion.split("_").includes("2") : false;

  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ scrollPosition, scrollPositionButtonState, fontSize }] = useStore(StoreTypes.extendedContent);
  const { tab, list } = synopsisInfo ? synopsisInfo : {};
  const [{ synopsisList, tabIndex, template }, setState] = useSetState({
    template: "",
    synopsisList: [],
    tabIndex: tab - 1,
  });
  const isAnswers = synopsisList.some((obj) => obj.html.includes("ANSWERS"));

  // --- 小視窗上下移動相關
  const [contentDirection, setContentDirection] = useState(
    ScrollDirection.Vertical
  ); // 小視窗上下頁移動方向
  const buttonListData = [
    { label: ScrollLabel.Next, value: ScrollPositionType.Next, stopSign: ScrollPositionButtonStateType.Bottom, },
    { label: ScrollLabel.Prev, value: ScrollPositionType.Prev, stopSign: ScrollPositionButtonStateType.Head, },
  ];

  const [popwinEl, setPopwinEl] = useState(null);

  useEffect(() => {
    if (synopsisInfo) {
      Promise.all(
        list.map((lists) =>
          ExtendedResourceRepository.getJSONContent({
            bookId,
            pathName: lists.src,
          })
        )
      ).then((obj) => {
        setState({ synopsisList: obj.filter((item) => item) });
      });
    }
  }, [bookId, list, setState, synopsisInfo]);

  useEffect(() => {
    if (html) {
      setState({ template: props.el.props.html });
    }

    if (synopsisInfo && synopsisList.length > 0) {
      setState({ template: synopsisList[tabIndex].html });
    }
  }, [html, props.el.props, setState, synopsisInfo, synopsisList, tabIndex]);

  const hasClass = (el, cls) => {
    return (" " + el.className + " ").indexOf(" " + cls + " ") > -1;
  };

  const toggleShow = (id) => () => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.toggle("show");
    }
  };

  const classToggleShow = () => {
    // 取得DOM上所有的ans class
    var cls = document.querySelectorAll(".ANSWERS");
    if (schoolYear === false && semester === false) {
      cls = document.querySelectorAll(".fill .ans");
    }
    // 所有被取得的class統一做事
    if (cls) {
      let checkCount = 0;
      for (let i = 0; i < cls.length; ++i) {
        if (hasClass(cls[i], "show")) {
          checkCount++;
        }
      }
      for (let i = 0; i < cls.length; ++i) {
        if (checkCount === cls.length) {
          cls[i].classList.remove("show");
          cls[i].classList.remove("next");
        } else {
          cls[i].classList.add("show");
          cls[i].classList.add("next");
        }
      }
    }
  };

  const fontSizeChange = (fontSize) => () => {
      EventBus.emit({
        eventBusType,
        event: ReaderToolsEvent.ChangePopWindowFontSizeEvent,
        payload: { fontSize }
      });
    let el = [];
    const topic = document.getElementsByClassName("topic");
    // const wordSection = document.getElementsByClassName("word_section");
    const plainText = document.getElementsByClassName("plain_text");
    const fill = document.getElementsByClassName("fill");
    const imageSet = document.getElementsByClassName("imageSet");
    const simple = document.getElementsByClassName("simple");

    // 判斷從哪個題型進來
    if (topic.length > 0) {
      el = topic;
    } else if (plainText.length > 0) {
      el = plainText;
    } else if (fill.length > 0) {
      el = fill;
    } else if (imageSet.length > 0) {
      el = imageSet;
    } else if (simple.length > 0) {
      el = simple;
    }
    // else if (wordSection.length > 0) {
    //   el = wordSection;
    // } 

    for (let i = 0; i < el.length; i++) {
      if (el) {
        // 移除 class
        if (hasClass(el[i], "large")) {
          el[i].classList.remove("large");
        } else if (hasClass(el[i], "medium")) {
          el[i].classList.remove("medium");
        } else if (hasClass(el[i], "small")) {
          el[i].classList.remove("small");
        }

        // 根據 size 加入class
        // eslint-disable-next-line default-case

        const title=document.getElementsByClassName("title")[0]
        const imageSet=document.getElementsByClassName("imageSet")[0]
        const abreast=document.getElementsByClassName("abreast")[0]
        if (abreast) {
          abreast.style.height=`${(1-title.clientHeight/imageSet.clientHeight)*100}%`
        }
        switch (fontSize) {
          case "large":
            el[i].classList.add("large");
            break;
          case "medium":
            el[i].classList.add("medium");
            break;
          case "small":
            el[i].classList.add("small");
            break;
        }
      }
    }
  };

  const contentPositionHandler = (scrollDirection) => {
    // console.log("---1號按鈕---");
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionEvent,
      payload: { scrollPosition: scrollDirection },
    });

    const topic = document.getElementsByClassName("topic");
    const wordSection = document.getElementsByClassName("word_section");
    const plainText = document.getElementsByClassName("plain_text");
    const fill = document.getElementsByClassName("fill");
    const imageSet = document.getElementsByClassName("imageSet");
    const simple = document.getElementsByClassName("simple");

    // 判斷從哪個題型進來
    if (topic.length > 0) {
      setPopwinEl(topic);
      setContentDirection(ScrollDirection.Vertical);
    } else if (wordSection.length > 0) {
      setPopwinEl(wordSection);
      setContentDirection(ScrollDirection.Horizontal);
    } else if (plainText.length > 0) {
      setPopwinEl(plainText);
      setContentDirection(ScrollDirection.Vertical);
    } else if (fill.length > 0) {
      setPopwinEl(fill);
      setContentDirection(ScrollDirection.Vertical);
    } else if (imageSet.length > 0) {
      const text = imageSet[0].getElementsByClassName("text")
      setPopwinEl(text);
      setContentDirection(ScrollDirection.Vertical);
    } else if (simple.length > 0) {
      setPopwinEl(simple);
      setContentDirection(ScrollDirection.Vertical);
    }

    if (scrollDirection === ScrollPositionType.Next){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.NextPage,
        label: ScrollLabel.Next,
      });
    } else if (scrollDirection === ScrollPositionType.Prev){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.PrevPage,
        label: ScrollLabel.Prev,
      });
    }
  };

  // useEffect(() => {
  //   if (contentDirection === ScrollDirection.Vertical) {
  //     // console.log("---A類小視窗---ExtendedContentButton---y", popwinEl);
  //     switch (scrollPosition) {
  //       case ScrollPositionType.Next: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           if (popwinEl) {
  //             setLastScrollTop(popwinEl[i].scrollTop);
  //             popwinEl[i].scrollTop += popwinEl[i].offsetHeight*ScrollLength;
  //             setCurrentScrollTop(popwinEl[i].scrollTop);
  //           }
  //         }
  //         break;
  //       }
  //       case ScrollPositionType.Prev: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           if (popwinEl) {
  //             setLastScrollTop(popwinEl[i].scrollTop);
  //             popwinEl[i].scrollTop -= popwinEl[i].offsetHeight*ScrollLength;
  //             setCurrentScrollTop(popwinEl[i].scrollTop);
  //           }
  //         }
  //         break;
  //       }
  //       default: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           scrollPositionButtonBehavior({currentScroll: currentScrollTop, lastScroll: lastScrollTop, coordinate: ScrollCoordinate.Y})
  //         }
  //         break;
  //       }
  //     }
  //   } else {
  //     // console.log("---A類小視窗---ExtendedContentButton---x", popwinEl);
  //     switch (scrollPosition) {
  //       case ScrollPositionType.Next: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           if (popwinEl) {
  //             setLastScrollLeft(popwinEl[i].scrollLeft);
  //             popwinEl[i].scrollLeft -= popwinEl[i].offsetWidth*ScrollLength;
  //             setCurrentScrollLeft(popwinEl[i].scrollLeft);
  //           }
  //         }
  //         break;
  //       }
  //       case ScrollPositionType.Prev: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           if (popwinEl) {
  //             setLastScrollLeft(popwinEl[i].scrollLeft);
  //             popwinEl[i].scrollLeft += popwinEl[i].offsetWidth*ScrollLength;
  //             setCurrentScrollLeft(popwinEl[i].scrollLeft);
  //           }
  //         }
  //         break;
  //       }
  //       default: {
  //         for (let i = 0; i < (popwinEl || []).length; i++) {
  //           scrollPositionButtonBehavior({currentScroll: currentScrollLeft, lastScroll: lastScrollLeft, coordinate: ScrollCoordinate.X})
  //         }
  //         break;
  //       }
  //     }
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contentDirection, popwinEl, scrollPosition]);

  return (
    <>
      {html || synopsisInfo ? (
        <div className={styles.extendedContentButton}>
          <div className={styles.left}>
            {buttonListData.map((item) => (
              <div
                className={`${styles.button} ${styles.scrollBtn}`}
                style={{backgroundColor: scrollPositionButtonState === item.stopSign && "gray"}}
                key={item.value}
                onMouseDown={() => {
                  contentPositionHandler(item.value);
                }}
                onMouseUp={() => {
                  contentPositionHandler(ScrollPositionType.None);
                }}
              >
                {item.label}
              </div>
            ))}

            <div className={classnames(styles.button, {[styles.active]: fontSize === "large"})} onClick={fontSizeChange("large")}>
              大
            </div>
            <div className={classnames(styles.button, {[styles.active]: fontSize === "medium"})} onClick={fontSizeChange("medium")}>
              中
            </div>
            <div className={classnames(styles.button, {[styles.active]: fontSize === "small"})} onClick={fontSizeChange("small")}>
              小
            </div>
          </div>
          <div className={styles.right}>
            {schoolYear === false &&
            semester === false &&
            template.indexOf("='fill'") > -1 ? (
              <div className={styles.button} onClick={classToggleShow}>
                解答
              </div>
            ) : (
              ""
            )}
            {template.indexOf("ANSWERS") > -1 || isAnswers === true ? (
              <div className={styles.button} onClick={classToggleShow}>
                解答
              </div>
            ) : (
              ""
            )}
            {template.indexOf("='answer'") > -1 ? (
              <div className={styles.button} onClick={toggleShow("answer")}>
                答案
              </div>
            ) : (
              ""
            )}
            {template.indexOf("analyze") > -1 ? (
              <div className={styles.button} onClick={toggleShow("analyze")}>
                解析
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
