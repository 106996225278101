import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

const Tab = ({ className ,data = [],currentIndex = 0,onChange=()=>{}}) => {
    return <div className={classnames(styles.tab,className)}>
        {
            data.map((item,index) => 
                <div className={classnames(styles.tabItem,{
                    [styles.active]:index === currentIndex
                })} key={item.id} onClick={()=>onChange(index)}> {item.label} </div>
            )
        }
    </div>
};

export default Tab;