import React, { useEffect } from 'react';
import { Script } from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { scriptLoader } from 'util/scriptLoader'

const StaticHtmlComponent = ({ html, extendedResourceStyle }) => {

  useEffect(()=>{
    if(html){
        scriptLoader(Script[extendedResourceStyle])
    }
},[extendedResourceStyle,html])

  return (
    <div dangerouslySetInnerHTML={{ __html: html }} />
  )
};

export default StaticHtmlComponent;