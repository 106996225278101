import React, { useCallback, useState, useEffect } from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';
import DialogModule from 'components/Dialogs';
import Button from '@material-ui/core/Button';
import PropertyForm from '../index';
import { EventBus, EventBusType } from 'events/EventBus';
import useSetState from 'customHooks/setState';
import { short_uuid } from 'util/uuid';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import { ContentTypeDisplayMap } from 'constants/contentType';

const dialogStatusTypes = {
    add: 'add',
    editor: 'editor'
}

const positions = [
    'top',
    'right',
    'bottom',
    'left'
];

const contentTypeBlackList = [
    InteractiveObjectContentType.Submenu,
    InteractiveObjectContentType.ToggleDisplay,
    InteractiveObjectContentType.ControlDisplay,
    InteractiveObjectContentType.Stage,
    InteractiveObjectContentType.ControlStage,
    InteractiveObjectContentType.Image,
    InteractiveObjectContentType.ControlImage,
    InteractiveObjectContentType.AreaZoom
];

const defaultObj = () => ({
    id: short_uuid()
});

// 為了兼容舊的Submenu 沒有id的情況
const addOldMenuItemId = list => {
    return list.map(item => {
        return item.id ? item : { ...item, id: short_uuid() }
    })
}

export const SubmenuPropertyForm = ({ object, saveProperty }) => {
    const submenuInfo = object.submenuInfo ?
        JSON.parse(object.submenuInfo) :
        { list: [], position: "bottom" };

    const [isDialogShow, setDialogShow] = useState(false);
    const [
        {
            submenuList,
            position,
            currentObject,
            currentIndex
        },
        setState
    ] = useSetState({
        submenuList: addOldMenuItemId(submenuInfo.list),
        position: submenuInfo.position,
        currentObject: defaultObj(),
        currentIndex: 0
    });

    useEffect(() => {
        setState({ currentObject: submenuList[currentIndex] || defaultObj() })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, submenuList])

    const handleAddList = useCallback(() => {
        setDialogShow(true)
        setState({ currentIndex: submenuList.length })
    }, [setState, submenuList])

    const handleSetObject = useCallback(obj => {
        setState({ currentObject: { ...obj } })
    }, [setState])

    const handleNameChange = useCallback(value => {
        currentObject["name"] = value;
        setState({ currentObject })
    }, [currentObject, setState])

    const dialogContent = (
        <div className={styles.dialogContent}>
            <label htmlFor="">Name</label>
            <Input value={currentObject.name || ''} onChange={(e) => handleNameChange(e.target.value)} />
            <PropertyForm
                object={currentObject}
                setObject={handleSetObject}
                contentTypeBlackList={contentTypeBlackList}
                isSubmenu={true}
            />
        </div>
    );

    const handleActionConfirm = useCallback(() => {
        const { name, id, contentType } = currentObject;
        if (!id || !name || !contentType) {
            alert("請輸入ContentType & Name")
            return;
        }

        submenuList[currentIndex] = currentObject;
        setState({ submenuList })
        setDialogShow(false);

        const nextObj = {
            list: submenuList,
            position: position
        };
        saveProperty(JSON.stringify(nextObj));

    }, [currentIndex, currentObject, position, saveProperty, setState, submenuList])

    const handleActionCancal = useCallback(() => {
        setDialogShow(false)
    }, [])

    const dialogAction = (
        <>
            <Button onClick={handleActionCancal} color="primary">
                取消
            </Button>
            <Button autoFocus onClick={handleActionConfirm} color="primary">
                確定
            </Button>
        </>
    )

    const handleModify = useCallback(index => {
        setState({ currentIndex: index });
        setDialogShow(true)
    }, [setState])

    const handleDelete = useCallback((id) => {
        const nextSubmenuList = submenuList.filter(item => item.id !== id);
        setState({
            submenuList: nextSubmenuList,
            currentObject: defaultObj()
        })
        const nextObj = {
            list: nextSubmenuList,
            position: position
        };
        saveProperty(JSON.stringify(nextObj));
    }, [position, saveProperty, setState, submenuList])


    return (
        <div>
            <select
                id="controlStage"
                value={position}
                onChange={e => setState({ position: e.target.value })}
            >
                {positions.map((type, i) => (
                    <option key={i} value={type}>
                        {type}
                    </option>
                ))}
            </select>
            <br />

            {
                submenuList.map((item, index) => {
                    return item.id && item.contentType && (
                        <div key={item.id} className={styles.list}>
                            <div className={styles.listName}>{item.name}</div>
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => handleDelete(item.id)}
                            >刪除</Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="default"
                                onClick={() => handleModify(index)}
                            >修改</Button>
                        </div>
                    )
                })
            }

            <DialogModule
                open={isDialogShow}
                setOpen={setDialogShow}
                content={dialogContent}
                action={dialogAction}
            />
            <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleAddList}>
                增加
            </Button>
        </div>
    )

}
