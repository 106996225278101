import React, { useRef, useEffect, useState } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { useStore, StoreTypes } from 'context';
import Icon from 'components/Icon';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const ReaderToolBox = ({ className, onClose }) => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [{ readerToolDirection }] = useStore(StoreTypes.reader);
    const panelRef = useRef(null);
    const [tools, setTools] = useState([]);

    useEffect(() => {
        bookId && (async () => {
            const tools = await ExtendedResourceRepository.getToolbarTools({ bookId });
            setTools(tools.filter(item => item.type && item.type === 'mathTool'));
        })();
    }, [bookId]);

    // useOutsideAlerter(panelRef, onClose);
    return (
        <div
            ref={panelRef}
            className={classnames(
                styles.popover,
                styles[readerToolDirection],
                { [styles.oneBtn]: tools.length === 1, [styles.twoBtn]: tools.length === 2 },
                className,
            )}
        >
            <div className={styles.closeBtn} onClick={onClose}>
                <Icon type="text" name="times" />
            </div>
            <div className={styles.title}>{'數學工具箱'}</div>
            <div className={styles.content}>
                {tools.map(tool => (
                    <div className={styles.btn} onClick={() => ExtendedResourceRepository.postTools({ bookId, filename: tool.filename })}>
                        <Icon className={styles.icon} name={tool.icon} type='fa' />
                        <div className={styles.text}>{tool.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
