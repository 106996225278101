import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

const Placeholder = props => {
    const router = useStore(StoreTypes.router);
    const [, bookDispatch] = useStore(StoreTypes.books);
    const [, canvasDispatch] = useStore(StoreTypes.canvas);
    let urlParams = new URLSearchParams(props.location.search);
    let bookId = urlParams.get('bookId');

    useEffect(() => {
        canvasDispatch({ type: types.CANVAS_RESET_JSON });
        canvasDispatch({ type: types.CANVAS_RESET_SVG });
        bookDispatch({ type: types.SET_BOOK_ID, payload: bookId });
        router.history.replace({ pathname: `/${bookId}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            Now loading...
        </div>
    )
};

export default Placeholder;
