import { useEffect, useCallback } from 'react';
import { useBoolean } from 'customHooks/boolean';
import { useWindowSize } from 'customHooks/windowSize';
import { useStore, StoreTypes } from 'context';
const useContextMenu = elementRef => {
  const [
    isMenuVisible,
    { setFalse: setMenuClose, setTrue: setMenuShow }
  ] = useBoolean();
  const [{ readerToolHeight }] = useStore(StoreTypes.reader);
  const windowSize = useWindowSize();
  const getMenuPosition = useCallback(
    (rect, { x, y }) => {
      const menuStyles = {
        top: y,
        left: x
      };
      const BUFFER = 10;
      let { width, height } = windowSize;
      // 扣掉工具列 跟 Buffer 算出實際畫面高
      height -= readerToolHeight + BUFFER;
      if (y + rect.height > height) {
        menuStyles.top -= rect.height;
      }
      if (x + rect.width > width) {
        menuStyles.left -= rect.width;
      }
      if (menuStyles.top < 0) {
        menuStyles.top = rect.height < height ? (height - rect.height) / 2 : 0;
      }
      if (menuStyles.left < 0) {
        menuStyles.left = rect.width < width ? (width - rect.width) / 2 : 0;
      }
      return menuStyles;
    },
    [readerToolHeight, windowSize]
  );

  const setPosition = useCallback(
    ({ top, left }) => {
      if (!elementRef.current) return;
      elementRef.current.style.left = `${left}px`;
      elementRef.current.style.top = `${top}px`;
      setMenuShow();
    },
    [elementRef, setMenuShow]
  );

  const windowClickHanlder = useCallback(
    e => {
      if (isMenuVisible) setMenuClose();
    },
    [isMenuVisible, setMenuClose]
  );

  const windowContextmenuHandler = useCallback(
    e => {
      e.preventDefault();
      const rect = elementRef.current.getBoundingClientRect();
      const { top, left } = getMenuPosition(rect, { x: e.pageX, y: e.pageY });
      setPosition({ top, left });
      return;
    },
    [elementRef, getMenuPosition, setPosition]
  );

  useEffect(() => {
    window.addEventListener('click', windowClickHanlder);
    window.addEventListener('contextmenu', windowContextmenuHandler);
    return () => {
      window.removeEventListener('click', windowClickHanlder);
      window.removeEventListener('contextmenu', windowContextmenuHandler);
    };
  }, [elementRef, windowClickHanlder, windowContextmenuHandler]);

  return [isMenuVisible];
};

export default useContextMenu;
