import { urlFormat } from 'util/urlFormat';
import { replaceBookPage } from 'util/book';
import { Roles } from 'constants/role';

import { fileExpressHost, fileExpressPort } from 'repositories/Repository';

import { IBookContentRepository } from './IBookContentRepository';

function FileBookContentRepository() { };
FileBookContentRepository.prototype = Object.create(IBookContentRepository);

FileBookContentRepository.prototype.fetchBooks = async () => {
    let books = [];
    try {
        const res = await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: 'books',
        }));
         books = await res.json();
         return books;
    } catch (err) {
        console.error('fetchBooks error', err);
    }

    return books
};

FileBookContentRepository.prototype.fetchBookList = () => {
    return {
        lists: [],
        total: 0,
        currentPage: 0,
        currentIndex: 0
    };
}

FileBookContentRepository.prototype.fetchShelfBook = () => {
    return [];
}

FileBookContentRepository.prototype.getPurchasedProducts = async () => {
    let books = [];
    try {
        // check for update
        await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: 'update',
        }), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ domain: process.env.REACT_APP_RESOURCE_CDN_DOMAIN })
        });
        const res = await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: 'books',
        }));
        books = await res.json();
    } catch (err) {
        console.error('getPurchasedProducts error', err);
    }
    
    return books || [];
};

FileBookContentRepository.prototype.getBookContent = async ({ book, pages = [] }) => {
    let results = [];
    try {
        
        const fetchingPages = book.pageInfos.filter(page => pages.length === 0 || pages.includes(page.pageIndex));
        const res = await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `page/${book.bookId}`,
            query: { pages: fetchingPages.map(page => page.pageIndex) },
        }));

        const json = await res.json();

        results = fetchingPages.map(page => {
            return replaceBookPage({
                page,
                pageUrl: urlFormat({
                    protocol: 'http:',
                    hostname: fileExpressHost,
                    port: fileExpressPort,
                    pathname: `resource/${book.bookId}/`,
                }),
                str: json[page.pageIndex]
            });
        });

    } catch (err) {
        console.error('getBookContent error', err);
    }
 

    return results;
};

FileBookContentRepository.prototype.getInteractiveObjects = async ({ interactiveObjectId, pages }) => {
   let interactiveObjects = {
        interactiveObjectJSON: {},
        interactiveObjectSVG: {}
    };
    try {
        const res = await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `interactive-object/${interactiveObjectId}`,
            query: { pages },
        }));
        const objects = await res.json();
        objects.forEach(({ pageIndex, json, svg }) => {
            interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
                acc[v.id] = v;
                return acc;
            }, {});
            interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
        });
    } catch (err) {
        console.error('getInteractiveObjects error', err);
    }

    return interactiveObjects;
};

FileBookContentRepository.prototype.getThumbnailUrls = ({ bookId, pages }) => {
    return pages.reduce((acc, page) => {
        acc[page] = urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `thumbnail/${bookId}`,
            query: { page },
        });
        return acc;
    }, {});
};

FileBookContentRepository.prototype.getCoverUrls = ({ bookId }) => {
    return urlFormat({
        protocol: 'http:',
        hostname: fileExpressHost,
        port: fileExpressPort,
        pathname: `books/${bookId}/cover`,
    });
};

FileBookContentRepository.prototype.getBookCatalog = async ({ bookId }) => {
    try {
        const res = await fetch(urlFormat({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `books/${bookId}/catalog`,
        }));
        const result = await res.json();
        return result.data;
    } catch (err) {
        console.error('getBookCatalog error', err);
    }
};

FileBookContentRepository.prototype.getIsShowInteractiveObject = ({ role, interactiveObject }) => {
    if (role === Roles.EDITOR) return true;
    return typeof interactiveObject.isOfflineVisible === 'undefined' ? true : interactiveObject.isOfflineVisible
}

FileBookContentRepository.prototype.fetchTag = () => {
    let tags = [];
    return tags;
}

export default new FileBookContentRepository();
