import React from 'react';
import { ExtendedContentContainer, ExtendedContentType } from 'components/ExtendedContent/ExtendedContentContainer';
import SynopsisWindow from 'components/SynopsisWindow';
import SynopsisUpdate from 'components/SynopsisWindow/SynopsisUpdate';

export const SynopsisModalComponent = ({ synopsisInfo, extendedResourceStyle }) => {
    return (
        <SynopsisUpdate synopsisInfo={synopsisInfo} extendedResourceStyle={extendedResourceStyle}>
            <ExtendedContentContainer extendedResourceStyle={extendedResourceStyle} extendedContentType={ExtendedContentType.Synopsis}>
                <SynopsisWindow synopsisInfo={synopsisInfo} extendedResourceStyle={extendedResourceStyle} />
            </ExtendedContentContainer>
        </SynopsisUpdate>
    )
};
