import React, { useEffect, useMemo, useRef, useState } from "react";
import { StoreTypes } from "context";
import { useStore } from "context";
import { EventBus } from "events/EventBus";
import { InteractiveObjectEvent } from "events/EventTypes";
import { LargerClickAreaStamps } from 'constants/stampTypes';
import { Roles } from 'constants/role';
import Repository from 'repositories/Repository';
import { flat } from 'util/array';
import styles from './index.module.scss';
import {
  Rectangle2d,
  resizeBox,
  ShapeUtil,
  stopEventPropagation,
} from "tldraw";
import useSetState from "customHooks/setState";

const transFormInteractiveObjectSVG = ({ svg, viewWidth, viewHeight }) => {
  svg = svg.replace(`width="100%"`, `width="${viewWidth}"`);
  svg = svg.replace(`height="100%"`, `height="${viewHeight}"`);
  svg = svg.replace("<svg", '<svg preserveAspectRatio="none"');
  return svg;
};

const { BookContentRepository } = Repository;

export class InteractiveObject extends ShapeUtil {
  static type = "interactiveObject";
  getDefaultProps() {
    return {
      w: 500,
      h: 500,
      svg: "",
      json: {},
      bookInfo: {},
    };
  }

  canEdit = () => false;
  canResize = () => false;
  isAspectRatioLocked = () => false;

  getGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.w,
      height: shape.props.h,
      isFilled: true,
    });
  }

  // onResize = (shape, info) => {
  //   return resizeBox(shape, info);
  // };

  component(shape) {
    const {bookInfo} = shape.props;
    const ref = useRef();
    const [ishoverInteractiveObject, setIsHoverInteractiveObject] = useState(false);
    const [{ role }] = useStore(StoreTypes.user);
    const [{ interactiveObjectState }] = useStore(StoreTypes.books);
    const [{ stampControls }] = useStore(StoreTypes.settings);
    const stampGroupsDisabledIds = useMemo(() => stampControls[bookInfo.bookId] || [], [bookInfo.bookId, stampControls])
    const [{ stampGroups }] = useStore(StoreTypes.stamp);
    const [{ musicModal: { activeObjectId, isShowing, vtts = [], isFollowReadMode, currentTime } }] = useStore(StoreTypes.global);
    const [, setState] = useSetState({
      isAlone: false,
    });

    const handleClick = (e) => {
      // if (e.currentTarget.id && e.currentTarget.id.match(/^[\d|a-zA-Z]+$/))  {
        // e.stopPropagation();
        const {pageIndex, isDoublePageMode} = shape.meta;
        EventBus.emit({
          event: InteractiveObjectEvent.ClickInteractiveObjectEvent,
          payload: {
            id: e.currentTarget.id,
            pageIndex: pageIndex,
            isDoublePageMode: isDoublePageMode,
          //   interactiveObjectState,
          //   setInteractiveObjectState,
            target: e.currentTarget
          }
        });
        // alert("點擊互動物件");
      // }
    };

    const hoverdInteractiveObject = () => {
      setIsHoverInteractiveObject(true);
    };

    const unHoverdInteractiveObject = () => {
      setIsHoverInteractiveObject(false);
    };

    useEffect(() => {
      const interactiveObjectNode = ref.current;
      interactiveObjectNode.querySelectorAll("svg>g").forEach((group) => {
        if (group && group.id.match(/^[\d|a-zA-Z]+$/)) {
          group.style.cursor = `pointer`;
          group.addEventListener("pointerover", hoverdInteractiveObject);
          group.addEventListener("pointerup", handleClick);
          group.addEventListener("pointerout", unHoverdInteractiveObject);
        }
      });

      return () => {
        if (interactiveObjectNode) {
          interactiveObjectNode.querySelectorAll("svg>g").forEach((group) => {
            group.style.cursor = `pointer`;
            group.removeEventListener("pointerover", hoverdInteractiveObject);
            group.removeEventListener("pointerup", handleClick);
            group.removeEventListener("pointerout", unHoverdInteractiveObject);
          });
        }
      };
    }, [ref, shape.props.svg, shape.props.w, shape.props.h]);

    useEffect(() => {
      const interactiveObjects = shape.props.json;
      let interactiveObjectsHidden = [];
      let interactiveObjectClickable = [];
      // let interactiveObjectControlObject = [];
      let interactiveObjectControlStage = [];
      let interactiveObjectCankeepHighLight = [];
      let interactiveObjectEmptyContentType = [];
      let interactiveObjectStampsHidden = [];
      const interactiveObjectArr = Object.values(shape.props.json);

      // 與跟讀互動物件有關（英語科）
      let lastActiveObjectId = "";
      vtts.forEach((vtt, index, array) => {
        if (index === 0 && currentTime <= array[index + 1].startTime) {
          lastActiveObjectId = vtt.text;
        }
        if (index !== 0 && index < array.length - 1) {
          if (
            vtt.endTime <= currentTime &&
            currentTime <= array[index + 1].startTime
          ) {
            lastActiveObjectId = vtt.text;
          }
          if (vtt.endTime >= currentTime && currentTime >= vtt.startTime) {
            lastActiveObjectId = vtt.text;
          }
        }
        if (index === array.length - 1 && currentTime >= vtt.startTime) {
          lastActiveObjectId = vtt.text;
        }
      });

      interactiveObjectArr.forEach((item) => {
        // 派筆記學生端欲隱藏「功能」及「按鈕」---start
        if (
          role === Roles.INTERACTIVITY_STUDENT &&
          BookContentRepository.getHiddenInteractiveObjectForStudent({
            interactiveObject: item,
          })
        ) {
          interactiveObjectsHidden.push(item.id);
        }
        if (
          role === Roles.INTERACTIVITY_STUDENT &&
          (item.stampName === "Btn-238" || item.stampName === "Btn-20")
        ) {
          interactiveObjectStampsHidden.push(item.id);
        }
        // 派筆記學生端欲隱藏「功能」及「按鈕」---end

        if (
          !BookContentRepository.getIsShowInteractiveObject({
            role,
            interactiveObject: item,
          })
        ) {
          interactiveObjectsHidden.push(item.id);
        }
        if (item.isClickable === true) {
          interactiveObjectClickable.push(item.id);
        }
        if (item.controlObject && item.contentType === "ControlStage") {
          // 把type為ControlStage的controlObject存起來
          // interactiveObjectControlObject.push(item.controlObject);
          interactiveObjectControlStage.push(item.id);
        }
        if (item.mouseOver && item.contentType === "Audio") {
          interactiveObjectCankeepHighLight.push(item.id);
        }
        if (!item.contentType && !item.style) {
          interactiveObjectEmptyContentType.push(item.id);
        }

        const stampGroupsDisabledNames = flat(
          stampGroupsDisabledIds
            .map((id) => (stampGroups[id] ? stampGroups[id].stamps : null))
            .filter((id) => id)
        );

        if (stampGroupsDisabledNames.includes(item.stampName)) {
          interactiveObjectStampsHidden.push(item.id);
        }
      });

      const checkMouseOver = function (group) {
        return this.interactiveObjects.some(
          (data) => data.id === group.id && data.mouseOver
        );
      }.bind({ interactiveObjects: Object.values(interactiveObjects) });

      let targetObjectId = "";
      // const cloneGroups = clonedSvgNode.querySelectorAll('g');
      // const nextInteractiveObjectControlObject = [...new Set(interactiveObjectControlObject)]
      const interactiveObjectNode = ref.current;
      const groups = interactiveObjectNode.querySelectorAll("svg>g");
      groups.forEach((group, index) => {
        if (LargerClickAreaStamps.includes(group.id)) {
          // 特定按鈕要加大選取範圍
          group.parentElement.parentElement.classList.add(
            styles.largerClickArea
          );
        }
        if (group.id && group.id.match(/^[\d|a-zA-Z]+$/)) {
          const element = groups[index];

          if (interactiveObjectStampsHidden.includes(group.id)) {
            group.style.display = "none";
            element.style.display = "none";
            return;
          } else {
            group.style.display = "block";
            element.style.display = "block";
          }

          if (interactiveObjectsHidden.includes(group.id)) {
            group.style.display = "none";
            element.style.display = "none";
          }

          if (interactiveObjectControlStage.includes(group.id)) {
            // 步驟解的互動物件（小三角形）有一個很大的透明矩形，會干擾點擊所以移除
            const transparentRect = group.querySelector("&>g>g:first-child");
            transparentRect.remove();
          }

          // const addClickable = () => {
          //   group.classList.add(styles.clickable);
          //   element.classList.add(styles.clickable);
          // }

          // const removeClickable = () => {
          //   group.classList.remove(styles.clickable);
          //   element.classList.remove(styles.clickable);
          // }

          // const isPainterClickable = interactiveObjectClickable.includes(group.id);
          // const isContentTypeAndStyleEmpty = interactiveObjectEmptyContentType.includes(group.id);

          //目前要求的書本2960c53f8529edf1 有上下一步的解答 空白處不能出現手指
          // if (nextInteractiveObjectControlObject.length > 0) {
          //   if (isPainterClickable) {
          //     addClickable();
          //   } else {
          //     if (!isActive) {
          //       addClickable();
          //     } else {
          //       removeClickable();
          //     }
          //   }
          // } else {
          //   if (!isActive) {
          //     //互動物件的Style和ContentType 未選擇的時候，也不要出現手指頭
          //     if (isContentTypeAndStyleEmpty) {
          //       removeClickable();
          //     } else {
          //       addClickable();
          //     }

          //   } else {
          //     if (isPainterClickable && !isContentTypeAndStyleEmpty) {
          //       addClickable();
          //     } else {
          //       removeClickable();
          //     }
          //   }
          // }

          if (checkMouseOver(group)) {
            const subGroup = group.querySelector("g");
            if (subGroup) {
              element.querySelector("g").style.opacity = "0";
            }
            element.querySelectorAll("rect").forEach((target) => {
              target.style["mix-blend-mode"] = "normal";
              target.style["opacity"] = "0";
            });
          }

          const handler = (event) => {
            if (targetObjectId !== group.id) return;
            if (!interactiveObjectClickable.includes(group.id)) return;
            event.preventDefault();
            if (interactiveObjectCankeepHighLight.includes(group.id)) {
              const subGroup = group.querySelector("g");
              setState({
                isAlone: true,
              });
              groups.forEach((group) => {
                group.querySelectorAll("rect").forEach((target) => {
                  target.classList.remove(styles.objectHover);
                });
              });
              element.querySelectorAll("rect").forEach((target) => {
                target.classList.remove(styles.objectHover);
              });

              if (subGroup) {
                element.querySelector("g").classList.add(styles.gOpacity);
              }
              element.querySelectorAll("rect").forEach((target) => {
                target.classList.add(styles.objectHover);
              });
            }
          };

          const mouseOverHandler = (event, isImportant) => {
            const subGroup = group.querySelector("g");
            if (subGroup) {
              element.querySelector("g").style.opacity = "1";
            }
            if (isImportant) {
              const vtt = vtts.filter(
                (item) => item.text === (lastActiveObjectId || activeObjectId)
              )[0];
              const endTime = vtt.endTime;

              element.querySelectorAll("rect").forEach((target) => {
                target.classList.add(styles.objectHover);
                if (isFollowReadMode) {
                  if (currentTime >= endTime) {
                    target.classList.remove(styles.objectHover);
                    target.classList.add(styles.studentRead);
                  } else {
                    target.classList.remove(styles.studentRead);
                    target.classList.add(styles.objectHover);
                  }
                }
              });
            } else {
              element.querySelectorAll("rect").forEach((target) => {
                target.style["mix-blend-mode"] = "multiply";
                target.style["opacity"] = 0.3;
              });
            }
          };
          const mouseOutHandler = (event) => {
            const subGroup = group.querySelector("g");
            if (subGroup) {
              element.querySelector("g").style.opacity = "0";
            }
            element.querySelectorAll("rect").forEach((target) => {
              target.style["mix-blend-mode"] = "normal";
              target.style["opacity"] = "0";
            });
          };

          const startHandler = () => {
            targetObjectId = group.id;
          };

          group.onpointerdown = startHandler;
          group.onpointerup = handler;
          if (checkMouseOver(group)) {
            group.onmouseover = mouseOverHandler;
            group.onmouseout = mouseOutHandler;
          }
          if (lastActiveObjectId.indexOf(group.id) > -1) {
            setState({ isAlone: false });
            mouseOverHandler(null, true);
          }
          if (!isShowing) {
            lastActiveObjectId = "";
            element.querySelectorAll("rect").forEach((target) => {
              if (isFollowReadMode) {
                target.classList.remove(styles.objectHover);
                target.classList.remove(styles.studentRead);
              }
            });
          }
        }
      });
    }, [
      shape.props.json,
      vtts,
      currentTime,
      role,
      BookContentRepository,
      stampGroupsDisabledIds,
      stampGroups,
      ref,
      LargerClickAreaStamps,
      styles,
      setState,
      activeObjectId,
      isFollowReadMode,
      isShowing,
    ]);

    useEffect(() => {
      if (!ref.current) return;
      for (let item of interactiveObjectState.state) {
        item.pageIndex.forEach(() => {
          const groups = ref.current.querySelectorAll('svg>g');
          if (groups) {
            for (let displayIndex in item.display) {
              const display = item.display[displayIndex];
              for (let group of groups) {
                if (group.id === display.id) {
                  for (let child of group.children) {
                    if (child.style) {
                      child.style.opacity = display.opacity;
                    }
                  }
                  break;
                }
              }
            }
            for (let stageIndex in item.stage) {
              const stage = item.stage[stageIndex];
              let phases = [];
              for (let group of groups) {
                if (stage.phase.includes(group.id)) {
                  phases.push(group);
                }
              }
              phases.forEach((phase, i) => {
                const phaseIndex = stage.phase.indexOf(phase.id);
                if (~phaseIndex) {
                  const element = phase.children[0];
                  element.style.opacity =
                    stage.revealStage ^ (phaseIndex >= stage.current);
                }
              });
            }
            for (let imageIndex in item.image) {
              const image = item.image[imageIndex];
              for (let group of groups) {
                if (group.id === image.id) {
                  const element = group.children[0];
                  element.setAttributeNS(
                    'http://www.w3.org/1999/xlink',
                    'href',
                    image.src
                  );
                  // replace image from item.src
                  break;
                }
              }
            }
          }
        });
      }

    }, [ref, interactiveObjectState])

    return (
      //   <HTMLContainer
      //     id={shape.id}
      //     style={{
      //       pointerEvents: "all",
      //         height: "100%",
      //         width: "100%",
      //     }}
      //   >
      <div
        style={{ pointerEvents: "all" }}
        onPointerDown={
          ishoverInteractiveObject ? stopEventPropagation : undefined
        }
        dangerouslySetInnerHTML={{
          __html: transFormInteractiveObjectSVG({
            svg: shape.props.svg,
            viewWidth: shape.props.w,
            viewHeight: shape.props.h,
          }),
        }}
        ref={ref}
      ></div>
      //   </HTMLContainer>
    );
  }
}
