import React, { useCallback } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { FontSizeType } from 'constants/fontSizeType';
import { EventBusType } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';

const eventBusType = EventBusType.ExtendedContent;

const buttonListData = [
  {
    label: '大',
    value: FontSizeType.Large
  },
  {
    label: '中',
    value: FontSizeType.Medium
  },
  {
    label: '小',
    value: FontSizeType.Small
  }
];

export const ExtendedFontSizeButton = ({
  value = FontSizeType.Medium
}) => {

  const changePopWindowFontSizeHandler = useCallback(fontSize =>{
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowFontSizeEvent,
      payload: { fontSize }
    });
  },[])

  return (
    <div className={styles.fontSizeButton}>
      {buttonListData.map((item,index) => (
        <div
          className={classnames(styles.button, {
            [styles.active]: value === item.value
          })}
          key={index}
          onClick={() => changePopWindowFontSizeHandler(item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
