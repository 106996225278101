import React from 'react';
import {
  ExtendedContentContainer,
  ExtendedContentType
} from 'components/ExtendedContent/ExtendedContentContainer';
import VocabularyExample from 'components/VocabularyExample';

export const VocabularyModalComponent = ({ vocabularyResource, word }) => {
  return (
    <ExtendedContentContainer
      extendedContentType={ExtendedContentType.Vocabulary}
    >
      <VocabularyExample data={vocabularyResource} defaultWord={word} />
    </ExtendedContentContainer>
  );
};
