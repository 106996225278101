import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { EventBusType } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import {ScrollLabel} from 'constants/scrollPositionType'
import { ScrollPositionType } from 'constants/scrollPositionType';
import { gaEventNextPrevPage } from "components/AppService/GAService";
import { CategoryType } from "constants/gaEventType";
import {ReaderToolType} from 'constants/ReaderTools'

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedPartialButton = ({ canvasState }) => {
  const [{ partialInfo }] = useStore(StoreTypes.extendedContent);
  const { canvasSVGObjectId } = canvasState;
  const {
    viewHeight,
    scrollHeight,
    scrollTop,
    space
  } = partialInfo;

  const onScrollHandler = (type) => {
    let scrollHh = 0
    switch (type) {
      case 'prev':
        scrollHh = (scrollTop <= 0) ? 0 : scrollTop - space;
        break;
      case 'next':
        scrollHh = (scrollTop + viewHeight >= scrollHeight) ? scrollHeight - viewHeight : scrollTop + space;
        break;
      default:
        break;
    }

    if (type === ScrollPositionType.Next){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.NextPage,
        label: ScrollLabel.Next,
      });
    } else if (type === ScrollPositionType.Prev){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.PrevPage,
        label: ScrollLabel.Prev,
      });
    }

    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent,
      payload: { canvasSVGObjectId }
    });

    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetExtendedContentPartialInfo,
      payload: {
        partialInfo: {
          ...partialInfo,
          scrollTop: scrollHh,
        }
      }
    });
  }

  return (
    <div className={styles.partialButton}>
      <div className={classnames(styles.button, { [styles.disabled]: scrollTop <= 0 })}
        onClick={() => onScrollHandler(ScrollPositionType.Prev)}>
        {ScrollLabel.Prev}
      </div>
      <div className={classnames(styles.button, { [styles.disabled]: (scrollTop + viewHeight + 1.5 >= scrollHeight) })}
        onClick={() => onScrollHandler(ScrollPositionType.Next)}>
        {ScrollLabel.Next}
      </div>
    </div>
  );
};
