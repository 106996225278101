import React from 'react';
import BoxModal from 'components/BoxModal'
import * as types from 'constants/actionTypes';
import { StoreTypes, useStore } from 'context'
import styles from './index.module.scss';

const Stopwatch = () => {
  const [_,dispatch] = useStore(StoreTypes.global);

  const onClose = () => {
    dispatch({  type: types.CLOSE_STOPWATCH_MODAL  })
  };

  return (
    <BoxModal onCloseButtonClick={onClose}>
      <div className={styles.stopwatch}>
        <iframe title="stopwatch" src="assets/Stopwatch/stopwatch.html" frameborder="0"></iframe>
      </div>
    </BoxModal>
  )
}

export default Stopwatch;