import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { LOGIN_POPUP } from "constants/loginTypes";

export const useAiSpeakCommand = () => {
    const [, globalDispatch] = useStore(StoreTypes.global);
    const [, readerDispatch] = useStore(StoreTypes.reader);
    const [, loginDispatch] = useStore(StoreTypes.login);
    const [{token}] = useStore(StoreTypes.user);

    const execute = useCallback(async ({ json: { src } }) => {
        if(!token){
            loginDispatch({ type: LOGIN_POPUP, popupState: true });
            return;
        }
        try {
            globalDispatch({ type: types.OPEN_AISPEAK_MODAL })
            let aiSpeakSrc = src;
            if (window.electron && !token) aiSpeakSrc = 'unLogin';
            readerDispatch({type: types.SET_AISPEAK_SRC, aiSpeakSrc})
        } catch (e) {
            console.error('AiSpeakCommand execute error', e);
        }
    }, [globalDispatch, loginDispatch, readerDispatch, token]);

    return { execute };
};
