import React, { useCallback, useEffect } from 'react';
import Input from 'components/common/Input';
import useSetState from 'customHooks/setState';
import styles from './index.module.scss';


const TimePicker = ({ value, onChange }) => {
  const [{ minutes, seconds }, setState] = useSetState({ minutes: 0, seconds: 0 });

  useEffect(() => {
    setState({ minutes: Math.floor((parseInt(value) / 60)), seconds: (parseInt(value % 60)) })
  }, [setState, value])

  const changeHandler = useCallback((key, val) => {
    var re = /[0-9]/g;
    if (!val.match(re)) {
      val = 0
    };
    let min = parseInt(minutes);
    let sec = parseInt(seconds);
    key === 'minutes' ? min = Math.min(parseInt(val), 59) : sec = Math.min(parseInt(val), 59);
    onChange(parseInt(min * 60 + sec));
  }, [minutes, onChange, seconds]);



  return (
    <div className={styles.timePicker}>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          value={minutes}
          onChange={e => changeHandler('minutes', e.target.value)}
        />
      </div>
      <span>:</span>
      <div className={styles.inputWrapper}>
        <Input
          className={styles.input}
          value={seconds}
          onChange={e => changeHandler('seconds', e.target.value)}
        />
      </div>
    </div>
  )
};


export default TimePicker;
