import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as actionTypes from 'constants/actionTypes';
import { useStore, StoreTypes } from 'context';
import { useReadUserSettings, useWriteUserSettings } from 'customHooks/db';
import { UserSettingsAPI } from 'api/UserSettingsAPI';

export const useUpdateDisplayLanguage = () => {
    const [{ userId }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const readUserSettings = useReadUserSettings();
    const { i18n } = useTranslation();

    const updateDisplayLanguage = useCallback(async () => {
        let userSettings = await readUserSettings(userId);
        if (userSettings) {
            userSettingsDispatch({ type: actionTypes.UPDATE_USER_SETTINGS, ...userSettings });
            if (userSettings.language) {
                const changeLanguageHandler = () => {
                    i18n.changeLanguage(userSettings.language);
                };
                if (i18n.isInitialized) {
                    changeLanguageHandler();
                } else {
                    i18n.on('initialized', changeLanguageHandler);
                }
            }
        }
    }, [i18n, readUserSettings, userId, userSettingsDispatch]);

    return { updateDisplayLanguage };
};

export const useUpdateUserSettings = () => {
    const [{ userId, token }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const writeUserSettings = useWriteUserSettings();

    const updateUserSettings = useCallback(async (userSettings, sync = true) => {
        userSettingsDispatch({ type: actionTypes.UPDATE_USER_SETTINGS, ...userSettings });
        await writeUserSettings(userId, userSettings);
        if (sync) {
            await UserSettingsAPI.updateUserSettings(token, userSettings);
        }
    }, [token, userId, userSettingsDispatch, writeUserSettings]);

    return updateUserSettings;
};

export const useUpdateUserFavorites = () => {
    const [{ userId, token }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const writeUserSettings = useWriteUserSettings();

    const updateUserFavorites = useCallback(async (userSettings, sync = true) => {
        userSettingsDispatch({ type: actionTypes.UPDATE_USER_FAVORITES, ...userSettings });
        await writeUserSettings(userId, userSettings);
        if (sync) {
            await UserSettingsAPI.updateUserFavorites(token, userSettings);
        }
    }, [token, userId, userSettingsDispatch, writeUserSettings]);

    return updateUserFavorites;
};

export const useDeleteUserFavorites = () => {
    const [{ userId, token }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const writeUserSettings = useWriteUserSettings();

    const deleteUserFavorites = useCallback(async (userSettings, sync = true) => {
        userSettingsDispatch({ type: actionTypes.DELETE_USER_FAVORITES, ...userSettings });
        await writeUserSettings(userId, userSettings);
        if (sync) {
            await UserSettingsAPI.updateUserFavorites(token, userSettings);
        }
    }, [token, userId, userSettingsDispatch, writeUserSettings]);

    return deleteUserFavorites;
};

export const useUserStampControls = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [{ userId, token }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const writeUserSettings = useWriteUserSettings();

    const updateUserStampControls = useCallback(async (stampControls, sync = true) => {
        userSettingsDispatch({ type: actionTypes.UPDATE_USER_SETTINGS, stampControls });
        await writeUserSettings(userId, { stampControls });
        if (sync) {
            const payload = {
                bookId,
                stampControls: stampControls[bookId]
            }
            await UserSettingsAPI.updateStampControls(token, payload);
        }
    }, [bookId, token, userId, userSettingsDispatch, writeUserSettings]);

    return { updateUserStampControls };
};


