import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useWordExampleCommand = () => {
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { wordExampleInfo } }) => {
        const { src, word, isZhuyin } = JSON.parse(wordExampleInfo);
        try {
            const wordExampleResource = await ExtendedResourceRepository.getJSONContent({ bookId, pathName: src });
            const component = ModalComponentGenerator.generate(ModalComponentType.WordExample, { wordExampleResource, defaultWord: word, isZhuyin });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('WordExampleCommand execute error', e);
        }
    }, [bookId, openModal, setModalComponents]);

    return { execute };
};
