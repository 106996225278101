import React, { useEffect, forwardRef } from 'react';
import Tab from 'components/Tab';
import Icon from 'components/Icon';
import useSetState from 'customHooks/setState';
import { timeFormatTommss } from 'util/timeFormater';
import classnames from 'classnames';
import styles from './index.module.scss';

const TAB_TYPE = {
  SEEK: 'SEEK',
  QUESTION: 'QUESTION'
}

const TAB_MAP = {
  [TAB_TYPE.SEEK]: { id: TAB_TYPE.SEEK, label: '段落' },
  [TAB_TYPE.QUESTION]: { id: TAB_TYPE.QUESTION, label: 'Q&A' }
}


const List = ({
  data,
  onSeekChange = () => { },
  onQuestionSeekChange = () => { }
}) => {
  return (
    <div className={styles.list}>
      {data.map((item, index) => (
        <div
          key={item.title}
          className={styles.listItem}
          onClick={() => {
            onSeekChange(item.time);
            onQuestionSeekChange(item.time, index)
          }}
        >
          <Icon type="text" name="angleRight" />
          <div className={styles.text}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.time}>{timeFormatTommss(item.time)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}


const TabList = forwardRef(({
  showing = false,
  seekList = [],
  questionList = [],
  onSeekChange = () => { },
  onQuestionSeekChange = () => { }
},ref) => {

  const [{ tabData, currentTabIndex, currentTabType }, setState] = useSetState({
    currentTabIndex: 0,
    currentTabType: '',
    tabData: [],
  });

  useEffect(() => {
    const tab = [];

    if (seekList.length > 0) tab.push(TAB_MAP[TAB_TYPE.SEEK]);
    if (questionList.length > 0) tab.push(TAB_MAP[TAB_TYPE.QUESTION])

    if (tab.length > 0) {
      setState({ tabData: tab, currentTabType: tab[0].id });
    }
  }, [questionList, questionList.length, seekList.length, setState])

  return (
    <div ref={ref} className={classnames(styles.tabList, {
      [styles.showing]: showing
    })}>
      <Tab
        data={tabData}
        onChange={(index) => setState({ currentTabIndex: index, currentTabType: tabData[index].id })}
        currentIndex={currentTabIndex}
      />
      {(seekList.length > 0 && currentTabType === TAB_MAP[TAB_TYPE.SEEK]['id']) && (
        <List onSeekChange={onSeekChange} data={seekList} />
      )}
      {(questionList.length > 0 && currentTabType === TAB_MAP[TAB_TYPE.QUESTION]['id']) && (
        <List onQuestionSeekChange={onQuestionSeekChange} data={questionList} />
      )}
    </div>
  )

});


export default TabList;
