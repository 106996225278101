import { useCallback } from 'react';
import { InteractiveObjectControlStageType } from 'constants/interactiveObjectContentTypes';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

export const useControlStageCommand = () => {
    const [{ interactiveObjectState }, bookDispatch] = useStore(StoreTypes.books);
    const execute = useCallback(({ json, pageIndex, isDoublePageMode }) => {
        const revealStage = json.revealStage || false;
        const phase = json.controlObject.split(',');
        let foundItem = interactiveObjectState.state.find(item => item.pageIndex.includes(pageIndex));
        if (foundItem) {
            const stage = foundItem.stage && foundItem.stage.find(stage => stage.phase.every((phaseId) => ~phase.indexOf(phaseId)));
            if (stage) {
                let current = stage.current;
                switch (json.controlStage) {
                    case InteractiveObjectControlStageType.Next:
                        current = Math.min(current + 1, phase.length);
                        break;
                    case InteractiveObjectControlStageType.All:
                        current = current >= phase.length ? 0 : phase.length;
                        break;
                    case InteractiveObjectControlStageType.Previous:
                        current = Math.max(current - 1, 0);
                        break;
                    default:
                        break;
                }
                stage.current = current;
                stage.revealStage = revealStage;
            } else {
                let current = 0;
                switch (json.controlStage) {
                    case InteractiveObjectControlStageType.Next:
                        current = 1;
                        break;
                    case InteractiveObjectControlStageType.All:
                        current = phase.length;
                        break;
                    case InteractiveObjectControlStageType.Previous:
                    default:
                        break;
                }
                foundItem.stage = [{
                    phase,
                    current,
                    revealStage
                }].concat(foundItem.stage || []);
            }
        } else {
            const basePageIndex = pageIndex - pageIndex % 2;
            let current = 0;
            switch (json.controlStage) {
                case InteractiveObjectControlStageType.Next:
                    current = 1;
                    break;
                case InteractiveObjectControlStageType.All:
                    current = phase.length;
                    break;
                case InteractiveObjectControlStageType.Previous:
                default:
                    break;
            }
            interactiveObjectState.state.push({
                pageIndex: isDoublePageMode ? [basePageIndex, basePageIndex + 1] : [pageIndex],
                stage: [{
                    phase,
                    current,
                    revealStage
                }]
            });
        }
        bookDispatch({type: types.SET_INTERACTIVE_OBJECTS_STATE, interactiveObjectState:{ ...interactiveObjectState, version: interactiveObjectState.version ? interactiveObjectState.version + 1 : 1 }})
    }, [interactiveObjectState, bookDispatch]);

    return { execute };
};
