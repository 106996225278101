import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

export const useAreaZoomCommand = () => {
    const execute = useCallback(async ({ json: { areaZoomInfo }, pageIndex, isDoublePageMode, interactiveObjectJSON }) => {
        let areaZoomInteractiveObjects = [];
        let pageIndexInfo = [pageIndex];
        if (isDoublePageMode) {
            if (pageIndex % 2) {
                pageIndexInfo = [pageIndex - 1, pageIndex]
            } else {
                pageIndexInfo = [pageIndex, pageIndex + 1]
            }
        }

        for (let obj in interactiveObjectJSON) {
            if (~pageIndexInfo.indexOf(parseInt(obj))) {
                Object.keys(interactiveObjectJSON[obj]).forEach((objKey) => {
                    const interactiveObject = interactiveObjectJSON[obj][objKey];
                    if (interactiveObject.contentType === InteractiveObjectContentType.AreaZoom) {
                        const step = JSON.parse(interactiveObject.areaZoomInfo).step;
                        areaZoomInteractiveObjects[step] = (JSON.parse(interactiveObject.areaZoomInfo))
                    }

                })
            }
        }

        const areaZoomInfoObject = JSON.parse(areaZoomInfo);
        if (areaZoomInteractiveObjects.length <= 0) {
            areaZoomInteractiveObjects[areaZoomInfoObject.step] =
                areaZoomInfoObject;
        } else {
            // areaZoomInteractiveObjects = areaZoomInteractiveObjects
            //     .filter((obj) => obj.step !== "")
            //     .sort((a, b) => parseInt(a.step) - parseInt(b.step));
        }
        EventBus.emit({
            event: ReaderEvent.AreaZoomForPageButtonEvent,
            payload: {
                rect: areaZoomInfoObject,
                areaZoomInteractiveObjects: areaZoomInfoObject.step !== "" ? {
                    nowStep: areaZoomInfoObject.step,
                    step: areaZoomInfoObject.step,
                    areaZoomInfos: areaZoomInteractiveObjects
                } : null
            }
        })
    }, []);
    return { execute };
};
