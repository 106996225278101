import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';

import { withTranslation } from 'react-i18next';

const ReaderToolPageModeComponent = ({ t, type, isDoublePageMode }) => {
    return (
        <Button
            key={type}
            onClick={() => toolsEventMap[type].clickHandler(isDoublePageMode)}
            title={t(toolsEventMap[type].title[isDoublePageMode])}
        >
            {<Icon name={toolsEventMap[type].icon[isDoublePageMode]} />}
            <div className={styles.text}>{t(toolsEventMap[type].title[isDoublePageMode])}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolPageModeComponent);
