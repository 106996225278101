import { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { UserEvent } from 'events/EventTypes';
import { useEvent, EventBus } from 'events/EventBus';
import { Roles } from 'constants/role';
// import { AnnotationType } from 'constants/annotationTypes';
import { API } from 'api';
import { useDeleteAnnotation } from 'customHooks/db';
import { LOGIN_POPUP, LOGIN_BTN } from 'constants/loginTypes';
import { logoutNaniOneClass, checkNaniOneClass, getThirdLoginToken, getNaniOneClassCookie } from 'components/Login/Popup/login.js';
import { UserSettingsAPI } from 'api/UserSettingsAPI';


export const useUserEventHandler = () => {
    const router = useStore(StoreTypes.router);
    const [{ isLogin }, userDispatcher] = useStore(StoreTypes.user);
    const [, userDispatch] = useStore(StoreTypes.user);
    const [, canvasDispatch] = useStore(StoreTypes.canvas);
    const { deleteAllAnnotation } = useDeleteAnnotation();
    const [, loginDispatch] = useStore(StoreTypes.login);

    const setRoleToLocaleUpperCase = (identity) => {
        let role = null;
        switch (identity) {
            case '教師':
                role = Roles.TEACHER;
                break;
            case 'TEACHER_REMOTE':
                role = Roles.TEACHER_REMOTE;
                break;
            case '編輯':
                role = Roles.EDITOR;
                break;
            case '家長':
                role = Roles.PARENT;
                break;
            case '學生':
                role = Roles.STUDENT;
                break;
            case 'tutor':
                role = Roles.TUTOR;
                break;
            case 'user':
                role = Roles.TUTOR_USER;
                break;
            case 'guest':
            default:
                role = Roles.GUEST;
                break;
        }
        return role;
    }

    const getOneTutorUserInfo = (async ({ studentId, token }) => {
        const options = { headers: { Authorization: token } }
        let userId;
        let name;
        let userName;
        let role;
        const userResult = await API.cloudFuncGet("https://us-central1-onetutor-dev.cloudfunctions.net/users/me", options)
        if (userResult.status === "success") {
            role = setRoleToLocaleUpperCase(userResult.data.role)
            if (studentId) {
                const memberResult = await API.cloudFuncGet("https://us-central1-onetutor-dev.cloudfunctions.net/users/members", options)
                const studentInfo = memberResult.data.members.find((menber) => studentId === menber.id);
                if (studentInfo) {
                    userId = studentInfo.id;//studentInfo.nickname;
                    userName = name = studentInfo.nickname;//studentInfo.nickname;
                } else {
                    role = setRoleToLocaleUpperCase("guest")
                }
            } else {
                if (role === Roles.TUTOR) {
                    userId = userResult.data.id;//userResult.data.lastName + userResult.data.firstName;
                    userName = name = userResult.data.nickname;
                }
            }
            return { userId, name, userName, role, isLogin: true }
        }
    })

    const getOneClubUserInfo = (async ({ token }) => {
        let userId;
        let userName;
        let role;
        const options = { headers: { Authorization: token } }
        const result = await API.cloudFuncGet("https://asia-northeast1-oneclass-onemall.cloudfunctions.net/users/profile", options)
        const { identity, name } = result.data.profile
        userId = result.data.id;
        userName = name;
        role = setRoleToLocaleUpperCase(identity)

        const userResult = await API.cloudFuncGet(`${process.env.REACT_APP_ONELIVE_API}/me`, options)
        let inOrganization = false;
        if (userResult.status === "success") {
            inOrganization = true;
        }
        return { userId, name: userName, userName, role, isLogin: true, inOrganization }
    })

    const isOneClassStudent = ({ users, userId }) => {
        return users.find((name) => name === userId) ? Roles.ONECLASS_STUDENT : Roles.GUEST;
    }

    const getOneClassUserInfo = (async ({ userId, roomId, timeSpanId, token }) => {
        let role = Roles.GUEST;
        let userName;
        const options = { headers: { Authorization: token } }
        const userResult = await API.cloudFuncGet(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/users/me`, options)
        if (userResult.status === "success") {
            userName = userResult.data.nickname;
            const result = await API.cloudFuncGet(`${process.env.REACT_APP_ONECLASS_API_DOMAIN}/sessions/${roomId}?timeSpanId=${timeSpanId}`, options)
            if (result.status === "success") {
                const { hostId, name, users } = result.data
                role = hostId === userId ? Roles.ONECLASS_TEACHER : users ? isOneClassStudent({ users, userId }) : Roles.GUEST;
                return { userId, name: userName, userName, role, isLogin: true, courseInfo: result.data }
            }
        }
        return { role }
    })

    const initUserInfo = useCallback(async ({ roomId, studentId, timeSpanId, token, role, userName, id, remote, bookId }) => {
        const jwt = require('jsonwebtoken');
        const jwtInfo = jwt.decode(token);

        let userInfo = {
            type: types.UPDATE_USER_INFO,
            name: "guest",
            role: Roles.GUEST,
            token,
            userId: jwtInfo ? jwtInfo.username : "",
            isLogin: false,
            isInitialized: true,
            inOrganization: false
        }

        if (!id) {
            try {
                if (token) {
                    //deleteAllAnnotation();
                    if (roomId) {
                        if (timeSpanId) {
                            userInfo = { ...userInfo, ...await getOneClassUserInfo({ token, roomId, timeSpanId, userId: userInfo.userId }) }
                        } else {
                            userInfo = { ...userInfo, ...await getOneTutorUserInfo({ token, studentId }) }
                        }
                    } else {
                        userInfo = { ...userInfo, ...await getOneClubUserInfo({ token }) }
                    }

                    if (userInfo.role === Roles.EDITOR) {
                        canvasDispatch({ type: types.CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR });
                    }
                } else if (router.location.pathname.includes('/bookForStudent/')) {
                    userInfo = {...userInfo, role: Roles.INTERACTIVITY_STUDENT}
                }
            } catch (e) {

            }
        } else {
            const courseInfo = {
                id: id,
                lastName: userName,
                nickname: userName,
                role: role,
                roomId
            }
            userInfo = {
                isLogin: true,
                name: userName,
                role: setRoleToLocaleUpperCase(role),
                type: types.UPDATE_USER_INFO,
                userId: id,
                userName: userName,
                courseInfo: courseInfo,
                inOrganization: false
            };
        }

        userInfo.name = userInfo.name ? userInfo.name : userInfo.userId;
        // console.log(userInfo);
        // console.log("window.location.search", window.location.search);
        const isNanipad = document.referrer.includes('pad.nani.com.tw')
        const isLive = document.referrer.includes('live') && document.referrer.includes('oneclass.com.tw')
        const isNewteacher = document.referrer.includes('newteacher') && document.referrer.includes('oneclass.com.tw')
        const isOneclubBackstage = document.referrer.includes('oneclub.backstage') && document.referrer.includes('oneclass.com.tw')

        const parentDomain = document.referrer ? document.referrer.split("://")[1].split("/")[0] : ''
        let isValidDomain = false;
        const checkDomain = await fetch(
            `${process.env.REACT_APP_API_DOMAIN}/checkValidDomain`,
            {
                method: "POST",
                body: JSON.stringify({
                    domain: parentDomain
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((checkDomain) => checkDomain.json()).catch(err => {
            console.log(err);
        });

        if (checkDomain && checkDomain.status === 'success') {
            isValidDomain = checkDomain.data.isValidDomain;
        }
        //const isNanipad = window.location.search.includes('test')
        if (!window.electron && !isNanipad && !isLive && !isNewteacher && !isOneclubBackstage && !isValidDomain && (userInfo.role === Roles.PARENT || userInfo.role === Roles.STUDENT)) {
            if (!userInfo.inOrganization) {
                alert("您好：\n感謝您對於南一的支持。\n從即日起，南一書局網頁版電子書僅提供教師授課使用，請老師使用教育雲端帳號(Open ID)或南一帳號登入。\n南一在疫情爆發導致大規模停課期間，為共體時艱，並使學習不中斷，全面開放予家長、學生應急使用。\n如今疫情趨緩，在政策下亦不會全面停課，因此，我們將教師用資源恢復為僅限教師身份使用。\n再次感謝您的體諒以及對南一書局的支持與愛戴。")
                loginDispatch({ type: LOGIN_BTN, loginBtn: false })
                userDispatch({ type: types.RESET_USER_INFO });
                try {
                    logoutNaniOneClass();
                } catch (e) {
                    deleteAllAnnotation();
                    window.nanilogout();
                    window.NanilCoollogout();
                }
                router.history.push('/bookshelf')
                return;
            }
        }
        userDispatcher(userInfo);
        return userInfo;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasDispatch, userDispatcher]);

    const fetchToken = useCallback((async (roomId, studentId, timeSpanId, code, role, userName, id, remote, bookId, callback) => {

        let token;
        if (window.android || window.ios) {
            token = getNaniOneClassCookie("mobile_nani_oneclass_login_token");
        } else {
            token = await new Promise((resolve, reject) => {
                checkNaniOneClass((result) => {
                    resolve(result)
                });
            });
        }

        if (!token && code) {
            token = getThirdLoginToken({ code })
        }

        if (!token && window.getNani_token) {
            token = window.getNani_token() && JSON.parse(window.getNani_token()).jwt
        }

        if(router.location.pathname.includes('/bookForStudent/')) { token = null }

        token && await UserSettingsAPI.getMe(token);
        const userInfo = await initUserInfo({ roomId, studentId, timeSpanId, token, role, userName, id, remote, bookId });
        callback && callback(userInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [initUserInfo, userDispatch]);


    const getThirTokenListenerBack = (e, token) => {
        //console.log("getThirTokenListenerBack token", token);
        loginDispatch({ type: LOGIN_POPUP, popupState: false });
        if (token.indexOf("error") > -1) {
            alert("登入失敗!")
        } else {
            localStorage.setItem("nani_tokenInfo", JSON.stringify({ code: "SUCCESS", jwt: token }));
            initUserInfo({ token })
        }
    }

    useEffect(() => {
        fetchToken();
        window.electron && window.electron.ipcRenderer.on("getThirTokenListener", getThirTokenListenerBack)
        return () => {
            window.electron && window.electron.ipcRenderer.removeListener("getThirTokenListener", getThirTokenListenerBack)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchToken])

    const getUserIdentityHandler = useCallback(({ roomId, studentId, timeSpanId, code, role, userName, id, remote, bookId, callback }) => {
        fetchToken(roomId, studentId, timeSpanId, code, role, userName, id, remote, bookId, callback);
    }, [fetchToken])

    const loginHandler = useCallback(async () => {
        if (isLogin) {
            try {
                logoutNaniOneClass();
            } catch (e) {
                deleteAllAnnotation();
                window.nanilogout();
                window.NanilCoollogout();
            }
            //userDispatcher({ type: types.RESET_USER_INFO });
        } else {
            await checkNaniOneClass(token => {
                if (!token) {
                    token = new Promise((resolve, reject) => {
                        window.getLoginNaniCool((result) => {
                            resolve(result)
                        });
                    });
                }
                initUserInfo({ token })
            });
        }
    }, [deleteAllAnnotation, initUserInfo, isLogin]);

    const hardLoginHandler = ({ token }) => {
        token && initUserInfo({ token })
    }

    useEvent({ event: UserEvent.LoginEvent }, loginHandler);
    useEvent({ event: UserEvent.hardLoginEvent }, hardLoginHandler);
    useEvent({ event: UserEvent.GetUserIdentityEvent }, getUserIdentityHandler);
};
