export const CategoryType = {
  InteractiveObject: 'InteractiveObject',
  ReaderTool: 'ReaderTool',
  OnePass: "OnePass",
  SyncAnnotation: 'SyncAnnotation',
  NextPrevPage: 'NextPrevPage',
}

export const CategoryTypeWording = {
  [CategoryType.InteractiveObject]: '互動物件',
  [CategoryType.ReaderTool]: '工具列',
  [CategoryType.OnePass]: 'OnePass',
  [CategoryType.SyncAnnotation]: '紀錄同步',
  [CategoryType.NextPrevPage]: '上下一頁',
}