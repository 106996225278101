import React from 'react';
import { SubtitlesPositionType, SubtitlesFontSizeType } from 'constants/videoSettingType';
import classnames from 'classnames';
import './content.scss';
import styles from './index.module.scss';

const Cue = ({ text }) =>
  <div
    className={styles.cue}
    dangerouslySetInnerHTML={{ __html: text }}
  />

const Subtitles = ({
  activeCue,
  language,
  position = SubtitlesPositionType.DOWN,
  fontSize = SubtitlesFontSizeType.MEDIUM
}) => {
  return (
    <div
      className={classnames(styles.subtitles, styles[position], styles[fontSize])}
    >
      {
        language.map(lang => activeCue[lang] && <Cue key={lang} text={activeCue[lang].text} />)
      }
    </div>
  )
};


export default Subtitles;
