import React from 'react';
import { ExtendedContentContainer } from 'components/ExtendedContent/ExtendedContentContainer';
import StaticHtmlComponent from 'components/StaticHtmlComponent';

export const StaticHtmlModalComponent = ({ extendedResource, extendedResourceStyle }) => {

    return (
        <ExtendedContentContainer extendedResourceStyle={extendedResourceStyle}>
            <StaticHtmlComponent html={extendedResource} extendedResourceStyle={extendedResourceStyle}/>
        </ExtendedContentContainer>
    )
};
