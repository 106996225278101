import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

export const useOnlineCheck = () => {
  const reducers = useStore();
  const [, globalDispatch] = reducers[StoreTypes.global];
	const checkIsOnline = useCallback(async()=>{
      const response = await fetch(`${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}`)
      .then(response => response)
      .catch(err => console.error(err));

      const isOnline=response?true:false;
      globalDispatch({ type: types.IS_ONLINE_CHECK, isOnline});
      return isOnline
    },[globalDispatch])
	return checkIsOnline;
};