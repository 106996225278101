import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Card = ({ className, children, onClick }) => {
  return (
    <div className={classnames(styles.card, className)} onClick={onClick}>
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};

export default Card;
