
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { PreparationAPI } from 'api/PreparationsAPI'

function FilePreparationRepository() { }

FilePreparationRepository.prototype.getPreparationList = async ({
    bookId,
    token
}) => {
    return [];
};

FilePreparationRepository.prototype.getPreparationById = async ({
    id,
    token
}) => {
    return null;
};

FilePreparationRepository.prototype.getPreparationAnnotations = async ({
    id,
    token,
}) => {
    if (token) {
        try {
            let res = await PreparationAPI.getById(
                `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/brief`,
                token
            );
            let annotations = [];
            // let progressMax = 100
            // let progressCount = 0
            if (res.status === 'success') {
                if (res.data.length > 0) {
                    // progressMax = res.data.length
                    let range = 10;
                    let tempArr = []
                    let arr = []
                    for (let i = 0; i < res.data.length; i++) {
                        tempArr.push(res.data[i])
                        if (tempArr.length === range) {
                            arr.push(tempArr)
                            tempArr = []
                        }
                    }
                    arr.push(tempArr)
    
                    annotations = await arr.reduce((promise, indexArr) => {
                        return promise.then(async (results) => {
                            let annotation = []
                            await Promise.all(indexArr.map(pageIndex => PreparationAPI.getById(
                                `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/${pageIndex}`,
                                token
                            ).then(data => {
                                // EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: progressCount / progressMax * 100 } })
                                // progressCount++
                                annotation.push(data.data)
                            })))
                            return [...results, ...annotation]
    
                            // return PreparationAPI.getById(`${process.env.REACT_APP_API_DOMAIN}/preparations/${id}/annotations/${pageIndex}`, token)
                            //     .then(annotation => [...results, annotation.data]);
                        });
                    }, Promise.resolve([]));
                }
            }
            return annotations;
        } catch (err) {
            console.log(`GET /preparations/:id/annotations/:page error ${err}`);
        }
        return null;
    } else {
        return await new Promise(resolve => {
            EventBus.emit({
                event: ReaderEvent.GetLocalAnnotationEvent,
                payload: {
                    id,
                    callback: resolve,
                }
            })
        });
    }
};

FilePreparationRepository.prototype.updatePreparation = async ({
    id,
    annotation,
    token
}) => {
    if (token) {
        try {
            const response = await PreparationAPI.put(
                `${process.env.REACT_APP_API_DOMAIN}/preparations/${id}`,
                annotation,
                token
            );
            if (response.status === 'success') {
                return response.data;
            }
        } catch (err) {
            console.log(`PUT /preparations/:id error ${err}`);
        }
        return null;
    } else {
        return null;
    }
};

FilePreparationRepository.prototype.updateSingleAnnotatoin = async ({
    annoataionInfo,
    token
}) => {
    if (token) {
        try {
            const response = await PreparationAPI.post(
                `${process.env.REACT_APP_API_DOMAIN}/preparations/annotation`,
                annoataionInfo,
                token
            );
            if (response.status === 'success') {
                return response.data;
            }
        } catch (err) {
            console.log(`POST /updateSingleAnnotatoin error ${err}`);
        }
        return null;
    } else {
        return null;
    }
};

FilePreparationRepository.prototype.copyPreparationAnnotations = async ({
    annotation,
    token
}) => {
    return new Promise((resolve) => {
        EventBus.emit({ event: ReaderEvent.CopyAnnotationEvent, payload: annotation })
        setTimeout(() => resolve(), 1000)
    })
};

export default new FilePreparationRepository();
