import React, { useCallback, useEffect, useRef } from 'react';
import Icon from 'components/Icon';
import TranslationControlsButton from 'components/common/TranslationControlsButton';
import AudioControlsButton from 'components/common/AudioControlsButton';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import useSetState from 'customHooks/setState';
import useScroll from 'customHooks/scroll';
import { findLastIndex } from 'util/array';
import { EventBusType } from 'events/EventBus';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';

const eventBusType = EventBusType.ExtendedContent;


const { ExtendedResourceRepository } = Repository;

const PageControlDots = ({ data, onClick, scrollOffset, offsetList }) => {
  const [{ activeIndex }, setState] = useSetState({
    activeIndex: 0
  });

  useEffect(() => {
    const BUFFER = 40;
    const lastIndex = findLastIndex(offsetList, item => {
      if (item) {
        return scrollOffset + item.height + BUFFER >= item.offsetTop;
      }
    });

    setState({ activeIndex: lastIndex });
  }, [offsetList, scrollOffset, setState]);

  const clickHandler = activeIndex => {
    setState({ activeIndex });
    onClick(activeIndex);
  };

  return (
    <>
      {data.map(item => (
        <div
          key={item}
          className={classnames(styles.pageControlDot, {
            [styles.active]: activeIndex === item
          })}
          onClick={() => clickHandler(item)}
        ></div>
      ))}
    </>
  );
};

const Translation = ({ data: { contents, parts = [] } }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize }] = useStore(
    StoreTypes.extendedContent
  );
  const [{ partialInfo }] = useStore(StoreTypes.extendedContent);
  const {
    scrollTop
  } = partialInfo;

  const listOffestTopRef = useRef([]);
  const scrollRef = useRef();
  const { y } = useScroll(scrollRef);
  const scrollTo = (id, opt = { behavior: 'smooth' }) => {
    document.querySelector(`#${id}`).scrollIntoView({
      behavior: opt.behavior
    });
  };
  
  const initViewInfo = ()=> {
    const viewHeight = scrollRef.current.offsetHeight;
    const scrollHeight = scrollRef.current.scrollHeight;
    const scrollTop = scrollRef.current.scrollTop;
    const space = viewHeight / 3 * 2;
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetExtendedContentPartialInfo,
      payload: {
        partialInfo: {
          viewHeight,
          scrollHeight,
          scrollTop,
          space
        }
      }
    });
  }

  useEffect(()=>{
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetExtendedContentPartialInfo,
      payload: {
        partialInfo: {
          ...partialInfo,
          scrollTop:y
        }
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[y,fontSize])

  useEffect(()=>{
    scrollRef.current.scrollTo({top: scrollTop, behavior: 'smooth'});
  },[scrollTop])

  useEffect(()=>{
    initViewInfo();
  },[fontSize])

  const [{ translationData, isEnShow, isChShow }, setState] = useSetState({
    translationData: contents,
    isEnShow: true,
    isChShow: false,
    isAllShow: false
  });

  const toggleEnShow = useCallback(() => {
    const data = translationData.map(item => ({
      ...item,
      enStatus: !isEnShow
    }));
    setState({ isEnShow: !isEnShow, translationData: data });
  }, [isEnShow, setState, translationData]);

  const toggleChShow = useCallback(() => {
    const data = translationData.map(item => ({
      ...item,
      chStatus: !isChShow
    }));
    setState({ isChShow: !isChShow, translationData: data });
  }, [isChShow, setState, translationData]);

  const setAllLangShow = useCallback(() => {
    const status = isEnShow && isChShow ? false : true;
    const data = translationData.map(item => ({
      ...item,
      enStatus: status,
      chStatus: status
    }));
    setState({ translationData: data, isEnShow: status, isChShow: status });
  }, [isChShow, isEnShow, setState, translationData]);

  const toggleOne = useCallback(
    (key, index) => {
      let temp = translationData;
      temp[index][key] = !temp[index][key];
      const isAllEnShow = temp.every(item => item.enStatus);
      const isAllChShow = temp.every(item => item.chStatus);
      setState({
        translationData: temp,
        isEnShow: isAllEnShow,
        isChShow: isAllChShow
      });
    },
    [setState, translationData]
  );

  useEffect(() => {
    // setup default data
    setState({
      translationData: contents.map(item => ({
        ...item,
        enStatus: true,
        chStatus: false
      }))
    });
  }, [contents, setState]);

  const pageControlDotsClickHandler = index => {
    scrollTo(`part${index}`);
  };

  const setOffsetTop = useCallback(
    (el, index) => {
      if (parts.includes(index)) {
        listOffestTopRef.current[index] = {
          offsetTop: el.offsetTop,
          height: el.offsetHeight
        };
      }
    },
    [parts]
  );

  return (
    <div className={styles.translationWrapper}>
      {
        parts && parts.length > 0 && <div className={styles.pageControl}>
          <PageControlDots
            offsetList={listOffestTopRef.current}
            data={parts}
            onClick={pageControlDotsClickHandler}
            scrollOffset={y}
          />
        </div>
      }
      <div className={styles.controls}>
        <TranslationControlsButton
          content="英文"
          active={isEnShow}
          onClick={() => toggleEnShow()}
        />
        <TranslationControlsButton
          content="中文"
          active={isChShow}
          onClick={() => toggleChShow()}
        />
        <TranslationControlsButton
          content="全部"
          active={isEnShow && isChShow}
          onClick={() => setAllLangShow()}
        />
      </div>
      <div ref={scrollRef} className={classnames(styles.translation, styles[fontSize])}>
        <div className={styles.contents}>
          {translationData.map((item, index) => (
            <div
              id={`part${index}`}
              className={styles.contentList}
              key={item.en}
              ref={el => (el ? setOffsetTop(el, index) : null)}
            >
              <div className={classnames(styles.listItem, styles.en)}>
                <div className={styles.icon}>
                  <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: item.mp3
                  })} />
                </div>
                <div
                  className={classnames(styles.sentence, {
                    [styles.active]: item.enStatus
                  })}
                  onClick={() => toggleOne('enStatus', index)}
                >
                  {Array.isArray(item.en) ? (
                    item.en.map(text => (
                      <div
                        key={text}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />))
                  ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: item.en }}
                      />
                    )}
                </div>
              </div>
              <div className={classnames(styles.listItem, styles.ch)}>
                <div className={styles.icon}>
                  <Icon type="text" name="longArrowAltRight" />
                </div>
                <div
                  className={classnames(styles.sentence, {
                    [styles.active]: item.chStatus
                  })}
                  onClick={() => toggleOne('chStatus', index)}
                >
                  {Array.isArray(item.ch) ? (
                    item.ch.map(text => <div>{text}</div>)
                  ) : (
                      <div>{item.ch}</div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Translation;
