import React from 'react';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import Draggable from 'react-draggable';
import oneLinkSuccess from 'components/PaiQuiz/oneLinkSuccess.png';
import { PaiStatus } from 'constants/paiQuiz';

const BoxModal = ({ title, content, bottom, onCloseButtonClick, paiStatus, goToOneLink, goToOneLinkLink }) => {
  const paiQuizContent = () => {
    if (goToOneLink) {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <div className={styles.closeButton} onClick={onCloseButtonClick}>
            <Icon type="text" name="times" />
          </div>
        </div>
        <h1 className={styles.oneLinkTitle}>
          <img src={oneLinkSuccess} alt="成功" />
          派送成功</h1>
        <p className={styles.oneLinkDialog}>可至作答紀錄頁檢討測驗。</p>
        <div className={styles.bottom}>
          {
            window.electron ? 
            (<button className={`${styles.oneLinkLink} ${styles.forElectron}`} onClick={() => {
              window.electron && window.electron.ipcRenderer.send("openExternal", goToOneLinkLink);
            }}>查看作答記錄  ❯</button>) : (
              <a href={goToOneLinkLink} className={styles.oneLinkLink} target="_blank" rel="noopener noreferrer">查看作答紀錄  ❯</a>
            )
          }
        </div>
      </div>)
    } else {
      return (<div className={styles.boxModal}>
        <div className={styles.controlHead}>
          <h1 className={styles.title}>{title}</h1>
          {paiStatus !== PaiStatus.EndClose &&
            <div className={styles.closeButton} onClick={onCloseButtonClick}>
              <Icon type="text" name="times" />
            </div>
          }
        </div>
        <div className={styles.content}>
          {content}
        </div>
        <div className={styles.bottom}>
          {bottom}
        </div>
      </div>)
    }
  }
  return (
    <div className={styles.paiquizMask}>
      <Draggable
        handle={`.${styles.controlHead}`}
        positionOffset={{ x: '-50%', y: '-50%' }}
      >
        {paiQuizContent()}
      </Draggable>
    </div>
  )
}


export default BoxModal;