import React, { useCallback, useEffect } from 'react';
import { StoreTypes, useStore } from 'context'
import { EventBus } from 'events/EventBus';
import * as types from 'constants/actionTypes';
import { ReaderEvent,InteractiveObjectEvent, ReaderToolsEvent } from 'events/EventTypes';
import SwitchButton from 'components/common/SwitchButton';
import Repository from 'repositories/Repository';
import { useUserStampControls } from 'customHooks/userSetting';
import Icon from 'components/Icon';
import styles from './index.module.scss';
import { useEvent } from 'events/EventBus';

const { ExtendedResourceRepository } = Repository;

const ButtonGroup = ({ label, stamps, isChecked, onChange }) => {

  return <div className={styles.buttonGroup}>
    <div className={styles.header}>
      <div className={styles.groupName}>{label}</div>
      <SwitchButton value={isChecked} onChange={onChange}/>
    </div>
    <div className={styles.stamps}>
      {
        stamps.map(stamp => (
          <div className={styles.stamp}>
            <Icon name={stamp}/>
          </div>
        ))
      }
    </div>
  </div>
}

const StampCollectionBox = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ stampGroups }, dispatch] = useStore(StoreTypes.stamp);
  const { updateUserStampControls } = useUserStampControls();

  
  const [{ stampControls }] = useStore(StoreTypes.settings);
  const stampGroupsDisabledIds = stampControls[bookId] || [];

  const setStampGroups = useCallback(stampGroups => {
    dispatch({ 
      type: types.SET_STAMP_GROUPS,
      payload: {
        stampGroups
      }
    })
  },[dispatch])

  useEffect(() => {
    if(!bookId) return;
    (async()=>{
      const stampGroups = await ExtendedResourceRepository.getStampGroups(bookId);
      setStampGroups(stampGroups ? stampGroups.groups || {} : {})
    })()
  },[bookId, setStampGroups])


  const closeCollectionBox = () => {
    EventBus.emit({
      event: ReaderToolsEvent.ToggleStampCollectionBoxShowEvent
    })
  }

  const changeHandler = ({ isChecked, id }) => {
    EventBus.emit({
      event: ReaderEvent.SetStampCollectionEventEvent,
      payload: {isChecked, id }
    })
    let disabledIds = stampGroupsDisabledIds.concat();

    if (isChecked) {
      disabledIds = stampGroupsDisabledIds.filter(item => item !== id);
    } else {
      disabledIds.push(id);
    }

    updateUserStampControls({
      ...stampControls,
      [bookId]: disabledIds
    })

  }

  const setStampCollectionHandler = useCallback(({ isChecked, id }) => {
    let disabledIds = stampGroupsDisabledIds.concat();

    if (isChecked) {
      disabledIds = stampGroupsDisabledIds.filter(item => item !== id);
    } else {
      disabledIds.push(id);
    }

    updateUserStampControls({
      ...stampControls,
      [bookId]: disabledIds
    })

  },[bookId,stampGroupsDisabledIds,stampControls,updateUserStampControls])

  useEvent({ event: InteractiveObjectEvent.SetStampCollection }, setStampCollectionHandler);

  return (
    <div className={styles.stampCollectionBox}>
      <div className={styles.buttonGroupContainer}>
        {
          Object.keys(stampGroups).map(id => {
            const item = stampGroups[id];

            return (
              <ButtonGroup
                key={id}
                isChecked={!stampGroupsDisabledIds.includes(id)}
                label={item.label}
                stamps={item.stamps}
                onChange={val => changeHandler({ isChecked: val, id })}
              />
            )
          })
        }
      </div>
      <div className={styles.closeButtonContainer}>
        <div className={styles.closeButton} onClick={closeCollectionBox}>關閉視窗</div>
      </div>
    </div>
  );
}


export default StampCollectionBox;