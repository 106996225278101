// 取得作業系統
export const getOSAndBrowser =  () => {
  var os = navigator.platform;
  var userAgent = navigator.userAgent;
  var info = '';
  //判斷作業系統
  if (os.indexOf('Win') > -1) {
    if (userAgent.indexOf('Windows NT 5.0') > -1) {
      info += 'Win2000';
    } else if (userAgent.indexOf('Windows NT 5.1') > -1) {
      info += 'WinXP';
    } else if (userAgent.indexOf('Windows NT 5.2') > -1) {
      info += 'Win2003';
    } else if (userAgent.indexOf('Windows NT 6.0') > -1) {
      info += 'WindowsVista';
    } else if (
      userAgent.indexOf('Windows NT 6.1') > -1 ||
      userAgent.indexOf('Windows 7') > -1
    ) {
      info += 'Win7';
    } else if (
      userAgent.indexOf('Windows NT 6.2') > -1 ||
      userAgent.indexOf('Windows 8') > -1
    ) {
      info += 'Win8';
    } else if (
      userAgent.indexOf('Windows NT 6.3') > -1 ||
      userAgent.indexOf('Windows 8.1') > -1
    ) {
      info += 'Win8.1';
    } else if (
      userAgent.indexOf('Windows NT 10.0') > -1 ||
      userAgent.indexOf('Windows 10') > -1
    ) {
      info += 'Win10';
    } else {
      info += 'Other';
    }
  } else if (os.indexOf('Mac') > -1) {
    info += 'Mac';
  } else if (os.indexOf('X11') > -1) {
    info += 'Unix';
  } else if (os.indexOf('Linux') > -1) {
    info += 'Linux';
  } else {
    info += 'Other';
  }

  return info;
};
