import React from 'react';
import styles from './index.module.scss';

const Textarea = ({ label, onChange, ...other }) => (
  <div className={styles.textareaWrapper}>
    <label>
      {label && <div> {label} </div>}
      <textarea
        onChange={onChange}
        className={styles.textarea}
        rows="3"
        {...other}
      />
    </label>
  </div>
);

export default Textarea;
