import React, { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';

import AudioControlsButton from 'components/common/AudioControlsButton';
import TabList from './TabList';

import styles from './index.module.scss';
const { ExtendedResourceRepository } = Repository;

const ZhuyinWord = ({ resource }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const { tab, contents } = resource;
  const [{ tabIndex, pageData }, setState] = useSetState({
    tabIndex: tab,
    pageData: null,
  })

  useEffect(() => {
    // 處理page的分頁格式
    let pageData = contents.map(item => {
      return {
        label: item.label,
        value: item.label,
        relatedWords: [item.label],
      }
    })
    setState({ pageData })
  }, [contents, setState])

  // 顯示該tab的內容
  const contantHandle = ({ word }) => {
    const nextIndex = contents.findIndex(item => {
      return item.label === word
    });

    setState({ tabIndex: nextIndex })
  }

  // 判斷注音是否高亮
  const checkHightlight = (arr, target) => {
    let checked = [];
    arr.forEach(item => {
      target.includes(item) && checked.push(item);
    })

    return checked.length === target.length;
  }

  return (
    <div className={styles.zhuyinWord}>
      <div className={styles.content}>
        <div className={styles.audio}>
          {
            contents[tabIndex].sound && <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].sound
            })} />
          }
        </div>
        <div className={styles.label}>
          {contents[tabIndex].label}
        </div>
        <div className={styles.word}>
          {
            contents[tabIndex].word.map(wordItem => {
              const wordItemArr = wordItem.split('');
              const label = contents[tabIndex].label.trim().split('')
              const isHightlight = checkHightlight(wordItemArr, label);
              return (
                <ruby>
                  {
                    wordItemArr.map(item => {
                      if (label.indexOf(item) > -1 && isHightlight) {
                        return <rt className={styles.activeRt}>{item}</rt>
                      } else {
                        return <rt>{item}</rt>
                      }
                    })
                  }
                </ruby>
              )
            })
          }
        </div>
        <div className={styles.img}>
          {
            contents[tabIndex].image && <img
              src={`${ExtendedResourceRepository.getDataSrc({
                bookId,
                pathName: contents[tabIndex].image
              })}`}
              alt=""
            />
          }
        </div>
        <div className={styles.sentence}>
          {
            contents[tabIndex].sentence.map(sentenceItem => {
              const sentenceItemArr = sentenceItem.split('');
              const label = contents[tabIndex].label.trim().split('')
              const isHightlight = checkHightlight(sentenceItemArr, label);
              return (
                <ruby>
                  {
                    sentenceItemArr.map(item => {
                      if (label.indexOf(item) > -1 && isHightlight) {
                        return <rt className={styles.activeRt}>{item}</rt>
                      } else {
                        return <rt>{item}</rt>
                      }
                    })
                  }
                </ruby>
              )
            })
          }
        </div>
      </div>
      <TabList
        word={contents[tabIndex].label}
        className={styles.tabBox}
        data={pageData}
        onChange={contantHandle}
      />
    </div>
  )
}

export default ZhuyinWord;