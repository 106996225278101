import React, { useCallback } from 'react';
import Icon from 'components/Icon';
import { useBoolean } from 'customHooks/boolean';
import classnames from 'classnames';
import styles from './index.module.scss';

const WordsMovieButtonGroup = ({
  isPlaying,
  wordingMap,
  data,
  activeIndex,
  onChange = () => { },
  play = () => {}
}) => {
  return (
    <div className={styles.buttonGroup}>
      {
        data.map((item, index) => (
          wordingMap[index] &&
          <button
            key={item.Path}
            className={classnames({ [styles.active]: index === activeIndex })}
            onClick={() => onChange({
              index,
              value: item.Path
            })}>
            {wordingMap[index]}
          </button>))
      }
      <button onClick={play}>
        {isPlaying ? '暫停' : '播放'}
      </button>
    </div>
  )
};

export default WordsMovieButtonGroup;
