export const LANGUAGES = {
    TRADITIONAL_CHINESE: 'zh-TW',
    ENGLISH: 'en',
    THAI: 'th-TH',
    VIETNAMESE: 'vi-VN',
};

export const LANGUAGES_DISPLAY = {
  [LANGUAGES.TRADITIONAL_CHINESE]: '🇹🇼 中文',
  [LANGUAGES.ENGLISH]: '🇺🇸 English',
  [LANGUAGES.THAI]: '🇹🇭 ไทย',
  [LANGUAGES.VIETNAMESE]: '🇻🇳 Tiếng Việt',
};
