export const LineType = {
  'Solid': "solid",
  'Dotted':"dotted",
  'Hidden': "hidden"
}


export const ExtensionType = {
  MeaningSet: 'meaningSet',
  WordsSet: 'wordsSet',
  IdiomSet: 'idiomSet',
  GlyphSet: 'glyphSet',
}