import React, { useEffect, forwardRef } from 'react';
import { useBoolean } from 'customHooks/boolean';
import Icon from 'components/Icon';
import AudioButton from 'components/common/AudioButton';
import ControlsButton from 'components/common/ControlsButton';
import classnames from 'classnames';
import styles from './index.module.scss';

const AudioControlsButton = forwardRef(({
  className,
  src,
  onPlaying = () => { },
  onEnded = () => { },
  onClick,
}, ref) => {
  const [isActive, { setFalse: setInactive, setTrue: setActive }] = useBoolean();

  useEffect(() => {
    setInactive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return (
    <ControlsButton
      active={isActive}
      className={classnames(styles.audioControlsButton, className)}
    >
      <AudioButton
        ref={ref}
        className={styles.audioButton}
        src={src}
        onPlaying={() => {
          setActive();
          onPlaying();
        }}
        onEnded={() => {
          setInactive();
          onEnded();
        }}
        onClick={onClick}
      >
        <Icon type="text" name="volumeUp" />
      </AudioButton>
    </ControlsButton>
  )
})


export default AudioControlsButton;
