import React, { useEffect, useRef, useCallback,useState } from 'react';
import useSetState from 'customHooks/setState';
import AudioControlsButton from 'components/common/AudioControlsButton';
import styles from './index.module.scss';
import './content.scss';
import classnames from 'classnames';
import { useStore, StoreTypes } from 'context';
import { parseZhuyinOnly } from 'util/zhuyin';
import { EventBusType, EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import Repository from 'repositories/Repository';
import { ScrollCoordinate, ScrollPositionButtonStateType,ScrollPositionType, ScrollLength} from "constants/scrollPositionType";
import { ScrollButtonStateBehavior,scrollPositionButtonBehavior } from "../ExtendedContent/ExtendedContentScrollPositionButton";

const { ExtendedResourceRepository, classNameRepository } = Repository;
const eventBusType = EventBusType.ExtendedContent;

const ListeningStory = ({ data, defaultObj }) => {
  // 為兼容上學期『傳值』寫法，因而用Number判斷
  const nextDefaultObj = typeof defaultObj === 'number' ? defaultObj : JSON.parse(defaultObj)
  const isZhuyin = nextDefaultObj['zhuyin']; // 純注音開關
  const { contents } = data;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ tabIndex, htmlArr, isContentLoading }, setState] = useSetState({
    // 為兼容上學期『傳值』寫法，因而用Number型別做判斷
    tabIndex: typeof nextDefaultObj === 'number' ? nextDefaultObj : parseInt(nextDefaultObj['tab'], 10),
    htmlArr: [],
    isContentLoading: false
  });
  const htmlContentRef = useRef();
  const contentTextsRef = useRef();
  const [{ fontSize, isShowAnswer,scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [currentScrollLeft, setCurrentScrollLeft] = useState(0);
  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  
  useEffect(() => {
    if (!contents || !bookId) return;
    (async () => {
      const tempObj = {};
      for (let [index, item] of Object.entries(contents)) {
        for (let word of item.words) {
          const htmlJson = await ExtendedResourceRepository.getJSONContent({
            bookId,
            pathName: word.word ? word.word.split('.json')[0] : ""
          })

          tempObj[index] = (tempObj[index] || []).concat({
            sound: word.sound,
            html: isZhuyin ? parseZhuyinOnly(htmlJson.html, '.listeningStory') : htmlJson.html
          });
        }
      }
      setState({ htmlArr: tempObj });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, contents, setState]);

  const changePopWindowAnswersHandler = useCallback((isShowAnswer) => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowAnswersEvent,
      payload: {
        isShowAnswer
      }
    });
  }, []);

  useEffect(() => {
    changePopWindowAnswersHandler(false)
  }, [changePopWindowAnswersHandler, tabIndex])

  useEffect(() => {
    if (!htmlContentRef.current) return;
    var answerContent = htmlContentRef.current.getElementsByClassName('answer');
    for (let i = 0; i < answerContent.length; ++i) {
      answerContent[i].onclick = e => {
        if (e) {
          e.currentTarget.classList.toggle("ansawer");
          changePopWindowAnswersHandler(!isShowAnswer)
        }
      }
    }
  })


  const tabClickHandler = index => {
    contentTextsRef.current.getElementsByClassName('article')[0].scrollIntoView({ inline: ExtendedResourceRepository.getWordGridAnchorDirection() });

    setState({ tabIndex: index })
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent
    });
  }

  useEffect(() => {
    setState({ isContentLoading: true })
    setTimeout(() => setState({ isContentLoading: false }), 500);
  }, [fontSize, setState])

  useEffect(() => {
    // 小視窗內容上下頁分段
    // 因為 none 而 useEffect 的時候才會知道最終移動結果
    switch (scrollPosition) {
      case ScrollPositionType.Next: {
        // console.log("---H類小視窗---ListeningStory", contentTextsRef);
        setLastScrollLeft(contentTextsRef.current.scrollLeft);
        contentTextsRef.current.scrollLeft -= contentTextsRef.current.offsetWidth*ScrollLength;
        setCurrentScrollLeft(contentTextsRef.current.scrollLeft);
        break;
      }
      case ScrollPositionType.Prev: {
        setLastScrollLeft(contentTextsRef.current.scrollLeft);
        contentTextsRef.current.scrollLeft += contentTextsRef.current.offsetWidth*ScrollLength;
        setCurrentScrollLeft(contentTextsRef.current.scrollLeft);
        break;
      }
      default: {
        scrollPositionButtonBehavior({currentScroll:currentScrollLeft, lastScroll: lastScrollLeft, coordinate: ScrollCoordinate.X})
        break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  

  useEffect(()=> { // 小視窗內容上下頁分段
    const timer = setTimeout(()=> {
    const updateScrollButtonState = () => {
      const offsetWidth = contentTextsRef.current.offsetWidth
      const scrollWidth = contentTextsRef.current.scrollWidth
      const scrollLeft = contentTextsRef.current.scrollLeft
      const headCond = scrollLeft >= 0
      const bottomCond = (scrollWidth - offsetWidth + scrollLeft) < 10
      if (headCond) {
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
      }else if(bottomCond) {
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.Bottom)
      } else {
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.Middle)
      }
    }
    if (contentTextsRef && contentTextsRef.current) {
      if(contentTextsRef.current.scrollWidth === contentTextsRef.current.offsetWidth) {
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.None)
      } else {
        ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
      }
      contentTextsRef.current.addEventListener("scroll", updateScrollButtonState, false)
    }
    return () => {
      contentTextsRef.current.removeEventListener('scroll', updateScrollButtonState, false)
    }
  }, 1000)
  return () => clearTimeout(timer)
  },[fontSize, tabIndex, isShowAnswer])

  // 文字排版，如稿紙徹底對齊文字和標點
  useEffect(() => {
    setTimeout(() => {
      const ruby = contentTextsRef.current.querySelector('ruby:not(.begin ruby)>rt');
      const rubyHeight = ruby ? getComputedStyle(ruby).height : null;
      const punctuation = contentTextsRef.current.querySelectorAll('sign');
      punctuation && punctuation.forEach(element => {
        element.style.height = rubyHeight;
        // element.style.display = 'inline-flex';
        // element.style.textIndent = 'initial';
        // element.style.justifyContent = 'center';
      });
    }, 100)
  }, [fontSize, tabIndex, isContentLoading])


  return (
    <div className={classnames(styles.listeningStory, {
      isShowAnswer,
      isZhuyin
    }, fontSize)}>
      <div className={styles.tab}>
        {
          contents.map((list, index) => <div className={classnames(styles.list, {
            [styles.active]: tabIndex === index
          })} key={index} onClick={() => tabClickHandler(index)}>{index + 1}</div>)
        }
      </div>
      {
        contents[tabIndex] && <div className={styles.contentBox}>
          <div className={styles.phoneticImg}>{contents[tabIndex].phoneticImg && <img
            src={`${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].phoneticImg
            })}`}
            alt=""
          />}</div>
          <div className={styles.contentImgs}>
            {
              contents[tabIndex].contentImgs.length > 0 && contents[tabIndex].contentImgs.map((img, index) => <div className={styles.img} key={index}>
                <img
                  src={`${ExtendedResourceRepository.getDataSrc({
                    bookId,
                    pathName: img
                  })}`}
                  alt=""
                />
              </div>)
            }
          </div>
          <div className={styles.contentTexts} ref={contentTextsRef}>
            {
              htmlArr[tabIndex] && htmlArr[tabIndex].length > 0 && htmlArr[tabIndex].map((wordobj, index) => {
                return (
                  <div className={classnames(styles.textBox, {
                    [styles.small]: fontSize === "small",
                    [styles.medium]: fontSize === "medium",
                    [styles.large]: fontSize === "large",
                  }, classNameRepository.getClassName('textBox'))} key={index}>
                    {
                      wordobj.sound && <div className={classnames(styles.radio, classNameRepository.getClassName('radio'))}>
                        <AudioControlsButton
                          className={styles.audioControlsButton}
                          src={ExtendedResourceRepository.getDataSrc({
                            bookId,
                            pathName: wordobj.sound
                          })} />
                      </div>
                    }
                    {
                      !isContentLoading && wordobj.html && <div className={styles.htmlContent} ref={htmlContentRef} dangerouslySetInnerHTML={{ __html: wordobj.html }}></div>
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </div >
  )
}

export default ListeningStory;
