import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import {
    ReaderToolsEvent,
} from 'events/EventTypes';

export const useMathToolsCommand = () => {
    const execute = useCallback(async ({ json }) => {
        const { mathTools } = json;
        mathTools && EventBus.emit({ event: ReaderToolsEvent.ClickMathToolsEvent, payload: { mathToolsInfo: JSON.parse(mathTools) } });
    }, []);

    return { execute };
};
