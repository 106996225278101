export const isExist = value =>
  value !== null && value !== '' && typeof value !== 'undefined';

export const encodeURIString = url =>
  encodeURI(url)
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29');

export const pipe = (func1, func2) => x => func2(func1(x));

export const isImageExtension = filename => {
  const imageExtension = ['png', 'jpg', 'jpeg', 'svg'];
  const name = filename.split('.').pop();
  return imageExtension.indexOf(name) > -1
};
