import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';


export const WordGridComponent = ({ object, saveProperty }) => {
  let wordGridInfo = object.wordGridInfo ? JSON.parse(object.wordGridInfo) : { src: '', word: '', wordsSetSrc: '', idiomSetSrc: '' }

  const changeHandler = infoType => e => {
    wordGridInfo[infoType] = e.target.value;
    saveProperty(JSON.stringify(wordGridInfo))
  };

  return (
    <>
      <Input className={styles.wordGridInput} label="Src" value={wordGridInfo.src} onChange={changeHandler('src')} />
      <Input className={styles.wordGridInput} label="Word" value={wordGridInfo.word} onChange={changeHandler('word')} />
      <br />
      <label htmlFor="defaultOpacity">延伸教學</label>
      <Input className={styles.wordGridInput} label="語詞列表" value={wordGridInfo.wordsSetSrc} onChange={changeHandler('wordsSetSrc')} />
      <Input className={styles.wordGridInput} label="成語列表" value={wordGridInfo.idiomSetSrc} onChange={changeHandler('idiomSetSrc')} />
    </>
  )
};
