function CloudBookshelfRepository() { }

CloudBookshelfRepository.prototype.getHeaderMenuVisibility = async () => {
  return true;
};

CloudBookshelfRepository.prototype.isShowBookStoreComponent = () => {
  return true;
};

export default new CloudBookshelfRepository();