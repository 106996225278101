import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';


export const HearingComponent = ({ object, saveProperty }) => {
  let hearingInfo = object.hearingInfo ? object.hearingInfo : 0

  const changeHandler = () => e => {
      hearingInfo = e.target.value;
      saveProperty(parseInt(hearingInfo))
  };

  return (
    <>
      <Input className={styles.vocabularyInput} label="預設頁籤" value={hearingInfo} onChange={changeHandler()} />
    </>
  )
};