import { useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { ActivityEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { API } from 'api';
import { useFlipBook } from 'customHooks/canvas';

export const useSetActivityInfo = () => {
    const reducers = useStore();
    const [, annotationDispatch] = reducers[StoreTypes.annotation];

    const setActivityInfo = useCallback(async ({ activityId, name, attendeeNumber, activityTarget, ownerId }) => {
        annotationDispatch({
            type: types.UPDATE_ACTIVITY_INFO,
            activityId,
            annotationType: AnnotationType.ACTIVITY,
            name,
            ownerId,
            attendeeNumber,
            activityTarget,
        });
    }, [annotationDispatch]);

    return setActivityInfo;
};

export const useMonitorActivityAttendeeList = () => {
    const reducers = useStore();
    const [{ activityInfo: { firestore } }, annotationDispatch] = reducers[StoreTypes.annotation];
    const unsubscribeRef = useRef(null);

    const monitorActivityAttendeeList = useCallback(async ({ teacherId, activityId }) => {
        console.log('========座位表========', activityId);
        const attendeeRequestRef = firestore.collection(`/teachers/${teacherId}/activities/${activityId}/attendee`)
        unsubscribeRef.current = attendeeRequestRef.onSnapshot(function(snapshot) {
            const seats = [];
            snapshot.docs.forEach((doc) => {
                seats.push({
                    id: doc.id,
                    attendee: doc.id,
                    join: doc.data().join
                });
            });
            annotationDispatch({ type: types.SET_SEATS, seats });
        });
    }, [annotationDispatch, firestore]);

    const unsubscribeMonitorActivityAttendeeList = useCallback(() => {
        unsubscribeRef.current && unsubscribeRef.current();
    }, []);

    return { monitorActivityAttendeeList, unsubscribeMonitorActivityAttendeeList };
};

export const useMonitorActivityAttendeeInfo = () => {
    const reducers = useStore();
    const [{ activityInfo: { firestore, attendeeNumber, ownerId } }] = reducers[StoreTypes.annotation];
    const unsubscribeRef = useRef(null);
    const flipBook = useFlipBook();

    const monitorActivityAttendeeInfo = useCallback(({ activityId, activityTarget }) => {
        const attendeeRequestRef = firestore.collection(`/teachers/${ownerId}/activities/${activityId}/attendee`).where('no', '==', activityTarget);
        unsubscribeRef.current = attendeeRequestRef.onSnapshot(function(snapshot) {
            snapshot.docs.forEach((doc) => {
                if (activityTarget !== attendeeNumber) {
                    flipBook({ pageIndex: doc.data().pageIndex });
                }
            });
        });
    }, [attendeeNumber, firestore, flipBook, ownerId]);

    const unsubscribeMonitorActivityAttendeeInfo = useCallback(() => {
        unsubscribeRef.current && unsubscribeRef.current();
    }, []);

    return { monitorActivityAttendeeInfo, unsubscribeMonitorActivityAttendeeInfo };
};

export const useMonitorActivityInfo = () => {
    const reducers = useStore();
    const [{ activityInfo: { firestore, ownerId } }] = reducers[StoreTypes.annotation];
    const unsubscribeRef = useRef(null);

    const monitorActivityInfo = useCallback(({ activityId }) => {
        //活動資料
        unsubscribeRef.current = firestore.collection(`/teachers/${ownerId}/activities/`).where("id", "==", activityId).onSnapshot(function(snapshot) {
            console.log('========活動資料========');
            snapshot.docs.forEach((doc) => {
                EventBus.emit({
                    event: ActivityEvent.UpdateActivityInfo,
                    payload: doc.data()
                });
            })
        });
    }, [firestore, ownerId]);

    const unsubscribeMonitorActivityInfo = useCallback(() => {
        unsubscribeRef.current && unsubscribeRef.current();
    }, []);

    return { monitorActivityInfo, unsubscribeMonitorActivityInfo };
};

export const useMonitorActivityAnnotation = () => {
    const reducers = useStore();
    const [{ activityInfo: { firestore, ownerId } }] = reducers[StoreTypes.annotation];
    const [{ token, userId }] = reducers[StoreTypes.user];
    const unsubscribeRef = useRef(null);
    const monitorActivityAnnotation = useCallback(async ({ activityId, activityTarget }) => {
        //畫筆資料
        let requestRef = firestore.collection(`/teachers/${ownerId}/activities/${activityId}/attendee/${activityTarget}/annotations`);
        unsubscribeRef.current = requestRef.onSnapshot(async function(snapshot) {
            console.log('=========畫筆狀態========', activityId, activityTarget, snapshot);
            const annotations = [];
            snapshot.docs.forEach((doc) => {
                annotations.push(doc.data()); //TODO: 看要不要座位表裡初始annotations為空
            });
            // TODO: call getAttendeeInfo API here to get pageIndex
            const obj = {
                token:{jwt:token},
                teacherId: ownerId,
                attendeeId: userId,
                activityId,
                attendeeNo: activityTarget,
            };
            const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getAttendee`, obj)
            if (response) {
                response.content.annotations = annotations;
                EventBus.emit({
                    event: ActivityEvent.OnAnnotationUpdateEvent,
                    payload: response.content
                });
            }
        })
    }, [firestore, ownerId, token, userId]);

    const unsubscribeMonitorActivityAnnotation = useCallback(() => {
        unsubscribeRef.current && unsubscribeRef.current();
    }, []);

    return { monitorActivityAnnotation, unsubscribeMonitorActivityAnnotation };
};
