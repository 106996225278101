export const getBookIdFromNative = () => {
  let bookId = '';

  if (window.android) {
    bookId = window.JsTest.showToast("12312");
  }

  if (window.ios) {
    bookId = window.iOS_BookId;
  }

  return bookId;

}
