export const convertArrayToMap = (arr, key) =>
  arr.reduce((acc, v) => {
    if (v[key]) {
      acc[v[key]] = v;
    }
    return acc;
  }, {});

export const groupByArrayToMap = (arr, key) => {
  return arr.reduce((acc, v) => {
    (acc[v[key]] = acc[v[key]] || []).push(v);
    return acc;
  }, {});
};

export const findLastIndex = (array, predicate, context) => {
  var length = array.length;
  for (var i = length; i >= 0; i--) {
    if (predicate.call(context, array[i], i, array)) return i;
  }
  return -1;
};

export const shuffle = arr => {
  var i, j, temp;
  for (i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

export const groupArray = (data, cols) => {
  const list = [];
  let current = [];

  data.forEach(t => {
      current.push(t);
      if (current.length === cols) {
          list.push(current);
          current = [];
      }
  });

  if (current.length) {
      list.push(current);
  }
  return list;
}



export const flat = (arr) => {
  let result = []
  if (_isArray(arr)) {
    for (let i = 0; i < arr.length; i++) {
      const v = arr[i]
      if (_isArray(v)) {
        arr[i] = flat(v)
        result = result.concat(arr[i])
      } else {
        result.push(v)
      }
    }
  }
  return result
}

function _isArray(arr) {
  return Array.isArray(arr) && arr.length
}