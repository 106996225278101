import React, { forwardRef } from 'react';
import CheckBox from 'components/common/CheckBox';
import Select from 'components/common/Select';
import classnames from 'classnames';
import styles from './index.module.scss';

const timesData = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  }
];

const speedData = [
  {
    label: '稍慢',
    value: 0.75
  },
  {
    label: '正常',
    value: 1
  },
  {
    label: '稍快',
    value: 1.25
  }
];

const timeGapData = [
  { label: '1', value: 1000 },
  { label: '2', value: 2000 },
  { label: '3', value: 3000 },
  { label: '4', value: 4000 },
  { label: '5', value: 5000 },
  { label: '6', value: 6000 },
  { label: '7', value: 7000 }
];

const SettingPanel = forwardRef(({
  className,
  value,
  active,
  advancedSettings,
  onTimesChange,
  onSpeedChange,
  onTimeGapChange,
  onUsingDefaultChange,
  hideDefaultCheckBox
}, ref) => {
  const { speed, timeGap, times, usingDefault } = value;
  const timesChangeHandler = val => {
    onTimesChange(val);
  };

  const speedChangeHandler = val => {
    onSpeedChange(val);
  };

  const onTimeGapChangeHandler = val => {
    onTimeGapChange(val);
  }

  const checkBoxChangeHandler = checked => {
    onUsingDefaultChange(checked)
  }

  const cancelDefaultHandler = () => {
    checkBoxChangeHandler(false);
  }

  return (
    <div
      className={classnames(styles.settingPanel, className, {
        [styles.active]: active
      })}
      ref={ref}
    >
      {
        !hideDefaultCheckBox && (
          <div className={classnames(styles.controlSection)}>
            <CheckBox
              checked={usingDefault}
              onChange={checkBoxChangeHandler}
            >預設值(原CD音檔的朗讀次數與間隔秒數)</CheckBox>
          </div>
        )
      }
      {
        advancedSettings && (
          <>
            <div className={styles.controlSection}>
              <div className={styles.label}>朗讀次數：</div>
              <div className={styles.selectGroup}>
                {
                  usingDefault ? (
                    <div className={styles.defaultWording} onClick={cancelDefaultHandler}>請選擇</div>
                  ) : (
                      <Select className={styles.select} onChange={e => timesChangeHandler(e.target.value)} value={times}>
                        {timesData.map(item => (
                          <option value={item.value}>{item.label} 次 </option>
                        ))}
                      </Select>
                    )
                }
              </div>
            </div>
            <div className={styles.controlSection}>
              <div className={styles.label}>語句間隔：</div>
              <div className={styles.selectGroup}>
                {
                  usingDefault ? (
                    <div className={styles.defaultWording} onClick={cancelDefaultHandler}>請選擇</div>
                  ) : (
                      <Select className={styles.select} onChange={e => onTimeGapChangeHandler(e.target.value)} value={timeGap}>
                        {timeGapData.map(item => (
                          <option value={item.value}>{item.label} 秒 </option>
                        ))}
                      </Select>
                    )
                }
              </div>
            </div>
          </>
        )
      }
      <div className={styles.controlSection}>
        <div className={styles.label}>播放速度：</div>
        <div className={styles.selectGroup}>
          <Select className={styles.select} onChange={e => speedChangeHandler(e.target.value)} value={speed}>
            {speedData.map(item => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
);

export default SettingPanel;
