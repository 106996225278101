import React, { useRef } from 'react';

import { ExtendedContentTools } from 'components/ExtendedContent/ExtendedContentTools';
import { ExtendedContentCanvas } from 'components/ExtendedContent/ExtendedContentCanvas';
import { ExtendedContentEvent } from '../ExtendedContentEvent';
import { EventBusType } from 'events/EventBus';
import classnames from 'classnames';
import styles from './index.module.scss';

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedHtmlContentContainer = ({ children ,toolbarOpen}) => {
    const contentView = useRef();
    const [
        { canvasState, canvasHeight, canvasWidth, isContentInit, svgContent, toolType },
        { canvasDispatch }
    ] = ExtendedContentEvent(contentView);

    const getCanvasSvg = str => {
        str = str.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
        return str;
    };

    return (
        <div id="exHtmlContainer" className={classnames(styles.extendedContentContainer, styles[`cursor${toolType}`])}>
            <div ref={contentView} className={styles.contentView}>
                {children}
                {svgContent && !canvasState.isActive && (
                <div
                    className={styles.svgContent}
                    dangerouslySetInnerHTML={{
                    __html: getCanvasSvg(svgContent)
                    }}
                />
                )}
                {isContentInit && <ExtendedContentCanvas {
                    ...{ canvasState, canvasDispatch, canvasWidth, canvasHeight }
                } />}
            </div>
            <ExtendedContentTools {...{ eventBusType, canvasState,toolbarOpen }} />
        </div>
    );
};
