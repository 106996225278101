export const ScrollPositionType = {
  Next: "next",
  Prev: "prev",
  None: "none",
};

export const ScrollDirection = {
  Vertical: "vertical",
  Horizontal: "horizontal"
}

export const ScrollLabel = {
  Prev: "上一頁",
  Next: "下一頁"
}

export const ScrollPositionButtonStateType = {
  Head: 'head',
  Middle: 'middle',
  Bottom: 'bottom',
  None: 'none',
}

export const ScrollCoordinate = {
  X:"x",
  Y: "y",
}

export const ScrollLength = 0.85