import React from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import classnames from 'classnames';

const Modal = ({ isShowing, closeModal, isHiding, children }) => {
    return isShowing ? ReactDOM.createPortal(
        <div className={classnames(styles.modalWrapper, {[styles.hideModal]: isHiding})}>
          <div className={styles.modalOverlay}  onClick={closeModal}/>
          <div className={styles.modalContent}>
              {children}
          </div>
        </div>, document.body
      ) : null;
}

export default Modal;