import React,{ useRef } from 'react';
import { debounce } from 'util/debounce';
import classnames from 'classnames';
import styles from './index.module.scss';

const ColorPalette = ({ colors = [], onChange = () => {} }) => {
  const colorInputRef = useRef();

  const onChangeHandler = debounce(() => {
    const { value } = colorInputRef.current;
    onChange(value)
  },300);

  return (
    <div className={styles.content_box}>
      {colors.map(color => {
          return (
              <div
                  key={color}
                  className={classnames(styles.content,{
                      [styles.transparent]:color === 'transparent'
                  })}
                  onClick={()=>onChange(color)}
              >
                  <div
                      className={classnames(
                          styles.color,
                          styles.border
                      )}
                      style={{ backgroundColor: color }}
                  />
              </div>
          );
      })}
      <label className={styles.content}>
          <input ref={colorInputRef} type="color" onChange={onChangeHandler}/>
          <div className={classnames(
              styles.color,
              styles.border
          )}>自訂</div>
      </label>
    </div>
  )
}

export default ColorPalette;