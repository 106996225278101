import React from 'react';
import { withRouter } from 'react-router';
import BookView from 'components/BookView';
// import FabricCanvas from 'components/Canvas/FabricCanvas';
// import { PinchPanContainer } from 'components/PinchPanContainer';
// import { useStore, StoreTypes } from 'context';
// import classnames from 'classnames';
import styles from './index.module.scss';
// import * as types from 'constants/actionTypes';
// import ReactDOM from 'react-dom';
// import TldrawContainer from 'components/TldrawContainer';

/**
 * BookContainer 內含 BookView（書本頁面） 、 FabricCanvas（畫筆畫布） 、 PinchPanContainer（放大及平移）
 * 改用 tldraw 後就不再需要 PinchPan，放大平移可由 tldraw 內部控制
 * @param {Object} props.book - 書本的基本資訊包含各頁面的 svg 路徑及書本長寬等
 * @returns {JSX.Element}
 */
const BookContainer = ({ book }) => {

  // const [
  //   {

  //     fullWidthInfo: { scale: fullWidthScale },
  //     objectPointerEventStatus
  //   }
  // ] = useStore(StoreTypes.reader);
  // const [
  //   {
  //     style: { width, height }
  //   }
  // ] = useStore(StoreTypes.books);

  return (
    <>
      {book && (
        <div className={styles.bookContainer}>
          {/* <PinchPanContainer> */}
            <div
              id="BookContainer"
              className={styles.bookContainer}
              // style={{ transform: `scaleX(${fullWidthScale})` }}
            >
              <BookView book={book} />
              {/* <FabricCanvas
                id="FabricCanvas"
                width={width}
                height={height}
                isDrawingMode={false}
                className={classnames(styles.canvasWrapper, { [styles.covered]: !objectPointerEventStatus })}
              /> */}
            </div>
          {/* </PinchPanContainer> */}
        </div>
      )}
    </>
  );
};

export default withRouter(BookContainer);
