import React, {
  useEffect,
  useState,
} from "react";
import path from "path";
import classnames from "classnames";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import { EventBus } from "events/EventBus";
import { ReaderToolsEvent } from "events/EventTypes";
import { BookFlipType } from "constants/flipTypes";
import Repository from "repositories/Repository";
import { ActivityStatus } from "constants/groupInteractivity";

const { BookContentRepository } = Repository;

const recordStatusText = {
  [ActivityStatus.Created]: "進行中",
  [ActivityStatus.Done]: "已結束",
};
const PaiNoteRecordForStudent = () => {
  const [{ books, bookId }] = useStore(StoreTypes.books);
  const [{ paiNoteNotes, paiNoteSelectedNote }] = useStore(
    StoreTypes.reader
  );
  const book = books.find((item) => item.bookId === bookId);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);

  useEffect(() => {
    if (!book) {
      return;
    }
    const { pageInfos } = book;
    const pages = pageInfos.reduce((acc, item) => {
      acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
      return acc;
    }, {});
    const results = BookContentRepository.getThumbnailUrls({
      bookId,
      pages: Object.values(pages),
    });
    setThumbnailUrls(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, bookId]);

  const clickNoteHandler = (note) => {
    EventBus.emit({
      event: ReaderToolsEvent.SelectNoteEvent,
      payload: { note },
    });
    EventBus.emit({
      event: ReaderToolsEvent.TogglePaiNoteRecordEvent
    });
  };

  return (
    <div className={classnames(styles.paiNoteRecords)}>
      <div className={styles.text}>筆記紀錄</div>
      <div
        // ref={thumbnailWrapperRef}
        className={classnames(styles.thumbnailWrapper, {
          [styles.horizontal]: true,
          [styles.rightToLeft]: book.LRFlip === BookFlipType.RIGHT_TO_LEFT,
          [styles.doublePage]: true,
        })}
      >
        {paiNoteNotes.map((note, index) => {
          const thumbnails = note.pageIndex
            .sort()
            .map((page) => page + 1)
            .slice(0, 2);
          return (
            <div
              className={classnames(styles.recordCard, {
                [styles.active]: note.id === paiNoteSelectedNote.id,
              })}
              onClick={() => clickNoteHandler(note)}
            >
              <div className={styles.recordHead}>
                <div className={styles.recordText}>
                  <span
                    className={classnames({
                      [styles.done]: note.status === ActivityStatus.Done,
                    })}
                  >
                    {recordStatusText[note.status]}
                  </span>
                  <span>{`筆記 ${index + 1}`}</span>
                  <span>
                    {new Date(note.createdAt.seconds * 1000).toLocaleString()}
                  </span>
                </div>
                <ArrowForwardIosIcon
                  style={{ color: "#637381", fontSize: "1em" }}
                />
              </div>
              <div className={styles.thumbnailWrapper}>
                <img src={thumbnailUrls[thumbnails[0]]} alt="" />
                <img src={thumbnailUrls[thumbnails[1]]} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaiNoteRecordForStudent;
