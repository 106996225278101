import React, { useEffect } from 'react';

const BitrixService = () => {
  const createBitrixService = (w, d, u) => {

    window.b24s = d.createElement('script'); window.async = true; window.b24s.src = u + '?' + (Date.now() / 60000 | 0);
    window.b24h = d.getElementsByTagName('script')[0]; window.b24h.parentNode.insertBefore(window.b24s, window.b24h);
  }

  const showB24 = () => {
    const b24BtnEle = document.getElementsByClassName("b24-widget-button-wrapper")[0]
    if (b24BtnEle) {
      b24BtnEle.style.display = 'block'
    }
    const b24ChatEle = document.getElementsByClassName("bx-livechat-wrapper")[0]
    if (b24ChatEle) {
      b24ChatEle.style.display = 'block'
    }
  }
  const deleteB24 = () => {
    const b24BtnEle = document.getElementsByClassName("b24-widget-button-wrapper")[0]
    console.log(b24BtnEle);
    if (b24BtnEle) {
      b24BtnEle.style.display = 'none'
    }
    const b24ChatEle = document.getElementsByClassName("bx-livechat-wrapper")[0]
    if (b24ChatEle) {
      b24ChatEle.style.display = 'none'
    }
  }

  useEffect(() => {
    if (!window.b24s) {
      createBitrixService(window, document, 'https://cdn.bitrix24.com/b17048917/crm/site_button/loader_15_nudfls.js');
    } else {
      showB24();
    }
    return () => {
      deleteB24();
    }
  }, [])
  return (
    <></>
  )
};
export default BitrixService;
