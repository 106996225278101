import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

export const useToggleDisplayCommand = () => {
    const [{ interactiveObjectState }, bookDispatch] = useStore(StoreTypes.books);

    const execute = useCallback(async ({ json, pageIndex, isDoublePageMode }) => {
        const baseDisplay = { id: json.id, opacity: json.opacity === 0 ? 1 : 0 };
        try {
            let isFound = false;
            for (let item of interactiveObjectState.state) {
                if (item.pageIndex.includes(pageIndex)) {
                    if (item.display) {
                        const existedDisplay = item.display.find((display) => display.id === json.id);
                        if (existedDisplay) {
                            existedDisplay.opacity = existedDisplay.opacity === 0 ? 1 : 0;
                        } else {
                            item.display.push(baseDisplay)
                        }
                    } else {
                        item.display = [baseDisplay];
                    }
                    isFound = true;
                    break;
                }
            }
            if (!isFound) {
                const baseIndex = pageIndex - pageIndex % 2;
                interactiveObjectState.state.push({
                    pageIndex: isDoublePageMode ? [baseIndex, baseIndex + 1] : [pageIndex],
                    display: [baseDisplay]
                })
            }
            bookDispatch({type: types.SET_INTERACTIVE_OBJECTS_STATE, interactiveObjectState: { ...interactiveObjectState, version: interactiveObjectState.version ? interactiveObjectState.version + 1 : 1 }})
        } catch (e) {

        }
    }, [interactiveObjectState, bookDispatch]);

    return { execute };
};


/*
{
    "version":1,
    "state":[
        {
        "pageIndex": [
            0,
            1
        ],
        "display": [
            {
                "id": "",
                "opacity": 1
            }
        ],
        "stage": [
            {
                "id": "",
                "phase": [
                    "",
                    "",
                    "",
                    ""
                ],
                "current": 2
            }
        ],
        "image": [
            {
                "id": "",
                "targetId": "",
                "src": ""
            }
        ]
    }]
}
*/
