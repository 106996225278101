import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';

export const useSubmenuCommand = () => {
    const [{ submenuInfo }, readerDispatch] = useStore(StoreTypes.reader);

    const execute = useCallback(async ({ json: { submenuInfo: data }, target }) => {
        try {
            if (submenuInfo) {
                readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: null })
            } else {
                const info = typeof data === 'string' ? JSON.parse(data) : data;
                readerDispatch({ type: types.SET_SUBMENU_INFO, submenuInfo: { ...info, target } })
            }
        } catch (err) {
            console.error('MenuCommand execute error', err);
        }
    }, [readerDispatch, submenuInfo]);

    return { execute };
};
