import React from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import check from "components/PaiQuiz/check.svg";

const BaseSnackbar = ({
  anchorOrigin,
  message,
  open,
  handleClose,
  status = "success",
}) => {
  return (
    <>
      {ReactDOM.createPortal(
        <div className={styles.snackbarContainer}>
          <Snackbar
            anchorOrigin={anchorOrigin}
            key={message}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <SnackbarContent
              style={{
                backgroundColor: "white",
                color: "#212B36",
              }}
              message={
                <>
                  {status === "success" && (
                    <div className={`${styles.snackbarIcon} ${styles.success}`}>
                      <img src={check} alt="check" />
                      {message}
                    </div>
                  )}
                </>
              }
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </Snackbar>
        </div>,
        document.body
      )}
    </>
  );
};

export default BaseSnackbar;
