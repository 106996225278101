import React, { useCallback } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import { ScrollPositionType, ScrollLabel } from "constants/scrollPositionType";
import { EventBusType } from "events/EventBus";
import { EventBus } from "events/EventBus";
import { ReaderToolsEvent } from "events/EventTypes";
import { useStore, StoreTypes } from "context";
import { ScrollPositionButtonStateType } from "constants/scrollPositionType";
import { ScrollCoordinate } from "constants/scrollPositionType";
import { gaEventNextPrevPage } from "components/AppService/GAService";
import { CategoryType } from "constants/gaEventType";
import {ReaderToolType} from 'constants/ReaderTools'

const eventBusType = EventBusType.ExtendedContent;

const buttonListData = [
  {
    label: ScrollLabel.Next,
    value: ScrollPositionType.Next,
    stopSign: ScrollPositionButtonStateType.Bottom,
  },
  {
    label: ScrollLabel.Prev,
    value: ScrollPositionType.Prev,
    stopSign: ScrollPositionButtonStateType.Head,
  },
];

export const ExtendedContentScrollPositionButton = () => {
  const [{ scrollPositionButtonState }] = useStore(StoreTypes.extendedContent);

  const changePopWindowScrollPositionHandler = useCallback((scrollPosition) => {
    if (scrollPosition === ScrollPositionType.Next){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.NextPage,
        label: ScrollLabel.Next,
      });
    } else if (scrollPosition === ScrollPositionType.Prev){
      gaEventNextPrevPage({
        category: CategoryType.NextPrevPage,
        action: ReaderToolType.PrevPage,
        label: ScrollLabel.Prev,
      });
    }

    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionEvent,
      payload: { scrollPosition },
    });
    
    // console.log("---2號按鈕---")
  }, []);

  return (
    <div className={styles.extendedContentScrollPositionButton}>
      {buttonListData.map((item) => {
        const disableCheck =
          scrollPositionButtonState === item.stopSign ||
          scrollPositionButtonState === ScrollPositionButtonStateType.None;
        return (
          <div
            className={classnames(styles.button, {
              [styles.disabled]: disableCheck,
            })}
            style={{ backgroundColor: disableCheck && "#999" }}
            key={item.value}
            onMouseDown={() => {
              if (disableCheck) return;
              return changePopWindowScrollPositionHandler(item.value);
            }}
            onMouseUp={() =>
              changePopWindowScrollPositionHandler(ScrollPositionType.None)
            }
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

// 記錄目前位置與上次位置，判斷是否擦掉劃記與按鈕顏色
export const scrollPositionButtonBehavior = ({
  currentScroll,
  lastScroll,
  coordinate,
}) => {
  const eventBusType = EventBusType.ExtendedContent;
  if (coordinate === ScrollCoordinate.X) {
    if (currentScroll !== lastScroll && (currentScroll < 0 || lastScroll < 0)) {
      EventBus.emit({
        eventBusType,
        event: ReaderToolsEvent.ClickEraseAllEvent,
      });
    }
  } else if (coordinate === ScrollCoordinate.Y) {
    if (currentScroll !== lastScroll && (currentScroll > 0 || lastScroll > 0)) {
      EventBus.emit({
        eventBusType,
        event: ReaderToolsEvent.ClickEraseAllEvent,
      });
    }
  }
};

export const ScrollButtonStateBehavior = (state) => {
  const eventBusType = EventBusType.ExtendedContent;
  if (state === ScrollPositionButtonStateType.Head) {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionButtonStateEvent,
      payload: {
        scrollPositionButtonState: ScrollPositionButtonStateType.Head,
      },
    });
  } else if (state === ScrollPositionButtonStateType.Bottom) {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionButtonStateEvent,
      payload: {
        scrollPositionButtonState: ScrollPositionButtonStateType.Bottom,
      },
    });
  } else if (state === ScrollPositionButtonStateType.None) {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionButtonStateEvent,
      payload: {
        scrollPositionButtonState: ScrollPositionButtonStateType.None,
      },
    });
  } else {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScrollPositionButtonStateEvent,
      payload: {
        scrollPositionButtonState: ScrollPositionButtonStateType.Middle,
      },
    });
  }
};
