import React, { useRef, useCallback, useEffect } from 'react';
import useMouse from 'customHooks/mouse';
import useSetState from 'customHooks/setState';
import { timeFormatTommss } from 'util/timeFormater';
import classnames from 'classnames';
import styles from './index.module.scss';

const ProcessBar = ({
  className,
  currentTime,
  endTime,
  controls,
  draggable = false
}) => {
  const [{ isDragging }, setState] = useSetState({ isDragging: false })
  const processBarRef = useRef(null);
  const { elX, elW } = useMouse(processBarRef);
  const toolTipsTime = endTime * elX / elW;

  const updateProcessBarTime = useCallback(() => {
    controls.seek(endTime * elX / elW);
  }, [controls, elW, elX, endTime])

  const onMouseDownHandler = useCallback((e) => {
    e.preventDefault();
    if (draggable) {
      controls.pause();
      setState({ isDragging: true })
    }
    updateProcessBarTime();
  }, [controls, draggable, setState, updateProcessBarTime]);

  const onMouseMoveHandler = useCallback((e) => {
    e.preventDefault();
    if (!isDragging) return;
    updateProcessBarTime();
  }, [isDragging, updateProcessBarTime]);

  const onMouseUpHandler = useCallback((e) => {
    e.preventDefault();
    if (!isDragging) return;
    setState({ isDragging: false })
    updateProcessBarTime();
    controls.play();
  }, [controls, isDragging, setState, updateProcessBarTime]);


  const draggableEvent = {
    onMouseMove: (e) => draggable ? onMouseMoveHandler(e) : () => { },
    onMouseUp: (e) => draggable ? onMouseUpHandler(e) : () => { },
    onMouseLeave: (e) => draggable ? onMouseUpHandler(e) : () => { }
  }

  useEffect(() => {
    const processBar = processBarRef.current;
    if (!processBar) return;
    processBar.addEventListener('touchstart', onMouseDownHandler, false);
    if (draggable) {
      processBar.addEventListener('touchmove', onMouseMoveHandler, false);
      processBar.addEventListener('touchend', onMouseUpHandler, false);
    }

    return () => {
      processBar.removeEventListener('touchstart', onMouseDownHandler, false);
      processBar.removeEventListener('touchmove', onMouseMoveHandler, false);
      processBar.removeEventListener('touchend', onMouseUpHandler, false);
    }
  }, [draggable, onMouseDownHandler, onMouseMoveHandler, onMouseUpHandler])

  return (
    <div
      ref={processBarRef}
      className={classnames(styles.processBar, className)}
      onMouseDown={(e) => onMouseDownHandler(e)}
      {...draggableEvent}
    >
      <div
        className={styles.processUsage}
        style={{
          width: `${draggable && isDragging ? elX / elW * 100 : currentTime / endTime * 100}%`,
          transition: `${draggable && isDragging ? 'none' : '0.3s'}`
        }}
      />
      {
        (toolTipsTime >= 0 && toolTipsTime <= endTime) && (
          <div className={styles.timeTooptips} style={{ left: `${elX}px` }}>
            {timeFormatTommss(toolTipsTime)}
          </div>
        )
      }

    </div>
  )
}


export default ProcessBar;
