import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useSynopsisCommand = () => {
    const [, { openModal, setModalComponents }] = useModal();

    const execute = useCallback(async ({ json: { synopsisInfo,style } }) => {
        try {
            const component = ModalComponentGenerator.generate(ModalComponentType.Synopsis, { synopsisInfo: JSON.parse(synopsisInfo), extendedResourceStyle: style  });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('SynopsisComponent execute error', e);
        }
    }, [openModal, setModalComponents]);

    return { execute };
};