import React,{useEffect,useRef,useState} from 'react';
import useSetState from 'customHooks/setState';
import AudioControlsButton from 'components/common/AudioControlsButton';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
import { ScrollCoordinate, ScrollPositionButtonStateType,ScrollPositionType, ScrollLength} from "constants/scrollPositionType";
import { ScrollButtonStateBehavior,scrollPositionButtonBehavior } from "../ExtendedContent/ExtendedContentScrollPositionButton";



const { ExtendedResourceRepository } = Repository;
const HearingDraft = ({ data, defaultTab }) => {
  const { contents } = data;
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize,scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [{ tabIndex }, setState] = useSetState({
    tabIndex: defaultTab
  });
  const topicRef = useRef()
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  useEffect(() => {
    // 小視窗內容上下頁分段
    // console.log("---G類小視窗---HearingDraft", topicRef);
    switch (scrollPosition) {
      case ScrollPositionType.Next: {
        setLastScrollTop(topicRef.current.scrollTop);
        topicRef.current.scrollTop += topicRef.current.offsetHeight*ScrollLength;
        setCurrentScrollTop(topicRef.current.scrollTop);

        break;
      }
      case ScrollPositionType.Prev: {
        setLastScrollTop(topicRef.current.scrollTop);
        topicRef.current.scrollTop -= topicRef.current.offsetHeight*ScrollLength;
        setCurrentScrollTop(topicRef.current.scrollTop);
        break;
      }
      default: {
        scrollPositionButtonBehavior({currentScroll: currentScrollTop, lastScroll: lastScrollTop, coordinate: ScrollCoordinate.Y})
        break;
      }
    }
  }, [scrollPosition]);

  const targetScrollHeight = topicRef.current? topicRef.current.scrollHeight : 0

  useEffect(()=> {
      const updateScrollButtonState = () => {
        const offsetHeight = topicRef.current.offsetHeight
        const scrollHeight = topicRef.current.scrollHeight
        const scrollTop = topicRef.current.scrollTop
        const headCond = scrollTop === 0
        const bottomCond = (scrollHeight - offsetHeight - scrollTop) < 1
        if (headCond) {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
        }else if(bottomCond) {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Bottom)
        } else {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Middle)
        }
      }
      if (topicRef && topicRef.current) {
        if(topicRef.current.scrollHeight === topicRef.current.offsetHeight) {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.None)
        } else {
          ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
        }
        topicRef.current.scrollTop = 0
        topicRef.current.addEventListener("scroll", updateScrollButtonState, false)
      }
      return () => {
        topicRef.current.removeEventListener('scroll', updateScrollButtonState, false)
      }
  },[targetScrollHeight,fontSize,tabIndex])

  return (
    <div className={classnames(styles.hearingDraft, styles[fontSize])}>
      <div className={styles.main}>
        <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
          bookId,
          pathName: contents[tabIndex].contentSound
        })} />
        <div className={styles.topic} ref={topicRef}>
          <div className={styles.title}>
            <img src={ExtendedResourceRepository.getDataSrc({ bookId, pathName: contents[tabIndex].topic })} alt="" />
          </div>
          <div className={styles.content}>
            {
              contents[tabIndex].content.map((list, index) => {
                return (
                  <div
                    className={styles.list}
                    key={list}
                    dangerouslySetInnerHTML={{ __html: list }}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={classnames(styles.tab)}>
        {
          contents.map((item, index) => <div className={classnames(styles.list, {
            [styles.active]: tabIndex === index
          })} key={index} onClick={() => setState({ tabIndex: index })}>{index + 1}</div>)
        }
      </div>
    </div>
  );
};

export default HearingDraft;
