import { urlFormat } from 'util/urlFormat';
import { fileExpressHost, fileExpressPort } from 'repositories/Repository';
import { IExtendedResourceRepository } from './IExtendedResourceRepository';
import { encodeURIString } from 'util/helper';

function FileExtendedResourceRepository() { }
FileExtendedResourceRepository.prototype = Object.create(
  IExtendedResourceRepository
);

FileExtendedResourceRepository.prototype.constructor = async function () {
  const { defaultRootPath } = await this.getDocumentPath();
  this.defaultRootPath = defaultRootPath;
};

FileExtendedResourceRepository.prototype.getVideoSrc = ({
  bookId,
  filename
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.mp4`,
  });
};

FileExtendedResourceRepository.prototype.getAudioSrc = ({
  bookId,
  filename
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${filename}.mp3`,
  });
};

FileExtendedResourceRepository.prototype.getAudioVTTSrc = ({
  bookId,
  audioInfo
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${audioInfo}.vtt`,
  });
};

FileExtendedResourceRepository.prototype.getSubtitleSrc = ({
  bookId,
  filename
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.vtt`,
  });
};

FileExtendedResourceRepository.prototype.getInternalHtmlSrc = ({
  bookId,
  filename
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${filename}.html`,
  });
};

FileExtendedResourceRepository.prototype.getDataSrc = ({
  bookId,
  pathName
}) => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${encodeURIString(pathName)}`,
  });
};

FileExtendedResourceRepository.prototype.getInternalWindowHtml = async ({
  bookId,
  filename
}) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${filename}.json`,
    })
  );
  const json = await res.json();
  return json.html;
};

FileExtendedResourceRepository.prototype.getJSONContent = async ({
  bookId,
  pathName
}) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${pathName}.json`,
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getMathJaxPath = () => {
  return urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: 'mathjax/MathJax.js',
  });
  // return 'http://localhost:54321/mathjax/MathJax.js';
};

FileExtendedResourceRepository.prototype.getBookmarks = async (bookId) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `bookmarks/${bookId}`,
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getPageTools = async ({ bookId }) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `offline-tools/${bookId}`,
      query: { type: 'pages' },
    })
  );
  const json = await res.json();
  json.getToolsByPage = function (page) {
    let tools = [];
    for (let tool of this) {
      if (page >= tool.pages[0] && page <= tool.pages[1]) {
        tools.push(tool);
      }
    }
    return tools;
  }
  json.getToolsByPage = json.getToolsByPage.bind(json);
  return json;
};

FileExtendedResourceRepository.prototype.getToolbarTools = async ({ bookId }) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `offline-tools/${bookId}`,
      query: { type: 'toolbar' },
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.postTools = async ({ bookId, filename }) => {
  fetch(urlFormat({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `offline-tools`,
  }), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ bookId, filename })
  });

  return null;
};

FileExtendedResourceRepository.prototype.getWordGridAnchorDirection = () => {
  return 'start';
};

FileExtendedResourceRepository.prototype.getStampGroups = async (bookId) => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `stampGroups/${bookId}`,
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getDocumentPath = async () => {
  const res = await fetch(
    urlFormat({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `documentPath`,
    })
  );
  const json = await res.json();
  return json;
};
 
 FileExtendedResourceRepository.prototype.openVideoBlank = async function ({bookId,filename}) {
   const { defaultRootPath } = await this.getDocumentPath();
   console.log("this",this.defaultRootPath)
   window.electron.shell.openPath(`${defaultRootPath}/media/${bookId}/video/${filename}.mp4`);
 };

export default new FileExtendedResourceRepository();
