import React, { useEffect, useCallback, useRef } from 'react';
import ControlButton from 'components/common/Player/ControlButton';
import { useBoolean } from 'customHooks/boolean';
import useMouse from 'customHooks/mouse';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';
import useSetState from 'customHooks/setState';

const VolumeBar = ({
  state,
  controls,
  onChange,
  className,
  hoverable = true,
  width = 100,
  draggable = false,
  onVolumeBarActiveStateChange = () => { }
}) => {
  const [isShowVolumeBar, { toggle: setVolumeBarToggle, setFalse: setVolumeBarDeactive, setTrue: setVolumeBarActive }] = useBoolean();
  const [{ isDragging }, setState] = useSetState({ isDragging: false })
  const volumeBarRef = useRef();
  const { elX, elW } = useMouse(volumeBarRef);

  const changeHandler = useCallback(e => {
    onChange(elX / elW);
  }, [elW, elX, onChange]);

  const onClickHandler = useCallback(() => {
    if (hoverable) {
      return (state.volume > 0 && !state.muted) || !state.muted
        ? controls.mute()
        : controls.unmute()
    } else {
      setVolumeBarToggle();
    }

  }, [controls, hoverable, setVolumeBarToggle, state.muted, state.volume]);

  const onMouseEnterHandler = useCallback(() => {
    hoverable && setVolumeBarActive();
  }, [hoverable, setVolumeBarActive]);

  const onMouseLeaveHandler = useCallback(() => {
    hoverable && setVolumeBarDeactive();
  }, [hoverable, setVolumeBarDeactive]);

  useEffect(() => {
    onVolumeBarActiveStateChange(isShowVolumeBar);
  }, [isShowVolumeBar, onVolumeBarActiveStateChange]);

  const onMouseDownHandler = useCallback((e) => {
    e.preventDefault();
    if (draggable) {
      setState({ isDragging: true })
    }
    changeHandler();
  }, [changeHandler, draggable, setState]);

  const onMouseMoveHandler = useCallback((e) => {
    e.preventDefault();
    if (!isDragging) return;
    changeHandler();
  }, [changeHandler, isDragging]);

  const onMouseUpHandler = useCallback((e) => {
    e.preventDefault();
    if (!isDragging) return;
    setState({ isDragging: false })
    changeHandler();
  }, [changeHandler, isDragging, setState]);


  const draggableEvent = {
    onMouseMove: (e) => draggable ? onMouseMoveHandler(e) : () => { },
    onMouseUp: (e) => draggable ? onMouseUpHandler(e) : () => { },
    onMouseLeave: (e) => draggable ? onMouseUpHandler(e) : () => { }
  }

  useEffect(() => {
    const volumeBar = volumeBarRef.current;
    if (!volumeBarRef) return;
    volumeBar.addEventListener('touchstart', onMouseDownHandler, false);
    if (draggable) {
      volumeBar.addEventListener('touchmove', onMouseMoveHandler, false);
      volumeBar.addEventListener('touchend', onMouseUpHandler, false);
    }

    return () => {
      volumeBar.removeEventListener('touchstart', onMouseDownHandler, false);
      volumeBar.removeEventListener('touchmove', onMouseMoveHandler, false);
      volumeBar.removeEventListener('touchend', onMouseUpHandler, false);
    }
  }, [draggable, onMouseDownHandler, onMouseMoveHandler, onMouseUpHandler])

  const volumeBarStyle = isDragging ? {
    width: `${elX / elW * 100 }%`,
    transition: `${draggable && isDragging ? 'none' : '0.3s'}`
  } : { width: `${(state.volume / 1) * 100}%` } 


  return (
    <div className={classnames(styles.volumeBarWrapper, className)}>
      <ControlButton
        onClick={() => onClickHandler()}
        onMouseEnter={() => onMouseEnterHandler()}
        onMouseLeave={() => onMouseLeaveHandler()}
      >
        {(state.volume === 0 || state.muted) && (
          <Icon type="text" name="volumeMute" />
        )}
        {state.volume > 0 && state.volume < 0.7 && !state.muted && (
          <Icon type="text" name="volumeDown" />
        )}
        {state.volume >= 0.7 && !state.muted && (
          <Icon type="text" name="volumeUp" />
        )}
      </ControlButton>

      <div
        ref={volumeBarRef}
        style={{ width: isShowVolumeBar ? `${width}px` : 0 }}
        className={classnames(styles.volumeBar, {
          [styles.active]: isShowVolumeBar
        })}
        onMouseEnter={() => onMouseEnterHandler()}
        onMouseLeave={() => onMouseLeaveHandler()}
        onMouseDown={(e) => onMouseDownHandler(e)}
        onClick={e => changeHandler(e)}
        {...draggableEvent}
      >
        <div
          className={styles.volumeUsage}
          style={volumeBarStyle}
        />
      </div>
    </div >
  );
};

export default VolumeBar;
