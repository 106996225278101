import { IClassNameRepository } from './IClassNameRepository';

function CloudClassNameRepository() { };

CloudClassNameRepository.prototype = Object.create(IClassNameRepository);

CloudClassNameRepository.prototype.getClassName = className => {
  return `Cloud_${className}`
}

export default new CloudClassNameRepository();