import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useZhuyinLessonCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const execute = useCallback(async ({ json: { src } }) => {
    // json : { src編輯路徑, 針對此小視窗的額外參數 }

    try {
      const zhuyinLessonResource = await ExtendedResourceRepository.getJSONContent(
        { bookId, pathName: src }
      ); // { 書本, SRC編輯路徑 }

      // 取得資料
      const component = ModalComponentGenerator.generate(ModalComponentType.ZhuyinLesson, { zhuyinLessonResource });
      setModalComponents(component);
      openModal();
    } catch (e) {
      console.error('ZhuyinLessonComponent execute error', e);
    }
  }, [bookId, openModal, setModalComponents]);

  return { execute };
}