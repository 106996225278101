import React, { useCallback } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const SwitchButton = ({ 
  value = false,
  onChange = () => {}
}) => {

  const changeHandler = useCallback(e => {
    onChange(e.target.checked);
  },[onChange])

  return (
      <label
        className={classnames(styles.switchButton,{
          [styles.active]: value
        })}
      >
        <input
          className={styles.checkbox}
          type="checkbox"
          checked={value}
          onChange={changeHandler}
        />
      </label>
  );
};

export default SwitchButton;
