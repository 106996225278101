import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { Roles } from 'constants/role';
import { useStore } from 'context';
import { StoreTypes } from 'context';

const floatDirectionType = {
  right: 'right',
  left: 'left',
  top: 'top',
  bottom: 'bottom'
};

const Sidebar = ({
  className,
  children,
  isShow,
  isFloat = true,
  floatDirection = '',
  isWithMask = false,
  vertical,
  horizontal,
  onMaskClick = () => { }
}) => {
  const direction =
    floatDirectionType[floatDirection] || floatDirectionType.right;
  const [{ role }] = useStore(StoreTypes.user);


  return (
    <>
      <div
        className={classnames(styles.sidebar, className, {
          [styles.vertical]: vertical,
          [styles.horizontal]: horizontal,
          [styles.show]: isShow,
          [styles.covered]: !isFloat,
          [styles.float]: isFloat,
          [styles[direction]]: isFloat,
          [styles.isWithMask]: isWithMask,
          [styles.greyBg]: role === Roles.INTERACTIVITY_STUDENT
        })}
      >
        {children}
      </div>
      {isWithMask && isShow && (
        <div className={styles.mask} onClick={onMaskClick}></div>
      )}
    </>
  );
};

export default Sidebar;
