import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import {
  ModalComponentGenerator,
  ModalComponentType
} from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useHearingDraftCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const defaultTab = 0;
  const execute = useCallback(
    async ({ json: { src, hearingInfo = defaultTab } }) => {
      try {
        const hearingResource = await ExtendedResourceRepository.getJSONContent(
          { bookId, pathName: src }
        );
        // 取得資料
        const component = ModalComponentGenerator.generate(
          ModalComponentType.Hearing,
          { hearingResource, tab: hearingInfo }
        );
        setModalComponents(component);
        openModal();
      } catch (e) {
        console.error('hearingCommand execute error', e);
      }
    },
    [bookId, openModal, setModalComponents]
  );

  return { execute };
};
