import React from 'react';
import styles from './index.module.scss';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

export const Indicator = ({ message }) => {
    return (
        <div className={styles.indicatorContainer}>
            <div className={styles.label}>{message}</div>
            <Spinner size={72} className={styles.indicator} />
        </div>
    );
};
