import React from 'react';
import ToolSectionTitle from 'components/Tools/ToolSectionTitle';
import SwitchButton from 'components/common/SwitchButton';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import styles from './index.module.scss';


const ObjectPointerEventTools = () => {
  const [{ objectPointerEventStatus }] = useStore(StoreTypes.reader);

  const onChangeHandler = val => {
    EventBus.emit({ event: ReaderToolsEvent.SetObjectPointerStatusEvent, payload: { objectPointerEventStatus: val } })
  }
  
  return (
    <div className={styles.objectPointerEventTools}>
      <ToolSectionTitle label="畫筆點擊按鈕" />
      <SwitchButton value={objectPointerEventStatus} onChange={val=>onChangeHandler(val)}/>
    </div>
  )
};

export default ObjectPointerEventTools;
