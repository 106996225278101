import { useCallback } from 'react';
import { useSetPageIndex } from 'customHooks/reader';
import { useStore, StoreTypes } from 'context';

export const useGoPageCommand = () => {
    const [{ isDoublePageMode }] = useStore(StoreTypes.reader);
    const { setPageIndex } = useSetPageIndex();
    const execute = useCallback(async ({ json: { pageIndex } }) => {
        const newPageIndex = Math.max(parseInt(isDoublePageMode ? Math.floor((pageIndex - 1) / 2) : pageIndex - 1), 0);
        setPageIndex({ convertToSVG: true, pageIndex: newPageIndex });
    }, [isDoublePageMode, setPageIndex]);

    return { execute };
};
