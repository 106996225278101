import React from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';

const useSyncNotificationStyles = makeStyles({
    paper: {
        borderRadius: '16px',
        maxWidth: '550px',
        '& .MuiDialogTitle-root': {
            padding: '16px 32px',
            color: '#EC7963',
            fontSize: '24px',
            fontWeight: '500',
        },
        '& .MuiDialogContent-root': {
            color: '#454B5C',
            fontSize: '18px',
            fontWeight: '400',
            padding: '0px 32px',
            '& span': {
                lineHeight: '30px',
            },
            '& a': {
                color: '#4C99A5',
                fontSize: '18px',
                fontWeight: '700',
                textDecoration: 'none',
                padding: '3px'
            },
            '& a::after': {
                content: 'url("/assets/img/syncNotificationOpenLink.png")',
                marginLeft: '9.5px',
                verticalAlign: 'middle'
            },
            '& img': {
                marginTop: '8.5px',
            }
        },
        '& .MuiDialogActions-root': {
            padding: '16px 32px',
            justifyContent: 'space-between',
            '& .Mui-checked': {
                color: '#EC7963'
            }
        }
    }
});

const SyncNotification = ({leave, setLeave}) => {
    const [{ syncNotifyDialog: {isShowing} }, globalDispatch] = useStore(StoreTypes.global);
    const router = useStore(StoreTypes.router);
    const notifyAndToBookshelf = () => {
        globalDispatch({type: types.CLOSE_SYNCNOTIFY_DIALOG});
        router.history.push('/bookshelf');
      }
      const notifyAndLeave = () => {
        globalDispatch({type: types.CLOSE_SYNCNOTIFY_DIALOG});
        setLeave(false);
        window.electron && window.electron.ipcRenderer.send('closeOneBook');
      }
      const setLocalStorage = (e) => {
        if (e.target.checked) {
            localStorage.setItem('notifySync', 'false');
        } else {
            localStorage.removeItem('notifySync');
        }
      }

      let closeNotificationHandler = leave ? notifyAndLeave : notifyAndToBookshelf;
    return (
        <>
            <Dialog
                open={isShowing}
                style={{zIndex:1000000}}
                classes={useSyncNotificationStyles()}
            >
                <DialogTitle id="syncNotification">
                    <span>班級紀錄已儲存於本機</span>
                </DialogTitle>
                <DialogContent>
                    <span>
                        如果要將本紀錄上傳雲端，請至【書櫃】，點擊【班級紀錄】後按下【紀錄同步】
                    </span>
                    <a href='http://video.nani.com.tw/notes_disc' target='_blank' rel='noopener noreferrer'>
                        點我觀看操作影片
                    </a>
                    <img src='assets/img/syncNotification.png' alt='離開書本點選書封下方班級紀錄按鈕即可見紀錄同步按鈕' />
                </DialogContent>
                <DialogActions>
                    <FormControlLabel control={<Checkbox name='notShow' />} label='下次不再提醒我' onChange={setLocalStorage}/>
                    <Button size='large' style={{ background: '#121232', color: 'white', width: '148px', marginLeft: '12px' }} onClick={closeNotificationHandler}>我知道了</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default SyncNotification;
