import basic from 'components/PaiQuiz/oneStar.png';
import advance from 'components/PaiQuiz/twoStar.png';
import challenge from 'components/PaiQuiz/threeStar.png';

export const QuizLevel = [
    {
        quizText: '基礎',
        quizIcon: basic
    },
    {
        quizText: '進階',
        quizIcon: advance
    },
    {
        quizText: '挑戰',
        quizIcon: challenge
    },
];

export const difficultyWeight = [
    {
        "begin": 4,
        "basic": 3,
        "intermediate": 2,
        "advanced": 1,
        "expert": 0
    },
    {
        "begin": 1,
        "basic": 2,
        "intermediate": 4,
        "advanced": 2,
        "expert": 1
    }, 
    {
        "begin": 0,
        "basic": 1,
        "intermediate": 2,
        "advanced": 3,
        "expert": 4
    }
];

export const PaiStatus = {
    ChooseQuiz: 'chooseQuiz',
    Original: 'original',
    Immediate: 'immediate',
    OneLink: 'oneLink',
    EndClose: 'endClose',
    UnLogin: 'unLogin',
};

export const Category = {
    PaperId: 'paperId',
    Knowledges: 'knowledges',
};

export const PaiType = {
    paper: 'paper',
    knowledge: 'knowledge',
};