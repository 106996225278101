import { Stamps } from 'components/Icon';

export const StampType = Object.keys(Stamps).reduce((acc, v) => {
    acc[v] = v;
    return acc;
}, {});

export const StampMap = Stamps

export const LargerClickAreaStamps = [
    'Btn-412',
    'Btn-413',
    'Btn-414',
    'Btn-415'
]