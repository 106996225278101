import React, { useCallback } from 'react';
import { LineType } from 'constants/wordGrid';
import classnames from 'classnames';
import styles from './index.module.scss';


const WORDING_MAP = {
  [LineType.Solid]: {
    label: '實線',
    value: LineType.Solid
  },
  [LineType.Dotted]: {
    label: '虛線',
    value: LineType.Dotted
  },
  [LineType.Hidden]: {
    label: '隱藏',
    value: LineType.Hidden
  }
}

const GridButtonGroup = ({
  value,
  onChange,
  noGrid = false
}) => {

  const clickHandler = useCallback(val => () => {
    onChange(val)
  }, [onChange])

  return (
    <div className={styles.buttonGroup}>
      {
        Object.values(WORDING_MAP).map(item => (
          <div className={classnames(styles.item, {
            [styles.active]: item.value === value,
            [styles.disabled]: noGrid
          })}>
            <button onClick={clickHandler(item.value)} disabled={noGrid}>
              {item.label}
            </button>
          </div>
        ))
      }
    </div>
  )
};

export default GridButtonGroup;
