import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import paiQuizLoading from './paiQuizLoading.json';
import paiQuizLoading2 from './paiQuizLoading2.json';

export const LottiePlayer = ({ width='70%' }) => {
    return (
        <Player
            loop
            autoplay
            src={paiQuizLoading}
            style={{ width }}
        >
        </Player>
    )
}

export const LottiePlayer2 = ({ width='70%' }) => {
    return (
        <Player
            loop
            autoplay
            src={paiQuizLoading2}
            style={{ width }}
        >
        </Player>
    )
}
