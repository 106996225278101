import React from 'react';
import styles from '../../index.module.scss';

import ActivatableReaderToolComponent from '../ActivatableReaderToolComponent';

const RelativePositionComponent = ({ currentReaderToolType, type, additionalClickHandler }) => {
    return (
        <div key={type} className={styles.toolBtn}>
            <ActivatableReaderToolComponent type={type} currentReaderToolType={currentReaderToolType} additionalClickHandler={additionalClickHandler} />
        </div>
    );
};

export default RelativePositionComponent;
