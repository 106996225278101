import React, { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import classnames from 'classnames';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';

import AudioControlsButton from 'components/common/AudioControlsButton';

import styles from './index.module.scss';
const { ExtendedResourceRepository } = Repository;

const ZhuyinImage = ({ resource }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const { tab, contents } = resource;
  const [{ tabIndex }, setState] = useSetState({
    tabIndex: tab,
  })

  return (
    <div className={styles.zhuyinImage}>
      <div className={styles.options}>
        <div className={styles.audio}>
          {
            contents[tabIndex].sound && <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].sound
            })} />
          }
        </div>
        <div className={styles.tabBox}>
          {
            contents && contents.map((item, idx) => <div key={idx} className={classnames(styles.tab, {
              [styles.active]: tabIndex === idx
            })} onClick={() => setState({ tabIndex: idx })}>{item.label}</div>)
          }
        </div>
      </div>
      <div className={styles.title}>
        {
          contents[tabIndex].title && contents[tabIndex].title.map((item, idx) => <ruby key={idx}><rt>{item}</rt></ruby>)
        }
      </div>
      <div className={styles.img}>
        {
          contents[tabIndex].image && <img
            src={`${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].image
            })}`}
            alt=""
          />
        }
      </div>
    </div >
  )
}

export default ZhuyinImage;