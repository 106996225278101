import React, { useCallback, useEffect, useState, useMemo } from "react";
import { xor } from "lodash";
import styles from "./index.module.scss";
import { copyIcon } from "../GroupInteractiveTool/CustomButtons";
import { ActivityState } from "constants/groupInteractivity";
import {
  CountButton,
  RectQuizButton,
  SideQuizButton,
  ChoiceQuizButton,
} from "./CustomButtons";
import Icon from "components/Icon";
import {
  makeStyles,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Popover,
} from "@material-ui/core";
import { QRCodeSVG } from "qrcode.react";
import { QuizType, ChoiceButtons, trueFalseMap, ActivityStatus } from "constants/groupInteractivity";
import * as types from "constants/actionTypes";
import { useStore, StoreTypes } from "context";
import uuid from "util/uuid";
import { interactivityApi } from "./api";
import { WarnDialog } from "./WarnDialog";
import { ActivityType } from "constants/groupInteractivity";
import SelectedPages from "./SelectedPages";
import { ExtendedContentType } from "components/ExtendedContent/ExtendedContentContainer";
import classNames from "classnames";
import { uniq } from "lodash";

const sideButtonText = {
  reset: {
    [ExtendedContentType.AnswerInteractiveTool]: "重新設定",
    [ExtendedContentType.PaiNote]: "重新設定"
  },
  sendOut: {
    [ExtendedContentType.PaiNote]: "派出筆記"
  },
  checkResult: {
    [ExtendedContentType.AnswerInteractiveTool]: "查看結果",
    [ExtendedContentType.PaiNote]: "查看結果"
  },
  next: {
    [ExtendedContentType.AnswerInteractiveTool]: "下一題",
    [ExtendedContentType.PaiNote]: "下一頁"
  },
  previous: {
    [ExtendedContentType.AnswerInteractiveTool]: "上一題",
    [ExtendedContentType.PaiNote]: "上一頁"
  }
}

const sideButton = {
  [ActivityState.Create]: [{
    icon: "arrowLeft",
    text: "reset",
    bottom: "30%",
    borderColor: "#454F5B",
    color: "white",
    background: "#637381",
  },
  {
    icon: "arrowRight",
    text: "sendOut",
    bottom: "3%",
    borderColor: "#B7601A",
    color: "#7A310A",
    background: "#FFA235",
  },
],
  [ActivityState.Wait]: [
    {
      icon: "arrowLeft",
      text: "reset",
      bottom: "30%",
      borderColor: "#454F5B",
      color: "white",
      background: "#637381",
    },
    {
      icon: "arrowRight",
      text: "checkResult",
      bottom: "3%",
      borderColor: "#B7601A",
      color: "#7A310A",
      background: "#FFA235",
    },
  ],
  [ActivityState.Review]: [
    {
      icon: "arrowLeft",
      text: "previous",
      bottom: "30%",
      borderColor: "#454F5B",
      color: "white",
      background: "#637381",
    },
    {
      icon: "arrowRight",
      text: "next",
      bottom: "3%",
      borderColor: "#454F5B",
      color: "white",
      background: "#637381",
    },
  ],
  [ActivityState.PreviewHandWrite]: []
};

const apiEndpoint = {
  [ExtendedContentType.AnswerInteractiveTool]: 'quizzes',
  [ExtendedContentType.PaiNote]: 'notes'
}

const createActivityOptionList = [
  {
    id: 'quizGroup',
    type: [ExtendedContentType.AnswerInteractiveTool, ExtendedContentType.PaiNote],
    text: "學生分組",
    subText: "(最多六組)",
    input: ({ clickHandler, state, id }) => (
      <>
        <CountButton
          plus={false}
          handleClick={() => clickHandler(id, state - 1)}
        />
        <span>{state} 組</span>
        <CountButton
          plus={true}
          handleClick={() => clickHandler(id, state + 1)}
        />
      </>
    ),
    divider: false,
  },
  {
    id: 'quizNum',
    type: [ExtendedContentType.AnswerInteractiveTool],
    text: "派題數量",
    subText: "(最多五題)",
    input: ({ clickHandler, state, id }) => (
      <>
        <CountButton
          plus={false}
          handleClick={() => clickHandler(id, state - 1)}
        />
        <span>{state} 題</span>
        <CountButton
          plus={true}
          handleClick={() => clickHandler(id, state + 1)}
        />
      </>
    ),
    divider: true,
  },
  {
    id: 'quizType',
    type: [ExtendedContentType.AnswerInteractiveTool],
    text: "派題類型",
    subText: "",
    input: ({ clickHandler, state, id, style }) => {
      return (
      <>
        <Select
          classes={style}
          variant="outlined"
          value={state}
          onChange={(e) => clickHandler(id, e.target.value)}
        >
          <MenuItem value={QuizType.MultipleChoice}>選擇題</MenuItem>
          <MenuItem value={QuizType.TrueFalse}>是非題</MenuItem>
          <MenuItem value={QuizType.HandWriting}>手寫題</MenuItem>
        </Select>
      </>
    )},
    divider: false,
  },
  {
    id: 'choiceNum',
    type: [ExtendedContentType.AnswerInteractiveTool],
    text: "選項數量",
    subText: "(最多五個選項)",
    input: ({ clickHandler, state, id }) => (
      <>
        <CountButton
          plus={false}
          handleClick={() => clickHandler(id, state - 1)}
        />
        <span>{state} 個</span>
        <CountButton
          plus={true}
          handleClick={() => clickHandler(id, state + 1)}
        />
      </>
    ),
    divider: false,
  },
];

const questionTypeSelectStyles = makeStyles({
  select: {
    width: "100%",
    padding: "15px 0px 0px 10px!important",
    border: "2px solid #315d83",
    fontSize: "1.5em",
    "&:focus": {
      borderRadius: "4px",
    },
    "& ~ .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent!important",
      borderWidth: "0px!important",
    },
  },
});

const studentCheckboxStyles = makeStyles({
  root: {
    margin: 0,
    flex: "1 1 6em",
    maxWidth: "78%",
    "& .MuiButtonBase-root": {
      padding: 0,
      marginRight: ".1em",
      "&.Mui-checked": {
        color: "#B7601A",
      },
    },
    "& .MuiTypography-root": {
      fontSize: "1em",
      lineHeight: "unset",
      fontWeight: "700",
      color: "#315d83",
      whiteSpace: "nowrap",
      overflowX: "hidden",
      textOverflow: "ellipsis",
      fontFamily:
        "Arial, Microsoft JhengHei, 微軟正黑體, -apple-system, BlinkMacSystemFont",
    },
    "& .MuiSvgIcon-root": {
      height: ".9em",
      width: ".9em",
    },
    "& .MuiButtonBase-root.Mui-disabled": {
      opacity: 0
    }
  },
});

const copyLinkButtonStyles = makeStyles({
  root: {
    background: '#DFE3E8',
    color: '#315D83',
    fontSize: '.6em',
    height: '30px',
    width: 'auto',
    "& .MuiButton-label": {
      whiteSpace: 'nowrap',
      fontSize: '.6em'
    }
  }
});

const copyLinkPopoverStyles = makeStyles({
  paper: {
    background: '#315D83',
    color: 'white',
    padding: '5px 5px',
  }
});

const reviewHandwritingWidthMap = {
  1: '70%',
  2: '48%',
  3: '33%',
  4: '33%',
  5: '30%',
  6: '30%'
}

const reviewHandwritingHeightMap = {
  1: '80%',
  2: '80%',
  3: '40%',
  4: '40%',
  5: '40%',
  6: '40%'
}

export const ResultHead = ({extendedContentType, quizInfo, reviewAnsNo}) => {
  const titleText = useMemo(() => {
    switch(extendedContentType) {
      case ExtendedContentType.AnswerInteractiveTool:
        return quizInfo.quizType
      case ExtendedContentType.PaiNote:
        return ActivityType.PaiNote
      default:
        return
    }
  }, [extendedContentType, quizInfo]);

  const unitText = useMemo(() => {
    switch(extendedContentType) {
      case ExtendedContentType.AnswerInteractiveTool:
        return '題'
      case ExtendedContentType.PaiNote:
        return '頁'
      default:
        return
    }
  }, [extendedContentType])

  return (
      <div className={styles.resultHead}>
        <Icon type="text" name="lightblub" className={styles.headerIcon} />
        {titleText}－{`第 ${reviewAnsNo} ${unitText}／共 ${quizInfo.quizNum || Math.floor(quizInfo.pageIndex.length / 2)} ${unitText}`}
      </div>
  )
}

const GroupInteractiveTool = ({extendedContentType}) => {
  const checkboxStyles = studentCheckboxStyles();
  const selectDropdownStyles = questionTypeSelectStyles();
  const copyButtonStyles = copyLinkButtonStyles();
  const copyPopOverStyles = copyLinkPopoverStyles();
  const [{ isDoublePageMode, pageIndex, recordPages }, readerDispatch ] = useStore(StoreTypes.reader);
  const [
    {
      annotationId,
      activityInfo: { firestore },
    },
  ] = useStore(StoreTypes.annotation);
  const [{ bookId, books, catalog }] = useStore(StoreTypes.books);
  const book = books.find(book => book.bookId === bookId);
  const bookName = book.display_name;
  const [{ userId, token }] = useStore(StoreTypes.user);
  const [
    {
      groupInteractivity: { activityState, activityInfo: existedActivityInfo, paiNoteSelectedPageIndex: existedSelectedPageIndex },
    },
    globalDispatch,
  ] = useStore(StoreTypes.global);
  const [createActivityInfo, setCreateActivityInfo] = useState({
    QRCode: uuid(),
    id: "",
    quizGroup: 3,
    quizType: QuizType.MultipleChoice,
    quizNum: 5,
    choiceNum: 4,
    status: ActivityStatus.Created,
    createdAt: "",
  });
  const [currentTime, setCurrentTime] = useState(new Date());
  const [students, setStudent] = useState([]);
  const [reviewAnsNo, setReviewAnsNo] = useState(1);
  const [reviewChoice, setReviewChoice] = useState("");
  const [reviewHandWriting, setReviewHandWritng] = useState([]);
  const [needSelectPage, setNeedSelectPage] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState([]);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [warnDialogContent, setWarnDialogContent] = useState({
    title: '',
    subTitle: '',
    handleCancel: () => {},
    handleConfirm: () => {}
  });
  const [copyPopoverOpen, setCopyPopoverOpen] = useState(null);

  const activityInfo = useMemo(() => {
    return existedActivityInfo.id ? existedActivityInfo : createActivityInfo
  }, [existedActivityInfo, createActivityInfo]);
  
  useEffect(() => {
    let getCurrentTime;
    if (activityState === ActivityState.Wait) {
      getCurrentTime = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
    }

    return () => clearInterval(getCurrentTime);
  }, [activityState]);

  //監聽學生加入及交卷
  useEffect(() => {
    if (!firestore || activityState !== ActivityState.Wait) return;
    const unsuscribe = firestore
      .collection(`teachers/${userId}/${apiEndpoint[extendedContentType]}`)
      .where("id", "==", activityInfo.id)
      .onSnapshot((snapshot) => {
        if (snapshot.docs.length === 0) return;
        const data = snapshot.docs[0].data();
        setStudent(data.students.sort((a, b) => {
          return a.createdAt - b.createdAt
        }));
      });

    return () => {
      unsuscribe();
    };
  }, [activityState, activityInfo.id, firestore, userId]);

  //檢視的題號改變時打 api
  useEffect(() => {
    if (activityState !== ActivityState.Review) return;
    setReviewChoice("");
    interactivityApi[extendedContentType]
      .getAnswer(token, {
        teacherId: userId,
        id: activityInfo.id,
        no: reviewAnsNo,
      })
      .then((res) => {
        if (res.status === "success") {
          setStudent(res.data.students);
        }
      })
      .catch((err) => console.log(err));
  }, [activityState, activityInfo.id, reviewAnsNo, token, userId]);

  // 切換預覽狀態時清空學生資料
  useEffect(() => {
    if (activityState !== ActivityState.Review) return;
    setReviewHandWritng([])
  },[activityState])
  // 切換上下頁時清空學生資料
  useEffect(() => {
    if (reviewAnsNo > activityInfo.quizNum) return;
    setReviewHandWritng([])
  },[activityInfo.quizNum, reviewAnsNo])
  
  const quizDuration = useMemo(() => {
    if (!activityInfo.createdAt) return;
    const quizCreateTime = new Date(activityInfo.createdAt);
    const duration = new Date(currentTime.getTime() - quizCreateTime.getTime());
    return `${duration.getMinutes()} 分 ${duration.getSeconds()} 秒`;
  }, [activityInfo.createdAt, currentTime]);

  const handedInStudents = useMemo(() => {
    return students.filter((student) => {
      return !!student.answers;
    });
  }, [students]);

  const groupedStudents = useMemo(() => {
    const groups = [];
    students.forEach((student) => {
      const groupNo = student.studentGroup - 1;
      if (!groups[groupNo]) {
        groups[groupNo] = [];
      }
      groups[groupNo].push(student);
    });
    return groups;
  }, [students]);

  const studentsWithReviewChoice = useMemo(() => {
    return students.filter(student => student.answer === reviewChoice)
  }, [reviewChoice, students]);

  const selectedPageLabels = useMemo(() => {
    const tempPageIndex = existedSelectedPageIndex.length > 0 ? existedSelectedPageIndex : selectedPageIndex;
    const pageIndex = tempPageIndex.map(page => [page * 2, page * 2 + 1]);
    const labels = pageIndex.map(pages => `第 ${pages.map(page => catalog.getLabel(page)).join('、')} 頁`);
    return labels.join(' & ');
  }, [selectedPageIndex, existedSelectedPageIndex])

  const QRCodeValue = useMemo(() => {
    const domain = window.location.href.includes('demo')? 'https://reader-demo.oneclass.com.tw' : process.env.REACT_APP_READER_DOMAIN;

    switch(extendedContentType) {
      case ExtendedContentType.AnswerInteractiveTool:
        return `${domain}/answerInteractiveTool/${activityInfo.QRCode}?user=${userId}`
      case ExtendedContentType.PaiNote:
        return `${domain}/bookForStudent/${bookId}?code=${activityInfo.QRCode}&user=${userId}`
      default:
        return
    }
  }, [activityInfo, extendedContentType, bookId, userId]);

  const handleWarnDialogClose = async () => {
    setOpenWarnDialog(false);
  };
  const handleCheckResultConfirm = useCallback(async () => {
    const res = await interactivityApi[
      extendedContentType
    ].update(token, {
      teacherId: userId,
      id: activityInfo.id,
      status: ActivityStatus.Done,
    });
    if (res.status === "success") {
      globalDispatch({
        type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
        payload: ActivityState.Review,
      });
      setOpenWarnDialog(false);
    }
  }, [activityInfo.id, extendedContentType, globalDispatch, token, userId]);

  const checkResultSubTitle = useMemo(() => {
    return (
      <div>
        有
        <span
          style={{
            color: "#D32613",
            display: "inline-block",
            margin: "0px 3px",
          }}
        >
          {students.length - handedInStudents.length}
        </span>
        {`位尚未交卷，確定要查看${ActivityType[extendedContentType]}結果嗎？`}
      </div>
    )
  }, [students, handedInStudents])

  const sideButtonAction = useCallback(
    async (text) => {
      let res;
      try {
        // eslint-disable-next-line default-case
        switch (text) {
          case "reset":
            if (
              extendedContentType === ExtendedContentType.AnswerInteractiveTool ||
              (extendedContentType === ExtendedContentType.PaiNote && activityState === ActivityState.Wait)
            ) {
              res = await interactivityApi[extendedContentType].update(token, {
                teacherId: userId,
                id: activityInfo.id,
                status: ActivityStatus.Done,
              });
              if (res.status === "success") {
                globalDispatch({
                  type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
                  payload: ActivityState.Create,
                });
                globalDispatch({
                  type: types.RESET_GROUP_INTERACTIVITY_INFO,
                });
              }
            }

            if (extendedContentType === ExtendedContentType.PaiNote ) {
              setNeedSelectPage(false);
            }
            
            break;
          case "checkResult":
            if (students.length > handedInStudents.length) {
              setWarnDialogContent({
                title: `查看${ActivityType[extendedContentType]}結果`,
                subTitle: checkResultSubTitle,
                handleCancel: handleWarnDialogClose,
                handleConfirm: handleCheckResultConfirm,
              });
              setOpenWarnDialog(true);
              return;
            } else {
              const res = await interactivityApi[extendedContentType].update(token, {
                teacherId: userId,
                id: activityInfo.id,
                status: ActivityStatus.Done,
              });
              if (res.status === "success") {
                globalDispatch({
                  type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
                  payload: ActivityState.Review,
                });
                setOpenWarnDialog(false);
              }
            };
            break;
          case "sendOut":
            if (selectedPageIndex.length === 0) {
              setWarnDialogContent({
                title: '無法派出筆記',
                subTitle: (<div style={{color: '#D32613'}}>請先勾選頁面</div>),
                handleCancel: null,
                handleConfirm: handleWarnDialogClose,
              });
              setOpenWarnDialog(true);
              return;
            }
            const pageIndex = selectedPageIndex.map(page => [page * 2, page * 2 + 1]).flat();
            globalDispatch({
              type: types.SET_GROUP_INTERACTIVITY_LOADING,
              payload: true
            })
            res = await interactivityApi[extendedContentType]
            .create(token, {
              preparationId: annotationId,
              teacherId: userId,
              bookId,
              pageIndex,
              QRCode: createActivityInfo.QRCode,
              status: ActivityStatus.Created,
              group: createActivityInfo.quizGroup
            }).finally(() => {
              globalDispatch({
                type: types.SET_GROUP_INTERACTIVITY_LOADING,
                payload: false
              })
            })
            if (res.status === "success") {
              globalDispatch({
                type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
                payload: ActivityState.Wait,
              });
              updateCreateActivityInfo("quizType", QuizType.HandWriting); // 派筆記當作手寫題
              updateCreateActivityInfo("quizNum", selectedPageIndex.length);
              setCreateActivityInfo((prev) => ({
                ...prev,
                id: res.data.id,
                createdAt: res.data.createdAt,
              }));
              globalDispatch({
                type: types.SET_GROUP_INTERACTIVITY_INFO,
                payload: {
                  activityType: extendedContentType,
                  QRCode: res.data.QRCode,
                  teacherId: res.data.teacherId,
                  id: res.data.id,
                  quizGroup: createActivityInfo.quizGroup,
                  quizType: QuizType.HandWriting, // 派筆記被視為手寫題
                  quizNum: selectedPageIndex.length,
                  // choiceNum: activityInfo.choiceNum,
                  status: res.data.status,
                  createdAt: res.data.createdAt
                },
              });
              const pages = Array.isArray(res.data.pageIndex) ? [...res.data.pageIndex] : [res.data.pageIndex];
              const newPages = recordPages[extendedContentType].concat(pages);
              readerDispatch({
                type: types.SET_RECORD_PAGES, 
                toolType: extendedContentType, 
                pages: uniq(newPages)
              });
              setNeedSelectPage(false);
            }
            break;
          case "previous":
            setReviewAnsNo((prev) => {
              if (prev === 1) return prev;
              return prev - 1;
            });
            break;

          case "next":
            setReviewAnsNo((prev) => {
              if (prev === activityInfo.quizNum) return prev; 
              return prev + 1;
            });
            break;
        }
      } catch (e) {
        console.log(`${text} error`, e);
      }
    },
    [extendedContentType, activityState, students.length, handedInStudents.length, selectedPageIndex, globalDispatch, token, annotationId, userId, bookId, createActivityInfo.QRCode, createActivityInfo.quizGroup, createActivityInfo.id, createActivityInfo.quizNum, recordPages, readerDispatch]
  );

  const updateCreateActivityInfo = (type, value) => {
    if (value < 1) return;
    if (type === "choiceNum") {
      if (value < 2 || value > 5) return;
    }
    if (type === "quizGroup") {
      if (value > 6) return;
    }
    if (type === "quizNum") {
      if (value > 5) return;
    }

    setCreateActivityInfo((prev) => ({
      ...prev,
      ...{ [type]: value },
    }));
  };

  const createActivity = useCallback(() => {
    const { QRCode, quizGroup, quizType, quizNum, choiceNum, status } =
    createActivityInfo;
    globalDispatch({
      type: types.SET_GROUP_INTERACTIVITY_LOADING,
      payload: true
    })
    interactivityApi[extendedContentType]
      .create(token, {
        QRCode,
        quizGroup,
        quizType,
        quizNum,
        choiceNum,
        status,
        ...(createActivityInfo.quizType === QuizType.TrueFalse && {
          choiceNum: 2,
        }),
        ...(createActivityInfo.quizType === QuizType.HandWriting && {
          choiceNum: 0,
        }),
        bookId,
        annotationId,
        pageIndex: pageIndex * (isDoublePageMode ? 2 : 1),
      })
      .then((res) => {
        if (res.status === "success") {
          setCreateActivityInfo((prev) => ({
            ...prev,
            id: res.data.id,
            createdAt: res.data.createdAt,
          }));
          globalDispatch({
            type: types.SET_GROUP_INTERACTIVITY_INFO,
            payload: {
              activityType: extendedContentType,
              QRCode: res.data.QRCode,
              teacherId: res.data.teacherId,
              id: res.data.id,
              quizGroup: createActivityInfo.quizGroup,
              quizType: createActivityInfo.quizType,
              quizNum: createActivityInfo.quizNum,
              choiceNum: createActivityInfo.choiceNum,
              status: res.data.status,
              createdAt: res.data.createdAt,
            },
          });
          globalDispatch({
            type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
            payload: ActivityState.Wait,
          });
          const pages = Array.isArray(res.data.pageIndex) ? [...res.data.pageIndex] : [res.data.pageIndex];
          const newPages = recordPages[extendedContentType].concat(pages);
          readerDispatch({
            type: types.SET_RECORD_PAGES, 
            toolType: extendedContentType, 
            pages: uniq(newPages)
          });
        }
      })
      .catch((err) => console.log("create quiz error", err))
      .finally(() => {
        globalDispatch({
          type: types.SET_GROUP_INTERACTIVITY_LOADING,
          payload: false
        })
      })
  }, [annotationId, bookId, createActivityInfo, extendedContentType, globalDispatch, isDoublePageMode, pageIndex, readerDispatch, recordPages, token]);

  const studentUnits = (group) => {
    const member = 8;
    const units = [];
    for (let i = 0; i < group.length; i += member) {
      const unit = group.slice(i, i + member);
      units.push(unit);
    }
    return units;
  };

  const displayAnswer = (answer) => {
    // eslint-disable-next-line default-case
    switch (activityInfo.quizType) {
      case QuizType.MultipleChoice:
        return answer ? answer : "未交";
      case QuizType.TrueFalse:
        return answer ? trueFalseMap[answer] : "未交";
      case QuizType.HandWriting:
        return answer ? "交卷" : "未交";
    }
  };

  const isCurrentPageHaveRecords = recordPages[extendedContentType].includes(pageIndex * (isDoublePageMode ? 2 : 1));

  const copyLinkToClipboard = async(value, popOverAnchor) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopyPopoverOpen(popOverAnchor);
      setTimeout(() => {
        setCopyPopoverOpen(null);
      }, 500)
    } catch (error) {
      console.error('copy group activity link error', error);
    }
  }

  return (
    <>
      <div className={styles.bg}>
        {sideButton[activityState].map((button, index) => {
          const props = {
            ...button,
            text: sideButtonText[button.text][extendedContentType],
          };
          return (
            <SideQuizButton
              key={button.text + index}
              left={true}
              hide={activityState === ActivityState.Create && !needSelectPage}
              {...props}
              handleClick={() => sideButtonAction(button.text)}
            />
          );
        })}
        {sideButton[activityState].map((button, index) => {
          const props = {
            ...button,
            text: sideButtonText[button.text][extendedContentType],
          };
          return (
            <SideQuizButton
              key={button.text + index}
              left={false}
              hide={activityState === ActivityState.Create && !needSelectPage}
              {...props}
              handleClick={() => sideButtonAction(button.text)}
            />
          );
        })}
        {activityState === ActivityState.Create && !needSelectPage && (
          <div className={styles.createCard}>
            <div className={styles.cardHead}>
              {ActivityType[extendedContentType]}
              {window.electron && ActivityType[extendedContentType] === ActivityType.PaiNote && <span style={{fontSize: '.6em', display: 'block'}}>＊請老師使用派筆記前，必先至書櫃，點擊班級紀錄後按下「紀錄同步」。</span>}
            </div>
            <div className={styles.cardContent}>
              {createActivityOptionList.map((option) => {
                if (!option.type.includes(extendedContentType)) {
                  return null;
                }
                return (
                  <>
                    <div
                      className={classNames(styles.inputGroup, {
                        [styles.hide]:
                          createActivityInfo.quizType !==
                            QuizType.MultipleChoice &&
                          option.id === "choiceNum",
                      })}
                    >
                      <div className={styles.text}>
                        <span>{option.text}</span>
                        <span>{option.subText}</span>
                      </div>
                      <div className={styles.input}>
                        {option.input({
                          clickHandler: updateCreateActivityInfo,
                          state: createActivityInfo[option.id],
                          id: option.id,
                          style:
                            option.id === "quizType"
                              ? selectDropdownStyles
                              : null,
                        })}
                      </div>
                    </div>
                    {option.divider && (
                      <span className={styles.dashedDivider} />
                    )}
                  </>
                );
              })}
              <div className={styles.actionBtn}>
                <RectQuizButton
                  width="85%"
                  text="確定"
                  color="#212B36"
                  borderColor="#FFA235"
                  background="#FFE1AD"
                  handleClick={() => {
                    if (
                      extendedContentType ===
                      ExtendedContentType.AnswerInteractiveTool
                    ) {
                      createActivity();
                    } else if (
                      extendedContentType === ExtendedContentType.PaiNote
                    ) {
                      setNeedSelectPage(true);
                    }
                  }}
                />
                <div
                  className={classNames(styles.historyBtn, {
                    [styles.isDisabled]: !isCurrentPageHaveRecords,
                  })}
                  rel="noopener noreferrer"
                  onClick={()=>{
                    const path = `${window.location.href.includes("demo")
                        ? "https://reader-demo.oneclass.com.tw"
                        : process.env.REACT_APP_READER_DOMAIN
                      }/groupInteractiveToolHistory?bookId=${bookId}&bookName=${bookName}&annotationId=${annotationId}&userId=${userId}&pageIndex=${pageIndex * (isDoublePageMode ? 2 : 1)
                      }&extendedContentType=${extendedContentType}`
                    if (window.electron) {
                      window.electron && window.electron.ipcRenderer.send("openExternal", path);
                    }else{
                      window.open(path)
                    }
                  }}
                >
                  查看紀錄
                </div>
              </div>
                {window.electron && <div style={{fontSize: '18px',color: '#d33e3e'}}>要先至網頁版登入後，才能使用「查看紀錄」</div>}
            </div>
          </div>
        )}
        {activityState === ActivityState.Create && needSelectPage && (
          <SelectedPages
            selectedPageIndex={selectedPageIndex}
            setSelectedPageIndex={setSelectedPageIndex}
          />
        )}
        {activityState === ActivityState.Wait && (
          <div className={styles.waitSection}>
            <div className={styles.qrCode}>
              <span className={styles.instruction}>
                {`掃描此 QR Code 加入${ActivityType[extendedContentType]}`}
              </span>
              <QRCodeSVG value={QRCodeValue} size="80%" />
              <div className={styles.copyLinkArea}>
                <span className={styles.link}>{QRCodeValue}</span>
                <Button
                  classes={copyButtonStyles}
                  startIcon={copyIcon}
                  onClick={(e) => copyLinkToClipboard(QRCodeValue, e.target)}
                >
                  複製連結
                </Button>
                <Popover
                  classes={copyPopOverStyles}
                  open={copyPopoverOpen}
                  anchorEl={copyPopoverOpen}
                  // onClose={() => setCopyPopoverOpen(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  已複製
                </Popover>
              </div>
              {extendedContentType ===
                ExtendedContentType.AnswerInteractiveTool && (
                <span
                  className={styles.discription}
                >{`${activityInfo.quizType} ${activityInfo.quizNum} 題`}</span>
              )}
              {extendedContentType === ExtendedContentType.PaiNote && (
                <span className={styles.discription}>{selectedPageLabels}</span>
              )}
            </div>
            <div className={styles.list}>
              <div className={styles.individual}>
                <div className={styles.individualHead}>學生作答情況</div>
                <div className={styles.individualContent}>
                  {students.map((student) => (
                    <div className={styles.listItem} key={student.name}>
                      <span className={styles.name}>
                        第{("0" + student.studentGroup).slice(-2)}組｜
                        {("0" + student.studentNum).slice(-2)}
                        {student.studentName}
                      </span>
                      <span
                        className={`${styles.status} ${
                          !student.answers && styles.warn
                        }`}
                      >
                        {student.answers ? "已交卷" : "未交卷"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.total}>
                <div>
                  <Icon type="text" name="user" className={styles.icon} />
                  已加入：{students.length} 人｜已交卷:
                  {handedInStudents.length} 人
                </div>
                <div>
                  <Icon type="text" name="stopWatch" className={styles.icon} />
                  已作答： {quizDuration}
                </div>
              </div>
            </div>
          </div>
        )}
        {activityState === ActivityState.Review &&
          groupedStudents.length > 0 && (
            <div className={styles.resultCard}>
              <ResultHead
                extendedContentType={extendedContentType}
                quizInfo={activityInfo}
                reviewAnsNo={reviewAnsNo}
              />
              <div className={styles.resultContent}>
                {groupedStudents.map((group, index) => (
                  <div
                    className={styles.group}
                    key={index}
                    style={{
                      minWidth: `calc(180px * ${studentUnits(group).length})`,
                    }}
                  >
                    <div className={styles.groupHead}>第 {index + 1} 組</div>
                    <div className={styles.students}>
                      {studentUnits(group).map((unit, index) => (
                        <div className={styles.unit} key={index}>
                          {unit.map((student) => (
                            <div
                              className={`${styles.name} ${
                                (student.answer === reviewChoice ||
                                  reviewHandWriting.includes(student)) &&
                                styles.selected
                              }`}
                              key={Math.random()}
                            >
                              {activityInfo.quizType !==
                                QuizType.HandWriting && (
                                <span className={styles.text}>
                                  {`${("0" + student.studentNum).slice(-2)} ${
                                    student.studentName
                                  }`}
                                </span>
                              )}
                              {activityInfo.quizType ===
                                QuizType.HandWriting && (
                                <FormControlLabel
                                  classes={checkboxStyles}
                                  control={
                                    <Checkbox
                                      name={`${student.studentNum} ${student.studentName}`}
                                      checked={reviewHandWriting.includes(
                                        student
                                      )}
                                      disabled={!student.answer}
                                      value={student.answer}
                                      onClick={(e) => {
                                        if (reviewHandWriting.length === 6)
                                          return;
                                        const reviewedStudent = students.find(
                                          (student) =>
                                            student.answer === e.target.value
                                        );
                                        const temp = xor(reviewHandWriting, [
                                          reviewedStudent,
                                        ]);
                                        setReviewHandWritng(temp);
                                      }}
                                    />
                                  }
                                  label={`${("0" + student.studentNum).slice(
                                    -2
                                  )} ${student.studentName}`}
                                />
                              )}
                              <span
                                className={`${styles.status} ${
                                  !student.answer && styles.warn
                                }`}
                              >
                                {displayAnswer(student.answer)}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {activityInfo.quizType !== QuizType.HandWriting && (
                <>
                  {reviewChoice ? (
                    <div className={styles.description}>
                      有 <span>{studentsWithReviewChoice.length}位</span> 選擇
                      {displayAnswer(reviewChoice)}
                    </div>
                  ) : (
                    <div className={styles.description}>
                      按下「選項」檢討學生答案
                    </div>
                  )}
                  <div className={styles.actionBtn}>
                    {ChoiceButtons[activityInfo.quizType]
                      .slice(0, activityInfo.choiceNum)
                      .map((option) => (
                        <ChoiceQuizButton
                          key={option}
                          text={option}
                          active={option === reviewChoice}
                          handleClick={() => setReviewChoice(option)}
                        />
                      ))}
                  </div>
                </>
              )}
              {activityInfo.quizType === QuizType.HandWriting && (
                <>
                  <div className={styles.description}>最多可以選擇 6 位</div>
                  <div className={styles.actionBtn}>
                    <RectQuizButton
                      width="20%"
                      text="開始檢視"
                      color="#7A310A"
                      borderColor="#B7601A"
                      background="#FFA235"
                      handleClick={() => {
                        if (reviewHandWriting.length === 0) return;
                        document.documentElement.style.setProperty(
                          "--picWidth",
                          reviewHandwritingWidthMap[reviewHandWriting.length]
                        );
                        document.documentElement.style.setProperty("--picHeight", reviewHandwritingHeightMap[reviewHandWriting.length]);
                        globalDispatch({
                          type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
                          payload: ActivityState.PreviewHandWrite,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        {activityState === ActivityState.PreviewHandWrite && (
          <div className={styles.previewHandWrite}>
            <ResultHead
              extendedContentType={extendedContentType}
              quizInfo={activityInfo}
              reviewAnsNo={reviewAnsNo}
            />
            <div className={styles.picContainer}>
              {reviewHandWriting.map((student) => (
                <div className={styles.image}>
                  <img src={student.answer} alt="手寫題預覽" />
                  <span className={styles.name}>
                    第 {student.studentGroup} 組｜
                    {("0" + student.studentNum).slice(-2)} {student.studentName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <WarnDialog open={openWarnDialog} {...warnDialogContent} />
    </>
  );
};

export default GroupInteractiveTool;
