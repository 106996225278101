import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ActivityEvent } from 'events/EventTypes';
import { useActivityEventHandler } from 'events/eventHandlers/ActivityEventHandler';
import { useMonitorActivityInfo, useMonitorActivityAttendeeInfo, useMonitorActivityAnnotation } from 'customHooks/activity';

const ActivityWork = () => {
    const [{ annotationId: activityId, activityInfo: { attendeeNumber, activityTarget } }] = useStore(StoreTypes.annotation);
    useActivityEventHandler();
    const { monitorActivityInfo, unsubscribeMonitorActivityInfo } = useMonitorActivityInfo();
    const { monitorActivityAttendeeInfo, unsubscribeMonitorActivityAttendeeInfo } = useMonitorActivityAttendeeInfo();
    const { monitorActivityAnnotation, unsubscribeMonitorActivityAnnotation } = useMonitorActivityAnnotation();

    useEffect(() => {
        window.onbeforeunload = function() {
            EventBus.emit({
                event: ActivityEvent.LeaveActivityEvent,
                payload: { attendeeNumber }
            });
            return;
        };
        monitorActivityInfo({ activityId });
        return () => {
            unsubscribeMonitorActivityInfo();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        monitorActivityAttendeeInfo({ activityId, activityTarget });
        monitorActivityAnnotation({ activityId, activityTarget });
        return () => {
            unsubscribeMonitorActivityAttendeeInfo();
            unsubscribeMonitorActivityAnnotation();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId, activityTarget]);

    return (
        <div id="ActivityWork" />
    );
};

export default ActivityWork;
