import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import { ExtensionType } from 'constants/wordGrid';
import * as types from 'constants/actionTypes';

export const initState = {
  isShowRadicalContent: false,
  isShowRadicalProgressModal:false,
  radicalData: null,
  isShowSubContent: false,
  word: '',
  tabIndex: [0, 0],
  relatedWords: [],
  wordsData: [],
  extensionType: ExtensionType.MeaningSet,
  extensionData: {
    meaningSet: [],
    wordsSet: [],
    idiomSet: [],
    glyphSet: []
  }
};


const wordGridReducer = (state, action) => {
  switch (action.type) {
    case types.SET_RADICAL_PROGRESS_MODAL_SHOW:
      const { isShowRadicalProgressModal } = action.payload;
      return {
        ...state,
        isShowRadicalProgressModal
      }
    case types.SET_RADICAL_CONTENT_SHOW:
      const { isShowRadicalContent, radicalData } = action.payload;
      return {
        ...state,
        isShowRadicalContent,
        radicalData
      }
    case types.SET_SUBCONTENT_SHOW:
      const { isShowSubContent, extensionType } = action.payload;
      return {
        ...state,
        isShowSubContent,
        extensionType
      }
    case types.SET_WORDS_VALUE:
      return {
        ...state,
        word: action.payload.word || ''
      }
    case types.SET_RELATED_WORDS:
      const { relatedWords } = action.payload;
      return {
        ...state,
        relatedWords
      }
    case types.SET_EXTENSION_DATA:
      const { extensionData } = action.payload;
      return {
        ...state,
        extensionData
      }
    case types.SET_CHANGE_TAB_INDEX:
      const { tabIndex } = action.payload;
      return {
        ...state,
        tabIndex,
      }
    case types.SET_WORDS_DATA:
      const { data } = action.payload;
      return {
        ...state,
        wordsData: data,
      }
    default:
      return state;
  }
};

const useWordGridReducer = () => useEnhanceReducer(wordGridReducer, initState);

export default useWordGridReducer;
