import React from 'react';
import { ExtendedContentContainer, ExtendedContentType } from 'components/ExtendedContent/ExtendedContentContainer';
import AboutTheAuthor from 'components/AboutTheAuthor';

export const AboutTheAuthorModalComponent = ({ aboutTheAuthorInfo, extendedResourceStyle }) => {
  return (
    <ExtendedContentContainer extendedResourceStyle={extendedResourceStyle} extendedContentType={ExtendedContentType.AboutTheAuthor}>
      <AboutTheAuthor data={aboutTheAuthorInfo} style={extendedResourceStyle} />
    </ExtendedContentContainer>
  )
};
