import path from 'path';
import { BookFlipType } from 'constants/flipTypes';

export const replaceBookPage = ({ page, pageUrl, str }) => {
  str = str.replace('position: relative;', '');
  str = str.replace('style="overflow', 'class="page" style="overflow');
  str = str.replace('width: 831px; height: 1156px;', '');
  str = str.replace('data="', 'data=' + pageUrl);
  str = str.replace(
    '<svg',
    '<svg id="' + page.bookId + '-' + page.pageIndex + '"'
  );
  str = str.replace('<html lang="en">', '<html lang="zh-Hant-TW">');
  str = str.replace(/fonts\//g, pageUrl + 'fonts/');
  str = str.replace(
    'image { pointer-events: none; }',
    'image { pointer-events: auto; }'
  );
  str = str.replace('src="', 'src="' + pageUrl);
  const xlink =
    'xlink:href="' + path.basename(page.html, path.extname(page.html));
  const reg = new RegExp(xlink, 'g');
  str = str.replace(
    reg,
    'xlink:href="' + pageUrl + path.basename(page.html, path.extname(page.html))
  );
  return {
    ...page,
    svg: str
  };
};

export const groupPages = (pages, isDoublePageMode) => {
  if (isDoublePageMode) {
    let groupedPages = {};
    for (let index in pages) {
      const newIndex = Math.floor(index / 2);
      if (Array.isArray(groupedPages[newIndex])) {
        groupedPages[newIndex].push(pages[index]);
      } else {
        groupedPages[newIndex] = [pages[index]];
      }
    }
    return groupedPages;
  } else {
    let groupedPages = {};
    for (let index in pages) {
      groupedPages[index] = [pages[index]];
    }
    return groupedPages;
  }
};

export const preserveSVGAspectRatio = ({
  svg,
  width,
  height,
  LRFlip,
  pageInfos,
  pageIndex,
  isDoublePageMode
}) => {
  svg = svg.replace(`width="${width}"`, 'width="100%"');
  svg = svg.replace(`height="${height}"`, 'height="100%"');
  if (isDoublePageMode) {
    const isEven = pageIndex % 2 === 0;
    if (
      pageInfos &&
      pageIndex === pageInfos.length - 1 &&
      pageInfos.length % 2 === 1
    ) {
      svg = svg.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
    } else {
      if (
        (isEven && LRFlip === BookFlipType.LEFT_TO_RIGHT) ||
        (!isEven && LRFlip === BookFlipType.RIGHT_TO_LEFT)
      ) {
        svg = svg.replace('<svg', '<svg preserveAspectRatio="xMaxYMid"');
      } else {
        svg = svg.replace('<svg', '<svg preserveAspectRatio="xMinYMid"');
      }
    }
  } else {
    svg = svg.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
  }
  return svg;
};
