import React,{useCallback} from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';


const OpacityTools = ({ target: object }) => {
    const setOpacityHandler = useCallback(()=> e => {
        if (object) {
            EventBus.emit({
                event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
                payload: { object, property:'opacity', value:e.target.value }
            });
        }
    }, [object]);
    return (
        <div className={styles.opacityTools}>
            <h3>不透明度</h3>
            <input type="number" step="0.1" max="1" min="0" value={object.opacity} onChange={setOpacityHandler()}/>
        </div>
    )
}

export default OpacityTools;