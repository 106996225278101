export const CanvasGroupAlignType = {
    HorizontalLeft: 'HorizontalLeft',
    HorizontalCenter: 'HorizontalCenter',
    HorizontalRight: 'HorizontalRight',
    VerticalTop: 'VerticalTop',
    VerticalCenter: 'VerticalCenter',
    VerticalBottom: 'VerticalBottom',
};
export const CanvasGroupSplitGapType = {
    HorizontallySplitGap: 'HorizontallySplitGap',
    VerticallySplitGap: 'VerticallySplitGap',
};
