import { useEnhanceReducer } from "customHooks/enhanceReducer";
import * as types from "../constants/actionTypes";

const initState = {
  tldrawEditor: null,
  extendedContentTldrawEditor: null,
};

const tldrawReducer = (state, action) => {
  switch (action.type) {
    case types.SET_TLDRAW_EDITOR:
      return {
        ...state,
        tldrawEditor: action.payload,
      };
		case types.SET_EXTENDED_CONTENT_TLDRAW_EDITOR:
			return {
				...state,
				extendedContentTldrawEditor: action.payload,
			}
    default:
      return state;
  }
};

const useTldrawReducer = () => useEnhanceReducer(tldrawReducer, initState);

export default useTldrawReducer;
