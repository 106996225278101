import React from "react";
import { StoreTypes, useStore } from "context";
import Button from "components/Button/index";
import Icon from "components/Icon";
import styles from "./index.module.scss";
import useModal from "components/common/Modal/useModal";
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import * as types from 'constants/actionTypes';
import { ActivityState, ActivityStatus } from "constants/groupInteractivity";
import { interactivityApi } from "../api";

export const GroupInteractiveExtendedContentTool = ({ extendedContentType }) => {
  const [, { closeModal, toggleHideModal }] = useModal();
  const [{ token }] = useStore(StoreTypes.user);
  const [{groupInteractivity: { activityState, activityInfo }}, globalDispatch] = useStore(StoreTypes.global)

  const setReaderToolType = (type) => {
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        readerToolType: type,
      },
    });
  };

  const onCloseHandler = () => {
    if (activityState === ActivityState.Create) {
      closeModal();
      return;
    }

    interactivityApi[extendedContentType].update(token, {
      teacherId: activityInfo.teacherId,
      id: activityInfo.id,
      status: ActivityStatus.Done
    }).then(res => {
      if (res.status === "success") {
        globalDispatch({
          type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
          payload: ActivityState.Create
        });
        globalDispatch({
          type: types.RESET_GROUP_INTERACTIVITY_INFO
        });
        closeModal();
        setReaderToolType(ReaderToolType.Drag);
      }
    }).catch(err => console.log('end GroupInteractiveTool error', err))
  };

  const cancelPreview = () => {
    globalDispatch({
      type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
      payload: ActivityState.Review,
    });
  }

  const reCreateActivity = () => {
    interactivityApi[extendedContentType]
      .update(token, {
        teacherId: activityInfo.teacherId,
        id: activityInfo.id,
        status: ActivityStatus.Done,
      })
      .then((res) => {
        if (res.status === "success") {
          globalDispatch({
            type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
            payload: ActivityState.Create,
          });
        }
      })
      .catch((err) => console.log("end quiz error", err))
  }

  return (
    <div className={styles.extended_content_tools}>
      <div className={styles.toolsWrapper}>
        <div className={styles.leftbox}>
          <div className={styles.toolBtn}>
            {activityState === ActivityState.PreviewHandWrite ? (
              <Button
                title="取消預覽"
                onClick={cancelPreview}
              >
                <Icon name="CancelPreviewHandWrite" />
                取消預覽
              </Button>
            ) : (
              <Button title="關閉" onClick={onCloseHandler}>
                <Icon name="Close" />
                關閉
              </Button>
            )}
          </div>
          {(activityState === ActivityState.Wait || activityState === ActivityState.Review) && (
            <div className={styles.toolBtn}>
              <Button
                title="縮小視窗"
                onClick={() => {
                  toggleHideModal(true);
                }}
              >
                <Icon name="MinimizeGroupInteractiveTool" />
                縮小視窗
              </Button>
            </div>
          )}
          {activityState === ActivityState.Review && (
            <div className={styles.toolBtn}>
              <Button
                title="再出題"
                onClick={reCreateActivity}
              >
                <Icon name="CreateNewGroupInteractiveTool" />
                再出題
              </Button>
            </div>
          )}
        </div>
        <div className={styles.rightbox}>
          {activityState === ActivityState.Review && (
            <div className={styles.toolBtn}>
              <Button
                title="再出題"
                onClick={reCreateActivity}
              >
                <Icon name="CreateNewGroupInteractiveTool" />
                再出題
              </Button>
            </div>
          )}
          {(activityState === ActivityState.Wait || activityState === ActivityState.Review) && (
            <div className={styles.toolBtn}>
              <Button
                title="縮小視窗"
                onClick={() => {
                  toggleHideModal(true);
                }}
              >
                <Icon name="MinimizeGroupInteractiveTool" />
                縮小視窗
              </Button>
            </div>
          )}
          <div className={styles.toolBtn}>
          {activityState === ActivityState.PreviewHandWrite ? (
              <Button
                title="取消預覽"
                onClick={cancelPreview}
              >
                <Icon name="CancelPreviewHandWrite" />
                取消預覽
              </Button>
            ) : (
              <Button title="關閉" onClick={onCloseHandler}>
                <Icon name="Close" />
                關閉
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
