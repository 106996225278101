import React, { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import BasicReaderToolComponent from './ReaderToolComponents/BasicReaderToolComponent';
import ActivatableReaderToolComponent from './ReaderToolComponents/ActivatableReaderToolComponent';
import ReaderToolPageSearcher from './ReaderToolComponents/ReaderToolPageSearcher';
import RelativePositionComponent from './ReaderToolComponents/RelativePositionComponent';
import ReaderToolBookshelf from './ReaderToolComponents/ReaderToolBookshelf';
import ReaderToolFlipPageComponent from './ReaderToolComponents/ReaderToolFlipPageComponent';
import ReaderToolFourZoomIn from './ReaderToolComponents/ReaderToolFourZoomIn';
import InteractiveObjectContentCommandComponent from './ReaderToolComponents/InteractiveObjectContentCommandComponent';
import ReaderToolPageModeComponent from './ReaderToolComponents/ReaderToolPageModeComponent';
import ReaderToolInsertComponent from './ReaderToolComponents/ReaderToolInsertComponent';
import { ReaderToolActivityAttendeeNumber } from './ReaderToolComponents/ReaderToolActivityAttendeeNumber';
import { useStore, StoreTypes } from 'context';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { Roles } from 'constants/role';
import ReaderToolPageSearcherForStudent from 'components/BookForStudent/ReaderToolPageSearcherForStudent';

export const useReaderToolsFactory = () => {
    const reducers = useStore();
    const [{ isDoublePageMode, readerToolType }] = reducers[StoreTypes.reader];
    const [{ activityInfo: { attendeeNumber } }] = reducers[StoreTypes.annotation];
    const [{ role }] = reducers[StoreTypes.user];


    const InteractiveObjectContentCommandFactory = useInteractiveObjectContentCommandFactory();

    const getInteractiveObjectContentCommand = useCallback((type) => {
        switch (type) {
            case ReaderToolType.Whiteboard:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.Whiteboard, canvasSVGObject: null });
            case ReaderToolType.NumberPicker:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.NumberPicker });
            case ReaderToolType.Timer:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.Timer });
            case ReaderToolType.Stopwatch:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.Stopwatch });
            case ReaderToolType.AnswerInteractiveTool:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.AnswerInteractiveTool });
            case ReaderToolType.PaiNote:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.PaiNote });
            default:
                return null;
        }
    }, [InteractiveObjectContentCommandFactory]);

    const createReaderTool = (type, { additionalClickHandler, clickHandlerParameters } = {},nowPanelState) => {
        switch (type) {
            case ReaderToolType.SynchronousCamera:
            case ReaderToolType.StampCollection:
            case ReaderToolType.Toolbox:
            case ReaderToolType.BackToCatalog:
            case ReaderToolType.Menu:
            case ReaderToolType.DeleteAll:
            case ReaderToolType.AllScreen:
            case ReaderToolType.IntervalZoomIn:
            case ReaderToolType.FullScreen:
            case ReaderToolType.SVG:
            case ReaderToolType.ReaderOption:
            case ReaderToolType.Bookmark:
            case ReaderToolType.Activity:
            case ReaderToolType.ExportInteractiveObject:
            case ReaderToolType.StickyNote:
            case ReaderToolType.Link:
            case ReaderToolType.MathTools:
            case ReaderToolType.Protractor:
            case ReaderToolType.ShortRuler:
            case ReaderToolType.LongRuler:
            case ReaderToolType.IsoscelesTriangle:
            case ReaderToolType.RightTriangle:
            case ReaderToolType.Undo:
            case ReaderToolType.Redo:
            case ReaderToolType.PaiNoteRecord:
                return <BasicReaderToolComponent type={type} clickHandlerParameters={clickHandlerParameters} />;
            case ReaderToolType.Mark:
            case ReaderToolType.Drag:
            case ReaderToolType.Select:
            case ReaderToolType.Delete:
            // case ReaderToolType.SyncAnnotation:
                return <ActivatableReaderToolComponent type={type} currentReaderToolType={readerToolType} nowPanelState={nowPanelState}/>;
            case ReaderToolType.PageSearcher:
                if (role === Roles.INTERACTIVITY_STUDENT) return <ReaderToolPageSearcherForStudent type={type} clickHandlerParameters={clickHandlerParameters}/>
                return <ReaderToolPageSearcher type={type} clickHandlerParameters={clickHandlerParameters} />
            case ReaderToolType.Painting:
            case ReaderToolType.Highlighter:
            case ReaderToolType.Shape:
            case ReaderToolType.Line:
            case ReaderToolType.Expression:
            case ReaderToolType.OfflineReaderToolBox:
            case ReaderToolType.MeasureTools:
            case ReaderToolType.SubmitNote:
                return <RelativePositionComponent type={type} currentReaderToolType={readerToolType} additionalClickHandler={additionalClickHandler} />;
            case ReaderToolType.Bookshelf:
                return <ReaderToolBookshelf type={type} />;
            case ReaderToolType.FourZoomIn:
                return <ReaderToolFourZoomIn type={type} />;
            case ReaderToolType.NumberPicker:
            case ReaderToolType.Whiteboard:
            case ReaderToolType.Timer:
            case ReaderToolType.Stopwatch:
            case ReaderToolType.AnswerInteractiveTool:
            case ReaderToolType.PaiNote:
                return <InteractiveObjectContentCommandComponent type={type} command={getInteractiveObjectContentCommand(type)} />;
            case ReaderToolType.PageMode:
                return <ReaderToolPageModeComponent type={type} isDoublePageMode={isDoublePageMode} />;
            case ReaderToolType.Img:
            case ReaderToolType.Text:
                return <ReaderToolInsertComponent type={type} currentReaderToolType={readerToolType} />;
            case ReaderToolType.AttendeeNumber: //沒有使用的功能2022.12.12
                return <ReaderToolActivityAttendeeNumber type={type} attendeeNumber={attendeeNumber} />
            case ReaderToolType.ArrowLeft: //沒有使用的功能2022.12.12
            case ReaderToolType.ArrowRight: //沒有使用的功能2022.12.12
                return <ReaderToolFlipPageComponent type={type} clickHandlerParameters={clickHandlerParameters} />
            default:
            // console.log(`unsupported type ${type}`);
        }
    };

    const batchCreateReaderTools = (types = [], payloads = {},nowPanelState) => {
        let results = {};
        for (let type of types) {
            results[type] = createReaderTool(type, payloads[type],nowPanelState);
        }
        return results;
    };

    return { createReaderTool, batchCreateReaderTools };
};
