import React, { useMemo } from "react";
import { Button, makeStyles } from "@material-ui/core";
import Icon from "components/Icon";

export const trueCircle = <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="18" cy="18" r="14.5" stroke="currentColor" stroke-width="6"/>
</svg>;

export const falseCross = <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.04784 4.00001L30.9994 32" stroke="currentColor" stroke-width="6" stroke-linecap="round"/>
<path d="M30.9521 3.99999L3.00056 32" stroke="currentColor" stroke-width="6" stroke-linecap="round"/>
</svg>

export const pencilForToolbar = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.2985 7.86836L10.1313 3.70098L1.0853 12.7467L0.671903 16.4649C0.63386 16.8068 0.820579 17.1125 1.10261 17.2522C1.15534 17.3027 1.22131 17.3327 1.29289 17.3327L16.1873 17.3327C16.3602 17.3327 16.5003 17.1578 16.5003 16.9421V15.64C16.5003 15.4243 16.3602 15.2494 16.1873 15.2494L6.91783 15.2494L14.2985 7.86836Z" fill="currentColor" />
<path d="M14.9193 1.12379L16.8759 3.08052C17.4862 3.69089 17.4862 4.68049 16.8759 5.29117L15.0351 7.13202L10.8679 2.96464L12.7087 1.12379C13.319 0.513425 14.3089 0.513425 14.9193 1.12379Z" fill="currentColor" />
</svg>

export const textForToolbar = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.95814 18.3327V17.2895C7.67074 17.2895 8.52704 16.594 8.52704 15.203V3.976C8.52704 3.47923 8.18946 3.23084 7.5143 3.23084C7.00381 3.23084 6.5592 3.32192 6.18045 3.50407C5.81817 3.66966 5.49705 3.92632 5.21711 4.27406C4.95363 4.6218 4.72309 5.06062 4.52548 5.59051C4.34434 6.10384 4.17967 6.70824 4.03146 7.40372L2.5 7.18017L3.19163 1.66602C3.61978 1.73225 4.0891 1.78193 4.59958 1.81505C5.11007 1.84816 5.51352 1.86472 5.80993 1.86472H15.5668C15.9291 1.86472 16.3737 1.84816 16.9007 1.81505C17.4276 1.78193 17.9052 1.73225 18.3333 1.66602V7.10566L16.9007 7.22985C16.8183 5.92169 16.5384 4.9447 16.0608 4.2989C15.5998 3.6531 14.7929 3.3302 13.6401 3.3302C12.932 3.3302 12.578 3.54547 12.578 3.976V15.203C12.578 16.594 13.4508 17.2895 15.1963 17.2895V18.3327H5.95814Z" fill="currentColor"/>
</svg>

export const copyIcon = <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.75 17.5V19.0625C12.75 19.5803 12.3303 20 11.8125 20H1.1875C0.669727 20 0.25 19.5803 0.25 19.0625V4.6875C0.25 4.16973 0.669727 3.75 1.1875 3.75H4V15.3125C4 16.5187 4.98129 17.5 6.1875 17.5H12.75ZM12.75 4.0625V0H6.1875C5.66973 0 5.25 0.419727 5.25 0.9375V15.3125C5.25 15.8303 5.66973 16.25 6.1875 16.25H16.8125C17.3303 16.25 17.75 15.8303 17.75 15.3125V5H13.6875C13.1719 5 12.75 4.57812 12.75 4.0625ZM17.4754 2.85043L14.8996 0.27457C14.7238 0.0987666 14.4853 1.29998e-06 14.2367 0L14 0V3.75H17.75V3.51332C17.75 3.26469 17.6512 3.02624 17.4754 2.85043Z" fill="currentColor"/>
</svg>

const countButtonStyles = makeStyles({
  root: {
    minWidth: "1.5em",
    width: "1.5em",
    height: "1.5em",
    borderRadius: "50%",
    borderBottom: "4px solid #18365E",
    background: "#88BCD9",
    fontSize: "1em",
    "&:hover": {
      background: "#315d83",
    },
  },
});

export const CountButton = ({ plus, handleClick }) => {
  return (
    <Button classes={countButtonStyles()} onClick={handleClick}>
      {plus ? (
        <Icon type="text" name="plus" />
      ) : (
        <Icon type="text" name="minus" />
      )}
    </Button>
  );
};

export const RectQuizButton = ({
  text,
  width,
  borderColor,
  color,
  background,
  handleClick,
}) => {
  const rectButtonStyles = useMemo(() => {
    return makeStyles({
      root: {
        minWidth: width,
        width,
        height: "2em",
        borderRadius: ".5em",
        borderWidth: "0px 0px 5px 0px",
        borderStyle: "solid",
        borderColor,
        background,
        color,
        fontSize: ".9em",
        "&:hover": {
          background: borderColor,
          color: background,
        },
      },
    });
  }, [width, borderColor, color, background]);
  return (
    <Button classes={rectButtonStyles()} onClick={handleClick}>
      {text}
    </Button>
  );
};

export const SideQuizButton = ({
  left,
  text,
  icon,
  bottom,
  borderColor,
  color,
  background,
  handleClick,
  hide
}) => {
  const sideQuizButtonStyles = useMemo(() => {
    return makeStyles({
      root: {
        display: hide ? "none" : "block",
        position: "absolute",
        bottom,
        height: "25%",
        minWidth: "3%",
        width: "3%",
        borderBottom: `5px solid ${borderColor}`,
        background,
        color,
        // writingMode: "vertical-rl",
        // whiteSpace: "nowrap",
        lineHeight: "1",
        borderRadius: left ? "0em 1.2em 1.2em 0em" : "1.2em 0em 0em 1.2em",
        ...(left ? { left: 0 } : { right: 0 }),
        "&:hover": {
          background: borderColor,
        },
      },
      label: {
        display: "flex",
        fontSize: "3vmin",
        flexDirection: "column",
        "& svg": {
          marginBottom: "8px",
        },
      },
    });
  }, [background, borderColor, bottom, color, left, hide]);

  return (
    <Button classes={sideQuizButtonStyles()} onClick={handleClick}>
      {icon &&  <Icon type="text" name={icon} onClick={handleClick} />}
      {text}
    </Button>
  );
};

export const ChoiceQuizButton = ({ text, active, handleClick }) => {
  const choiceQuizButtonStyles = useMemo(
    () =>
      makeStyles({
        root: {
          height: "1.8em",
          minWidth: "2.8em",
          width: "2.8em",
          borderBottom: `5px solid ${active ? "#B7601A" : "#637381"}`,
          background: active ? "#FFA235" : "#C4CDD5",
          fontSize: "1em",
          fontWeight: "700",
          color: active ? "#7A310A" : "#454F5B",
          borderRadius: ".4em",
          "&:hover": {
            background: active ? "#B7601A" : "#637381",
          },
        },
      }),
    [active]
  );

  const btnText = useMemo(() => {
    if (text === 'true') {
      return trueCircle
    }

    if (text === 'false') {
      return falseCross
    }

    return text
  }, [text])

  return <Button classes={choiceQuizButtonStyles()} onClick={handleClick}>{btnText}</Button>;
};

export const StudentQuizActionButton = ({
  text,
  startIcon,
  endIcon,
	width,
  color,
  background,
  borderColor,
  handleClick
}) => {
  const studentQuizActionButtonStyles = useMemo(
    () =>
      makeStyles({
        root: {
					width: "100%",
					minWidth: "100%",
          height: "2.9em",
          borderRadius: ".5em",
          borderBottom: `5px solid ${borderColor}`,
          background,
          color,
          fontSize: ".6em",
          fontWeight: "700",
          "&:hover": {
            background: borderColor,
            // color: background,
          },

					"& .MuiButton-startIcon": {
						marginRight: '10px',
						"& svg": {
							fontSize: '30px'
						}
					},

					"& .MuiButton-endIcon": {
						marginLeft: '10px',
						"& svg": {
							fontSize: '1em'
						}
					}
        },
      }),
    [borderColor, background, color]
  );

  return (
    <Button
      classes={studentQuizActionButtonStyles()}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export const StudentChoiceQuizButton = ({text, selected, handleClick}) => {
  const studentChoiceQuizButtonStyles = useMemo(
    () =>
      makeStyles({
        root: {
          position: "relative",
					width: "100%",
					minWidth: "100%",
          height: "100%",
          borderRadius: ".1em",
          borderBottom: `5px solid ${selected ? "#0C53B7" : "#88BCD9"}`,
          background: selected ? "#1890FF" : "white",
          color: selected ? "white" : "#315D83",
          fontSize: "1.2em",
          fontWeight: "700",
          "&:hover": {
            background: selected ? "#0C53B7" : "#88BCD9",
          },

					"& .MuiButton-startIcon": {
						position: "absolute",
            left: ".6em",
						"& svg": {
							fontSize: '35px'
						}
					},
        },
      }),
    [selected]
  );

  const btnText = useMemo(() => {
    if (text === 'true') {
      return trueCircle
    }

    if (text === 'false') {
      return falseCross
    }

    return text
  }, [text])

  return <Button classes={studentChoiceQuizButtonStyles()} startIcon={selected ? <Icon type="text" name="circleCheck" /> : null} onClick={handleClick}
  >{btnText}</Button>
}