import { ReaderToolType } from 'constants/ReaderTools';

export const BaseReaderStrategy = {
    getFilteredReaderToolComponents: (readerToolComponents, blacklist) => {
        let components = Object.keys(readerToolComponents).reduce((acc, type) => {
            if (ReaderToolType[type]) {
                if (!blacklist.includes(type)) {
                    acc.push(readerToolComponents[type]);
                }
            }
            return acc;
        }, []);

        return components;
    },
    getAcceptedReaderToolComponents: (readerToolComponents, whitelist) => {
        let components = Object.keys(readerToolComponents).reduce((acc, type) => {
            if (ReaderToolType[type]) {
                if (whitelist.includes(type)) {
                    acc.push(readerToolComponents[type]);
                }
            }
            return acc;
        }, []);

        return components;
    }
};
