import React, { useEffect } from 'react';
import useSetState from 'customHooks/setState';
import classnames from 'classnames';
import { useStore, StoreTypes } from 'context';
import { parseZhuyinOnly } from 'util/zhuyin';
import Repository from 'repositories/Repository';

import AudioControlsButton from 'components/common/AudioControlsButton';

import styles from './index.module.scss';
import './content.scss';
const { ExtendedResourceRepository } = Repository;

const ZhuyinLesson = ({ resource }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);
  const [{ html }, setState] = useSetState({
    html: null,
  })
  const { contents } = resource;
  // label 標題
  // sound 音檔
  // word 課文
  const { label, sound, word } = contents[0]; // 預留以後要多個的通道

  useEffect(() => {
    if (!contents || !bookId) return;

    (async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: word ? word.split('.json')[0] : ""
      })
      const nextHtml = parseZhuyinOnly(htmlJson.html, '.article');

      setState({ html: nextHtml })
    })()

  }, [bookId, contents, setState, word])

  return (
    < div className={classnames(styles.zhuyinLesson, fontSize)} >
      <div className={styles.options}>
        <div className={styles.audio}>
          <AudioControlsButton src={ExtendedResourceRepository.getDataSrc({
            bookId,
            pathName: sound
          })} />
        </div>
        <div className={styles.title}>
          {
            label && label.map((item, idx) => {
              return (
                <div key={idx}><ruby><rt>{item}</rt></ruby></div>
              )
            })
          }
        </div>
      </div>
      <div className={styles.lesson}>
        {
          html && <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: html }} />
        }
      </div>
    </div >
  )
}

export default ZhuyinLesson;