import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import * as types from 'constants/actionTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';

export const useStopwatchCommand = () => {
  const [_, globalDispatch] = useStore(StoreTypes.global);

    const execute = useCallback(async () => {
        try {
          gaEventReaderToolsClick({
            category: CategoryType.ReaderTool,
            action: ReaderToolType.Stopwatch,
            label: ReaderToolType.Stopwatch
          });

          globalDispatch({ type: types.OPEN_STOPWATCH_MODAL })
          EventBus.emit({
            event: ReaderToolsEvent.ClickReaderToolBoxEvent,
            payload: {
              isToolboxShow: false
            }
          })
        } catch (e) {
            console.error('StopwatchCommand execute error', e);
        }
    }, [globalDispatch]);

    return { execute };
};
