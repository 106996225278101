export const parseZhuyinOnly = (html, rangeNode) => {
  let fragment = new DOMParser().parseFromString(html, "text/html");
  const fragmentInRangeCildNode = fragment.querySelector(rangeNode).childNodes;
  const fragmentInRange = fragment.querySelector(rangeNode);
  const elArr = [];
  if (fragmentInRangeCildNode.length > 0) {
    for (let i = 0; i < fragmentInRangeCildNode.length; i++) {
      // 第一層，根據ruby標籤裡面，判斷是否有rt標籤
      if (fragmentInRangeCildNode[i].tagName.toLowerCase() === "ruby") {
        // 去除中文字

        fragmentInRangeCildNode[i].removeChild(
          fragmentInRangeCildNode[i].firstChild
        );
      }

      // 子層還有中文要刪除時 TODO: 改用遞迴寫法
      const rubyNodes = fragmentInRangeCildNode[i].querySelectorAll("ruby");
      if (rubyNodes.length > 0) {
        for (let i = 0; i < rubyNodes.length; i++) {
          if (rubyNodes[i].tagName.toLowerCase() === "ruby") {
            // 去除中文字
            rubyNodes[i].removeChild(rubyNodes[i].firstChild);
          }
        }
      }

      elArr.push(fragmentInRangeCildNode[i]);
    }
  }

  fragmentInRange.innerHTML = "";
  elArr.forEach((item) => {
    fragment.querySelector(rangeNode).appendChild(item);
  });

  return fragment.querySelector("body").innerHTML;
};

export const parseChineseOnly = (html, type) => {
  // 針對國小高年級小視窗無注音的樣式設定
  // 只出現中文字而無注音，行距減少
  // type: pirls, wordLearning
  const zhuyinRegex = /<\/?(ru|zhuyin|rb)[^>]*>/gi;
  const yinRegex = /<\/?(yin)[^>]*>/gi;
  const diaoRegex = /<\/?(diao)[^>]*>/gi;
  const bopomofoRegex = /[\u3105-\u3129\u02CA\u02C7\u02CB\u02D9]/gi;
  const zhuyinTrueRegex = /(zhuyin="true" diao="")/gi;
  const signRegex = /(<sign>)/gi;
  const paragraphRegex = /(paragraph)/gi;
  const articleRegex = /(article)/gi;
  const rubyRegex = /(<ruby)/gi;
  const rtRegex = /<\/?(rt)[^>]*>/gi;
  const contentRegex = /(content)/g;
  const wordRadicalRegex = /("wordRadical")/g;
  const emptySignRegex = /(<sign class='chineseOnly'><\/sign>)/g;
  const especialTexts = {
    one: {
      before:
        "山還<sign class='chineseOnly'>「</sign><sign class='chineseOnly'> </sign><sign class='chineseOnly'>」</sign>低語了幾聲",
      after: "山還「ㄆㄥ ㄆㄥ」低語了幾聲"
    },
  };

  let context = null;

  switch (type) {
    case "pirls": {
      context = html
        .replace(zhuyinRegex, "")
        .replace(yinRegex, "")
        .replace(diaoRegex, "")
        .replace(bopomofoRegex, "")
        .replace(signRegex, "<sign class='chineseOnly'>")
        .replace(emptySignRegex, "")
        .replace(zhuyinTrueRegex, "")
        .replace(paragraphRegex, "paragraph chineseOnly")
        .replace(articleRegex, "article chineseOnly")
        .replace(especialTexts.one.before, especialTexts.one.after)
      break;
    }
    case "wordingLearning": {
      context = html
        .replace(yinRegex, "")
        .replace(diaoRegex, "")
        .replace(bopomofoRegex, "")
        .replace(rubyRegex, "<ruby class='chineseOnly'")
        .replace(rtRegex, "")
        .replace(articleRegex, "article chineseOnly")
        .replace(contentRegex, "content chineseOnly")
        .replace(signRegex, "")
        .replace(wordRadicalRegex, "'wordRadical chineseOnly'");
      break;
    }
    case "aboutTheAuthor": {
      context= html.replace(zhuyinRegex, "")
      .replace(yinRegex, "")
      .replace(diaoRegex, "")
      .replace(bopomofoRegex, "")
      .replace(articleRegex, "article chineseOnly")

      break
    }
    default: {
      context = html
        .replace(zhuyinRegex, "")
        .replace(yinRegex, "")
        .replace(diaoRegex, "")
        .replace(bopomofoRegex, "");
    }
  }

  return context;
};
