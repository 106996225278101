import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import {
  ModalComponentGenerator,
  ModalComponentType
} from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useFlashCardCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const execute = useCallback(
    async ({ json: { src } }) => {
      try {
        const flashCardResourceList = await ExtendedResourceRepository.getJSONContent(
          { bookId, pathName: src }
        );

        const { paths } = flashCardResourceList;

        const flashCardDataList = await Promise.all(
          paths.map(path =>
            ExtendedResourceRepository.getJSONContent({
              bookId,
              pathName: path
            })
          )
        );

        let flashCardResource = [];

        flashCardDataList.forEach(item => {
          flashCardResource = flashCardResource.concat(item.contents);
        });

        const component = ModalComponentGenerator.generate(
          ModalComponentType.FlashCard,
          { flashCardResource }
        );
        setModalComponents(component);
        openModal();
      } catch (e) {
        console.error('FlashCardCommand execute error', e);
      }
    },
    [bookId, openModal, setModalComponents]
  );

  return { execute };
};
