import queryString from 'query-string';
export const urlFormat = ({
  protocol = 'http:',
  hostname = 'localhost',
  port,
  pathname,
  query
}) => {
  const requestUrl = new URL(`${protocol}//${hostname}`);
  requestUrl.port = port;
  requestUrl.pathname = pathname;
  requestUrl.search = queryString.stringify(query, { arrayFormat: 'index' });
  return requestUrl.toString();
};