import React from 'react';
import {
  ExtendedContentContainer,
  ExtendedContentType
} from 'components/ExtendedContent/ExtendedContentContainer';
import FlashCard from 'components/FlashCard';

export const FlashCardModalComponent = ({ flashCardResource }) => {
  return (
    <ExtendedContentContainer
      extendedContentType={ExtendedContentType.FlashCard}
    >
      <FlashCard data={flashCardResource} />
    </ExtendedContentContainer>
  );
};
