import React, { useCallback } from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { CanvasFlipType } from 'constants/flipTypes';

const FlipTools = ({ target: object }) => {
    const flipHandler = useCallback(flipType => e => {
        EventBus.emit({ event: CanvasEvent.FlipCanvasEvent, payload: { object, flipType } });
    }, [object])

    return (
        <div className={styles.flipTools}>
            <h3>翻轉</h3>
            <button onClick={flipHandler(CanvasFlipType.HORIZONTAL)}>水平</button>
            <button onClick={flipHandler(CanvasFlipType.VERTICAL)}>垂直</button>
        </div>
    )
};


export default FlipTools;
