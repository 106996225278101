import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const ControlsButton = ({ className, children, onClick, active = false, disabled = false }) => (
  <div
    className={classnames(styles.button, className, {
      [styles.active]: active,
      [styles.disabled]: disabled
    })}
    onClick={onClick}
  >
    {children}
  </div>
);

export default ControlsButton;
