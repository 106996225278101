import React, { useState } from 'react';
import { useStore, StoreTypes } from 'context';
import AnnotationModal from '../Bookshelf/AnnotationModal';
import styles from './index.module.scss';
import classnames from 'classnames';

export const AnnotationForMobile = () => {
    const router = useStore(StoreTypes.router);
    const [ showMobileModal, setShowMobileModal ] = useState(true)
    const goBook = (bookId, interactiveObjectId) => {
        router.history.push({ pathname: `/${bookId}`, search: interactiveObjectId ? `?interactiveObjectId=${interactiveObjectId}` : '' });
    };
    return (
        <div id='annotationForMobile' className={classnames(styles.mobileAnnotation, {[styles.show]: showMobileModal})}>
            <AnnotationModal 
            open={showMobileModal}
            setOpen={null}
            selectBookID={null}
            goBook={goBook}
            setState={null}
            setShowMobileModal = {setShowMobileModal}
            />
        </div>
    )
};
    

export default AnnotationForMobile;