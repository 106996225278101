import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import classnames from 'classnames';
import styles from './index.module.scss';
import BoxModal from 'components/PaiQuiz/BoxModal'
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { QRCodeSVG } from 'qrcode.react';
import immediatePaiPic from 'components/PaiQuiz/immediatePai.png';
// import oneLinkPaiPic from 'components/PaiQuiz/oneLinkPai.png';
import oneLinkClassPic from 'components/PaiQuiz/oneLinkClass.png';
import oneLinkClassChoosePic from 'components/PaiQuiz/oneLinkClassChoose.png';
import basic from 'components/PaiQuiz/oneStar.png';
import advance from 'components/PaiQuiz/twoStar.png';
import challenge from 'components/PaiQuiz/threeStar.png';
import chooseIcon from 'components/PaiQuiz/chooseQuizIcon.png';
import noContent from 'components/PaiQuiz/noContent.png';
import returnArrow from 'components/PaiQuiz/returnArrow.png';
import copyIcon from 'components/PaiQuiz/copyIcon.png';
import lockIcon from 'components/PaiQuiz/noAccessLock.png';
import customerServiceQRCode from 'components/PaiQuiz/paiQuizCustomerServiceQR.svg';
import check from 'components/PaiQuiz/check.svg';
import { QuizLevel, difficultyWeight, PaiStatus, Category, PaiType } from 'constants/paiQuiz';
import { LottiePlayer2 } from '../LottieLoading/lottiePlayer';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const PaiQuizContent = ({ paiStatus, getImmediatePaiExam, immediatePaiResult, getOneLinkClass, oneLinkClass, choosenClass, chooseClass, paiquizSrcs, setChoosenQuizIndex, choosenQuizIndex, hasOnlineExam, isLoading, setChoosenPaiway, choosenPaiway, paperId, category, oneexamUrl }) => {
  const examLink = useRef(null);
  const [state, setState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });
  const { vertical, horizontal, open } = state;
  const copyLink = (newState) => {
    navigator.clipboard.writeText(examLink.current.textContent)
    setState({ open: true, ...newState });
  }
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [{ userId }] = useStore(StoreTypes.user);

  const setQRCodeSize=()=>{
    let size=0.15 * window.innerWidth
    return size>196?196:size
  }

  switch (paiStatus) {
    case PaiStatus.Immediate:
      return (
        <div className={styles.immediateContent}>
          <div className={styles.qrCode}>
            <QRCodeSVG value={`${oneexamUrl}/quiz/${immediatePaiResult.quizCode}`} size={setQRCodeSize()} />
          </div>
          <div className={styles.qrTextBlock}>
            <div className={styles.qrTitle}>
              <span>{immediatePaiResult.examName}</span>
            </div>
            <div className={styles.linkArea}>
              <div className={styles.linkText}>
                <span ref={examLink}>{`${oneexamUrl}/quiz/${immediatePaiResult.quizCode}`}</span>
              </div>
              <button className={styles.desktop} type="button" onClick={() => copyLink({ vertical: 'bottom', horizontal: 'left' })}><img src={copyIcon} alt='複製' />複製連結</button>
              <button className={styles.mobile} type="button" onClick={() => copyLink({ vertical: 'top', horizontal: 'center' })}><img src={copyIcon} alt='複製' />複製連結</button>
            </div>
            {ReactDOM.createPortal(
              <div className={styles.snackbarWrap}>
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  key={`${vertical},${horizontal}`}
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  >
                    <SnackbarContent style={{
                      backgroundColor:'white',
                      color: '#212B36',
                    }}
                    message={
                      <React.Fragment>
                        <div className={styles.snackbarIcon}>
                          <img src={check} alt="check" />
                          連結已複製
                        </div>
                      </React.Fragment>
                    }
                    action={
                      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    />
                </Snackbar>
              </div>
              ,document.body
            )}

            <div className={styles.qrText}>
              <span>結束時間：</span>
              <span>{immediatePaiResult.endAt}</span>
            </div>
          </div>
        </div>
      )
    case PaiStatus.OneLink:
      return (
        isLoading ? <div className={classnames(styles.oneLinkContent, { [styles.freeze]: !hasOnlineExam })}>
            <div className={styles.loadingContent}>
              <LottiePlayer2 />
              <p>等待中...</p>
            </div>
          </div> :
          <div className={classnames(styles.oneLinkContent, { [styles.freeze]: !hasOnlineExam })}>
            <div className={classnames(styles.noAccess, { [styles.show]: !hasOnlineExam })}>
            <img src={customerServiceQRCode} alt='數位產品客服' />
              <div className={styles.noAccessText}>
                <img src={lockIcon} alt='解鎖權限' />
                <span>您尚未開通權限請洽南一數位產品客服</span>
              </div>
              <a href='https://lin.ee/FdL4cmD' target='_blank' rel='noopener noreferrer'>https://lin.ee/FdL4cmD</a>
            </div>
            {oneLinkClass.map((classroom) => {
              return (
                <div className={styles.class} key={classroom.id} onClick={() => chooseClass(classroom.id, classroom.organizationId, userId)}>
                  <img src={classroom.id === choosenClass.id ? oneLinkClassChoosePic : oneLinkClassPic} alt="班級" />
                  <p>{classroom.name}</p>
                </div>
              )
            })}
          </div>
      )
    case PaiStatus.ChooseQuiz:
      return (
        <div className={classnames(styles.chooseQuizContent, {[styles.loading]: isLoading})}>
          {isLoading && <div className={styles.loadingContent}>
            <LottiePlayer2 />
            <p>等待中...</p>
          </div>}
          {!isLoading && paiquizSrcs.length === 0 && <div className={styles.empty}>
          <img src={noContent} alt='題目準備中，敬請期待 ～' width='114px' height='95px' />
          題目準備中，敬請期待 ～
          </div>}
            {!isLoading && paiquizSrcs.map((item, index) => {
            return (
              <div
                className={classnames(styles.chooseQuiz, {
                  [styles.choose]: index === choosenQuizIndex,
                })}
                key={index}
                onClick={() => setChoosenQuizIndex(index)}
              >
                <img
                  className={classnames(styles.chooseIcon, {
                    [styles.choose]: index === choosenQuizIndex,
                  })}
                  src={chooseIcon}
                  alt="choose"
                />
                <img
                  className={styles.quizIcon}
                  src={QuizLevel[index].quizIcon}
                  alt="測驗卷"
                />
                <span>{QuizLevel[index].quizText}</span>
              </div>
            );
          })}
        </div>
      );
    case PaiStatus.UnLogin:
      return (
        <div className={styles.empty}>請登入以使用派測驗功能</div>
      )
    case PaiStatus.EndClose:
      return (
        <div className={styles.endCloseContent}>
          <p className={styles.confirmDialog}>建議預先查看<span>「作答紀錄」</span>並儲存，<br />
            若關閉本視窗，會造成紀錄遺失並無法查看測驗結果。</p>
        </div>
      );
    case PaiStatus.Original:
    default:
      return <>
        {isLoading &&  
          <div className={styles.originalContent}>
            <div className={styles.loadingContent}>
              <LottiePlayer2 />
              <p>等待中...</p>
            </div>
          </div>
        }
        {!isLoading && !paperId && 
          <div className={styles.empty}>
            <img src={noContent} alt='題目準備中，敬請期待 ～' width='114px' height='95px' />
            題目準備中，敬請期待 ～
          </div>}
        {!isLoading && paperId && 
          <div className={styles.originalContent}>
            <div
              className={classnames(styles.immediatePai, {
                [styles.choose]: choosenPaiway === "immediate",
              })}
              onClick={() => setChoosenPaiway("immediate")}
            >
              <img
                className={classnames(styles.chooseIcon, {
                  [styles.choose]: choosenPaiway === "immediate",
                })}
                src={chooseIcon}
                alt="choose"
              />
              <div>
                <h2>立即派</h2>
              </div>
              <p>
                免建班
                <br />
                掃描QRcode立即測驗
              </p>
            </div>
            {/* <div
              className={classnames(styles.oneLinkPai, {
                [styles.choose]: choosenPaiway === "oneLink",
              })}
              onClick={() => {
                setChoosenPaiway("oneLink");
                setTimeout(() => {
                  getOneLinkClass();
                }, 1000);
              }}
            >
              <img
                className={classnames(styles.chooseIcon, {
                  [styles.choose]: choosenPaiway === "oneLink",
                })}
                src={chooseIcon}
                alt="choose"
              />
              <div>
                <h2>OneLink派</h2>
                <img src={oneLinkPaiPic} alt="oneLink派" />
              </div>
              <p>
                輕鬆指派班級作業
                <br />
                測驗結果完整留存
              </p>
            </div> */}
          </div>
        }
      </>

  }
}

const PaiQuizBottom = ({ paiStatus, immediatePaiResult, choosenClass, getOneLinkExam, setPaiStatus, setTitle, hasOnlineExam, isLoading, token, getImmediatePaiExam, onCloseButtonClick, goBack, getPaperId, choosenPaiway, setChoosenPaiway, choosenQuizIndex, paperId, oneexamUrl, paiquizSrcs }) => {
  switch (paiStatus) {
    case PaiStatus.Immediate:
      const resultSrc = `${oneexamUrl}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`;
      return (
        <div className={styles.immediateBottom}>
          {
            window.electron ? 
            <button className={`${styles.resultLink} ${styles.forElectron}`} onClick={() => {
              // const data = {
              //   src: `${oneexamUrl}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`,
              //   token
              // }
              // window.electron && window.electron.ipcRenderer.send("openImmediatePai", data);
              window.electron && window.electron.ipcRenderer.send("openExternal", resultSrc);
            }}>作答記錄</button> : 
            <a href={resultSrc} className={styles.resultLink} target="_blank" rel="noopener noreferrer">作答記錄</a>
          }
          {/* <a href={`${oneexamUrl}/quiz/review/${immediatePaiResult.schoolYear}/${immediatePaiResult.quizCode}/${immediatePaiResult.quizId}`} className={styles.resultLink} target="_blank" rel="noopener noreferrer">作答記錄</a> */}
        </div>
      )
    case PaiStatus.OneLink:
      return (
        !hasOnlineExam ?
          <div className={styles.noAccessBottom}>
            <button type="button" onClick={() => setPaiStatus(PaiStatus.Original)}>返回上一頁<img src={returnArrow} alt='返回' /></button>
          </div>
          : <div className={styles.oneLinkBottom}>
            <button type="button" disabled={choosenClass.id === ''} className={classnames({ [styles.disable]: choosenClass.id === '' })} onClick={() => getOneLinkExam(choosenClass)}>確定派送</button>
          </div>
      )

    case PaiStatus.Original:
      return (
        !isLoading && paperId && <>
        <div className={styles.noAccessBottom}>
          <button type="button" onClick={() => {
              setPaiStatus(PaiStatus.ChooseQuiz);
              setTitle('請選擇派送卷');
              setChoosenPaiway('');
            }}>重新選卷<img src={returnArrow} alt='返回' /></button>
        </div>
        <div className={classnames(styles.originalBottom, { [styles.disabled]: choosenPaiway === '' })}>
          <button type="button" disabled={ choosenPaiway === ''} onClick={() => getImmediatePaiExam()}>下一步</button>
        </div>
      </>
      )

    case PaiStatus.ChooseQuiz:
    return (
        !isLoading && paiquizSrcs.length !== 0 && (
          <div className={classnames(styles.originalBottom, { [styles.disabled]: choosenQuizIndex === null })}>
            <button type="button" disabled={choosenQuizIndex === null} onClick={() => getPaperId()}>
              下一步
            </button>
          </div>
        )
      )

    case PaiStatus.EndClose:
      return (
        !isLoading && <>
          <div className={styles.noAccessBottom}>
            <button type="button" onClick={goBack}>取消</button>
          </div>
          <div className={styles.originalBottom}>
            <button type="button" onClick={onCloseButtonClick}>確定</button>
          </div>
        </>
      );

    default:
      return null
  }
}

const PaiQuiz = () => {
  const [{ token }] = useStore(StoreTypes.user);
  const [paiStatus, setPaiStatus] = useState(PaiStatus.ChooseQuiz);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState(Category.PaperId);

  // 關閉彈窗及確認關閉
  const [_, globalDispatch] = useStore(StoreTypes.global);
  const onClose = useCallback(() => {
    if (paiStatus === PaiStatus.Immediate) {
      setPaiStatus(PaiStatus.EndClose);
      setTitle('確定要關閉嗎？');
    } else {
      globalDispatch({ type: types.CLOSE_PAIQUIZ_MODAL });
    }
  }, [globalDispatch, paiStatus]);
  const goBack = () => {
    setPaiStatus(PaiStatus.Immediate);
    setTitle('測驗請掃描QR code或複製連結');
  }

  // 選取考卷獲取知識向度
  const [{ paiquizSrc }, readerDispatch] = useStore(StoreTypes.reader);
  const [paiquizSrcs, setPaiquizSrcs] = useState([]);
  const [choosenQuizIndex, setChoosenQuizIndex] = useState(null);
  useEffect(() => {
    if (paiquizSrc === 'unLogin') {
      setPaiStatus(PaiStatus.UnLogin);
      readerDispatch({type: types.SET_PAIQUIZ_SRC, paiquizSrc: ''});
      return
    }
    const newPaiquizSrc = paiquizSrc.trim();
    if (!newPaiquizSrc) return
    const keys = newPaiquizSrc.split(',');

    const getMappingsCheckPaperApi = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          'https://us-central1-onebook-dev.cloudfunctions.net/mapping/getMappings',
          {
            method: 'POST',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({keys})
          }
        );
        const result = await res.json();
          
        const checkAllPaperIdValid = async (ids) => {
          const checkPromises = ids.map(async (id) => {
            const checkUrl = `https://onepaper-api.oneclass.com.tw/api/Service/ExamPaper/GetOne?UID=${id}`;
            try {
              const result = await fetch(checkUrl);
              const json = await result.json();
              return json;
            } catch (err) {
              console.log('ExamPaper/GetOne API error: ', err)
              return { data: null };
            }
          });
          const checkResults = await Promise.all(checkPromises);
          return checkResults.some(
            (result) => result.data === null
          );
        }

        // 確認 SVG button 的 code 是否為 paperId。
        let quizList = result.data.docs;
        if (quizList.length === 0) {
          // 檢查是否為真的 paperId
          const hasInvalidPaperId = await checkAllPaperIdValid(keys);
          if (hasInvalidPaperId) {
            setPaiquizSrcs([]);
            console.log('SVG code 不為 paperId')
            return;
          }
          const newList = keys.map(key => ({type: 'paper', value: key}))
          setPaiquizSrcs(newList);
          return;
        }
        // 確保順序與 keys 一致
        quizList = keys.map((key) =>
          quizList.find((quizItem) => quizItem.key === key)
        );
        // 確認內容是否都為 paper，
        // 如果為混合或 knowledge，找到第一個 knowledge，並補滿三個。
        const isAllPaper = quizList.every((quiz) => quiz.type === "paper");
        if (isAllPaper) {
          const paperIdList = quizList.map(quiz => quiz.value);
          const hasInvalidPaperId = await checkAllPaperIdValid(paperIdList);
          if (hasInvalidPaperId) {
            setPaiquizSrcs([]);
            console.log('有無效的 paperId')
          } else {
            setPaiquizSrcs(quizList);
          }
          return;
        }

        if (!isAllPaper) {
          const paperIdList = quizList.filter(quiz => quiz.type === "paper").map(quiz => quiz.value);
          const hasInvalidPaperId = await checkAllPaperIdValid(paperIdList);
          if (hasInvalidPaperId) {
            setPaiquizSrcs([]);
            console.log('有無效的 paperId')
            return;
          }
        }
        if (quizList.length < 3) {
          const knowledgeQuiz = quizList.find(
            (item) => item.type === "knowledge"
          );
          while (quizList.length < 3) {
            quizList.push({ ...knowledgeQuiz }); // 深拷貝
          }
        }

        const hasEmpty = quizList.some((quiz) => quiz.value.length <= 0);
        if (hasEmpty) {
          setPaiquizSrcs([]);
          console.log('knowledge 為空')
          return;
        }
        
        setPaiquizSrcs(quizList);
      } catch (err) {
        console.log('fetch paperId', err)
      } finally {
        setIsLoading(false);
      };
    };
    getMappingsCheckPaperApi();
    
    return () => {
      readerDispatch({type: types.SET_PAIQUIZ_SRC, paiquizSrc: ''});
    }

  }, [paiquizSrc, readerDispatch, token])
  

  // 取得考卷
  const [choosenPaiway, setChoosenPaiway] = useState('');
  const [paperId, setPaperId] = useState('');
  const getPaperId = async () => {
    if (paiquizSrcs[choosenQuizIndex].type === PaiType.paper) {
      setPaperId(paiquizSrcs[choosenQuizIndex].value);
      return
    }

    setIsLoading(true);
    const eduSubject = paiquizSrcs[choosenQuizIndex].value[0].split('-')[0];
    const data = {
      "scopes": [
        {
          "eduSubject": eduSubject,
          "knowledges": paiquizSrcs[choosenQuizIndex].value,
          "questionCount": 10
        }
      ],
      "difficultyWeight": difficultyWeight[choosenQuizIndex],
    }

    try {
      // TODO: 如果以後改為知識點，可能要改成知識點產券的 API
      const res = await fetch(
        `${process.env.REACT_APP_ONE_PAPER_URL}/api/Practice/CreatePractice`,
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }
      );
      const result = await res.json();
      if (result.status === 'error') {
        console.log("get paperId ", result.content);
      } else {
        if (result.paperId !== '') setPaperId(result.paperId);
      };
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    };
  }
  useEffect(() => {
    if (paperId !== '') {
      setPaiStatus(PaiStatus.Original);
      setTitle('請選擇派送方式');
    }
  }, [paperId])

  // 取得立即派測驗
  const [{ books }] = useStore(StoreTypes.books);
  const url = window.location.href.split("?")[0];
  let bookId = url.split("/")[url.split("/").length - 1];
  const book = books.find(book => book.bookId === bookId);
  const [immediatePaiResult, setImmediatePaiResult] = useState({});
  const [title, setTitle] = useState('請選擇派送卷');

  // const generateExamName = () => {
  //   const today = new Date();
  //   const day = ("0" + (today.getMonth() + 1)).slice(-2) + ("0" + today.getDate()).slice(-2);
  //   return `${day} ${book.display_name.slice(book.display_name.indexOf("國"), book.display_name.indexOf("國") + 4)}測驗 ${choosenQuizIndex.type}`
  // }

  const generateExamEndTime = () => {
    const now = Date.now();
    const endAt = new Date(now + 35 * 60 * 1000).toISOString();
    return endAt;
  }
  const localizeEndAt = (endAt) => {
    const endTime = new Date(endAt);
    const month = ('0' + (endTime.getMonth() + 1)).slice(-2);
    const day = ('0' + endTime.getDate()).slice(-2);
    const hour = endTime.getHours();
    const minutes = ('0' + endTime.getMinutes()).slice(-2);
    return `${month}/${day} ${hour}:${minutes}`
  }
  const oneexamUrl =
    choosenQuizIndex !== null && paiquizSrcs[choosenQuizIndex].type === PaiType.paper
      ? "https://oneexam.oneclass.com.tw"
      : `${process.env.REACT_APP_ONE_EXAM_RESULT_URL}`;

  const getImmediatePaiExam = async () => {
    setTitle('出題中');
    setIsLoading(true);
    const examName =
      paiquizSrcs[choosenQuizIndex].type === PaiType.paper
        ? ""
        : `${book.display_name}_${paiquizSrcs[choosenQuizIndex].originalValue.chapter}_${QuizLevel[choosenQuizIndex].quizText}`;

    const data = {
      'examName': examName,
      'paperId': paperId,
      'examPeriod': 30,
      'service': 'OneBook',
      'endAt': generateExamEndTime(),
      'paperType': 'exam',
      'isAnnounced': true
    }

    // paper 要在 release 環境才能用
    // 使用知識向度可根據環境產券
    const url =
      paiquizSrcs[choosenQuizIndex].type === PaiType.paper
        ? "https://asia-northeast1-oneexam-release.cloudfunctions.net"
        : `${process.env.REACT_APP_ONE_EXAM_DOMAIN}`;

    try {
      const res = await fetch(`${url}/api/quiz`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const result = await res.json();

      if (result.status !== 'success') {
        console.log("get immediate quiz", result);
        return
      };

      const {endAt, quizCode, quizId, schoolYear, attribute} = result.content;

      const immediatePaiData = {
        examName: attribute.examName,
        endAt: localizeEndAt(endAt),
        quizCode,
        quizId,
        schoolYear
      };
      setImmediatePaiResult(immediatePaiData);
    } catch (err) {
      console.log("Create immediate quiz Error", err);
    } finally {
      setIsLoading(false);
    };
  }
  useEffect(() => {
    if (Object.keys(immediatePaiResult).length !== 0) {
      setPaiStatus(PaiStatus.Immediate);
      setTitle('測驗請掃描QR code或複製連結');
    }
  }, [immediatePaiResult])

  // 取得 oneLink 班級
  const [oneLinkClass, setOneLinkClass] = useState([]);
  const [hasOnlineExam, setHasOnlineExam] = useState(true);
  const getOneLinkClass = async () => {
    setTitle('查詢班級中');
    setIsLoading(true);
    const classroomResult = await fetch(`${process.env.REACT_APP_ONE_LINK_DOMAIN}/groups`, {
      method: 'GET',
      headers: {
        'Authorization': token
      }
    })
      .then(res => res.json())
      .catch(err => console.log(err));
    if (classroomResult.status !== 'success') {
      console.log("get onelink quiz", classroomResult);
      setIsLoading(false);
      return
    }
    if (classroomResult.data.length === 0) {
      setPaiStatus(PaiStatus.OneLink);
      setHasOnlineExam(false);
    } else {
      setOneLinkClass(classroomResult.data);
      // setHasOnlineExam(classroomResult.data.every(item => item.navigationFunctions.includes('onlinExam')));
      setHasOnlineExam(classroomResult.data[0].navigationFunctions.includes('onlineExam'));
    }
    setTitle('派測驗');
    setIsLoading(false);
  }
  useEffect(() => {
    if (oneLinkClass.length !== 0) {
      setPaiStatus(PaiStatus.OneLink);
    }
  }, [oneLinkClass])

  // 選取班級
  const [choosenClass, setChoosenClass] = useState({
    id: '',
    organizationId: '',
    userId: ''
  });
  const chooseClass = (id, organizationId, userId) => {
    setChoosenClass({
      id,
      organizationId,
      userId
    });
  }

  // 派測驗
  const [goToOneLink, setGoToOneLink] = useState(false);
  const [goToOneLinkLink, setGoToOneLinkLink] = useState('');
  const getOneLinkExam = async (choosenClass) => {
    setTitle('派卷中');
    setIsLoading(true);
    setChoosenClass({
      id: '',
      organizationId: '',
      userId: ''
    })
    const data = {
      'name': '',
      'paperId': paperId,
      'eduSubjectName': book.display_name.slice(book.display_name.indexOf("國"), book.display_name.indexOf("國") + 4),
      'period': 30,
      // 'startAt': Date.now(),
      // 'endAt': Date.now() + 30 * 60 * 1000,
      'markers': [choosenClass.userId]
    }
    const result = await fetch(`${process.env.REACT_APP_ONE_LINK_DOMAIN}/organizations/${choosenClass.organizationId}/groups/${choosenClass.id}/oneBookExams`, {
      method: 'POST',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .catch(err => console.log(err))

    if (result.status !== 'success') {
      console.log('get onelink exam', result);
      if (result.error.errorCode === 20005) {
        setHasOnlineExam(false);
        setPaiStatus(PaiStatus.OneLink);
        setIsLoading(false);
      }
      return
    } else {
      setGoToOneLinkLink(`${oneexamUrl}/user/review/${result.data.id}`);
      setIsLoading(false);
      setGoToOneLink(true);
    }

    

  }

  return (
    <BoxModal
      onCloseButtonClick={onClose}
      title={title}
      content={
        <PaiQuizContent
          paiStatus={paiStatus}
          getImmediatePaiExam={getImmediatePaiExam}
          immediatePaiResult={immediatePaiResult}
          getOneLinkClass={getOneLinkClass}
          oneLinkClass={oneLinkClass}
          choosenClass={choosenClass}
          chooseClass={chooseClass}
          paiquizSrcs={paiquizSrcs}
          setChoosenQuizIndex={setChoosenQuizIndex}
          choosenQuizIndex={choosenQuizIndex}
          hasOnlineExam={hasOnlineExam}
          isLoading={isLoading}
          setChoosenPaiway={setChoosenPaiway}
          choosenPaiway={choosenPaiway}
          paperId={paperId}
          category={category}
          oneexamUrl={oneexamUrl}
        />
      }
      bottom={
        <PaiQuizBottom
          paiStatus={paiStatus}
          setPaiStatus={setPaiStatus}
          setTitle={setTitle}
          immediatePaiResult={immediatePaiResult}
          choosenClass={choosenClass}
          getOneLinkExam={getOneLinkExam}
          hasOnlineExam={hasOnlineExam}
          isLoading={isLoading}
          token={token}
          getPaperId={getPaperId}
          getImmediatePaiExam={getImmediatePaiExam}
          onCloseButtonClick={onClose}
          goBack={goBack}
          choosenPaiway={choosenPaiway}
          setChoosenPaiway={setChoosenPaiway}
          choosenQuizIndex={choosenQuizIndex}
          paperId={paperId}
          category={category}
          oneexamUrl={oneexamUrl}
          paiquizSrcs={paiquizSrcs}
        />
      }
      paiStatus={paiStatus} goToOneLink={goToOneLink} goToOneLinkLink={goToOneLinkLink}>
    </BoxModal>
  )
}

export default PaiQuiz;
