import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const DialogModule = ({ open, setOpen, content, action, style = {} || null , ...otherProps }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen && setOpen(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...otherProps}
    >
      <DialogContent style={style}>
        {content}
      </DialogContent>
      {
        action && <DialogActions>
          {action}
        </DialogActions>
      }
    </Dialog>
  );
};

export default DialogModule;
