import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core";

const useWarningStyles = makeStyles({
    paper: {
      width: "522px",
      borderRadius: "16px",
      padding: "24px",
      "& .MuiDialogTitle-root": {
        padding: 0,
        fontSize: "20px",
        marginBottom: "24px",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
        fontSize: "16px",
        fontWeight: "400",
        color: "#637381",
        marginBottom: "24px",
      },
    },
  });

const WarningDialog = ({ open, type, handleCancel, handleConfirm }) => {
    const decideWarningContent = () => {
        let title, content, dialogAction;
        switch (type) {
            case 'token':
                title = '紀錄無法同步';
                content = '身分驗證過期，請重新登入以使用此功能！';
                break;
            case 'deleteAnnotation':
                title = '確定要刪除此記錄？';
                content = null;
                break;
            case 'deleteBook':
                title = '確定要刪除此書本？';
                content = '刪除後，會將書本從您的書櫃中移除。';
                break
            default:
                title = '彈窗錯誤';
                content = null;
                dialogAction = null;
                break;
        }
        return {
            title,
            content,
            dialogAction
        }
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={useWarningStyles()}
            >
                <DialogTitle id="alert-dialog-title">{decideWarningContent().title}</DialogTitle>
                {decideWarningContent().content && <DialogContent>{decideWarningContent().content}</DialogContent>}
                <DialogActions>
                    {handleCancel &&
                        <Button onClick={() => handleCancel()} variant='outlined' style={{ background: '#D5D7DE', width: '60px' }}>
                            取消
                        </Button>}
                    <Button onClick={() => handleConfirm()} style={{ background: '#F44336', color: 'white', width: '60px' }} >
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default WarningDialog;
