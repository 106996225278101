import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';

const TranslationControlsButton = ({ className, active, content, onClick }) => (
  <div
    className={classnames(styles.button, className, {
      [styles.active]: active
    })}
    onClick={onClick}
  >
    <div>{content}</div>
    <div>{active ? '隱藏' : '顯示'}</div>
  </div>
);

export default TranslationControlsButton;
