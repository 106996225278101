import React, { useState, useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import Icon from 'components/Icon';
import styles from './index.module.scss';

const MathToolsContainer = ({ mathToolsInfo }) => {
  //const ifraRef = useRef() //use this iframe can not get contentWindow
  const { type } = mathToolsInfo
  const [ifraRef, setContentRef] = useState(null)
  const closeHandler = () => {
    EventBus.emit({ event: ReaderToolsEvent.ClickMathToolsEvent, payload: { mathToolsInfo: null } });
  }

  const iframeOnLoad = useCallback(() => {
    if (!ifraRef) return;
    setTimeout(() => {
      createMathTools(type, ifraRef.contentWindow.flashRoot)
    }, 1000);
  }, [type, ifraRef])

  const createMathTools = (type, flashRoot) => {
    flashRoot.mathToolsInit(type);
  }

  return (
    <div className={styles.mathToolsContainer}>
      <iframe ref={setContentRef} className={styles.iframe} onLoad={() => iframeOnLoad()} title="compass" src="assets/MathTools/MathTools.html" frameborder="0"></iframe>
      <div className={styles.closeLeftButton} onClick={closeHandler}><Icon name="Close" /></div>
      <div className={styles.closeRightButton} onClick={closeHandler}><Icon name="Close" /></div>
    </div>
  )
}

export default MathToolsContainer;
