import { EventBus } from 'events/EventBus';
import { useStore, StoreTypes } from "context";
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderZoomType } from 'constants/ReaderTools';
import { domToBlob } from "modern-screenshot";
import * as types from "constants/actionTypes";


export const usePaiNote = (pageIndex) => {
  const [, globalDispatch] = useStore(StoreTypes.global);
  const [{ paiNotePage }] = useStore(StoreTypes.reader);

  const nowPageIndex = paiNotePage && paiNotePage.indexOf(pageIndex);
  const note = document.querySelector("#studentScreenshotArea");

  const waitForResizeBook = async() => {
		EventBus.emit({
			event: ReaderToolsEvent.SetReaderZoomEvent,
			payload: { type: ReaderZoomType.OriginZoom },
		});
		await new Promise(resolve => setTimeout(resolve, 1000));
  }


	const saveStudentAnswer = async() => {
		globalDispatch({
			type: types.SET_PAINOTE_STUDENT_LOADING,
			payload: true
		});

		try {
			await waitForResizeBook();
			const result = await domToBlob(note, { type: "image/jpg", width: note.offsetWidth, height: note.offsetHeight, backgroundColor: 'lightgrey', scale: 2 });
			globalDispatch({
				type: types.SET_PAINOTE_ANSWERS,
				payload: { index: nowPageIndex, answer: result },
			});
		} finally {
			globalDispatch({
				type: types.SET_PAINOTE_STUDENT_LOADING,
				payload: false
			});
		}
	}

	return {
		saveStudentAnswer
	}
};
