import { IClassNameRepository } from './IClassNameRepository';

function FileClassNameRepository() { };

FileClassNameRepository.prototype = Object.create(IClassNameRepository);

FileClassNameRepository.prototype.getClassName = className => {
  return `File_${className}`
}

export default new FileClassNameRepository();