import { urlFormat } from 'util/urlFormat';
import { fileExpressHost, fileExpressPort } from 'repositories/Repository';
import { IReaderToolBarRepository } from './IReaderToolBarRepository';
import {
	ReaderToolType,
	DefaultLeftReaderTools,
	DefaultMiddleReaderTools,
	DefaultRightReaderTools,
	ElectronDefaultReaderTools,
	DefaultToolboxCategory,
	ToolboxCategory
} from 'constants/ReaderTools';

function FileReaderToolBarRepository() { };
FileReaderToolBarRepository.prototype = Object.create(IReaderToolBarRepository);

FileReaderToolBarRepository.prototype.getDefaultReaderTools = () => {
	return ElectronDefaultReaderTools;
};

FileReaderToolBarRepository.prototype.getLeftReaderTools = () => {
	return DefaultLeftReaderTools;
};

FileReaderToolBarRepository.prototype.getMiddleReaderTools = () => {
	return DefaultMiddleReaderTools;
};

FileReaderToolBarRepository.prototype.getRightReaderTools = async (bookId) => {
	const results = [...DefaultRightReaderTools];
	const res = await fetch(
		urlFormat({
			protocol: 'http:',
			hostname: fileExpressHost,
			port: fileExpressPort,
			pathname: `offline-tools/${bookId}`,
			query: { type: 'toolbar' },
		})
	);
	const json = await res.json();
	const mathTools = json.filter(item => item.type && item.type === 'mathTool')
	if (!mathTools || mathTools.length === 0) {
		if (results.indexOf(ReaderToolType.OfflineReaderToolBox) >= 0) {
			results.splice(results.indexOf(ReaderToolType.OfflineReaderToolBox), 1)
		}
	}
	return results;
};

FileReaderToolBarRepository.prototype.getGuestReaderToolBarWhitelist = () => {
	return [
		ReaderToolType.Bookshelf,
		ReaderToolType.AnswerInteractiveTool,
		ReaderToolType.PaiNote,
		ReaderToolType.SynchronousCamera,
		ReaderToolType.Toolbox,
		ReaderToolType.BackToCatalog,
		ReaderToolType.Menu,
		ReaderToolType.PageMode,
		ReaderToolType.Mark,
		ReaderToolType.Drag,
		ReaderToolType.Select,
		ReaderToolType.Painter,
		ReaderToolType.Painting,
		ReaderToolType.Highlighter,
		ReaderToolType.Shape,
		ReaderToolType.Line,
		ReaderToolType.DeleteAll,
		ReaderToolType.Delete,
		ReaderToolType.ArrowLeft,
		ReaderToolType.ArrowRight,
		ReaderToolType.PageSearcher,
		ReaderToolType.FourZoomIn,
		ReaderToolType.AllScreen,
		ReaderToolType.IntervalZoomIn,
		ReaderToolType.FullScreen,
		ReaderToolType.Whiteboard,
		ReaderToolType.NumberPicker,
		ReaderToolType.Img,
		ReaderToolType.Text,
		//ReaderToolType.SVG,
		ReaderToolType.Bookmark,
		ReaderToolType.ReaderOption,
		ReaderToolType.RightSideBar,
		ReaderToolType.FullWidth,
		ReaderToolType.OfflineReaderToolBox,
		ReaderToolType.StickyNote,
		ReaderToolType.Link,
		ReaderToolType.MathTools,
		ReaderToolType.Timer,
		ReaderToolType.Stopwatch,
		ReaderToolType.MeasureTools,
		// ReaderToolType.Protractor,
		// ReaderToolType.ShortRuler,
		// ReaderToolType.LongRuler,
		// ReaderToolType.IsoscelesTriangle,
		// ReaderToolType.RightTriangle
		ReaderToolType.StampCollection,
		ReaderToolType.Expression,
		ReaderToolType.Undo,
		ReaderToolType.Redo,
	];
};

FileReaderToolBarRepository.prototype.getReaderToolboxCategory = () => {
	return [...DefaultToolboxCategory, ToolboxCategory.OfflineTools]
};

export default new FileReaderToolBarRepository();
