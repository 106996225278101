import React, { useEffect, useState, useCallback, useRef, createRef } from 'react';
import path from 'path';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { groupPages } from 'util/book';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { BookFlipType } from 'constants/flipTypes';
import { SideBarType } from 'constants/ReaderTools';
import Repository from 'repositories/Repository';
import Input from 'components/common/Input';
import PropTypes from "prop-types";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Roles } from 'constants/role';

const { BookContentRepository } = Repository;
const PageSearcherForStudent = () => {
  const [{ books, bookId, catalog }] = useStore(StoreTypes.books);
  const [{ pageIndex, isDoublePageMode, isLeftBarShow, paiNotePage }] = useStore(StoreTypes.reader);
  const book = books.find(item => item.bookId === bookId);
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState({});
  // const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  // const [filterMin, setFilterMin] = useState(null);
  // const [filterMax, setFilterMax] = useState(null);
  const thumbnailWrapperRef = useRef();
  const thumbnailGroupsRef = useRef([]);

  useEffect(() => {
    if (!book) {
      return;
    }
    const { pageInfos } = book;
    const pages = pageInfos.reduce((acc, item) => {
      acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
      return acc;
    }, {});
    const imgList = getLabel(groupPages(pages, true)).filter((img, index) => paiNotePage.includes(index));
    setData(imgList);
    setDataList(imgList);
    thumbnailGroupsRef.current = Object.values(imgList).map(() => createRef())
    const results = BookContentRepository.getThumbnailUrls({ bookId, pages: Object.values(pages) });
    setThumbnailUrls(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, bookId, isDoublePageMode]);

  const getLabel = (data) => {
    return Object.values(data).map((pages, index) => {
      const labels = []
      for (let page of pages) {
        const label = (catalog.getLabel(page - 1)).toString()
        labels.push({ label, page, index })
      }
      return labels;
    })
  }

  const setImg = useCallback((pages) => {
    let pagesElement = []
    if (pages.length < 2) {
      pagesElement.push(<div className={styles.pagesElement} key={pages[0].page}>
        <LazyLoadImage key={pages[0].page} src={thumbnailUrls[pages[0].page]} alt='' effect="blur" />
      </div>)
    } else {
      for (let page of pages) {
        const pageIndex = parseInt(page.page)
        pagesElement.push(<div className={styles.pagesElement} key={page.page}>
          <LazyLoadImage key={pageIndex} src={thumbnailUrls[pageIndex]} alt='' effect="blur" />
        </div>)
      }
    }
    return pagesElement
  }, [thumbnailUrls]);

  useEffect(() => {
    const dataListArr = Object.values(dataList);
    const totalLength = dataListArr.length;
    if (totalLength < 1) return;
    let newPageIndex = 0;
    newPageIndex = dataListArr.findIndex(item => item[0].index === pageIndex);

    const target = thumbnailGroupsRef.current[newPageIndex].current;
    const wrapper = thumbnailWrapperRef.current;
    if (!target || !wrapper) return;
    // const { offsetTop } = target;
    const { offsetWidth } = wrapper;
    // const BUFFER = offsetWidth / 2;

   target.scrollIntoView({ inline: newPageIndex < 3 ? 'nearest' : 'center' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList, pageIndex]);

  // useEffect(() => {
  //   const catalogMap = catalog.map((book, index) => {
  //     let labels = book.sections.reduce((acc, section) => {
  //       section.pages.forEach(page => {
  //         acc.push({
  //           startPage: path.basename(page.src, path.extname(page.src)) - 1,
  //           label: page.label
  //         });
  //       });

  //       return acc;
  //     }, []);
  //     return {
  //       startPage: (labels[0] && labels[0].startPage) || 0,
  //       labels,
  //     };
  //   });

  //   const nowPageIndex = isDoublePageMode ? (Math.floor(pageIndex * 2)) : pageIndex;
  //   let searchRange = catalogMap.filter(item => {
  //     return item.labels.findIndex(labelObj => {
  //       return labelObj.startPage === nowPageIndex
  //     }) > -1;
  //   })
  //   if (searchRange.length > 0) {
  //     const pageLength = searchRange[0].labels.length
  //     setFilterMin(isDoublePageMode ? Math.floor(searchRange[0].startPage / 2) : searchRange[0].startPage);
  //     setFilterMax(isDoublePageMode ? Math.floor(searchRange[0].labels[pageLength - 1].startPage / 2) : searchRange[0].labels[pageLength - 1].startPage);
  //   }
  // }, [book, catalog, isDoublePageMode, pageIndex]);

  const clickBookHandler = (pages) => {
    EventBus.emit({ event: ReaderEvent.ClickBookThumbnailEvent, payload: { pageIndex: pages[0].index } });
    if (isLeftBarShow) {
      EventBus.emit({
        event: ReaderToolsEvent.ToggleSideBarEvent,
        payload: { sideBarType: SideBarType.LEFT }
      });
    } else {
      EventBus.emit({ event: ReaderToolsEvent.TogglePageSearcherEvent });
    }
  }

  return (
    <div className={classnames(styles.pageSearcher)}>
      <div className={styles.text}>頁面選擇</div>
      <div
        ref={thumbnailWrapperRef}
        className={classnames(styles.thumbnailWrapper, {
          [styles.horizontal]: true,
          [styles.rightToLeft]: book.LRFlip === BookFlipType.RIGHT_TO_LEFT,
          [styles.doublePage]: true
        })}>
        {
          Object.values(dataList).map((pages, index) => (
            <div
              ref={thumbnailGroupsRef.current[index]}
              key={index}
              className={classnames(styles.thumbnailGroup, {
                [styles.active]: pageIndex === pages[0].index
              })}>
              <div key={index}
                className={styles.thumbnail}
                onClick={() => clickBookHandler(pages)}
              >
                {
                  setImg(pages)
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
PageSearcherForStudent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default PageSearcherForStudent;
