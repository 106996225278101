import React from 'react';

import classnames from 'classnames'
import styles from './index.module.scss';

const ControlButton = ({ className, children, active , onClick ,...props }) => (
  <button 
    className={classnames(styles.button,className,{
      [styles.active]:active
    })}
    onClick={onClick}
    {...props}
  > 
    {children}
  </button>
);


export default ControlButton;