import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { useStore, StoreTypes } from 'context';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const useAboutTheAuthorCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const execute = useCallback(async ({ json: { src, style } }) => {
    try {
      const aboutTheAuthorInfo = await ExtendedResourceRepository.getJSONContent(
        { bookId, pathName: src }
      );

      const component = ModalComponentGenerator.generate(ModalComponentType.AboutTheAuthor, { aboutTheAuthorInfo, extendedResourceStyle: style });
      setModalComponents(component);
      openModal();
    } catch (e) {
      console.error('SynopsisComponent execute error', e);
    }
  }, [bookId, openModal, setModalComponents]);

  return { execute };
};