import React, { useState } from 'react';
// import BookmarkPanel from './BookmarkPanel'
// import PageMark from './PageMark';
import PageSearcher from 'components/PageSearcher';
import Tab from 'components/Tab';
import styles from './index.module.scss';
const tabData = [
  { id: 0, label: '目次' },
  //{ id: 1, label: '書籤' },
  // { id: 2, label: '標記' },
];
const TableContentsMenu = () => {
  const [currentTabIndex, setTabIndex] = useState(0);
  return (
    <div className={styles.tableContentsMenu}>
      <Tab className={styles.tab} data={tabData} onChange={(index) => setTabIndex(index)} currentIndex={currentTabIndex} />
      {currentTabIndex === 0 ? <PageSearcher vertical /> : null}
      {
        //currentTabIndex === 1 ? <BookmarkPanel /> : null
      }
      {/* {currentTabIndex === 2 ? <PageMark /> : null} */}
    </div>
  )
}

export default TableContentsMenu;
