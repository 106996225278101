import React from 'react';
import {
  ExtendedContentContainer,
  ExtendedContentType
} from 'components/ExtendedContent/ExtendedContentContainer';
import Translation from 'components/Translation';

export const TranslationModalComponent = ({ TranslationResource }) => {
  return (
    <ExtendedContentContainer
      extendedContentType={ExtendedContentType.Translation}
    >
      <Translation data={TranslationResource} />
    </ExtendedContentContainer>
  );
};
