import React, { useEffect, useState } from 'react';
import { ExtendedContentContainer, ExtendedContentType } from 'components/ExtendedContent/ExtendedContentContainer';
import WordLearningGrid from 'components/WordLearningGrid';
import { WordLearningContentWrapper } from 'components/WordLearningGrid/WordLearningContent';
import WordContentList from 'components/WordContentList';
import WordRadical from 'components/WordRadical';
import { ExtensionType } from 'constants/wordGrid';
import { EventBus, EventBusType } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import AnchorAudioButton from 'components/WordExample/AnchorAudioButton';
import * as actionTypes from 'constants/actionTypes';
import classnames from 'classnames'
import styles from './index.module.scss';
import Repository from "repositories/Repository";
const { ExtendedResourceRepository } = Repository;

const WORDING_MAP = {
  meaningSet: '字義教學',
  wordsSet: '語詞教學',
  idiomSet: '生字成語',
  glyphSet: '字音字形'
};

const EXTENSION_TYPE={
  wordsSet:{
    fristName:'語詞',
    otherName:'延伸'
  },
  idiomSet:{
    fristName:'成語',
    otherName:'補充'
  }
}

const convertIdiomResource = data =>
  Object.entries(data).map(([key, item]) => ({ label: key, value: item.RelatedWords[0], relatedWords: item.RelatedWords }))

const convertWordGridResource = data => data.map(item => ({ label: item.Name, value: item.Name, relatedWords: [item.Name] }))
const MeaningContent = ({ data, list }) => {
  const [{
    word,
    extensionType
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);

  const onListChangeHandler = ({ word, relatedWords }) => {

    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType
      }
    })


    word && wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word
      }
    })


    wordGridDispatch({
      type: actionTypes.SET_RELATED_WORDS,
      payload: {
        relatedWords
      }
    })

  }

  useEffect(() => {
    const current = list.filter(item => item.relatedWords.includes(word));
    if (current.length > 0) {
      onListChangeHandler({ relatedWords: current[0].relatedWords })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.meaningContentWrapper}>
      <WordLearningContentWrapper
        data={data}
      />
      <WordContentList
        word={word}
        className={styles.wordContentList}
        data={list}
        onChange={onListChangeHandler}
      />
    </div>
  )
}


const WordLearningGridContainer = ({
  wordGridResource,
  defaultWord,
  idiomResource,
  wordsResource
}) => {
  const [{
    isShowRadicalContent,
    radicalData,
    isShowSubContent,
    word,
    relatedWords,
    extensionType,
    extensionData,
    wordsData,
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);

  const [{ bookId }] = useStore(StoreTypes.books);
  const [extensionList,setExtensionList]=useState([])
  const clearCanvas = () => {
    const eventBusType = EventBusType.ExtendedContent;
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent
    });
  }

  const subContentCloseHandler = () => {
    wordGridDispatch({ type: actionTypes.SET_SUBCONTENT_SHOW, payload: { isShowSubContent: false } })
  }

  const swtichContent = type => {
    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType: type
      }
    })
    clearCanvas();
  }

  const onRadicalCloseHandler = () => {
    wordGridDispatch({ type: actionTypes.SET_RADICAL_CONTENT_SHOW, payload: { isShowRadicalContent: false, radicalData: null } })
  }

  const onScrollToAnchor = tabIndex => {
    wordGridDispatch({
      type: actionTypes.SET_CHANGE_TAB_INDEX,
      payload: {
        tabIndex
      }
    })
  }

  useEffect(() => {
    wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word: defaultWord
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    (async()=>{
      let extensionArray=extensionData[extensionType] || []
      const wordPromise=[]

        //以下註解打開可以測試語詞延伸json資料填寫狀況
        //extensionData.wordsSet[0]={...extensionData.wordsSet[0],type:'語詞'}
        // extensionData.wordsSet[1]={...extensionData.wordsSet[1],type:'延伸'}
        // extensionData.wordsSet[2]={...extensionData.wordsSet[2],type:'延伸'}
  
        // extensionData.wordsSet[0]={...extensionData.wordsSet[0],type:'成語'}
        // extensionData.wordsSet[1]={...extensionData.wordsSet[1],type:'補充'}
        // extensionData.wordsSet[2]={...extensionData.wordsSet[2],type:'補充'}
        extensionArray.forEach((word,index) => {
          wordPromise.push(new Promise(async(resolve, reject)=>{
            const htmlJson = await ExtendedResourceRepository.getJSONContent({
              bookId,
              pathName: word.Content ? word.Content.split(".json")[0] : "",
            });
            if(htmlJson){
              resolve({htmlJson,index,type:word.type})
            }
          }))
        });
    
        await Promise.all(wordPromise).then(result=>{
          console.log(result);
          setExtensionList(result)
        })
    })()
    
  },[bookId,extensionData,extensionType])

  return (
    <>
      <ExtendedContentContainer extendedContentType={ExtendedContentType.WordGrid}>
        <WordLearningGrid dataList={wordGridResource} defaultWord={defaultWord} />
      </ExtendedContentContainer>
      {
        isShowSubContent && (
          <ExtendedContentContainer
            extendedContentType={ExtendedContentType.WordGridSubContent}
            onClose={subContentCloseHandler}
          >
            <div className={styles.wordGridConentContainer}>
              {
                extensionType === ExtensionType.MeaningSet && (
                  <MeaningContent data={extensionData[ExtensionType.MeaningSet]} list={convertWordGridResource(wordsData.filter(item => item.MeaningSet.length > 0))} />
                )
              }
              {
                extensionType === ExtensionType.WordsSet && (
                  <MeaningContent data={extensionData[ExtensionType.WordsSet]} list={convertIdiomResource(wordsResource)} />
                )
              }
              {
                extensionType === ExtensionType.IdiomSet && (
                  <MeaningContent data={extensionData[ExtensionType.IdiomSet]} list={convertIdiomResource(idiomResource)} />
                )
              }
              {
                extensionType === ExtensionType.GlyphSet && (
                  <MeaningContent data={extensionData[ExtensionType.GlyphSet]} list={convertWordGridResource(wordsData.filter(item => item.GlyphSet.length > 0))} />
                )
              }
              <div className={styles.setup}>
                {
                  (extensionType === 'wordsSet' ||extensionType === 'idiomSet') &&extensionList&&<div className={styles.breakAnchor}>
                  {
                    extensionData[extensionType].map((item, index) => {
                      const extension=extensionList.find(e=>e.index===index)
                      if(!extension)return;
                      const {htmlJson}=extensionList.find(e=>e.index===index)

                      //檢查有沒有填寫第一個type屬性  語詞或成語
                      const checkExtensionType=EXTENSION_TYPE[extensionType]?EXTENSION_TYPE[extensionType]:null
                      const checkFristType=checkExtensionType?extensionList.find(e=>e.type===EXTENSION_TYPE[extensionType].fristName):null

                      //檢查json是否沒有填任何type參數
                      const isNoType=extensionList.filter(e=>e.type).length<=0

                      //以下註解打開可以測試語詞延伸json資料填寫狀況
                      //htmlJson.html=item.type==='語詞'?htmlJson.html.replace('解',''):htmlJson.html
                      
                      const explanation=htmlJson.html.includes('解')
                      const example=htmlJson.html.includes('例')
                      const checkFristSetting=(checkFristType||isNoType)?index===0?'fristName':'otherName':'otherName'
                      let sortIndex=index;
                      if(checkFristType){
                        if(index===0){
                          sortIndex=''
                        }
                        if(extensionList.length<=2){
                          sortIndex=''
                        }
                      }else{
                        if(isNoType){
                          if(index===0){
                            sortIndex=''
                          }
                          if(extensionList.length<=2){
                            sortIndex=''
                          }
                        }else{
                          if(extensionList.length<=1){
                            sortIndex=''
                          }else{
                            sortIndex++
                          }
                        }
                      }

                      return (
                        <>
                          {explanation&&<AnchorAudioButton 
                            audioSrc={item.CommentAudio}
                            onScrollToAnchor={() => onScrollToAnchor([index, 0])}
                          >
                            {`${EXTENSION_TYPE[extensionType][checkFristSetting]}解釋${sortIndex}`}
                          </AnchorAudioButton>}
                          {example&&<AnchorAudioButton 
                            audioSrc={item.ExampleAudio}
                            onScrollToAnchor={() => onScrollToAnchor([index, 1])}
                          >
                            {`${EXTENSION_TYPE[extensionType][checkFristSetting]}例句${sortIndex}`}
                          </AnchorAudioButton>}
                        </>
                      )
                    })
                  }
                </div>
                }
                <div className={classnames(styles.controls, {
                  [styles.notWordsSet]: extensionType !== 'wordsSet'
                })}>
                  <div className={styles.words}>
                    {
                      relatedWords.map(item => (
                        <div className={styles.word}>{item}</div>
                      ))
                    }
                  </div>
                  <div className={styles.extensionButtons}>
                    {
                      Object.values(ExtensionType).map(item => {
                        const isDisabled = extensionData[item] && extensionData[item].length < 1;
                        return (
                          <div key={item} className={classnames(styles.button,
                            {
                              [styles.active]: item === extensionType,
                              [styles.disabled]: isDisabled
                            })
                          }
                            onClick={() => {
                              !isDisabled && swtichContent(item)
                            }}>
                            {WORDING_MAP[item]}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </ExtendedContentContainer>
        )
      }
      {
        isShowRadicalContent && (
          <ExtendedContentContainer onClose={onRadicalCloseHandler} extendedContentType={ExtendedContentType.WordRadical}>
            <WordRadical data={radicalData} />
          </ExtendedContentContainer>
        )
      }
    </>
  )
}

export const WordGridModalComponent = ({
  wordGridResource,
  defaultWord,
  wordsResource,
  idiomResource
}) => {
  return (
    <WordLearningGridContainer
      wordGridResource={wordGridResource}
      defaultWord={defaultWord}
      wordsResource={wordsResource}
      idiomResource={idiomResource}
    />
  )
};
