import { PlatformType } from 'constants/platformType';
import { getPlatform } from 'util/platform';
import { createRepository } from './createRepository';

const getFilePort = platform => {
    switch (platform) {
        case PlatformType.Electron:
            return window.fileExpressPort || process.env.REACT_APP_EXPRESS_PORT || 54321;

        case PlatformType.AndroidDev:
            return 8081;
        case PlatformType.AndroidUat:
            return 8082;
        case PlatformType.AndroidRelease:
            return 8080;

        case PlatformType.IosDev:
            return 3001;
        case PlatformType.IosUat:
            return 3002;
        case PlatformType.IosRelease:
            return 3000;

        default:
            return;
    }
}


export const platform = getPlatform();

export const fileExpressHost = process.env.REACT_APP_EXPRESS_HOST || 'localhost';
export const fileExpressPort = getFilePort(platform);

const Repository = createRepository(platform);

export default Repository;
