import { useCallback } from 'react';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

export const useMeasureToolsCommand = () => {
    const execute = useCallback(async ({ json }) => {
        const { measureToolType } = json;
        measureToolType && EventBus.emit({
            event: CanvasEvent.CanvasMeasureToolCreatedEvent,
            payload: { 
              type: measureToolType,
              sendSelectionDispatch:false
            }
          })
    }, []);

    return { execute };
};