import React, { useRef, useEffect, useCallback,useState } from "react";
import MathJax from "react-mathjax2";
import { ExtendedContentButton } from "components/ExtendedContent/ExtendedContentButton";
import { ExtendedContentCanvas } from "components/ExtendedContent/ExtendedContentCanvas";
import { ExtendedFontSizeButton } from "components/ExtendedContent/ExtendedFontSizeButton";

import { ExtendedAnswersButton } from "components/ExtendedContent/ExtendedAnswersButton";
import { ExtendedPartialButton } from "components/ExtendedContent/ExtendedPartialButton";
import Snackbar from "components/Snackbar";
import {
  ExtendedContentTools,
  ExtendedContentContextMenu,
} from "components/ExtendedContent/ExtendedContentTools";
import { EventBus, EventBusType } from "events/EventBus";
import { ExtendedContentEvent } from "../ExtendedContentEvent";
import "./content.scss";
import classnames from "classnames";
import styles from "./index.module.scss";
import { Layout } from "./layout";
import Draggable from "react-draggable";
import { getOSAndBrowser } from "util/getOs";

import Button from "components/Button/index";
import { ReaderZoomType } from "constants/ReaderTools";
import { ReaderToolsEvent } from "events/EventTypes";
import Icon from "components/Icon";
import { ExtendedContentScrollPositionButton, ScrollButtonStateBehavior } from "../ExtendedContentScrollPositionButton";
import { ScrollPositionType } from "constants/scrollPositionType";
import { useStore, StoreTypes } from "context";
import { GroupInteractiveExtendedContentTool } from "components/GroupInteractiveTool/CustomExtendedContentTools";
import ExtendedTldrawContainer from "../../TldrawContainer/ExtendedTldrawContainer";
import { BookFlipType } from "constants/flipTypes";
import { ScrollPositionButtonStateType } from "constants/scrollPositionType";

const eventBusType = EventBusType.ExtendedContent;

export const ExtendedContentType = {
  StaticHtml: "staticHtml",
  IFrame: "iframe",
  Vocabulary: "vocabulary",
  Video: "video",
  WordGrid: "wordGrid",
  WordGlyph: "wordGlyph",
  WordExample: "wordExample",
  Whiteboard: "whiteboard",
  NumberPicker: "numberPicker",
  Synopsis: "synopsis",
  MarkerWithSound: "MarkerWithSound",
  Translation: "translation",
  Hearing: "hearing",
  FlashCard: "flashCard",
  Listening: "listening",
  AboutTheAuthor: "aboutTheAuthor",
  ZhuyinLesson: "zhuyinLesson",
  ZhuyinImage: "zhuyinImage",
  ZhuyinWord: "zhuyinWord",
  WordGridSubContent: "wordGridSubContent",
  WordRadical: "wordRadical",
  AnswerInteractiveTool: "AnswerInteractiveTool",
  PaiNote: "PaiNote",
};

const FontSizeButtonWhiteList = [
  ExtendedContentType.Translation,
  ExtendedContentType.Hearing,
  ExtendedContentType.Vocabulary,
  ExtendedContentType.Listening,
  ExtendedContentType.AboutTheAuthor,
  ExtendedContentType.WordExample,
  ExtendedContentType.ZhuyinLesson,
  ExtendedContentType.WordGridSubContent,
  ExtendedContentType.WordGlyph,
  ExtendedContentType.WordRadical,
];

const AnswersButtonWhiteList = [ExtendedContentType.Listening];

const FullScreenWhiteList = [ExtendedContentType.WordGrid];

const groupInteractivity = [ExtendedContentType.AnswerInteractiveTool, ExtendedContentType.PaiNote];

const getCanvasSvg = (str) => {
  str = str.replace("<svg", '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

export const ExtendedContentContainer = ({
  children,
  extendedResourceStyle,
  extendedContentType,
  onClose,
}) => {
  const contentView = useRef();
  const wrapperRef = useRef();
  const disablePopWindowScale = useRef(true);
  const [{ scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [{ extendedContentTldrawEditor }] = useStore(StoreTypes.tldraw);
  const [
    {
      canvasState,
      canvasHeight,
      canvasWidth,
      fontSize,
      isShowAnswer,
      popWindowScale,
      toolType,
    },
  ] = ExtendedContentEvent(contentView);
  const exContainerRef = useRef(null);
  const popWinStyle = children.props
    ? children.props.extendedResourceStyle
    : null;


  // 判斷word直書樣式，顯示縮放按鈕與否
  useEffect(() => {
    const wordStyle = extendedResourceStyle
      ? extendedResourceStyle.split("_")[0]
      : "";
    if (wordStyle === "WordSection") {
      disablePopWindowScale.current = false;
    }
  }, [extendedResourceStyle]);

  useEffect(() => {
    // 小視窗內容上下頁分段
    // console.log("---E類小視窗---ExtendedContentContainer", div);
    if (!extendedContentTldrawEditor) return;
    
    const { meta: { bookFlipType }} = extendedContentTldrawEditor.getCurrentPageShapes().find((shape) => shape.type === "extendedContent");
    const camera = extendedContentTldrawEditor.getCamera();
    const cameraBounds = extendedContentTldrawEditor.getCameraOptions().constraints.bounds;
    const pageBounds = extendedContentTldrawEditor.getViewportPageBounds();
    
    switch (scrollPosition) {
        case ScrollPositionType.Next: {
          if (bookFlipType === BookFlipType.RIGHT_TO_LEFT) {
            if (camera.x !== 0) {
            extendedContentTldrawEditor.setCamera({ x: camera.x + 500 }, { force: true, animation: { duration: 500 }});
            }
          } else {
            if (Math.ceil(pageBounds.h - cameraBounds.h) === Math.ceil(camera.y)) return;
            extendedContentTldrawEditor.setCamera({ y: camera.y - 500 }, { force: true, animation: { duration: 500 }});
          }    
          break;
        }
        case ScrollPositionType.Prev: {
          if (bookFlipType === BookFlipType.RIGHT_TO_LEFT) {
            if (Math.ceil(pageBounds.w - cameraBounds.w) === Math.ceil(camera.x)) return;
            extendedContentTldrawEditor.setCamera({ x: camera.x - 500 }, { force: true, animation: { duration: 500 }});
          } else {
            if (camera.y !== 0) {
              extendedContentTldrawEditor.setCamera({ y: camera.y + 500 }, { force: true, animation: { duration: 500 }});
            }
          }
          break;
        }
        default: {
          if (bookFlipType === BookFlipType.RIGHT_TO_LEFT) {
            if (Math.ceil(pageBounds.w - cameraBounds.w) === Math.ceil(camera.x)) {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
            } else if (camera.x === 0) {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Bottom)
            } else {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Middle)
            }
          }
      
          if (bookFlipType === BookFlipType.LEFT_TO_RIGHT) {
            if (camera.y === 0) {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Head)
            } else if (Math.ceil(pageBounds.h - cameraBounds.h) === Math.ceil(camera.y)) {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Bottom)
            } else {
              ScrollButtonStateBehavior(ScrollPositionButtonStateType.Middle)
            }
          }
          break;
        }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition, extendedContentTldrawEditor]);

  // useEffect(() => {
  //   if (popWinStyle && popWinStyle.match("WordSection") != null) {
  //     contentView.current.style.direction = "rtl";
  //   }
  // }, [popWinStyle]);

  useEffect(() => {
    if (popWindowScale === 1) {
      wrapperRef.current.style.transform = "";
    }
  }, [popWindowScale]);

  useEffect(() => {
    if (!extendedContentTldrawEditor || extendedContentType !== undefined) return;
    const timer = setTimeout(() => {
      extendedContentTldrawEditor.run(
        () => {
          const extendedContentShape = extendedContentTldrawEditor
            .getCurrentPageShapes()
            .find((shape) => shape.type === "extendedContent");
          const contentDiv = document.querySelector("#tl-extendedContent");
          if (!extendedContentShape || !contentDiv) return;

          if (popWinStyle && popWinStyle.match("WordSection") != null) {
            contentDiv.style.direction = "rtl";
          }

          extendedContentTldrawEditor.updateShape({
            id: extendedContentShape.id,
            type: extendedContentShape.type,
            props: { w: contentDiv.scrollWidth, h: contentDiv.scrollHeight },
            x: 0,
            y: 0,
          });
          extendedContentTldrawEditor.setCameraOptions({
            constraints: {
              behavior: { x: "fixed", y: "inside" },
              bounds: {
                w: contentDiv.scrollWidth,
                h: contentDiv.scrollHeight,
                x: 0,
                y: 0,
              },
              padding: { x: 0, y: 0 },
              origin: { x: 0, y: 0 },
              initialZoom: "fit-x",
              baseZoom: "fit-x",
            },
          });
          extendedContentTldrawEditor.setCamera(
            extendedContentTldrawEditor.getCamera(),
            { reset: true }
          );
        },
        { ignoreShapeLock: true, history: "ignore" }
      );
    }, 500)
    return () => clearTimeout(timer)
  }, [extendedContentTldrawEditor, extendedContentType, popWinStyle]);

  const checkClassName = (className) => {
    switch (className) {
      case "tab":
      case "voca":
      case "list":
        return true;
      default:
        break;
    }
    return false;
  };

  const clearCanvas = useCallback((e) => {
    let tagType = e.target.tagName || '';
    let clearCanvas = false;
    switch (tagType.toLowerCase()) {
      case "div":
        const classNames = e.target.className.split("_");
        clearCanvas = checkClassName(classNames[1]);
        break;
      default:
        break;
    }
    clearCanvas &&
      EventBus.emit({
        eventBusType,
        event: ReaderToolsEvent.ClickEraseAllEvent,
      });
  }, []);

  return (
    <Draggable disabled={popWindowScale === 1 || canvasState.isActive}>
      <div
        ref={wrapperRef}
        onClick={(e) => {
          clearCanvas(e);
        }}
      >
        <div
          id="exContainer"
          className={classnames(
            styles.extendedContentContainer,
            styles[extendedContentType],
            styles[`cursor${toolType}`],
          )}
          ref={exContainerRef}
          style={{ transform: `scale(${popWindowScale})` }}
        >
          <link
            rel="stylesheet"
            type="text/css"
            href={Layout[extendedResourceStyle]}
          />
          {/* 判斷選用樣式“直書”並且作業系統為ＭＡＣ載入此樣式 */}
          {extendedResourceStyle === "WordSection" &&
          getOSAndBrowser() === "Mac" ? (
            <link
              rel="stylesheet"
              type="text/css"
              href="/layout/wordSection/wordSectionForMac.css"
            />
          ) : (
            ""
          )}

          {extendedResourceStyle === "WordSection_108_2" &&
            getOSAndBrowser() !== "Mac" && (
              <link
                rel="stylesheet"
                type="text/css"
                href="/layout/wordSection/wordSection108-2ForWin.css"
              />
            )}
          <div ref={contentView} className={classnames(styles.contentView, fontSize, {[styles.noPadding]:groupInteractivity.includes(extendedContentType)})}>
            {/* {svgContent && !canvasState.isActive && (
              <div
                className={styles.svgContent}
                dangerouslySetInnerHTML={{
                  __html: getCanvasSvg(svgContent),
                }}
                ref={contentRef}
              />
            )}
            <MathJax.Context
              id="target"
              script={`${
                window.electron || window.ios || window.android ? "" : "/"
              }${ExtendedResourceRepository.getMathJaxPath()}?config=TeX-MML-AM_CHTML`}
            >
              <MathJax.Text text={children} />
            </MathJax.Context> */}
            {/* {isContentInit && (
              <ExtendedContentCanvas
                {...{
                  canvasState,
                  canvasDispatch,
                  canvasWidth,
                  canvasHeight,
                }}
              />
            )} */}
            <ExtendedTldrawContainer
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              extendedContentType={extendedContentType}
              content={children}
            />
          </div>
          {/* <ExtendedContentButton el={children} />
          <div className={styles.extendedButtonBox}>
            {FontSizeButtonWhiteList.includes(extendedContentType) && (
              <ExtendedFontSizeButton value={fontSize} />
            )}
            {AnswersButtonWhiteList.includes(extendedContentType) && (
              <ExtendedAnswersButton value={isShowAnswer} />
            )}
          </div> */}
          {groupInteractivity.includes(extendedContentType) && <GroupInteractiveExtendedContentTool extendedContentType={extendedContentType} />}
          {!groupInteractivity.includes(extendedContentType) && <ExtendedContentTools
            {...{
              extendedContentType,
              eventBusType,
              canvasState,
              popWindowScale,
              disablePopWindowScale: disablePopWindowScale.current,
              onClose,
            }}
          >
            {extendedContentType === "translation" && (
              <ExtendedPartialButton {...{ canvasState }} />
            )}
            <ExtendedContentButton el={children} />
            {FontSizeButtonWhiteList.includes(extendedContentType) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {extendedContentType !== "translation" && (
                  <ExtendedContentScrollPositionButton />
                )}
                <ExtendedFontSizeButton value={fontSize} />
              </div>
            )}
            {AnswersButtonWhiteList.includes(extendedContentType) && (
              <ExtendedAnswersButton value={isShowAnswer} />
            )}
            {FullScreenWhiteList.includes(extendedContentType) && (
              <Button
                onClick={() => {
                  EventBus.emit({
                    event: ReaderToolsEvent.SetReaderZoomEvent,
                    payload: { type: ReaderZoomType.OriginZoom },
                  });
                }}
                title="全螢幕"
                className={styles.unfocus}
              >
                <Icon name="AllScreen" />
                <div className={styles.text}>全螢幕</div>
              </Button>
            )}
          </ExtendedContentTools>}
          <ExtendedContentContextMenu {...{ eventBusType, canvasState }} />

          <Snackbar />
        </div>
      </div>
    </Draggable>
  );
};
