import React, { useRef, useCallback } from 'react';
import Icon from 'components/Icon';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import { SideBarType } from 'constants/ReaderTools';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import { EventBus, EventBusType } from 'events/EventBus';

const SidePanel = ({
  direction = SideBarType.LEFT,
  children
}) => {

  const panelRef = useRef(null);

  const onClose = useCallback(()=>{
    EventBus.emit({ eventBusType: EventBusType.Default, event: CanvasEvent.CanvasSelectionClearEvent });
  },[])

  useOutsideAlerter(panelRef, onClose);

  return (
    <div className={classnames(styles.sidePanel,styles[direction])}>
      <div className={styles.closeBtn} onClick={onClose}>
        <Icon type="text" name="times" />
      </div>
      {children}
    </div>
  )
};

export default SidePanel;