import React, { useCallback } from 'react';
import LineWidthSlider from 'components/Painter/LineWidthSlider';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

import styles from './index.module.scss';

const LineSelector = ({ target: object }) => {
  const setStrokeWidthHandler = useCallback((e, lineWidth) => {
    if (object) {
      EventBus.emit({
        event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
        payload: { object, property: 'strokeWidth', value: lineWidth }
      });
    }
  }, [object]);

  return (
    <div className={styles.line_box}>
      <div className={styles.title}>線段</div>
      <div className={styles.content_box}>
        <div className={styles.content}>
          <select>
            <option value=''>—————————</option>
            <option value=''>‥‥‥‥‥‥‥‥‥‥‥‥‥‥</option>
          </select>
        </div>
        <div className={styles.lineWidthSlide}>
          <LineWidthSlider value={object.strokeWidth} onChange={setStrokeWidthHandler} />
        </div>
      </div>
    </div>
  );
}
export default LineSelector;
