import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { withTranslation } from 'react-i18next';

const BasicReaderToolComponent = ({ t, type, clickHandlerParameters = [] }) => {
    return (
        <Button
            key={type}
            onClick={() => toolsEventMap[type].clickHandler(...clickHandlerParameters)}
            title={t(toolsEventMap[type].title)}
        >
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(BasicReaderToolComponent);
