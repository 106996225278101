import { useEffect } from 'react';
import useSetState from './setState';
import {debounce} from 'util/debounce';

const useScroll = ref => {
  const element = ref.current;
  const [state, setState] = useSetState({
    x: 0,
    y: 0
  });

  useEffect(() => {
    const handler = () => {
      if (element) {
        setState({
          x: element.scrollLeft,
          y: element.scrollTop
        });
      }
    };

    if (element) {
      element.addEventListener('scroll', debounce(handler,500));
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handler);
      }
    };
  }, [element, setState]);

  return state;
};

export default useScroll;
