import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useStickyNoteCommand = () => {
    const [, { openModal, setModalComponents }] = useModal();
    const execute = useCallback(async ({ canvasSVGObject }) => {
        try {
            const component = ModalComponentGenerator.generate(ModalComponentType.StickyNote, { canvasSVGObject });
            setModalComponents(component);
            openModal();
        } catch (e) {
            console.error('InternalWindowCommand execute error', e);
        }
    }, [openModal, setModalComponents]);
    return { execute };
};
