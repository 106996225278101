import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

export const initState = {
  courseId: '',
  timeSpanId: '',
  startAt: '',
  endAt: '',
  organizationId: null,
  remoteZoomInfo: null,
  firestore: null,
  chimeRole: '',
  remoteId: null,
  onConnected: false
};

const courseReducer = (state, action) => {
  switch (action.type) {
    case types.SET_COURSE_INFO:
      const { id, timeSpanId, startAt, endAt, organizationId, remoteId } = action.courseInfo;
      return { ...state, courseId: id, timeSpanId, startAt, endAt, organizationId, remoteId };
    case types.SET_REMOTE_ZOOM_INFO:
      return { ...state, remoteZoomInfo: action.remoteZoomInfo };
    case types.SET_COURSE_FIRESTORE:
      return { ...state, firestore: action.firestore };
    case types.SET_CHIME_ROLE:
      return { ...state, chimeRole: action.chimeRole };
    case types.SET_ONCONNECTED:
      return { ...state, onConnected: action.onConnected };
    default:
      return state;
  }
};

const useCourseReducer = () => useEnhanceReducer(courseReducer, initState);

export default useCourseReducer;
