import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';

import {withTranslation} from 'react-i18next';

import { active } from '../ActivatableReaderToolComponent';

const ReaderToolInsertComponent = ({ t, currentReaderToolType, type }) => {
    return (
        <Button 
            title={t(toolsEventMap[type].title)}
            key={type} 
            inlineStyle={currentReaderToolType === type && active} 
            onClick={toolsEventMap[type].clickHandler}
        >
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolInsertComponent);
