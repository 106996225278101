import React from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const Select = ({ className, onChange, value, children }) => {
  return (
    <div className={classnames(styles.selectWrapper,className)}>
      <select 
        className={styles.select} 
        onChange={onChange} 
        value={value}
      >
        {children}
      </select>
      <div className={styles.icon}>
        <Icon type="text" name="caretDown"/>
      </div>
    </div>
  )
};

export default Select;
