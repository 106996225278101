import events from 'events';
import { useEffect } from 'react';

const MAX_LISTENERS = 100;

export const EventBusType = {
    Default: 'default',
    ExtendedContent: 'extendedContent',
};

export const EventBus = new (function() {
    this.emitters = [];

    for (let type in EventBusType) {
        let emitter = new events.EventEmitter();
        emitter.setMaxListeners(MAX_LISTENERS);
        this.emitters[EventBusType[type]] = emitter;
    }

    this.emitter = (eventBusType = EventBusType.Default) => this.emitters[eventBusType];

    this.addListener = ({ eventBusType = EventBusType.Default, event, handler }) => {
        let emitter = this.emitter(eventBusType);
        if (!emitter.listeners(event).includes(handler)) {
            emitter.addListener(event, handler);
        }
    }

    this.removeListener = ({ eventBusType = EventBusType.Default, event, handler }) => {
        let emitter = this.emitter(eventBusType);
        emitter.removeListener(event, handler);
    }

    this.emit = ({ eventBusType = EventBusType.Default, event, payload }) => {
        let emitter = this.emitter(eventBusType);
        emitter.emit(event, payload);
    };
})();

export const useEvent = (event, handler) => {
    useEffect(() => {
        if (Array.isArray(event)) {
            event.forEach(e => {
                EventBus.addListener({ ...e, handler });
            });
        } else {
            EventBus.addListener({ ...event, handler });
        }

        return () => {
            if (Array.isArray(event)) {
                event.forEach(e => {
                    EventBus.removeListener({ ...e, handler });
                });
            } else {
                EventBus.removeListener({ ...event, handler });
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handler]);
};
