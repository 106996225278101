import React, { useState, useEffect, useRef, useCallback } from "react";
// import ReactDOM from "react-dom";
import { EASINGS, Tldraw, createShapeId, Box, DefaultColorThemePalette } from "tldraw";
import { useStore, StoreTypes } from "context";
import { groupPages, preserveSVGAspectRatio } from "util/book";
import { useFetchBooks } from "customHooks/book";
import { BookFlipType } from "constants/flipTypes";
import { TLDrawPainterEventType } from "constants/painterTypes";
import { InteractiveObject } from "./CustomShapes/InteractiveObject";
import { BookPage } from "./CustomShapes/BookPage";
import {
  ReaderToolType,
  ReaderZoomType
} from 'constants/ReaderTools';
import { TLDrawEvent } from 'events/EventTypes';
import { useEvent, EventBus } from 'events/EventBus';
import { DefaultPanelColors } from 'constants/colors';

// function useTick(isEnabled = true) {
// 	const [_, setTick] = useState(0)
// 	const editor = useEditor()
// 	useEffect(() => {
// 		if (!isEnabled) return
// 		const update = () => setTick((tick) => tick + 1)
// 		editor.on('tick', update)
// 		return () => {
// 			editor.off('tick', update)
// 		}
// 	}, [editor, isEnabled])
// }

let pageData = []
let isPreparedPage = false;
let isPreparedDrawShapes = false;
let color = DefaultPanelColors[1]
const customShape = [InteractiveObject, BookPage];

const TldrawContainer = ({ book }) => {
  const [{ bookContent, interactiveObjectSVG, interactiveObjectJSON }] =
    useStore(StoreTypes.books);
  const [{ scale, isDoublePageMode, isMarkModeShow, pageIndex, fullWidthInfo }] = useStore(
    StoreTypes.reader
  );
  const [{ tldrawEditor }, tldrawDispatch] = useStore(StoreTypes.tldraw);
  const { fetchBook } = useFetchBooks();
  const [
    {
      fullWidthInfo: { mode: fullWidthMode },
    },
  ] = useStore(StoreTypes.reader);
  const { bookId, pageInfos, LRFlip } = book;

  const [bookPages, setBookPages] = useState([]);
  // const [editor, setEditor] = useState();
  const readerView = document.querySelector("#readerView");

  let width = 500;
  let height = 500;

  if (fullWidthMode) {
    width = readerView?.clientWidth;
    height = readerView?.clientHeight;
  } else {
    // TODO: 符合書本比例時，該以長為基準，還是寬為基準，以及對應長寬的計算，需要重新思考。尤其是螢幕超大，高 3000px ，而書高（book.height）是 1000px 的情況。
    if (
      book.height > readerView?.clientHeight &&
      readerView?.clientHeight * ((book.width * 2) / book.height) <
      readerView?.clientWidth
    ) {
      width = readerView?.clientHeight * ((book.width * 2) / book.height);
      height = readerView?.clientHeight;
    } else {
      width = readerView?.clientWidth;
      height = readerView?.clientWidth * (book.height / (book.width * 2));
    }
  }

  useEffect(() => {
    let pages = [];
    const threshold = 3;
    if (isDoublePageMode) {
      let startPage = Math.max(0, pageIndex * 2 - threshold * 2);
      let endPage = Math.min(
        pageIndex * 2 + 1 + threshold * 2,
        pageInfos.length - 1
      );
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(0, pageIndex - threshold);
      let endPage = Math.min(pageIndex + threshold, pageInfos.length - 1);
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    }
    pages = pages.filter((page) => !bookContent[page]);
    if (pages.length > 0) {
      fetchBook({ bookId, pages });
    }
  }, [pageIndex, fetchBook, bookId, pageInfos, bookContent, isDoublePageMode]);

  useEffect(() => {
    const groupBookPages = groupPages(bookContent, isDoublePageMode);
    if (!groupBookPages[pageIndex]) return;

    setBookPages(groupBookPages[pageIndex]);

  }, [bookContent, isDoublePageMode, pageIndex, tldrawEditor]);

  useEffect(() => {
    if (!tldrawEditor || bookPages.length === 0) return;
    if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
      bookPages.reverse();
    }
    isPreparedPage = false;
    isPreparedDrawShapes = false;
    tldrawEditor.run(
      () => {
        if (!isPreparedPage) {
          tldrawEditor.deleteShapes(tldrawEditor.getCurrentPageShapes());

          bookPages.forEach((page, index) => {
            const shapeId = createShapeId(`bookPage-${page.html}`);
            tldrawEditor.createShape({
              id: shapeId,
              type: "bookPage",
              x: index ? width / 2 : 0,
              y: 0,
              isLocked: true,
              props: {
                w: width / 2,
                h: height,
                svg: page.svg,
                bookInfo: book,
              },
              meta: {
                pageIndex,
                isDoublePageMode,
                bookFlipType: LRFlip,
              },
            });
            tldrawEditor.sendToBack([shapeId]);

            const interactiveObjectId = createShapeId(`interactiveObject-${page.pageIndex}`);
            tldrawEditor.createShape({
              id: interactiveObjectId,
              type: "interactiveObject",
              x: index ? width / 2 : 0,
              y: 0,
              isLocked: true,
              props: {
                svg: interactiveObjectSVG[page.pageIndex] || "",
                json: interactiveObjectJSON[page.pageIndex] || {},
                bookInfo: book,
                w: width / 2,
                h: height,
              },
              meta: {
                bookFlipType: LRFlip,
                pageIndex: page.pageIndex,
                isDoublePageMode,
              },
            });
          });

          const data = pageData[pageIndex] || []

          data.forEach(shape => {
            tldrawEditor.createShape(JSON.parse(shape)); // 新增 shape 到畫布
          });
        }
      },
      { history: "ignore", ignoreShapeLock: true }
    );

    tldrawEditor.getCurrentPageShapes().forEach(shape => {
      const specificShape = document.getElementById(`${shape.id}`);
      if (specificShape) {
        const pathElement = specificShape.querySelector('path'); // 找到 <path> 元素
        if (pathElement) {
          pathElement.setAttribute('fill', shape.meta.color); // 設定新的填充顏色
        }
      }
    });
    isPreparedPage = true;
    isPreparedDrawShapes = true;
  }, [
    pageIndex,
    width,
    height,
    book,
    bookPages,
    tldrawEditor,
    isDoublePageMode,
    LRFlip,
    interactiveObjectSVG,
    interactiveObjectJSON,
  ]);

  useEffect(() => {
    isPreparedPage = false;
  }, [pageIndex])

  useEffect(() => {
    if (!tldrawEditor || !width || !height) return;
    const resizeShapes = () => {
      tldrawEditor.run(() => {
        const shapes = tldrawEditor.getCurrentPageShapes();
        shapes.forEach((shape) => {
          if (shape.type === "bookPage" || shape.type === "interactiveObject") {
            tldrawEditor.updateShape({
              id: shape.id,
              type: shape.type,
              props: { w: width / 2, h: height },
              x: shape.x ? width / 2 : 0,
              y: 0,
            });
          }
        });
      }, { ignoreShapeLock: true, history: "ignore" });
    };
    tldrawEditor.run(
      () => {
        tldrawEditor.setCameraOptions({
          constraints: {
            initialZoom: "fit-max",
            baseZoom: "fit-max",
            bounds: { w: width, h: height, x: 0, y: 0 },
            padding: { x: 0, y: 0 },
            origin: { x: 0, y: 0 },
            behavior: "contain",
          },
          zoomSteps: [1, 2, 4, 8],
          zoomSpeed: 1,
          panSpeed: 1,
          isLocked: false,
        });
        tldrawEditor.setCamera(tldrawEditor.getCamera(), { reset: true });
        resizeShapes();
      },
      { history: "ignore" }
    );
  }, [tldrawEditor, width, height]);


  const tldrawEventHandler = useCallback((e) => {
    if (!tldrawEditor) return;
    switch (e.type) {
      case ReaderToolType.Painting:
        tldrawEditor.setCurrentTool('draw')
        break;
      case ReaderToolType.Select:
        tldrawEditor.setCurrentTool('select')
        break;
      case ReaderToolType.Drag:
        tldrawEditor.setCurrentTool('hand')
        break;
      case ReaderToolType.Eraser:
        tldrawEditor.setCurrentTool('eraser')
        break;
      case ReaderZoomType.OriginZoom:
        tldrawEditor.zoomToFit();
        break;
      case TLDrawPainterEventType.SetPenColor:
        color = e.color
        DefaultColorThemePalette.lightMode.black.solid = e.color
        break;
      default:
        break;
    }
  }, [tldrawEditor, fullWidthInfo, pageIndex])

  useEvent({ event: TLDrawEvent.TLDrawEvent }, tldrawEventHandler);



  return (
    <div
      style={{
        position: "fixed",
        width: width,
        height: height,
        inset: 0,
        margin: "0 auto",
      }}
      id="tlContainer"
    >
      <Tldraw
        shapeUtils={customShape}
        components={{
          DebugPanel: false,
          ZoomMenu: false,
          NavigationPanel: false,
          PageMenu: false,
        }}
        onMount={(editor) => {
          editor.setCurrentTool("draw");

          tldrawDispatch({ type: "SET_TLDRAW_EDITOR", payload: editor });
          editor.sideEffects.registerAfterChangeHandler('shape', (newShape) => {
            if (!isPreparedPage || !isPreparedDrawShapes) return;

            const index = editor.getCurrentPageShapes().find(shape => shape.type === 'bookPage').meta.pageIndex;
            if (!pageData[index]) {
              pageData[index] = [];
            }

            const shapeData = {
              id: newShape.id,
              type: newShape.type,
              props: newShape.props,
              x: newShape.x,
              y: newShape.y,
              meta: { ...newShape.meta, color }
            };

            const existingShapeIndex = pageData[index].findIndex(shape => shape.id === newShape.id);
            if (existingShapeIndex !== -1) {
              pageData[index][existingShapeIndex] = shapeData;
            } else {
              pageData[index].push(JSON.stringify(shapeData));
            }
          });

        }}
      ></Tldraw>
    </div>
  );
};

export default TldrawContainer;
