import React from "react";
import {
  ExtendedContentContainer,
  ExtendedContentType,
} from "components/ExtendedContent/ExtendedContentContainer";
import GroupInteractiveTool from "components/GroupInteractiveTool";
import { GroupInteractivetToolLoading } from "components/GroupInteractiveTool/Loading";

export const AnswerInteractiveToolComponent = ({ canvasSVGObject }) => {
  
  return (
    <>
      <ExtendedContentContainer
        extendedContentType={ExtendedContentType.AnswerInteractiveTool}
      >
        <GroupInteractiveTool extendedContentType={ExtendedContentType.AnswerInteractiveTool} />
      </ExtendedContentContainer>
      <GroupInteractivetToolLoading />
    </>
  );
};
