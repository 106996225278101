import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { AnnotationType } from 'constants/annotationTypes';

export const initState = {
    annotationId: '',
    annotationType: '',
    name: '',
    marks: [],
    markObject: {},
    activityInfo: {
        isActivityPanelShow: false,
        firestore: null,
        seats: [],
        ownerId: "",
        attendeeNumber: "",
        activityTarget: ""
    },
};

const updateAnnotationInfo = (state, action) => {
    const { annotationId = state.annotationId, annotationType = state.annotationType, name = state.name, marks = state.marks } = action;
    return { annotationId, annotationType, name, marks };
};

const updateActivityInfo = (state, action) => {
    const {
        activityId: annotationId = state.annotationId,
        annotationType = AnnotationType.ACTIVITY,
        name = state.name,
        ownerId = state.activityInfo.ownerId,
        attendeeNumber = state.activityInfo.attendeeNumber,
        activityTarget = state.activityInfo.activityTarget,
    } = action;
    const activityInfo = { ...state.activityInfo, ownerId, attendeeNumber, activityTarget };

    return { annotationId, annotationType, name, activityInfo };
};

const activityReducer = (state, action) => {
    switch (action.type) {
        case types.UPDATE_ANNOTATION_INFO:
            return { ...state, ...updateAnnotationInfo(state, action) };
        case types.UPDATE_ACTIVITY_INFO:
            return { ...state, ...updateActivityInfo(state, action) };
        case types.TOGGLE_ACTIVITY_PANEL:
            const { enable: isActivityPanelShow = !state.activityInfo.isActivityPanelShow } = action;
            return { ...state, activityInfo: { ...state.activityInfo, isActivityPanelShow } };
        case types.SET_SEATS:
            return { ...state, activityInfo: { ...state.activityInfo, seats: action.seats } };
        case types.SET_FIRESTORE:
            return { ...state, activityInfo: { ...state.activityInfo, firestore: action.firestore } };
        case types.RESET_ANNOTATION_INFO:
            return {
                ...state,
                annotationId: initState.annotationId,
                annotationType: initState.annotationType,
                name: initState.name,
                marks: initState.marks,
                activityInfo: { ...initState.activityInfo, firestore: state.activityInfo.firestore }
            };
        case types.SET_MARK_OBJECT:
            return { ...state, markObject: action.markObject };
        case types.ADD_MARK_OBJECTS:
            return { ...state, marks: [...state.marks, action.markObject] };
        case types.UPDATE_MARK_OBJECTS:
            return { ...state, marks: action.marks };
        default:
            return state;
    }
};

const useActivityReducer = () => useEnhanceReducer(activityReducer, initState);

export default useActivityReducer;
