import React from 'react';
import Input from 'components/common/Input';
import styles from './index.module.scss';


export const VocabularyComponent = ({ object, saveProperty }) => {

  let vocabularyInfo = object.vocabularyInfo ? JSON.parse(object.vocabularyInfo) : {  word: '' }

  const changeHandler = infoType => e => {
    vocabularyInfo[infoType] = e.target.value;
    saveProperty(JSON.stringify(vocabularyInfo))
  };

  return (
    <>
      <Input className={styles.vocabularyInput} label="Word" value={vocabularyInfo.word} onChange={changeHandler('word')} />
    </>
  )
};