import React from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { useStore, StoreTypes } from 'context';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import * as types from 'constants/actionTypes';

import { withTranslation } from 'react-i18next';

import { gaEventReaderToolsClick } from 'components/AppService/GAService'
import { ReaderToolTypeDisplayMap } from 'constants/contentType';
import { CategoryType } from 'constants/gaEventType';
import { interactivityApi } from 'components/GroupInteractiveTool/api';
import { ActivityStatus } from 'constants/groupInteractivity';
import { ActivityState } from 'constants/groupInteractivity';



const ReaderToolBookshelf = ({ t, type }) => {
    const router = useStore(StoreTypes.router);
    const [{ groupInteractivity: { activityInfo } },globalDispatch] = useStore(StoreTypes.global);
    const [{ readerToolType }] = useStore(StoreTypes.reader);
    const [{ token }] = useStore(StoreTypes.user);
    const notNotify = localStorage.getItem('notifySync');
    const clickHandler = () => {
        gaEventReaderToolsClick({
            category: CategoryType.ReaderTool,
            action: ReaderToolType.Bookshelf,
            label: ReaderToolType.Bookshelf
          });


        // 清空團班工具
        if (activityInfo.id) {
          interactivityApi[readerToolType]
            .update(token, {
              teacherId: activityInfo.teacherId,
              id: activityInfo.id,
              status: ActivityStatus.Done,
            })
            .then((res) => {
              if (res.status === "success") {
                globalDispatch({
                  type: types.CHANGE_GROUP_INTERACTIVITY_STATE,
                  payload: ActivityState.Create,
                });
                globalDispatch({
                  type: types.RESET_GROUP_INTERACTIVITY_INFO,
                });
              }
            })
            .catch((err) => console.log("end GroupInteractiveTool error", err));
        }
          
        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: ReaderToolType.Drag
            }
        });

        EventBus.emit({
            event: ReaderToolsEvent.ClickDragEvent
        });
        EventBus.emit({
            event: ReaderToolsEvent.ClickReaderToolBoxEvent,
            payload: {
              isToolboxShow: false
            }
          })
        if (window.electron && notNotify === null) {
            globalDispatch({ type: types.OPEN_SYNCNOTIFY_DIALOG });
        } else {
            router.history.push('/bookshelf');
        }
    }
    return (
        <Button key={type} onClick={clickHandler}>
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolBookshelf);
