import React, { useEffect, useCallback } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import useSetState from 'customHooks/setState';
import Bopomofo from 'components/common/Bopomofo';
import Icon from 'components/Icon';

const getPageCount = () => {
  const windowWidth = window.innerWidth;
  if (1920 < windowWidth) return 30
  if (1680 < windowWidth && windowWidth <= 1920) return 22;
  if (1440 < windowWidth && windowWidth <= 1680) return 19;
  if (1280 < windowWidth && windowWidth <= 1440) return 16;
  if (1024 < windowWidth && windowWidth <= 1280) return 13;
  if (640 < windowWidth && windowWidth <= 1024) return 10;
  return 4
}


const WordContentList = ({
  zhuyin = false,
  word,
  className,
  data = [],
  onChange = () => { }
}) => {
  const [{ dataSet, currentPageIndex }, setState] = useSetState({
    dataSet: null,
    currentPageIndex: 0
  })
  const maxPage = dataSet ? Object.keys(dataSet).length - 1 : 0;

  const groupByPage = useCallback((data) => {
    const dataMap = {};
    let pageIndex = -1; // for start to index 0

    data.forEach((item, index) => {
      if (index % getPageCount() === 0) {
        pageIndex += 1;
      }

      if (!dataMap[pageIndex]) dataMap[pageIndex] = [];
      dataMap[pageIndex].push(item);
      if (item.relatedWords.includes(word)) {
        setState({ currentPageIndex: pageIndex })
      }
    })
    return dataMap
  }, [setState, word])


  useEffect(() => {
    if (dataSet) return;
    const nextDataSet = groupByPage(data)
    setState({ dataSet: nextDataSet })
  }, [data, dataSet, groupByPage, setState])

  const prevPage = () => {
    setState({ currentPageIndex: Math.max(currentPageIndex - 1, 0) })
  };

  const nextPage = () => {
    setState({ currentPageIndex: Math.min(currentPageIndex + 1, maxPage) })
  }

  return (
    <div className={classnames(styles.wordContentList, className, {
      [styles.zhuyin]: zhuyin
    })}>
      <div className={classnames(styles.controls, {
        [styles.disabled]: currentPageIndex === maxPage
      })} onClick={nextPage}>
        <Icon type="text" name="angleLeft" />
      </div>
      <div className={styles.wordList}>
        {
          dataSet && dataSet[currentPageIndex].map((item, index) => {
            return <div key={index} className={classnames(styles.word, {
              [styles.zhuyin]: zhuyin,
              [styles.active]: item.relatedWords.includes(word)
            })} onClick={() => onChange({ word: item.value, relatedWords: item.relatedWords || null })}>
              {
                zhuyin && Array.isArray(item.label) ? (
                  <>
                    {
                      item.label.map(item => (
                        <Bopomofo
                          zhuyin={item}
                        />
                      ))
                    }
                  </>
                ) : item.label
              }
            </div>
          })
        }
      </div>
      <div className={classnames(styles.controls, {
        [styles.disabled]: currentPageIndex === 0
      })} onClick={prevPage}>
        <Icon type="text" name="angleRight" />
      </div>
    </div>
  )
}


export default WordContentList;