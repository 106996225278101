import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const CheckBox = ({
  className,
  name,
  checked,
  children,
  onChange = () => { },
  disabled
}) => {
  return (
    <label className={classnames(styles.checkBox, className)}>
      <input
        disabled={disabled}
        className={styles.input}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <span className={styles.check}></span>
      <span className={styles.label}>{children}</span>
    </label>
  )
};


export default CheckBox;