import { API } from 'api';
import { replaceBookPage } from 'util/book';
import { Roles } from 'constants/role';
import { isExist } from 'util/helper'
import queryString from 'query-string';

import { IBookContentRepository } from './IBookContentRepository';

function CloudBookContentRepository() { };
CloudBookContentRepository.prototype = Object.create(IBookContentRepository);

const InteractivityStudentInteractiveObjectBlackList = {
    GoPage: 'GoPage',
    NumberPicker: 'NumberPicker',
    PageWidth: 'PageWidth',
    ControlDisplay: 'ControlDisplay',
    ControlStage: 'ControlStage',
  };

CloudBookContentRepository.prototype.fetchBooks = async ({ token, bookIds = [] }) => {
    let books = [];
    try {
        const payload = (token && { jwt:token }) || {};
        payload.bookIds = bookIds;
        const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getBooks`, payload);
        if (response.status === 'success') {
            const promises = response.content.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            books = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBooks error', err);
    }
    return books;
};

CloudBookContentRepository.prototype.fetchBookList = async (page = 1, tags = []) => {
    const bookList = {
        lists: [],
        total: 0,
        currentPage: 0,
        currentIndex: page - 1
    };
    const skip = 10 * bookList.currentIndex;
    const limit = 10;
    let listUrl = `${process.env.REACT_APP_BOOKSTORE_API_DOMAIN}/books?skip=${skip}&limit=${limit}&orderBy=publishedAt&isPublish=true`;
    if (tags.length !== 0) {
        const tagsId = tags.map(tag => tag.tagId);
        const tagParameters = `&${queryString.stringify({ tags: tagsId }, { arrayFormat: 'bracket' })}`;
        listUrl = listUrl + tagParameters;
    }
    try {
        const response = await fetch(listUrl, {
            method: 'GET'
        })
            .then(res => res.json())
            .catch(err => console.error(`fetchBookList`, err));

        if (response.status === 'success') {
            const promises = response.data.books.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            bookList.total = response.data.total;
            bookList.lists = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBookList error', err);
    }
    return bookList;
};

CloudBookContentRepository.prototype.fetchShelfBook = async ({ token, bookIds = [] }) => {
    let bookList = [];
    const skip = 0;
    const limit = 100;
    const parameters = `?skip=${skip}&limit=${limit}&${queryString.stringify({ bookIds: bookIds }, { arrayFormat: 'bracket' })}`;
    const fetchUrl = `${process.env.REACT_APP_BOOKSTORE_API_DOMAIN}/books${parameters}`;
    try {
        const options = (token && { headers: { Authorization: token } }) || {};
        const response = await fetch(fetchUrl, {
            method: 'GET',
            ...options
        })
            .then(res => res.json())
            .catch(err => console.error(`fetchBook`, err));

        if (response.status === 'success') {
            const promises = response.data.books.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            bookList = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBook error', err);
    }
    return bookList;
};

CloudBookContentRepository.prototype.getPurchasedProducts = async ({ token }) => {
    let books = [];
    try {
        const options = (token && { headers: { Authorization: token } }) || {};
        const response = await API.cloudFuncGet(`${process.env.REACT_APP_API_DOMAIN}/products/purchased`, options);
        if (response.status === 'success') {
            const promises = response.data.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            books = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBooks error', err);
    }
    return books;
};

CloudBookContentRepository.prototype.getBookContent = async ({ book, pages = [] }) => {
    let results = [];
    try {
        const pageUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${book.bookId}/`;
        results = await Promise.all(book.pageInfos.filter(page => pages.length === 0 || pages.includes(page.pageIndex)).map(async page => {
            let str = await API.getText(pageUrl + page.html);
            return replaceBookPage({ page, pageUrl, str });
        }));
    } catch (err) {
        console.error('getBookContent error', err);
    }
    return results;
};

CloudBookContentRepository.prototype.getInteractiveObjects = async ({ interactiveObjectId, pages }) => {
    let interactiveObjects = {
        interactiveObjectJSON: {},
        interactiveObjectSVG: {}
    };
    try {
        let results = await Promise.all(pages.map(async page => {
            try {
                const result = await API.getJSON(`${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/interactiveObjects/${interactiveObjectId}/${page}.dat`);
                return result.rawData;
            } catch {
                return null;
            }
        }));
        results = results.filter(result => result);
        results.forEach(({ pageIndex, json, svg }) => {
            interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
                acc[v.id] = v;
                return acc;
            }, {});
            interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
        });

        // const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getInteractiveObjects`, { interactiveObjectId, pages });
        // if (response.status === 'success') {
        //     response.content.forEach(({ pageIndex, json, svg }) => {
        //         interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
        //             acc[v.id] = v;
        //             return acc;
        //         }, {});
        //         interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
        //     });
        // }
    } catch (err) {
        console.error('getInteractiveObjects error', err);
    }

    return interactiveObjects;
};

CloudBookContentRepository.prototype.getThumbnailUrls = ({ bookId, pages }) => {
    return pages.reduce((acc, page) => {
        acc[page] = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/thumbnails/${page}.jpg`;
        return acc;
    }, {});
};

CloudBookContentRepository.prototype.getCoverUrls = ({ bookId }) => {
    return `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/cover.jpg`;
};

CloudBookContentRepository.prototype.getBookCatalog = async ({ bookId }) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/catalog.json`);
        const result = await res.json();
        return result.content.books;
    } catch (err) {
        console.error('getBookCatalog error', err);
    }
};

CloudBookContentRepository.prototype.getIsShowInteractiveObject = ({ role, interactiveObject }) => {
    if (role === Roles.EDITOR) return true;
    return typeof interactiveObject.isWebVisible === 'undefined' ? true : interactiveObject.isWebVisible
}

CloudBookContentRepository.prototype.getHiddenInteractiveObjectForStudent = ({ interactiveObject }) => {
    return Object.keys(InteractivityStudentInteractiveObjectBlackList).includes(interactiveObject.contentType) 
}

CloudBookContentRepository.prototype.fetchTag = async () => {
    let tags = [];
    const getTagUrl = `${process.env.REACT_APP_BOOKSTORE_API_DOMAIN}/tags`;
    try {
        const response = await fetch(getTagUrl, {
            method: 'GET'
        })
            .then(res => res.json())
            .catch(err => console.error(`fetchTag`, err));
        if (response.status === 'success') {
            const promises = response.data;

            tags = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchTag error', err);
    }
    return tags;
};

export default new CloudBookContentRepository();
