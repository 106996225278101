import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './App';
import './index.scss'

window.android = process.env.REACT_APP_PLATFORM === 'android-dev' || process.env.REACT_APP_PLATFORM === 'android-uat' || process.env.REACT_APP_PLATFORM === 'android-release';
window.ios = process.env.REACT_APP_PLATFORM === 'ios-dev' || process.env.REACT_APP_PLATFORM === 'ios-uat' || process.env.REACT_APP_PLATFORM === 'ios-release';

const root = document.getElementById('root');


if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default; // eslint-disable-line global-require
        ReactDOM.render(<NextApp />, root);
    });
}


ReactDOM.render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
