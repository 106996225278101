import React, { useEffect } from 'react';
import path from 'path';
import { useStore, StoreTypes } from 'context';
import useSetState from 'customHooks/setState';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { BookFlipType } from 'constants/flipTypes';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { groupPages } from 'util/book';
import Icon from 'components/Icon';
import styles from './index.module.scss';

const ReaderToolPageSearcherForStudent = ({ t, type, clickHandlerParameters: [book, LRFlip] = [] }) => {
    const [{ books, bookId }] = useStore(StoreTypes.books);
    const [{ pageIndex, paiNotePage }] = useStore(StoreTypes.reader);

    book = books.find(item => item.bookId === bookId);

    return (
        <>
            <div className={styles.pageSearcher} key={type}>
                <div className={classnames(styles.button, styles.prev)}
                    onClick={() => toolsEventMap[type].clickLeftHandler(book, LRFlip)}>
                    <Icon type="text" name="angleLeft" />
                </div>
                <div className={styles.pageInfo}
                    onClick={() => toolsEventMap[type].clickHandler()}>{`${paiNotePage.findIndex(item => item === pageIndex) + 1} / ${paiNotePage.length}`}</div>
                <div className={classnames(styles.button, styles.next)}
                    onClick={() => toolsEventMap[type].clickRightHandler(book, LRFlip)}>
                    <Icon type="text" name="angleRight" />
                </div>
            </div>
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </>
    );
};

export default withTranslation()(ReaderToolPageSearcherForStudent);
