import React, { useRef } from 'react';

import { useStore, StoreTypes } from 'context';
import Icon from 'components/Icon';

import { useOutsideAlerter } from 'customHooks/outsideAlerter';


import classnames from 'classnames';
import styles from './index.module.scss';
import {
  MeasureToolType
} from 'constants/painterTypes';
import { EventBus } from 'events/EventBus';
import {
  CanvasEvent
} from 'events/EventTypes';

export const MeasureToolsMap = {
  [MeasureToolType.Protractor]: '量角器',
  [MeasureToolType.LongRuler]: '長尺',
  [MeasureToolType.ShortRuler]: '短尺',
  [MeasureToolType.IsoscelesTriangle]: '等腰三角板',
  [MeasureToolType.RightTriangle]: '直角三角板',
}

const MeasureToolsPanel = ({ className, onPanelClose, children }) => {
  const [{ readerToolDirection }] = useStore(StoreTypes.reader);
  const panelRef = useRef(null);

  useOutsideAlerter(panelRef, onPanelClose);

  const createMeasure = (type) => {
    switch (type) {
      case MeasureToolType.Protractor:
        EventBus.emit({
          event: CanvasEvent.CanvasMeasureToolCreatedEvent,
          payload: { 
            type: MeasureToolType.Protractor
          }
        })
        break;
      case MeasureToolType.ShortRuler:
        EventBus.emit({
          event: CanvasEvent.CanvasMeasureToolCreatedEvent,
          payload: { 
            type: MeasureToolType.ShortRuler
          }
        })
        break;
      case MeasureToolType.LongRuler:
        EventBus.emit({
          event: CanvasEvent.CanvasMeasureToolCreatedEvent,
          payload: { 
            type: MeasureToolType.LongRuler
          }
        })
        break;
      case MeasureToolType.IsoscelesTriangle:
        EventBus.emit({
          event: CanvasEvent.CanvasMeasureToolCreatedEvent,
          payload: { 
            type: MeasureToolType.IsoscelesTriangle
          }
        })
        break;
      case MeasureToolType.RightTriangle:
        EventBus.emit({
          event: CanvasEvent.CanvasMeasureToolCreatedEvent,
          payload: { 
            type: MeasureToolType.RightTriangle
          }
        })
        break;

      default:
        break;
    }
    onPanelClose()
  }
  return (
    <div
      ref={panelRef}
      className={classnames(
        styles.popover,
        styles[readerToolDirection],
        className,
      )}
    >
      <div className={styles.closeBtn} onClick={onPanelClose}>
        <Icon type="text" name="times" />
      </div>
      <div className={styles.measureBtn} onClick={() => createMeasure(MeasureToolType.Protractor)}>
        <div className={styles.btns}>
          <Icon name="MeasureProtractor" />
          <div className={styles.text}>{MeasureToolsMap[MeasureToolType.Protractor]}</div>
        </div>
      </div>
      <div className={styles.measureBtn} onClick={() => createMeasure(MeasureToolType.ShortRuler)}>
        <div className={styles.btns}>
          <Icon name="MeasureShortRuler" />
          <div className={styles.text}>{MeasureToolsMap[MeasureToolType.ShortRuler]}</div>
        </div>
      </div>
      <div className={styles.measureBtn} onClick={() => createMeasure(MeasureToolType.LongRuler)}>
        <div className={styles.btns}>
          <Icon name="MeasureLongRuler" />
          <div className={styles.text}>{MeasureToolsMap[MeasureToolType.LongRuler]}</div>
        </div>
      </div>
      <div className={styles.measureBtn} onClick={() => createMeasure(MeasureToolType.IsoscelesTriangle)}>
        <div className={styles.btns}>
          <Icon name="MeasureIsoscelesTriangle" />
          <div className={styles.text}>{MeasureToolsMap[MeasureToolType.IsoscelesTriangle]}</div>
        </div>
      </div>
      <div className={styles.measureBtn} onClick={() => createMeasure(MeasureToolType.RightTriangle)}>
        <div className={styles.btns}>
          <Icon name="MeasureRightTriangle" />
          <div className={styles.text}>{MeasureToolsMap[MeasureToolType.RightTriangle]}</div>
        </div>
      </div>
    </div>
  );
};



export default MeasureToolsPanel
