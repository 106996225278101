import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { checkImgExists } from 'util/checkImgExists';

const Card = ({ book = {}, BookContentRepository, addShelf }) => {
    const [imgExists, setImgExists] = useState(false);
    const imgCover = BookContentRepository.getCoverUrls({ bookId: book.bookId });
    const imgErrorPath = 'assets/img/cover.jpg';

    useEffect(() => {
        checkImgExists(imgCover).then(() => {
            setImgExists(true);
        }).catch(() => {
            setImgExists(false)
        })
    }, [])

    return (
        <li className={styles['list-item']}>
            <div className={styles['list-img']}
                style={{ backgroundImage: `url(${imgExists ? imgCover : imgErrorPath})` }}></div>
            <div className={styles['list-title']}>{book.displayName}</div>
            <div className={styles['list-tool']}>
                <button className={styles.btn} onClick={(event) => addShelf(event, book)} >加入書櫃</button>
            </div>
        </li>
    )
};
export default Card;
