import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import Icon from 'components/Icon';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import { useStore, StoreTypes } from "context";
import { withTranslation } from 'react-i18next';
import { useGetGroupInteractiveToolPages } from 'customHooks/groupInteractiveToolPages';

const InteractiveObjectContentCommandComponent = ({ t, type, command, canvasSVGObject }) => {
    const [{ pageIndex }] = useStore(StoreTypes.reader);
    const pages = useGetGroupInteractiveToolPages(type);
    
    return (
        <Button
            key={type}
            onClick={() => command && command.execute({ canvasSVGObject })}
            className={`${pages.includes(pageIndex) && styles.buttonForAnswerTool}`}
            title={t(toolsEventMap[type].title)}
        >
            <Icon name={toolsEventMap[type].icon} />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(InteractiveObjectContentCommandComponent);
