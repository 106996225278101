import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
// import FontAwesome from 'react-fontawesome';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { groupPages } from 'util/book';
import BookPage from 'components/BookPage';
import { InteractiveObjectView } from 'components/InteractiveObjectView';
import { useFetchBooks } from 'customHooks/book';
import { isExist } from 'util/helper'
import { BookFlipType } from 'constants/flipTypes';
import { CanvasSVGObjectView } from 'components/CanvasSVGObjectView';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { useEvent } from 'events/EventBus';
import { InteractiveObjectEvent } from 'events/EventTypes';
import TldrawContainer from 'components/TldrawContainer';

const getCanvasSvg = str => {
  str = str.replace('<svg', '<svg preserveAspectRatio="xMidYMid"');
  return str;
};

const InitInteractiveObjectState = { version: 0, state: [] };


/**
 * BookView render 書本頁面並疊加互動物件
 * 改用 tldraw 後書本頁面及互動物件皆直接藉由 custom shape 載入 tldraw 中
 * @param {Object} props.book - 書本的基本資訊包含各頁面的 svg 路徑及書本長寬等
 * @returns {JSX.Element}
*/
const BookView = ({ book }) => {
  const [bookData, setBookData] = useState({});
  const [{ areaZoomInteractiveObjects }] = useStore(StoreTypes.reader);
  const { step, areaZoomInfos } = areaZoomInteractiveObjects || {};
  const [{ isActive, canvasSVG }] = useStore(StoreTypes.canvas);

  const [{ isDoublePageMode, isMarkModeShow, pageIndex }] = useStore(
    StoreTypes.reader
  );
  const [{ annotationId }] = useStore(
    StoreTypes.annotation
  );
  const [{ bookContent, interactiveObjectState, interactiveObjectSVG, interactiveObjectJSON }, bookDispatch] = useStore(StoreTypes.books);
  // const [interactiveObjectState, setInteractiveObjectState] = useState(InitInteractiveObjectState);
  const { fetchBook } = useFetchBooks();
  const { bookId, pageInfos, LRFlip } = book;
  // const bookRef = useRef();
  const CommandFactory = useInteractiveObjectContentCommandFactory();

  const {
    x1,
    x2,
    y1,
    y2
  } = useMemo(() => {
    if (!areaZoomInfos || !isExist(step)) return {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
    };
    const {
      // originWidthScale,
      originHeightScale,
      originXScale,
      originYScale
    } = areaZoomInfos[step];
    const currentOriginXScale = isDoublePageMode ? (originXScale <= 0.5 ? 0 : 0.5) : 0;
    return {
      x1: (currentOriginXScale * 100) + '%',
      // x2: (currentOriginXScale + originWidthScale * 2) * 100 + '%',
      x2: (currentOriginXScale + (isDoublePageMode ? 0.5 : 1)) * 100 + '%',
      y1: (originYScale * 100) + '%',
      y2: (originYScale + originHeightScale) * 100 + '%'
    }
  }, [areaZoomInfos, step, isDoublePageMode]);

  const SetInteractiveObjectStateEventHandler = useCallback(
    async ({
      json
    }) => {
      if (!json) return;
      const command = CommandFactory.createCommand(json);
      command &&
        command.execute({
          json,
          pageIndex,
          isDoublePageMode,
          // interactiveObjectState,
          // setInteractiveObjectState
        });
    },
    [CommandFactory, pageIndex, isDoublePageMode]
  );

  useEvent({ event: InteractiveObjectEvent.SetInteractiveObjectStateEvent }, SetInteractiveObjectStateEventHandler);

  useEffect(() => {
    const data = groupPages(bookContent, isDoublePageMode);
    setBookData(data);
    // reset interactive object state
    bookDispatch({ type: types.SET_INTERACTIVE_OBJECTS_STATE, interactiveObjectState: InitInteractiveObjectState });
    // setInteractiveObjectState(InitInteractiveObjectState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LRFlip, bookContent, isDoublePageMode]);

  // const bookPageRenderer = useCallback(
  //   ({ index, key }) => {
  //     const page = bookData[index];
  //     let pages = [];
  //     const threshold = 3;
  //     if (isDoublePageMode) {
  //       let startPage = Math.max(0, index * 2 - threshold * 2);
  //       let endPage = Math.min(
  //         index * 2 + 1 + threshold * 2,
  //         pageInfos.length - 1
  //       );
  //       for (let i = startPage; i <= endPage; ++i) {
  //         pages.push(i);
  //       }
  //     } else {
  //       let startPage = Math.max(0, index - threshold);
  //       let endPage = Math.min(index + threshold, pageInfos.length - 1);
  //       for (let i = startPage; i <= endPage; ++i) {
  //         pages.push(i);
  //       }
  //     }
  //     pages = pages.filter(page => !bookContent[page]);
  //     if (pages.length > 0) {
  //       fetchBook({ bookId, pages });
  //     }

  //     return page ? (
  //       <div
  //         className={classnames({
  //           [styles.reverse]: LRFlip === BookFlipType.RIGHT_TO_LEFT,
  //           [styles.markMode]: isMarkModeShow
  //         })}
  //       >
  //         {page.map(item => {
  //           return item ? (
  //             <div className={styles.page} key={item.pageIndex} ref={bookRef}>
  //               <BookPage
  //                 content={item.svg}
  //                 bookInfo={book}
  //                 pageIndex={item.pageIndex}
  //               />

  //               {/* 新增的svg層  start */}
  //               {interactiveObjectSVG[item.pageIndex] && (
  //                 <InteractiveObjectView
  //                   svgString={interactiveObjectSVG[item.pageIndex]}
  //                   pageIndex={item.pageIndex}
  //                   bookInfo={book}
  //                   interactiveObjectState={interactiveObjectState}
  //                   setInteractiveObjectState={setInteractiveObjectState}
  //                   interactiveObjectJSON={interactiveObjectJSON}
  //                 />
  //               )}
  //               {/* 新增的svg層  end */}
  //             </div>
  //           ) : (
  //             <div className={styles.page} key="blank" />
  //           );
  //         })}
  //         {/* {canvasSVG[index] && !isActive && (
  //           <CanvasSVGObjectView annotationId={annotationId} viewIndex={index} svgString={getCanvasSvg(canvasSVG[index])} />
  //         )}
  //         {areaZoomInteractiveObjects &&
  //           <div className={styles.mask} style={{
  //             clipPath: `polygon(0 0, 0% 100%, ${x1} 100%, ${x1} ${y1}, ${x2} ${y1}, ${x2} ${y2}, ${x1} ${y2}, ${x1} 100%, 100% 100%, 100% 0%)`,
  //           }} />} */}
  //       </div>
  //     ) : (
  //       <div key={key} />
  //     );
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [LRFlip, annotationId, book, bookContent, bookData, bookId, canvasSVG, fetchBook, interactiveObjectSVG, interactiveObjectState, isActive, isDoublePageMode, isMarkModeShow, pageInfos.length]
  // );

  return (
    <div className={styles.bookViewContainer}>
      <TldrawContainer book={book} />
      {/* {book && bookPageRenderer({ index: pageIndex, key: pageIndex })} */}
    </div>
  );
};

export default BookView;
