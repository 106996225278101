import ReactGA from "react-ga4";
import { useLocationChange } from "customHooks/locationChange";
import { CategoryTypeWording } from "constants/gaEventType";
import {
  ContentTypeDisplayMap,
  ReaderToolTypeDisplayMap,
  SyncAnnotationType,
  QrcodeLoginType
} from "constants/contentType";

const deviceTag = window.electron ? '(electron)' :
  window.ios ? '(iOS)' : window.android ? '(android)' : '';

const gaPageView = (location) => {
  // ReactGA.pageview(`${location.pathname}${location.search}`);
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "" + deviceTag });
};

export const useGAPageView = () => useLocationChange(gaPageView);

export const gaEventInteractiveObjectClick = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: (ContentTypeDisplayMap[action] || action) + deviceTag,
    label: (label || "") + deviceTag,
  });
};

export const gaEventReaderToolsClick = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: (ReaderToolTypeDisplayMap[action] || action) + deviceTag,
    label: (label || "") + deviceTag,
  });
};

export const gaEventQrcodeLogin = ({ category, action, label }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: (QrcodeLoginType[action] || action) + deviceTag,
    label: (label || "") + deviceTag
  })
}

export const gaEventAnnotationSynchronizeClick = ({ 
  category,
  action,
  label
 }) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: (SyncAnnotationType[action] || action) + deviceTag,
    label: label + deviceTag || ""
  })
}

export const gaEventNextPrevPage = ({category, action, label}) => {
  ReactGA.event({
    category: CategoryTypeWording[category],
    action: (ReaderToolTypeDisplayMap[action] || action) + deviceTag,
    label: label + deviceTag || "",
  })
}
