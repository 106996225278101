export const checkImgExists = (imgurl) => {
  return new Promise(function (resolve, reject) {
      let ImgObj = new Image(); //判斷圖片是否存在
      ImgObj.src = imgurl;
      ImgObj.onload = function (res) {
          resolve(res);
      }
      ImgObj.onerror = function (err) {
          reject(err)
      }
  })
}