import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

export const initState = {
    stampGroups: {}
};


const stampReducer = (state, action) => {
    switch (action.type) {
        case types.SET_STAMP_GROUPS:
            const { stampGroups } = action.payload;
            return { 
              ...state, 
              stampGroups
            };
        default:
            return state;
    }
};

const useStampReducer = () => useEnhanceReducer(stampReducer, initState);

export default useStampReducer;
