import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Repository from 'repositories/Repository';
import {
  ModalComponentGenerator,
  ModalComponentType
} from 'components/common/Modal/ModalComponentGenerator';

const { ExtendedResourceRepository } = Repository;

export const useTranslationCommand = () => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, { openModal, setModalComponents }] = useModal();

  const execute = useCallback(
    async ({ json: { src } }) => {
      try {
        const TranslationResource = await ExtendedResourceRepository.getJSONContent(
          { bookId, pathName: src }
        );
        const component = ModalComponentGenerator.generate(
          ModalComponentType.Translation,
          { TranslationResource }
        );
        setModalComponents(component);
        openModal();
      } catch (e) {
        console.error('TranslationCommand execute error', e);
      }
    },
    [bookId, openModal, setModalComponents]
  );

  return { execute };
};
