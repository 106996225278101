import React, { useEffect, useRef, useCallback, forwardRef } from 'react';
import Icon from 'components/Icon';
import classnames from 'classnames';
import styles from './index.module.scss';

const AudioButton = forwardRef(({
  className,
  src,
  children,
  autoPlay = false,
  empty = false,
  disabled = false,
  onPlaying = () => { },
  onEnded = () => { },
  onClick,
}, ref) => {
  const audioRef = useRef();

  const playingHandler = useCallback(e => {
    onPlaying();
  }, [onPlaying])

  const endedHandler = useCallback(e => {
    onEnded();
  }, [onEnded]);

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.addEventListener('play', playingHandler);
    audioRef.current.addEventListener('ended', endedHandler);
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('play', playingHandler);
        audioRef.current.removeEventListener('ended', endedHandler);
        audioRef.current.pause();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    if (autoPlay) {
      audioRef.current.play();
    }
  }, [autoPlay]);

  const clickHandler = e => {
    if (disabled) return;
    if (onClick) {
      onClick(audioRef.current);
      return;
    }
    audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
  }
  return (
    <>
      <audio 
        ref={audioRef} 
        src={src}
        playsInline
        crossOrigin="anonymous"
      />
      <button
        ref={ref}
        className={classnames(styles.audioButton, className)}
        onClick={clickHandler}
      >
        {children || empty ? children : <Icon type="text" name="headphones" />}
      </button>
    </>
  );
});

export default AudioButton;
