export const SideToolContent = {
    None: 'None',
    CanvasObjectPropertyViewer: 'CanvasObjectPropertyViewer',
    ReaderOptionPanel: 'ReaderOptionPanel',
    StampPanel: 'StampPanel'
};


export const SideToolDirection = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const PainterPosition = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const PanelState = {
    NONE: 'none',
    PEN: 'pen',
    HIGHLIGHT: 'highLight',
    SHAPE: 'shape',
    LINE: 'LINE'
};