import React from "react";
import { Rectangle2d, ShapeUtil } from "tldraw";

const transFormSVG = ({ svg, width, height, viewWidth, viewHeight }) => {
  svg = svg.replace(`width="${width}"`, `width="${viewWidth}"`);
  svg = svg.replace(`height="${height}"`, `height="${viewHeight}"`);
  svg = svg.replace("<svg", '<svg preserveAspectRatio="none"');
  return svg;
};

export class BookPage extends ShapeUtil {
  static type = "bookPage";
  getDefaultProps() {
    return {
      w: 500,
      h: 500,
      svg: "",
      bookInfo: {},
    };
  }
  canEdit = () => false;
  canResize = () => false;
  isAspectRatioLocked = () => false;
  getGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.w,
      height: shape.props.h,
      isFilled: true,
    });
  }
  component(shape) {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        // className={classnames(styles.svgWrapper, {
        //     [styles.switch]: SVGSwitch
        //   })}
      >
        <div
          id="svgContent"
          //   className={classnames(styles.svgContent, {
          //     [styles.disableSelection]: !isMarkModeShow
          //   })}
          dangerouslySetInnerHTML={{
            __html: transFormSVG({
              svg: shape.props.svg,
              ...shape.props.bookInfo,
              viewHeight: shape.props.h,
              viewWidth: shape.props.w,
            }),
            // __html: shape.props.svg
          }}
        />
      </div>
    );
  }
}
