import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { ReaderToolType } from 'constants/ReaderTools';

export const ContentTypeDisplayMap = {
  [InteractiveObjectContentType.ExternalWindow]: '外開連結',
  [InteractiveObjectContentType.InternalWindow]: '內開視窗',
  [InteractiveObjectContentType.ToggleDisplay]: '蓋答案',
  [InteractiveObjectContentType.ControlDisplay]: '全解',
  [InteractiveObjectContentType.Stage]: '步驟編號',
  [InteractiveObjectContentType.ControlStage]: '步驟解',
  [InteractiveObjectContentType.Image]: '外開連結',
  [InteractiveObjectContentType.Image]: '圖片',
  [InteractiveObjectContentType.ControlImage]: '換圖',
  [InteractiveObjectContentType.GoPage]: '跳頁',
  [InteractiveObjectContentType.Video]: '影片',
  [InteractiveObjectContentType.Audio]: '音檔',
  [InteractiveObjectContentType.AreaZoom]: '區域放大',
  [InteractiveObjectContentType.InternalHtmlWindow]: 'HTML內開視窗',
  [InteractiveObjectContentType.Vocabulary]: '單字例句',
  [InteractiveObjectContentType.Translation]: '翻譯小視窗',
  [InteractiveObjectContentType.WordGrid]: '生字庫',
  [InteractiveObjectContentType.VolumeOneWords]: '首冊生字庫',
  [InteractiveObjectContentType.Submenu]: '次選單',
  [InteractiveObjectContentType.WordExample]: '語詞選單',
  [InteractiveObjectContentType.Synopsis]: '多題小視窗',
  [InteractiveObjectContentType.Hearing]: '聽力稿',
  [InteractiveObjectContentType.FlashCard]: 'Flash Card',
  [InteractiveObjectContentType.Listening]: '聆聽故事',
  [InteractiveObjectContentType.WordGlyph]: '字音字形',
  [InteractiveObjectContentType.WordMeaning]: '字義教學',
  [InteractiveObjectContentType.AboutTheAuthor]: '作者介紹',
  [InteractiveObjectContentType.ReadAloud]: '朗讀',
  [InteractiveObjectContentType.ZhuyinLesson]: '注音課文',
  [InteractiveObjectContentType.ZhuyinImage]: '注音圖片',
  [InteractiveObjectContentType.ZhuyinWord]: '一音二詞',
  [InteractiveObjectContentType.MathTools]: '尺規作圖',
  [InteractiveObjectContentType.MeasureTools]: '測量工具',
  [InteractiveObjectContentType.PaiQuiz]: '派測驗',
  [InteractiveObjectContentType.PageWidth]: '固定延伸比例',
  [InteractiveObjectContentType.AiSpeak]: 'AI練口說',
};

export const ReaderToolTypeDisplayMap = {
  [ReaderToolType.Expression]: '表情',
  [ReaderToolType.SynchronousCamera]: '遙控器',
  [ReaderToolType.BackToCatalog]: '回目次',
  [ReaderToolType.StickyNote]: '便利貼',
  [ReaderToolType.Link]: '連結',
  [ReaderToolType.MathTools]: '尺規作圖',
  [ReaderToolType.Bookshelf]: '書櫃',
  [ReaderToolType.Menu]: '目次',
  [ReaderToolType.Mark]: '標記文字',
  [ReaderToolType.Drag]: '滑鼠',
  [ReaderToolType.Select]: '選取',
  [ReaderToolType.Painting]: '畫筆',
  [ReaderToolType.Highlighter]: '螢光筆',
  [ReaderToolType.Shape]: '形狀',
  [ReaderToolType.Line]: '線段',
  [ReaderToolType.DeleteAll]: '全部刪除',
  [ReaderToolType.Delete]: '部分刪除',
  [ReaderToolType.ArrowLeft]: '左翻',
  [ReaderToolType.ArrowRight]: '右翻',
  [ReaderToolType.ViewPrevPage]: '書邊上一頁',
  [ReaderToolType.ViewNextPage]: '書邊下一頁',
  [ReaderToolType.PageSearcherPrevPage]: '頁數選擇上一頁',
  [ReaderToolType.PageSearcherNextPage]: '頁數選擇下一頁',
  [ReaderToolType.PageSearcher]: '頁數選擇',
  [ReaderToolType.FourZoomIn]: '四格放大',
  [ReaderToolType.AllScreen]: '原尺寸',
  [ReaderToolType.IntervalZoomIn]: '區間放大',
  [ReaderToolType.FullScreen]: '全螢幕',
  [ReaderToolType.Whiteboard]: '白板',
  [ReaderToolType.NumberPicker]: '選號器',
  [ReaderToolType.Img]: '新增圖片',
  [ReaderToolType.Text]: '新增文字',
  [ReaderToolType.SVG]: 'SVG物件',
  [ReaderToolType.PageMode]: '單/雙頁',
  [ReaderToolType.ReaderOption]: '設定',
  [ReaderToolType.Bookmark]: '頁籤',
  [ReaderToolType.Activity]: '活動',
  [ReaderToolType.ExportInteractiveObject]: '匯出',
  [ReaderToolType.AttendeeNumber]: '座號',
  [ReaderToolType.OfflineReaderToolBox]: '工具箱',
  [ReaderToolType.Toolbox]: '工具箱',
  [ReaderToolType.Timer]: '計時器',
  [ReaderToolType.Stopwatch]: '馬表',
  [ReaderToolType.MeasureTools]: '測量工具',
  [ReaderToolType.Protractor]: '量角器',
  [ReaderToolType.ShortRuler]: '短尺',
  [ReaderToolType.LongRuler]: '長尺',
  [ReaderToolType.IsoscelesTriangle]: '等腰三角形',
  [ReaderToolType.RightTriangle]: '直角三角形',
  [ReaderToolType.StampCollection]: '按鈕收納',
  // [ReaderToolType.SyncAnnotation]: '儲存雲端',
  [ReaderToolType.AnswerInteractiveTool]: '快問快答',
  [ReaderToolType.PaiNote]: '派筆記',
  [ReaderToolType.SubmitNote]: '筆記回傳',
  [ReaderToolType.PaiNoteRecord]: '筆記紀錄',
  [ReaderToolType.PrevPage]:"上一頁",
  [ReaderToolType.NextPage]:"下一頁"
};

export const SyncAnnotationType = {
  web: '網頁同步紀錄',
  electron: '離線版同步紀錄',
  mobile: '行動裝置同步紀錄',
};

export const QrcodeLoginType ={
  accept: '接收',
  sent:'送出',
}