import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles({
  ul: {
    justifyContent: 'center'
  }
});

export default function BasicPagination({ count, page = 1, defaultPage, onChange }) {
  const classes = useStyles();
  return (
    <div>
      <Pagination count={count} page={page} defaultPage={defaultPage} onChange={onChange} classes={{ ul: classes.ul }} />
    </div>
  );
}
