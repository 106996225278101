import React, { useEffect, useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
// import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';
import EnhancedTable from './list';
import { LottiePlayer } from 'components/LottieLoading/lottiePlayer';
import { AnnotationType } from 'constants/annotationTypes';
import { Roles } from 'constants/role';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import { useReadAnnotations } from 'customHooks/db';
import * as types from 'constants/actionTypes';
// import { usePreparation } from 'customHooks/preparation';

// function TabContainer(props) {
//     return (
//         <Typography component="div">
//         {/* <Typography component="div" style={{ padding: 8 * 3 }}> */}
//             {props.children}
//         </Typography>
//     );
// }

// TabContainer.propTypes = {
//     children: PropTypes.node.isRequired,
// };

// const Tip = () => (<div className={styles.tip}>小提醒：點選編輯的"複製"可以另存班級紀錄喔！</div>);

export const AnnotationActivityTab = (props) => {
    // const [{ role, token }] = useStore(StoreTypes.user);
    // const [{ bookId }] = useStore(StoreTypes.books);
    // const [annotations, setAnnotations] = React.useState({})
    // const [{ annotationType }, annotationDispatch] = useStore(StoreTypes.annotation);
    // const { loadingOpen, setLoadingOpen, setAnnotationTabOpen, setInputText, annotations, setOpenWaring, openCopyAnnotationField, renameAnnotation, selectAnnotationAndEnter } = props;
    // const { readAnnotations } = useReadAnnotations();
    // const { getPreparationList } = usePreparation();

    // useEffect(() => {
    //     if (~TabAnnotationType.indexOf(annotationType)) {
    //         setTab(TabAnnotationType.indexOf(annotationType));
    //     }
    //     // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     (async () => {
    //         setLoadingOpen(true)
    //         const results = await getPreparationList(bookId, token, (!window.electron&&role === Roles.TEACHER) ? AnnotationType.CLASS_PREPARATION : AnnotationType.GUEST);
    //         results.sort((a, b) => {
    //             return b.updatedAt - a.updatedAt;
    //         })
    //         let annotations = results.reduce((acc, v) => {
    //             if (!v.isDeleted) {
    //                 if (Array.isArray(acc[v.type])) {
    //                     acc[v.type].push(v);
    //                 } else {
    //                     acc[v.type] = [v];
    //                 }
    //             }
    //             return acc;
    //         }, {});
    //         setLoadingOpen(false);
    //         setAnnotations(annotations);
    //     })();
    // }, [bookId, getPreparationList, readAnnotations, role, setLoadingOpen, token]);

    // const handleChange = useCallback((event, newValue) => {
    //     //如果切tab就把annotationId洗成空，不然假設備課有切到活動點確定，就會造成沒辦法創建新的annotationId
    //     let payload = { type: types.UPDATE_ANNOTATION_INFO, annotationType: TabAnnotationType[newValue] };
    //     if (!annotations[TabAnnotationType[newValue]]) {
    //         payload.annotationId = '';
    //     }
    //     annotationDispatch(payload);
    //     setTab(newValue);

    // }, [TabAnnotationType, annotationDispatch, annotations, setTab]);

    // if (role === Roles.TEACHER) {
    //     return <div className={classnames(styles.noteActivitySelect, { [styles.loading]: loadingOpen })} >
    //         <AppBar position="static" style={{ backgroundColor: "#ccc" }}>
    //             <Tabs value={tab} onChange={handleChange}>
    //                 <Tab label="班級紀錄" style={{ fontSize: 28, backgroundColor: "#f50057", maxWidth: "100%", width: "100%" }} />
    //                 {
    //                     //<Tab label="活動" style={{ backgroundColor: "#f50057" }} />
    //                 }
    //             </Tabs>

    //         </AppBar>
    //         {/* <Tip /> */}
    //         {tab === 0 && <TabContainer><EnhancedTable setAnnotationTabOpen={setAnnotationTabOpen} listData={annotations[AnnotationType.CLASS_PREPARATION] || []} setInputText={setInputText} /></TabContainer>}
    //         {tab === 1 && <TabContainer><EnhancedTable setAnnotationTabOpen={setAnnotationTabOpen} listData={annotations[AnnotationType.ACTIVITY] || []} setInputText={setInputText} /></TabContainer>}
    //         {loadingOpen && <LottiePlayer />}
    //         {!loadingOpen && <EnhancedTable setAnnotationTabOpen={setAnnotationTabOpen} listData={annotations || []} setInputText={setInputText} setOpenWaring={setOpenWaring} openCopyAnnotationField={openCopyAnnotationField} renameAnnotation={renameAnnotation} selectAnnotationAndEnter={selectAnnotationAndEnter} />}
    //         {tab === 1 && <EnhancedTable setAnnotationTabOpen={setAnnotationTabOpen} listData={annotations[AnnotationType.ACTIVITY] || []} setInputText={setInputText} />}
    //     </div>;
    // } else {
    //     return <div className={styles.noteActivitySelect} >
    //         <AppBar position="static" style={{ backgroundColor: "#ccc" }}>
    //             <Tabs value={tab} onChange={handleChange}>
    //                 <Tab label="班級紀錄" style={{ fontSize: 28, backgroundColor: "#f50057", maxWidth: "100%", width: "100%" }} />
    //             </Tabs>

    //         </AppBar>
    //         <Tip />
    //         <TabContainer>
    //         {loadingOpen && <LottiePlayer />}
    //         {!loadingOpen && <EnhancedTable
    //             setLoadingOpen={setLoadingOpen}
    //             setAnnotationTabOpen={setAnnotationTabOpen}
    //             listData={annotations || []}
    //             setInputText={setInputText}
    //             setOpenWaring={setOpenWaring}
    //             openCopyAnnotationField={openCopyAnnotationField}
    //             renameAnnotation={renameAnnotation}
    //             selectAnnotationAndEnter={selectAnnotationAndEnter}
    //         />}
    //         </TabContainer>
    //     </div>;
    // }
};
