import React from 'react';
import { useStore, StoreTypes } from 'context';
import AudioControlsButton from 'components/common/AudioControlsButton';
import Repository from 'repositories/Repository';
import styles from './index.module.scss';

const { ExtendedResourceRepository } = Repository;
const AnchorAudioButton = ({ children, audioSrc, onScrollToAnchor }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  return (
    <div className={styles.anchorGroup}>
      {audioSrc && 
      <AudioControlsButton 
        src={ExtendedResourceRepository.getDataSrc({
          bookId,
          pathName: audioSrc
        })} 
        className={styles.audio}
      />
      }
      <div className={styles.anchor} onClick={onScrollToAnchor}>
        {children}
      </div>
    </div>
  )
}

export default AnchorAudioButton;