import React from 'react';
import styles from '../../index.module.scss';
import Button from 'components/Button';
import FourZoomIn from "components/Icon/FourZoomIn";
import toolsEventMap from 'components/ReaderTools/toolsEventMap';

import { withTranslation } from 'react-i18next';

const ReaderToolFourZoomIn = ({ t, type }) => {
    return (
        <Button
            key={type}
            title={t(toolsEventMap[type].title)}
            width="7vh"
        >
            <FourZoomIn />
            <div className={styles.text}>{t(toolsEventMap[type].title)}</div>
        </Button>
    );
};

export default withTranslation()(ReaderToolFourZoomIn);
