import React, { useState, useRef } from 'react'
import styles from './index.module.scss';
import classnames from 'classnames';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { useStore, StoreTypes } from 'context';
import useModal from 'components/common/Modal/useModal';
import Icon from 'components/Icon';



const LinkComponent = ({ canvasSVGObject }) => {
    const { id, info } = canvasSVGObject;
    const { list, color } = info;
    const rectRef = useRef();
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const [, { closeModal }] = useModal();
    const [link, setLink] = useState(list);

    const addLinkInfo = () => {
        setLink([...link, { contentType: "ExternalWindow", name: '', src: '' }])
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target
        const nextLinkInfo = link.map(item => item);
        nextLinkInfo[index][name] = value
        setLink(nextLinkInfo)
    }

    const handleDelete = (e, pos) => {
        const nextLinkInfo = link.filter((item, index) => (index !== pos));
        setLink(nextLinkInfo)
    }

    const sendLinkInfo = () => {
        const linkData = link.filter(item => (item.name !== '' && item.src !== ''))
        const nextSubmenuInfo = { ...canvasSVGObject, info: { ...info, list: linkData } };
        EventBus.emit({ event: ReaderEvent.SaveCanvasSVGObjectEvent, payload: { annotationId, id, canvasSVGObject: nextSubmenuInfo } });
        closeModal();
    }

    return (
        <div
            className={classnames(styles.stickyContainer)}
        >

            <div className={styles.sticky}
                style={{
                    backgroundColor: color,
                }}
                ref={rectRef}
            >
                <div className={styles.stickyContent}
                    style={{
                        //backgroundColor: color,
                    }}
                >
                    <div className={styles.topTools}
                        style={{
                            backgroundColor: color,
                        }}
                    >
                        <div>
                            <div className={styles.title} >
                                <Icon type='svg' name={'globe'} />網頁
                            </div>
                            <div className={styles.addBtn}
                                onClick={() => { addLinkInfo(); }}
                            >
                                新增
                            </div>
                        </div>
                    </div>
                    <div className={styles.itemList}>
                        {
                            link.length > 0 && link.map((item, index) => (
                                <div key={index} className={styles.item + ' item'}>
                                    <div className={styles.delBtn} onClick={(e) => handleDelete(e, index)}>刪除</div>
                                    <div className={styles.linkInfo}>
                                        <div className={styles.linkRow}>
                                            <div>標題：</div>
                                            <input name='name' value={item.name} autoComplete="off" onChange={(e) => handleChange(e, index)} />
                                        </div>
                                        <div className={styles.linkRow}>
                                            <div>網址：</div>
                                            <input name='src' value={item.src} autoComplete="off" onChange={(e) => handleChange(e, index)} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={styles.bottomTools}
                        style={{
                            backgroundColor: color,
                        }}
                    >
                        <div className={styles.comfirm}
                            onClick={() => { sendLinkInfo(); }}
                        >
                            確定
                            </div>
                        <div className={styles.addBtn} onClick={() => { closeModal(); }}>
                            取消
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkComponent;

