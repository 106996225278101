import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import {
	LOGIN_POPUP,
	LOGIN_BTN,
	LOGIN_CONFIRM_POPUP,
} from "constants/loginTypes";



export const initState = {
    popupState:false,
    confirmPopupState:false,
    loginBtn:false
}



const loginReducer = (state, action) => {
    switch (action.type) {
        case LOGIN_POPUP :
            return { ...state,popupState:action.popupState }
        case LOGIN_CONFIRM_POPUP :
            return { ...state,confirmPopupState:action.confirmPopupState }
        case LOGIN_BTN :
            return { ...state,loginBtn:action.loginBtn }
        default :
            return state
    }
}


const useLoginReducer = () => useEnhanceReducer(loginReducer, initState);


export default useLoginReducer;