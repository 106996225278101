import React from 'react';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';

const ShadowTools = ({target: object}) => {
    const shadowChangeHandler = e => {
        EventBus.emit({ event: CanvasEvent.SetCanvasShadowEvent ,
            payload:{ object, isShowShadow:e.target.checked  }
        });
    }

    return (
        <div className={styles.shadowTools}>
            陰影
             <input type="checkbox" onChange={shadowChangeHandler} checked={object.shadow}/>
        </div>
    )
}


export default ShadowTools;