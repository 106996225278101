import React, { useEffect, useState, useRef } from 'react';
import Reader from 'components/Reader/index';
import { useStore, StoreTypes } from 'context';
import LiveStreamMasterPanel from 'components/LiveStreamMasterPanel';
import LiveStreamViewerPanel from 'components/SideToolContents/LiveStreamViewerPanel'
import { useAnnotationChecker } from 'customHooks/annotationChecker';
import { Roles } from 'constants/role';
import Sidebar from 'components/Sidebar';
import styles from './index.module.scss';
import classnames from 'classnames';

const Master = React.memo(({ roomName, userName, chimeRole, userId }) => {
  return <Sidebar vertical isShow={true} isFloat={false}>
    <LiveStreamMasterPanel
      roomName={roomName}
      userName={userName}
      userId={userId}
      chimeRole={chimeRole} />
  </Sidebar>
})

const Viewer = React.memo(({ roomName, userName, chimeRole, userId }) => {
  return <Sidebar vertical isShow={true} isFloat={false}>
    <LiveStreamViewerPanel roomName={roomName}
      userName={userName}
      userId={userId}
      chimeRole={chimeRole} />
  </Sidebar >
})
const LiveStreamSelect = React.memo(({ isTeacher, roomName, userName, userId, chimeRole }) => {
  if (!roomName || !userName || !userId || !chimeRole) return null;
  const role = chimeRole === Roles.ONECLASS_TEACHER ? "teacher" : "student"
  const roomInfo = {
    roomName: roomName,
    userName: userName,
    role: role,
    userId: userId
  }
  return <>
    {
      chimeRole === Roles.ONECLASS_TEACHER ?
        <Master roomName={roomName}
          userName={userName}
          userId={userId}
          chimeRole={"teacher"} /> :
        <Viewer roomName={roomName}
          userName={userName}
          userId={userId}
          chimeRole={"student"} />
    }
  </>
})

const ReaderActivity = (props) => {
  const bookId = props.match.params.bookId;
  let urlParams = new URLSearchParams(props.location.search);
  let paramsRole = urlParams.get('role');
  let paramsActivityId = urlParams.get('activityId');
  let paramsTeacherId = urlParams.get('teacherId');
  let paramsInteractiveObjectId = urlParams.get('interactiveObjectId');
  let roomId = urlParams.get('roomId');
  let studentId = urlParams.get('studentId');
  let timeSpanId = urlParams.get('timeSpanId');
  let code = urlParams.get('code');
  let userName = urlParams.get('userName');
  let id = urlParams.get('id');
  let remote = urlParams.get('remote');
  let remoteId = urlParams.get('remoteId');
  props = { ...props, bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId, timeSpanId, code, userName, id, remote,remoteId }
  useAnnotationChecker(props);
  const [{ role, name, userId }] = useStore(StoreTypes.user);
  const [{ chimeRole }] = useStore(StoreTypes.course);


  const readerClass = (role) => {
    switch (role) {
      case Roles.ONECLASS_TEACHER:
      case Roles.ONECLASS_STUDENT:
        return styles.studentMode;
      default:
        return styles.otherMode;
    }
  }

  return (
    !bookId.includes('index') ? (
      role && <div className={classnames(styles.LiveStreamContent, readerClass(role))}>
        <Reader {...props} />
        {
          chimeRole && <LiveStreamSelect
            isTeacher={chimeRole === Roles.ONECLASS_TEACHER}
            roomName={roomId}
            userName={name}
            userId={userId}
            chimeRole={chimeRole}
          />
        }
      </div>
    ) : (
      null
    )
  )
  
}
export default ReaderActivity

