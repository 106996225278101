import { useCallback, useState, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import useModal from 'components/common/Modal/useModal';
import { useMusicModal } from 'components/common/MusicPlayer/MusicModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';

export const useAudioCommand = () => {
    const [, globalDispatch] = useStore(StoreTypes.global);
    const [, { openModal: openMusicModal, closeModal }] = useMusicModal();
    const [, { openModal: externalWindownModal, setModalComponents }] = useModal();
    const [isAudioHighlighterMode, setIsAudioHighlighter] = useState(false);
    const [activeObjectId, setActiveObjectId] = useState('');
    const [vtts, setVtts] = useState([]);
    const [currentTime, setCurrentTime] = useState(0);
    const [isFollowReadMode, setIsFollowReadMode] = useState(false);

    const execute = useCallback(async ({ json: { src, audioInfo, partialSrc = [], isShowAudioControlBar = true, isAudioHighlighter, isFollowRead = false } }) => {
        closeModal();

        setIsAudioHighlighter(isAudioHighlighter);
        setIsFollowReadMode(isFollowRead);
        const onAudioHightlighterPlay = ({ audio, state, controls, ref, activeCue, cues, currentTime }) => {
            const activeObject = activeCue['en'];
            const id = activeObject ? activeObject.text : '';
            setActiveObjectId(id);
            setCurrentTime(currentTime)
            setVtts(cues['en']);
        }


        if (audioInfo && !isAudioHighlighter) {
            const component = ModalComponentGenerator.generate(ModalComponentType.MarkerWithSound, { src, audioInfo, isShowAudioControlBar });
            setModalComponents(component);
            externalWindownModal();
        } else {
            openMusicModal({ src, audioInfo, partialSrc, isShowAudioControlBar, isAudioHighlighter, onAudioHightlighterPlay });
        }

    }, [closeModal, externalWindownModal, openMusicModal, setModalComponents]);


    useEffect(() => {
        if (!isAudioHighlighterMode) return;
        globalDispatch({
            type: types.SET_MUSIC_MODAL_PROPERTY,
            payload: {
                activeObjectId,
                vtts,
                isFollowReadMode,
                currentTime
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeObjectId, isAudioHighlighterMode, currentTime])


    // send objectIds to global reducer

    return { execute };
};
