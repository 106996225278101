export const getMoeLoiginUrl = () => {

  return fetch('https://zib0bugold.execute-api.ap-northeast-1.amazonaws.com/prod/users/createOrgLoginForMoe', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              clientid: process.env.REACT_APP_ONECLUB_CLIENT_ID,
              params: ""
          })
        })
        .then(res => res.json())
        .then(response => response.data.url)
        .catch(err => console.error(err));
}