import React, { useCallback, useState } from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import {
  MathToolsType
} from 'constants/interactiveObjectContentTypes';
import styles from './index.module.scss';

export const MathToolsMap = {
  [MathToolsType.Compass]: '圓規',
  [MathToolsType.Ruler]: '直尺',
}


export const MathToolsComponent = ({ object }) => {
  const mathTools = object.mathTools ? JSON.parse(object.mathTools) : { type: "" }

  const changeHandler = useCallback((property, value) => {
    mathTools[property] = value;
    EventBus.emit({
      event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
      payload: { object, property: 'mathTools', value: JSON.stringify(mathTools) }
    });
  }, [mathTools, object]);

  return (
    <>
      <select
        id="mathTools"
        value={mathTools.type || ''}
        onChange={e => changeHandler('type', e.target.value)}
      >
        {mathTools.type===""&&(
          <option key={-1} value={mathTools.type || ''}>
            {
              "請選尺規項目"
            }
          </option>
        )}
        {Object.values(MathToolsType).map(
          (type, i) =>
            MathToolsMap[type] && (
              <option key={i} value={type}>
                {MathToolsMap[type]}
              </option>
            )
        )}
      </select>
    </>
  )
};