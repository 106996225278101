import React, { useRef, useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import { ReaderZoomType } from "constants/ReaderTools";
import { ReaderToolsEvent } from "events/EventTypes";
import { EventBus } from "events/EventBus";
import * as types from "constants/actionTypes";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
//import { useCourse } from "customHooks/course";
import { debounce } from "util/debounce";
import { Roles } from "constants/role";

let scaleTemp = 1;

export const PinchPanContainer = ({ index, children }) => {
	const [
		{
			style: { width, height },
		},
	] = useStore(StoreTypes.books);
	const [{ role }] = useStore(StoreTypes.user);
	const [
		{ offsetX, offsetY, scale, isMarkModeShow, zoomType, fullWidthInfo },
		readerDispatch,
	] = useStore(StoreTypes.reader);
	const [{ firestore, remoteZoomInfo }] = useStore(StoreTypes.course);
	const [{ canvas, isActive }] = useStore(StoreTypes.canvas);
	const [{ wheelScale, wheelX, wheelY }, setWheelInfo] = useState({
		wheelScale: 1,
		wheelX: 0,
		wheelY: 0,
	});
	const [transformWrapperParams, setTransformWrapperParams] = useState({});
	const ref = useRef(null);
	const updateScaleTimeout = useRef(null);
	//const [, { setEventStatusToFirebase }] = useCourse();

	const panningHandler = useCallback(
		(e) => {
			const scaleX = e.positionX / (width * e.scale - width);
			const scaleY = e.positionY / (height * e.scale - height);
			const zoomInfo = {
				scaleX,
				scaleY,
				scale: e.scale,
				windowWidth: width,
				del: "=",
			};

			EventBus.emit({
				event: ReaderToolsEvent.ZoomToolEvent,
				payload: { type: ReaderZoomType.PanZoom },
			});

			// firestore &&
			// 	setEventStatusToFirebase({
			// 		areaInfo: {
			// 			type: ReaderZoomType.PanZoom,
			// 			zoomInfo,
			// 			timestamp: new Date().getTime(),
			// 		},
			// 	});

			const { positionX, positionY, scale } = e;
			const params = {
				positionX,
				positionY,
				scale,
			};

			const fullWidthInfoGapX = (width * fullWidthInfo.scale - width) / 2
			const newOffsetX = ((fullWidthInfoGapX - (fullWidthInfoGapX * scale) + (positionX)) - (positionX)) / fullWidthInfo.scale
			const newPositionX = newOffsetX + positionX / fullWidthInfo.scale
			canvas.setViewportTransform([params.scale, 0, 0, params.scale, newPositionX, positionY])

			readerDispatch({
				type: types.SET_SCALE_INFO,
				offsetX: positionX,
				offsetY: positionY,
				scale,
			});

			setTransformWrapperParams(params);

			readerDispatch({
				type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
				areaZoomInteractiveObjects: null,
			});
		},
		[width, height, firestore, fullWidthInfo.scale, canvas, readerDispatch]
	);

	useEffect(() => {
		if (remoteZoomInfo) {
			let { del, scaleX, scaleY } = remoteZoomInfo;
			if (del === "+") {
				scaleTemp = wheelScale + 0.04;
			} else if (del === "-") {
				scaleTemp = wheelScale - 0.04;
			}
			if (scaleTemp <= 1) {
				scaleTemp = 1;
			}
			if (!scaleX) {
				scaleX = 0;
			}
			if (!scaleY) {
				scaleY = 0;
			}

			let params = {
				positionX: (width * scaleTemp - width) * scaleX,
				positionY: (height * scaleTemp - height) * scaleY,
				scale: scaleTemp,
			};
			setTransformWrapperParams(params);
			setWheelInfo({
				wheelX: (width * scaleTemp - width) * scaleX,
				wheelY: (height * scaleTemp - height) * scaleY,
				wheelScale: scaleTemp,
			});

		}
	}, [remoteZoomInfo, width, height, wheelScale]);

	const handler = useCallback((event) => {
		event.stopImmediatePropagation();
		return true;
	}, []);

	useEffect(() => {
		if (ref.current) {
			if (isMarkModeShow) {
				ref.current.onmousemove = handler;
				ref.current.onwheel = handler;
			} else {
				ref.current.onmousemove = null;
				ref.current.onwheel = null;
			}
		}
	}, [isMarkModeShow, handler]);

	useEffect(() => {
		if (ref.current) {
			ref.current.style.cursor = `inherit`;
			//ref.current.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0px) scale(${scale})`;
		}

		if (offsetX !== undefined) {
			scaleTemp = scale;
			setWheelInfo({
				wheelScale: scale,
				wheelX: offsetX,
				wheelY: offsetY,
			});
		}
	}, [scale, offsetX, offsetY]);

	const wheelHandler = useCallback(
		(e) => {
			var windowEvent = window.event;
			if (!windowEvent) return;
			var posX = windowEvent.clientX;
			var posY = windowEvent.clientY;

			const scaleX = e.positionX / (width * e.scale - width);
			const scaleY = e.positionY / (height * e.scale - height);
			EventBus.emit({
				event: ReaderToolsEvent.ZoomToolEvent,
				payload: { type: ReaderZoomType.WheelZoom },
			});
			const zoomInfo = {
				scaleX: scaleX ? scaleX : 1,
				scaleY: scaleY ? scaleY : 1,
				scale: e.scale,
				windowWidth: width,
				del: "=",
			};
			// firestore &&
			// 	setEventStatusToFirebase({
			// 		areaInfo: {
			// 			type: ReaderZoomType.RangeZoom,
			// 			zoomInfo,
			// 			timestamp: new Date().getTime(),
			// 		},
			// 	});

			const { positionX, positionY, scale } = e;
			const params = {
				positionX,
				positionY,
				scale,
			};

			const fullWidthInfoGapX = (width * fullWidthInfo.scale - width) / 2
			// const newPositionX = fullWidthInfo.scale === 1 ? positionX : (positionX - ((positionX) - positionX / fullWidthInfo.scale) / 2)
			// const leftGap = width - (posX - 22)
			// const leftScale = leftGap / width

			const newOffsetX = ((fullWidthInfoGapX - (fullWidthInfoGapX * scale) + (positionX)) - (positionX)) / fullWidthInfo.scale
			canvas.setViewportTransform([params.scale, 0, 0, params.scale, newOffsetX + positionX / fullWidthInfo.scale, positionY])

			readerDispatch({
				type: types.SET_SCALE_INFO,
				offsetX: positionX,
				offsetY: positionY,
				scale,
			});
			setTransformWrapperParams(params);

			readerDispatch({
				type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
				areaZoomInteractiveObjects: null,
			});
		},
		[width, height, firestore, canvas, readerDispatch, fullWidthInfo]
	);

	const zoomEnable =
		role === Roles.TUTOR_USER || role === Roles.ONECLASS_STUDENT
			? false
			: true;

	useEffect(() => {
		let params = null;
		switch (zoomType) {
			case ReaderZoomType.LeftTop:
			case ReaderZoomType.RightTop:
			case ReaderZoomType.LeftBottom:
			case ReaderZoomType.RightBottom:
			case ReaderZoomType.RangeZoom:
			case ReaderZoomType.AreaZoom:
				params = {
					positionX: offsetX,
					positionY: offsetY,
					scale: scale,
				};
				break;
			case ReaderZoomType.OriginZoom:
				params = {
					positionX: 0,
					positionY: 0,
					scale: 1,
				};
				break;
			default:
				break;
		}

		if (!params) return;
		const { positionX, positionY } = params

		const fullWidthInfoGapX = (width * fullWidthInfo.scale - width) / 2
		const newOffsetX = ((fullWidthInfoGapX - (fullWidthInfoGapX * scale) + (positionX)) - (positionX)) / fullWidthInfo.scale
		canvas.setViewportTransform([params.scale, 0, 0, params.scale, newOffsetX + positionX / fullWidthInfo.scale, positionY])


		setTransformWrapperParams({ ...params });
	}, [readerDispatch, zoomType, scale, offsetX, offsetY, canvas, width, fullWidthInfo.scale]);

	const pinchHandler = useCallback(
		(e) => {
			const scaleX = e.positionX / (width * e.scale - width);
			const scaleY = e.positionY / (height * e.scale - height);
			EventBus.emit({
				event: ReaderToolsEvent.ZoomToolEvent,
				payload: { type: ReaderZoomType.WheelZoom },
			});
			const zoomInfo = {
				scaleX: scaleX ? scaleX : 1,
				scaleY: scaleY ? scaleY : 1,
				scale: e.scale,
				windowWidth: width,
				del: "=",
			};
			// firestore &&
			// 	setEventStatusToFirebase({
			// 		areaInfo: {
			// 			type: ReaderZoomType.RangeZoom,
			// 			zoomInfo,
			// 			timestamp: new Date().getTime(),
			// 		},
			// 	});

			const { positionX, positionY, scale } = e;
			const params = {
				positionX,
				positionY,
				scale,
			};

			const fullWidthInfoGapX = (width * fullWidthInfo.scale - width) / 2
			const newOffsetX = ((fullWidthInfoGapX - (fullWidthInfoGapX * scale) + (positionX)) - (positionX)) / fullWidthInfo.scale
			canvas.setViewportTransform([params.scale, 0, 0, params.scale, newOffsetX + positionX / fullWidthInfo.scale, positionY])

			readerDispatch({
				type: types.SET_SCALE_INFO,
				offsetX: positionX,
				offsetY: positionY,
				scale,
			});
			setTransformWrapperParams(params);

			readerDispatch({
				type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS,
				areaZoomInteractiveObjects: null,
			});
		},
		[width, height, firestore, fullWidthInfo.scale, canvas, readerDispatch]
	);

	return (
		<div className={styles.pinchPanContainer} id="studentScreenshotArea">
			<TransformWrapper
				doubleClick={{ disabled: true }}
				options={{
					minScale: 1,
					//maxScale: 2.7,
				}}
				scalePadding={{
					disabled: true,
				}}
				pan={{
					disabled: isActive,
					paddingSize: 0,
				}}
				wheel={{
					step: 75,
				}}
				onPanning={panningHandler}
				onWheel={wheelHandler}
				onPinching={pinchHandler}
				zoomIn={{ disabled: !zoomEnable }}
				zoomOut={{ disabled: !zoomEnable }}
				{...transformWrapperParams}
			>
				{() => {
					return (
						<TransformComponent>
							<div
								ref={(v) => (ref.current = v)}
								className={classnames(styles.swipeContainer)}
							>
								{children}
							</div>
						</TransformComponent>
					);
				}}
			</TransformWrapper>
		</div>
	);
};
