import React from "react";
import styles from "./index.module.scss";
import { MenuItem, TextField, makeStyles } from "@material-ui/core";

const studentInfoInputStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #919EAB",
    },
  },
});

const StudentInfoInputField = ({
  studentInfoField,
  studentInfo,
  studentInfoErrors,
  updateStudentInfo,
  group,
}) => {
  return Object.keys(studentInfoField).map((info) => (
    <div key={info} className={styles.input}>
      <span>{studentInfoField[info]}</span>
      <TextField
        classes={studentInfoInputStyles()}
        variant="outlined"
        size="medium"
        select={info === "studentGroup" || info === "studentNum"}
        type={info === "studentNum" ? "number" : null}
        pattern="\d*"
        value={studentInfo[info]}
        error={!!studentInfoErrors[info]}
        helperText={studentInfoErrors[info]}
        onChange={(e) => updateStudentInfo(info, e.target.value)}
      >
        {info === "studentGroup" &&
          Array.from({ length: group }, (_, i) => i + 1).map((no) => (
            <MenuItem value={no} key={no}>
              {no}
            </MenuItem>
          ))}
        {info === "studentNum" &&
          Array.from({ length: 60 }, (_, i) => i + 1).map((no) => (
            <MenuItem value={no} key={no}>
              {no}
            </MenuItem>
          ))}
      </TextField>
    </div>
  ));
};

export default StudentInfoInputField;
