import React from 'react';
import styles from './index.module.scss';

const Loading = () => {
  return (
    <div className={styles.loadWrapper}>
      <div className={styles.load}>
        <div className={styles.ring}></div>
      </div>
    </div>
  )
}

export default Loading;