import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const WordsMeaningButtonGroup = ({
  wordingMap,
  onChange,
  data,
  className
}) => (
    <div className={classnames(styles.buttonGroup, className)}>
      {
        Object.entries(data).map((item, index) => {
          return (
            <button
              key={item[0]}
              onClick={() => onChange({
                type: item[0]
              })}
              disabled={item[1].length < 1}
            >
              {wordingMap[item[0]]}
            </button>
          )
        }
        )
      }
    </div>
  )
export default WordsMeaningButtonGroup;
