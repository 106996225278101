import React, { memo } from 'react';
import BoxModal from 'components/BoxModal';
import * as types from 'constants/actionTypes';
import { StoreTypes, useStore } from 'context'
import styles from './index.module.scss'

const Timer = memo(() => {
  const [_,dispatch] = useStore(StoreTypes.global);

  const onClose = () => {
    dispatch({  type: types.CLOSE_TIMER_MODAL  })
  };

  return (
    <BoxModal onCloseButtonClick={onClose}>
      <div className={styles.timer}>
        <iframe title="timer" src="assets/Timer/timer.html" frameBorder="0"></iframe>
      </div>
    </BoxModal>
  )
});

export default Timer;
