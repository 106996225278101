import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
	Button,
	makeStyles,
	CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";

const warnDialogStyles = makeStyles({
	root: {
		"& .MuiDialog-paper": {
			borderRadius: "16px",
			maxWidth: "480px"
		},
		"& .MuiDialogTitle-root": {
			background: "#315D83",
		},
		"& .MuiTypography-root": {
			textAlign: "center",
			fontSize: "2em",
			color: "white",
			fontWeight: "700"
		},
		"& .MuiDialogContent-root": {
			padding: ".8em 1.1em",
			// height: "3.5em",
			color: "#637381",
			fontSize: "1.8em",
			background: "#D9F1F8",
			textAlign: "center",
		},
		"& .MuiDialogActions-root": {
			height: "5em",
			justifyContent: "center",
			background: "#D9F1F8"
		}
	},
	
})

const cancelButtonStyles = makeStyles({
	root: {
		color: "#454F5B",
		background: "#D4DDE5",
		borderBottom: "5px solid #637381",
		width: "35%",
		height: "2.3em",
		borderRadius: "12px",
		fontSize: "1.5em",
		fontWeight: "700",

		"&:hover": {
			background: "#637381"
		}
	}
})

const confirmButtonStyles = makeStyles({
	root: {
		color: "#7A310A",
		background: "#FFA235",
		borderBottom: "5px solid #B7601A",
		width: "35%",
		height: "2.3em",
		borderRadius: "12px",
		fontSize: "1.5em",
		fontWeight: "700",
		"&:hover": {
			background: "#B7601A"
		}
	}
})

const circularProgressStyles = makeStyles({
	root: {
		color: 'white',
		marginRight: '10px'
	}
})

export const WarnDialog = ({
  open,
  title,
  subTitle,
  handleCancel,
  handleConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const circularProgressStyle = circularProgressStyles();
  const cancelButtonStyle = cancelButtonStyles();
  const confirmButtonStyle = confirmButtonStyles();

  return (
    <Dialog open={open} classes={warnDialogStyles()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{subTitle}</DialogContent>
      <DialogActions>
        {handleCancel && (
          <Button classes={cancelButtonStyle} onClick={handleCancel}>
            取消
          </Button>
        )}
        {handleConfirm && (
          <Button
            classes={confirmButtonStyle}
            onClick={() => {
              setLoading(true);
              handleConfirm().finally(() => {
                setLoading(false);
              });
            }}
          >
            {loading ? (
              <CircularProgress classes={circularProgressStyle} size={25} />
            ) : (
              "確定"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
