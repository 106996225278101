const baseUrl = 'https://us-central1-nani-docconvert.cloudfunctions.net';

export const API = {
  get: url => {
    return fetch(baseUrl + url)
      .then(response => response.json())
      .catch(err => console.error(err));
  },
  post: (url, params = {}) => {
    const body = JSON.stringify(params);
    return fetch(baseUrl + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
      .then(response => response.json())
      .catch(err => console.error(err));
  },
  postJson: (url, params = {}) => {
    const body = JSON.stringify(params);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
      .then(response => response.json())
      .catch(err => console.error(err));
  },
  cloudFuncGet: (url, options) => {
    return fetch(url, {
      method: 'GET',
      ...options
    })
      .then(res => res.json())
      .catch(err => console.error(`cloudFuncGet ${url} error`, err));
  },
  coursePost: (url, params, jwt) => {
    const body = JSON.stringify(params);
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': jwt
      },
      body
    })
      .then(response => response.json())
      .catch(err => console.error(err));
  },
  cloudFuncPost: (url, params = {}) => {
    const body = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body
    })
      .then(response => response.status === 200 && response.json())
      .catch(err => console.error(err));
  },
  getText: url => {
    return fetch(url)
      .then(response => response.status === 200 && response.text())
      .catch(err => console.error(err));
  },
  getJSON: url => {
    return fetch(url)
      .then(response => response.status === 200 && response.json())
      .catch(err => console.error(err));
  }
};
