import React, { useCallback } from 'react';
import { useBoolean } from 'customHooks/boolean';
import PlaybackRateControl from '../PlaybackRateControl';
import styles from './index.module.scss';
import classnames from 'classnames';

const WordsVideoControl = ({
  previousButtonClick,
  previousButtonDisabled,
  nextButtonClick,
  nextButtonDisabled,
  repeatButtonClick,
  repeatDisabled,
  playbackRate,
  onChangePlaybackRate,
  onPlayAllWordsClick
}) => {

  const [isPlaying, { toggle }] = useBoolean(false);

  const onPlayAllWordsClickHandler = useCallback(e => {
    onPlayAllWordsClick(!isPlaying);
    toggle();
  }, [onPlayAllWordsClick, isPlaying, toggle]);

  return (
    <>
      <div className={styles.buttonGroup}>
        <button
          className={classnames({ [styles.active]: isPlaying })}
          onClick={onPlayAllWordsClickHandler}
        >
          生字連播
        </button>
        <button onClick={previousButtonClick} disabled={previousButtonDisabled}>
          上一筆
        </button>
        <button onClick={nextButtonClick} disabled={nextButtonDisabled}>
          下一筆
        </button>
        <button className={styles.singleRepeat} onClick={repeatButtonClick} disabled={repeatDisabled}>
          單筆重複
        </button>
        <PlaybackRateControl
          onChange={onChangePlaybackRate}
          value={playbackRate}
        />
      </div>
    </>
  );
};

export default WordsVideoControl;
