import React, { useCallback } from 'react';
import useSetState from 'customHooks/setState';
import { useBoolean } from 'customHooks/boolean';
import Button from '@material-ui/core/Button';
import DialogModule from 'components/Dialogs';
import Textarea from 'components/common/Textarea';
import TimePicker from 'components/common/TimePicker';
// import Input from 'components/common/Input';
import Radio from 'components/common/Radio';
import Icon from 'components/Icon';
import { short_uuid } from 'util/uuid';
import { convertArrayToMap } from 'util/array';
import { questionTypes } from 'constants/questionTypes';
import classnames from 'classnames';
import styles from './index.module.scss';


const QUESTION_TYPE = [
  { label: '選擇題', value: questionTypes.Choice },
  { label: '是非題', value: questionTypes.TFTest },
  { label: '問答題', value: questionTypes.Assay }
];

const defaultData = {
  Choice: { questionType: questionTypes.Choice, time: 0, value: 0, title: "", options: ['', '', '', ''] },
  TFTest: { questionType: questionTypes.TFTest, time: 0, value: true, title: "", options: [] },
  Assay: { questionType: questionTypes.Assay, time: 0, value: '', title: "", options: [] }
};

const QuestionCommonComponent = ({
  time,
  title,
  onRemoveClick,
  onInputChange
}) => (
    <>
      <div className={styles.deleteIcon} onClick={onRemoveClick} >
        <Icon type="text" name="trashAlt" />
      </div>
      <div className={styles.option}>
        <span className={styles.label}>時間</span>
        <TimePicker value={time} onChange={val => onInputChange('time', val)} />
      </div>
      <div className={styles.option}>
        <Textarea
          placeholder="請輸入問題"
          value={title}
          onChange={(e) => onInputChange('title', e.target.value)}
        />
      </div>
    </>
  )

const Choice = ({
  id,
  value,
  options,
  onInputChange
}) => {
  const optionsChangeHandler = (index, value) => {
    let temp = options.concat();
    temp[index] = value;
    onInputChange('options', temp)
  };

  return (
    <>
      {
        options.map((item, index) => (
          <div className={styles.option} key={`${id}_${index}`}>
            <Radio
              name={id}
              value={index.toString()}
              currentValue={value.toString()}
              onChange={e => onInputChange('value', e.target.value)}
            />
            <Textarea
              placeholder={item.label}
              value={item}
              onChange={e => optionsChangeHandler(index, e.target.value)}
            />
          </div>
        ))
      }
    </>
  );
}


const TFTest = ({
  id,
  value,
  onInputChange
}) => {
  const template = [
    { label: 'circle', value: true },
    { label: 'times', value: false }
  ];

  return (
    <div className={styles.TFTest}>
      {
        template.map((item, index) => (
          <div className={styles.option} key={`${id}_${index}`}>
            <Radio
              className={styles.radio}
              name={id}
              value={item.value.toString()}
              currentValue={value.toString()}
              onChange={e => onInputChange('value', e.target.value)}
              label={<div className={styles.icon}><Icon type="text" name={item.label} /></div>}
            />
          </div>
        ))
      }
    </div>
  );
}


const Assay = ({
  value,
  onInputChange
}) => (
    <div className={styles.option}>
      <Textarea placeholder="請輸入回答" value={value} onChange={e => onInputChange('value', e.target.value)} />
    </div>
  );


const QuestionSettingForm = ({ questionList = [], onSubmit }) => {
  const [isActive, { setFalse: setInactive, setTrue: setActive }] = useBoolean();
  const [{
    questions,
    questionTypeValue
  }, setState] = useSetState({
    isOpenDialog: false,
    questions: convertArrayToMap(questionList, 'id')
  });

  const questionTypeChangeHandler = useCallback(typeValue => {
    setState({ questionTypeValue: typeValue })
  }, [setState])

  const addNewQuestionHandler = useCallback(type => {
    if (!type) return;
    const uuid = short_uuid();
    const temp = { ...questions, [uuid]: { ...defaultData[type], id: uuid } };
    setState({ questions: temp })
  }, [questions, setState]);

  const questionListRemoveHandler = useCallback(id => {
    const temp = { ...questions };
    delete temp[id];
    setState({ questions: temp })
  }, [questions, setState])

  const inputChangeHandler = useCallback((id, key, value) => {
    const temp = { ...questions, [id]: { ...questions[id], [key]: value } };
    setState({ questions: temp });
  }, [questions, setState])


  const DialogContent = (
    <div className={styles.dialogContent}>
      <div className={styles.dialogContentHeader}>
        <div className={styles.questionSelection}>
          選擇題型：
        <select onChange={e => questionTypeChangeHandler(e.target.value)}>
            <option value="">請選擇</option>
            {
              QUESTION_TYPE.map(item => (
                <option key={item.value} value={item.value}>{item.label}</option>
              ))
            }
          </select>
        </div>
        <div className={styles.tools}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => addNewQuestionHandler(questionTypeValue)}
          >
            新增
        </Button>
        </div>
      </div>
      <div className={styles.dialogContentBody}>
        {
          Object.values(questions).sort((a, b) => a.time - b.time).map(item => (
            <div className={classnames(styles.questionSection)} key={item.id}>
              <QuestionCommonComponent
                time={item.time}
                title={item.title}
                onRemoveClick={() => questionListRemoveHandler(item.id)}
                onInputChange={(key, val) => inputChangeHandler(item.id, key, val)}
              />
              {item.questionType === 'Choice' && <Choice {...item}
                onInputChange={(key, val) => inputChangeHandler(item.id, key, val)}
              />}
              {item.questionType === 'TFTest' && <TFTest {...item}
                onInputChange={(key, val) => inputChangeHandler(item.id, key, val)}
              />}
              {item.questionType === 'Assay' && <Assay {...item}
                onInputChange={(key, val) => inputChangeHandler(item.id, key, val)}
              />}
            </div>
          ))
        }
      </div>
    </div>
  );


  const submitHandler = useCallback(() => {
    setInactive();
    onSubmit(Object.values(questions));
  }, [onSubmit, questions, setInactive])


  const DialogActionComponent = (
    <div className={styles.dialogActionComponent}>
      <Button size="small" variant="contained" color="primary" onClick={() => submitHandler()}>確定</Button>
      <Button size="small" variant="contained" color="default" onClick={() => setInactive()}>取消</Button>
    </div >
  )

  return (
    <div className={styles.questionSettingForm}>
      <DialogModule
        open={isActive}
        setOpen={setInactive}
        content={DialogContent}
        action={DialogActionComponent}
        maxWidth="xl"
      />
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        onClick={() => setActive()}
      >
        新增問題
      </Button>
    </div>
  )
};


export default QuestionSettingForm;
