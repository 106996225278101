import React from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from '../../context';
import { SideToolContent } from 'constants/sideToolContents';

import { CanvasObjectPropertyViewer } from 'components/SideToolContents/CanvasObjectPropertyViewer';
import { ReaderOptionPanel } from 'components/SideToolContents/ReaderOptionPanel';
import { StampPanel } from "components/SideToolContents/StampPanel";

const SideToolContainer = () => {
    const reducers = useStore();
    const [{ sideToolContent }] = reducers[StoreTypes.sideTool];
    return (
        <div
            className={styles.sideToolContainer}
        >
            {sideToolContent === SideToolContent.ReaderOptionPanel && <ReaderOptionPanel />}
            {sideToolContent === SideToolContent.CanvasObjectPropertyViewer && <CanvasObjectPropertyViewer/>}
            {sideToolContent === SideToolContent.StampPanel && <StampPanel />}
        </div>
    );
};

export default SideToolContainer;
