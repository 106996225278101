import { useCallback } from 'react';
import useModal from 'components/common/Modal/useModal';
import { ModalComponentGenerator, ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { PainterMode } from 'constants/painterModes';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';

export const useWhiteboardCommand = () => {
    const [, { openModal, setModalComponents }] = useModal();

    const changePainterMode = ({ event, painterMode, painterToolType }) => {
        EventBus.emit({ event, payload: { painterMode, painterToolType } });
    };

    const setReaderToolType = type => {
        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: type
            }
        });
    }

    const execute = useCallback(async ({ canvasSVGObject }) => {
        try {
            gaEventReaderToolsClick({
                category: CategoryType.ReaderTool,
                action: ReaderToolType.Whiteboard,
                label: ReaderToolType.Whiteboard
              });
            
            const component = ModalComponentGenerator.generate(ModalComponentType.Whiteboard, { canvasSVGObject });
            setModalComponents(component);

            changePainterMode({
                event: ReaderToolsEvent.ClickSelectEvent,
                painterMode: PainterMode.Selection
            });
            setReaderToolType(ReaderToolType.Select);

            openModal();
        } catch (e) {
            console.error('WhiteboardCommand execute error', e);
        }
    }, [openModal, setModalComponents]);

    return { execute };
};
